import React, { useEffect, useMemo, useState } from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { AngledArrrow } from 'src/v2/icons/angled-arrow'
import LeakedEmployeeCredChart from './leaked-employee-chart'
import { ReportTypesOfImpersonationChart } from './types-of-impersonation-chart'
import { useNavigate } from 'react-router-dom'
import { getCyberScanVendorDetails } from 'src/services/cyberScanService'
import { hasValue } from 'src/utils/commonFns'

export const DarkWebMonitoring = ({ insecureWebScanId }) => {
  let navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [darkWebStats, setDarkWebStats] = useState({})

  useEffect(() => {
    const fetchDarkWebStats = async () => {
      try {
        setLoading(true)
        const data = await getCyberScanVendorDetails(insecureWebScanId)
        setDarkWebStats(data)
      } finally {
        setLoading(false)
      }
    }
    if (hasValue(insecureWebScanId)) fetchDarkWebStats()
  }, [insecureWebScanId])

  const typesOfImpersonations = useMemo(() => {
    const data = darkWebStats?.cyberScanCompanyImpersonationThreatCounts?.threatCounts
    const colorCodes = [
      '#FFC300',
      '#FF5733',
      '#C70039',
      '#900C3F',
      '#ff8a33',
      '#ff33d4',
      '#da33ff',
      '#33f3ff',
      '#5abeb9',
      '#7f947e',
      '#1a8a17',
      '#3c9c7b',
      '#bcc5c2',
      '#812789',
      '#988b99',
      '#d114e1',
    ]
    if (data) {
      return Object.keys(data)
        .slice(0, 16)
        .map((eachKey, index) => {
          return { name: eachKey, value: data[eachKey], color: colorCodes[index] }
        })
    } else {
      return []
    }
  }, [darkWebStats?.cyberScanCompanyImpersonationThreatCounts?.threatCounts])

  return (
    <div className={styles['dark-web-score']}>
      <div className={styles.container}>
        <div className={styles['section-wrapper']}>
          <div className={styles.heading}>
            <div className={styles['text-wrapper-8']}>Leaked employee credentials</div>
            {insecureWebScanId && (
              <div
                className={`${'menu-links'} ${styles.button}`}
                onClick={() => {
                  navigate(`/cyberscanning/${insecureWebScanId}`)
                }}
              >
                <div className={styles.text}>View details</div>
                <IconPlaceholder
                  icon={<AngledArrrow className={styles['icons-2']} />}
                  size="twenty-x-20"
                />
              </div>
            )}
          </div>
          <div className={styles.section}>
            <LeakedEmployeeCredChart
              chartSeries={[
                darkWebStats?.cyberScanDarkWebBreachCounts?.domainBreachCount || 0,
                darkWebStats?.cyberScanDarkWebBreachCounts?.emailBreachCount || 0,
                darkWebStats?.cyberScanDarkWebBreachCounts?.ipBreachCount || 0,
                darkWebStats?.cyberScanDarkWebBreachCounts?.userNameBreachCount || 0,
                darkWebStats?.cyberScanDarkWebBreachCounts?.phoneBreachCount || 0,
              ]}
              loading={loading}
            />
            <div className={styles.value}>
              <div className={styles['metric-card']}>
                <div className={styles.content}>
                  <div className={styles['text-wrapper-2']}>Breach element type</div>
                </div>
              </div>
              <div className={styles.metrics}>
                <div className={styles['sub-section']}>
                  <div className={styles.domain}>
                    <div className={styles.high}>
                      <div className={styles['div-2']}>
                        <div className={styles['ellipse-2']} />
                        <div className={styles['text-wrapper-3']}>Domain -</div>
                      </div>
                    </div>
                    <div className={styles.score}>
                      <div className={styles['text-wrapper-4']}>
                        {darkWebStats?.cyberScanDarkWebBreachCounts?.domainBreachCount}
                      </div>
                      <div className={styles['text-wrapper-5']}>
                        {parseInt(
                          (darkWebStats?.cyberScanDarkWebBreachCounts?.domainBreachCount /
                            darkWebStats?.cyberScanDarkWebBreachCounts?.totalDarkWebBreachCount) *
                            100,
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <div className={styles['divider-3']} />
                  <div className={styles.domain}>
                    <div className={styles.high}>
                      <div className={styles['div-2']}>
                        <div className={styles['ellipse-3']} />
                        <div className={styles['text-wrapper-6']}>P.Email -</div>
                      </div>
                    </div>
                    <div className={styles.score}>
                      <div className={styles['text-wrapper-7']}>
                        {darkWebStats?.cyberScanDarkWebBreachCounts?.emailBreachCount}
                      </div>
                      <div className={styles['text-wrapper-5']}>
                        {' '}
                        {parseInt(
                          (darkWebStats?.cyberScanDarkWebBreachCounts?.emailBreachCount /
                            darkWebStats?.cyberScanDarkWebBreachCounts?.totalDarkWebBreachCount) *
                            100,
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <div className={styles['divider-3']} />
                  <div className={styles.domain}>
                    <div className={styles.high}>
                      <div className={styles['div-2']}>
                        <div className={styles['ellipse-4']} />
                        <div className={styles['text-wrapper-3']}>IP Address -</div>
                      </div>
                    </div>
                    <div className={styles.score}>
                      <div className={styles['text-wrapper-7']}>
                        {darkWebStats?.cyberScanDarkWebBreachCounts?.ipBreachCount}
                      </div>
                      <div className={styles['text-wrapper-5']}>
                        {parseInt(
                          (darkWebStats?.cyberScanDarkWebBreachCounts?.ipBreachCount /
                            darkWebStats?.cyberScanDarkWebBreachCounts?.totalDarkWebBreachCount) *
                            100,
                        )}
                        %
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles['sub-section-2']}>
                  <div className={styles.domain}>
                    <div className={styles.high}>
                      <div className={styles['div-2']}>
                        <div className={styles['ellipse-5']} />
                        <div className={styles['text-wrapper-3']}>Username -</div>
                      </div>
                    </div>
                    <div className={styles.score}>
                      <div className={styles['text-wrapper-7']}>
                        {darkWebStats?.cyberScanDarkWebBreachCounts?.userNameBreachCount}
                      </div>
                      <div className={styles['text-wrapper-5']}>
                        {parseInt(
                          (darkWebStats?.cyberScanDarkWebBreachCounts?.userNameBreachCount /
                            darkWebStats?.cyberScanDarkWebBreachCounts?.totalDarkWebBreachCount) *
                            100,
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <div className={styles['divider-3']} />
                  <div className={styles.domain}>
                    <div className={styles.high}>
                      <div className={styles['div-2']}>
                        <div className={styles['ellipse-6']} />
                        <div className={styles['text-wrapper-3']}>Phone -</div>
                      </div>
                    </div>
                    <div className={styles.score}>
                      <div className={styles['text-wrapper-7']}>
                        {darkWebStats?.cyberScanDarkWebBreachCounts?.phoneBreachCount}
                      </div>
                      <div className={styles['text-wrapper-5']}>
                        {parseInt(
                          (darkWebStats?.cyberScanDarkWebBreachCounts?.phoneBreachCount /
                            darkWebStats?.cyberScanDarkWebBreachCounts?.totalDarkWebBreachCount) *
                            100,
                        )}
                        %
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['divider-4']} />
        <div className={styles['div-wrapper']}>
          <div className={styles.heading}>
            <div className={styles['text-wrapper-8']}>Look-alike website</div>
            {insecureWebScanId && (
              <div
                className={`${'menu-links'} ${styles.button}`}
                onClick={() => {
                  navigate(`/cyberscanning/${insecureWebScanId}`)
                }}
              >
                <div className={styles.text}>View details</div>
                <IconPlaceholder
                  icon={<AngledArrrow className={styles['icons-2']} />}
                  size="twenty-x-20"
                />
              </div>
            )}
          </div>
          <div className={styles.data}>
            <>
              <div className={styles['look-alike-website']}>
                <div className={styles.table}>
                  <div className={styles.col}>
                    {typesOfImpersonations?.map((eachCategory, index) => {
                      if (index % 2 === 0)
                        return (
                          <div key={index} className={styles['cell-2']}>
                            <div className={styles['domain-2']}>
                              <div className={styles['value-wrapper']}>
                                <div className={styles['value-3']}>
                                  <div
                                    className={styles['ellipse-7']}
                                    style={{ backgroundColor: eachCategory.color }}
                                  />
                                  <div className={styles['text-wrapper-9']}>
                                    {eachCategory.name}
                                  </div>
                                </div>
                              </div>
                              <div className={styles['score-2']}>
                                <div className={styles['text-wrapper-10']}>
                                  {eachCategory.value}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      else return ''
                    })}
                  </div>
                  <div className={styles.col}>
                    {typesOfImpersonations?.map((eachCategory, index) => {
                      if (index % 2 !== 0)
                        return (
                          <div key={index} className={styles['cell-2']}>
                            <div className={styles['domain-2']}>
                              <div className={styles['value-wrapper']}>
                                <div className={styles['value-3']}>
                                  <div
                                    className={styles['ellipse-7']}
                                    style={{ backgroundColor: eachCategory.color }}
                                  />
                                  <div className={styles['text-wrapper-9']}>
                                    {eachCategory.name}
                                  </div>
                                </div>
                              </div>
                              <div className={styles['score-2']}>
                                <div className={styles['text-wrapper-10']}>
                                  {eachCategory.value}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      else return ''
                    })}
                  </div>
                </div>
              </div>
              <div className={styles['basic-bar-chart']}>
                <ReportTypesOfImpersonationChart
                  typesOfImpersonations={typesOfImpersonations}
                  loading={loading}
                />
                <div className={styles['div-wrapper-2']}>
                  <div className={styles['text-wrapper-15']}>Types of Impersonation</div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import styles from './style.module.css'
import {
  Box,
  InputAdornment,
  InputBase,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { CrossMark } from 'src/v2/icons/cross-mark'
import { getComparator, stableSort } from 'src/utils/commonFns'
import { SearchIcon } from 'src/v2/icons/search-icon'
import classes from './style.module.scss'
import { visuallyHidden } from '@mui/utils'
import BeaconerLogo from 'src/v2/assets/images/beaconer-logo.svg'

export const SearchVendorList = ({ open, handleAction, vendorInfo }) => {
  const [searchText, setSearchText] = useState('')
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('assessmentNumber')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [vendorList, setVendorList] = useState(vendorInfo)

  const headCells = [
    {
      name: 'VENDOR',
      disableSort: true,
    },
    {
      name: 'WEBSITE',
      disableSort: true,
    },
    // {
    //   name: 'LINKED IN URL',
    //   disableSort: true,
    // },
    {
      name: 'DESCRIPTION',
      disableSort: true,
    },
  ]

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const visibleRows = React.useMemo(
    () =>
      stableSort(vendorList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [vendorList, order, orderBy, page, rowsPerPage],
  )

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Modal open={open} className={styles.modal}>
      <div className={styles.modal}>
        <div className={styles.title}>
          <div className={styles.vendor}>
            <div className={styles['text-wrapper']}>Vendor List</div>
          </div>
          <IconPlaceholder
            icon={<CrossMark className={styles['icon-instance-node']} />}
            size="twenty-four-x-24"
            onClick={() => {
              handleAction(false)
            }}
          />
        </div>
        <div className={styles.frame}>
          <div className={styles.searchframe}>
            <div className={styles['search-filter']}>
              <InputBase
                sx={{ height: 36 }}
                className={`${styles['search-bar']}`}
                startAdornment={
                  <InputAdornment position="start">
                    <IconPlaceholder
                      icon={<SearchIcon className={styles['icons-10']} />}
                      size="twenty-four-x-24"
                    />
                  </InputAdornment>
                }
                placeholder="Search website"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value)
                  if (e.target.value) {
                    setVendorList(
                      vendorList.filter((x) =>
                        x.website.toLowerCase().includes(e.target.value.toLowerCase()),
                      ),
                    )
                  } else {
                    setVendorList(vendorList)
                  }
                }}
              ></InputBase>
            </div>
          </div>
          <div className={classes['vendor-list']}>
            <Box className={classes.content}>
              <TableContainer className={classes.table}>
                <Table size={'medium'}>
                  <TableHead className={classes.tablehead}>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          className={classes.tableheadcell}
                          key={headCell.name}
                          sortDirection={orderBy === headCell.name ? order : false}
                        >
                          {!headCell.disableSort ? (
                            <TableSortLabel
                              active={orderBy === headCell.name}
                              direction={orderBy === headCell.name ? order : 'asc'}
                              onClick={() => handleRequestSort(headCell.name)}
                              className={classes.tablesortlabel}
                            >
                              {headCell.name}
                              {orderBy === headCell.name ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            <>{headCell.name}</>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {/* <div className={classes.tablebody}> */}
                  <TableBody className={classes.tablebody}>
                    {visibleRows &&
                      visibleRows.map((row, index) => (
                        <TableRow
                          key={index}
                          className={`${'menu-links'} ${classes['vendor-row']}`}
                          onClick={() => {
                            handleAction(true, row)
                          }}
                        >
                          <TableCell className={`${classes.tablecell}`}>
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              {row.logoUrl && (
                                <img className={classes['img-3']} alt="Avatar" src={row.logoUrl} />
                              )}
                              {!row.logoUrl && (
                                <img className={classes['img-3']} alt="Avatar" src={BeaconerLogo} />
                              )}
                              {row.name}
                            </div>
                          </TableCell>
                          <TableCell className={classes.tablecell}>{row.website}</TableCell>
                          {/* <TableCell className={classes.tablecell}>{row.linkedinUrl}</TableCell> */}
                          <TableCell className={classes.tablecell}>{row.description}</TableCell>
                        </TableRow>
                      ))}
                    {!visibleRows ||
                      (visibleRows.length <= 0 && (
                        <TableRow>
                          <TableCell
                            colSpan={3}
                            className={classes.tablecell}
                            style={{ textAlign: 'center' }}
                          >
                            <h6 className={styles.norecords}>No Records to Display</h6>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  {/* </div> */}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={vendorList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className={classes.tablepagination}
                classes={{
                  selectLabel: classes.tablePaginationSelectLabel,
                  displayedRows: classes.tablePaginationDisplayedRows,
                  selectIcon: classes.tablePaginationSelectIcon,
                  actions: classes.tablePaginationActions,
                }}
              />
            </Box>
          </div>
        </div>
      </div>
    </Modal>
  )
}

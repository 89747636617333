import PropTypes from 'prop-types'
import React from 'react'
import { Icons3 } from '../../icons/Icons3'
import styles from './style.module.css'
import OutlinedInput from '@mui/material/OutlinedInput'
import { Icons6 } from '../../icons/Icons6'
import InputAdornment from '@mui/material/InputAdornment'

export const SearchBar = ({
  className,
  iconPlaceholderIcon = <Icons3 className={styles['icons-3']} />,
  placeholder = 'Search',
  searchQuery,
  setSearchQuery,
}) => {
  return (
    <OutlinedInput
      className={`${styles['search-bar']} ${className}`}
      id="outlined-adornment-weight"
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <Icons6 />
        </InputAdornment>
      }
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  )
}

SearchBar.propTypes = {
  text: PropTypes.string,
}

import React from 'react'
import styles from './style.module.css'
import { Modal } from '@mui/material'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { CrossMark } from 'src/v2/icons/cross-mark'
// import { RedArrowDown } from '../icons/red-arrow-down'
// import { GreenArrowDown } from '../icons/green-arrow-down'
import { hasValue } from 'src/utils/commonFns'
import Terminal from 'src/v2/components/Terminal/Terminal'
import moment from 'moment'
export const EmployeeExposedCredentialDetails = ({ open, handleAction, breachInfo }) => {
  const getCurlCommand = () => {
    return `$ curl /api/darkweb/${breachInfo.breachId}
HTTP response code : 200
{
  "domain": "${breachInfo.domain}",
  "email": "${breachInfo.email}",
  "ipAddress": "${breachInfo.ipAddress}",
  "username": "${breachInfo.username}",
  "password": "${breachInfo.password}",
  "hashedPassword": "${breachInfo.hashedPassword}",
  "name": "${breachInfo.name}",
  "phone": "${breachInfo.phone}",
  "databaseName": "${breachInfo.databaseName}",
  "foundIn": "${breachInfo.foundIn}",
  "source": "${breachInfo.source}",
  "leakName": "${breachInfo.LeakName}",
  "compromisedData": "${breachInfo.compromisedData}",
  "victimDomain": "${breachInfo.victimDomain}",
  "distributionType": "${breachInfo.distributionType}"
}`
  }

  return (
    <Modal open={open}>
      <div className={styles.modal}>
        <div className={styles['title-wrapper']}>
          <div className={styles['text-wrapper-9']}>Breach {breachInfo.breachId}</div>
          <IconPlaceholder
            icon={<CrossMark className={styles['icons-2']} />}
            size="twenty-four-x-24"
            onClick={() => {
              handleAction(false)
            }}
          />
        </div>
        <div className={styles['div-wrapper']}>
          <div className={styles.image}>
            {hasValue(breachInfo.imageSignedUrl) ? (
              <img className={styles.img} alt="Screenshot" src={breachInfo.imageSignedUrl} />
            ) : (
              <>
                <Terminal command={getCurlCommand()} />
              </>
            )}
          </div>
          <div className={styles.details}>
            <div className={styles.content}>
              <div className={styles['title-2']}>
                <div className={styles['title-3']}>
                  <div className={styles['title-4']}>
                    <div className={styles['text-wrapper-7']}>Description</div>
                  </div>
                </div>
              </div>
              <div className={styles.description}>
                <p className={styles.p}>{breachInfo.breachDescription}</p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles['level-3']}>
                <div className={styles['text-wrapper-8']}>Compromised Data</div>
              </div>
              <div className={styles['answer-3']}>
                {breachInfo.compromisedData?.split(',').map((compromisedData, index) => (
                  <div className={styles['compromised-data-wrapper']} key={index}>
                    <div className={styles['compromised-element']}>{compromisedData?.trim()}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles['content-wrapper']}>
              <div className={styles.container}>
                <div className={styles.section}>
                  <div className={styles.div}>
                    <div className={styles.level}>
                      <div className={styles['text-wrapper-2']}>ID</div>
                    </div>
                    <div className={styles.answer}>
                      <div className={styles['text-wrapper-3']}>{breachInfo.breachId}</div>
                    </div>
                  </div>
                  <div className={styles.div}>
                    <div className={styles.level}>
                      <div className={styles['text-wrapper-2']}>Domain</div>
                    </div>
                    <div className={styles.answer}>
                      <div className={styles['text-wrapper-3']}>{breachInfo.domain}</div>
                    </div>
                  </div>
                  <div className={styles.div}>
                    <div className={styles.level}>
                      <div className={styles['text-wrapper-2']}>Username</div>
                    </div>
                    <div className={styles.answer}>
                      <div className={styles['text-wrapper-3']}>{breachInfo.username}</div>
                    </div>
                  </div>
                  <div className={styles.div}>
                    <div className={styles.level}>
                      <div className={styles['text-wrapper-2']}>Name</div>
                    </div>
                    <div className={styles.answer}>
                      <div className={styles['text-wrapper-3']}>{breachInfo.name}</div>
                    </div>
                  </div>
                  <div className={styles.div}>
                    <div className={styles.level}>
                      <div className={styles['text-wrapper-2']}>Database Name</div>
                    </div>
                    <div className={styles.answer}>
                      <div className={styles['text-wrapper-3']}>{breachInfo.databaseName}</div>
                    </div>
                  </div>
                  <div className={styles.div}>
                    <div className={styles.level}>
                      <div className={styles['text-wrapper-2']}>Source</div>
                    </div>
                    <div className={styles.answer}>
                      <div className={styles['text-wrapper-3']}>{breachInfo.source}</div>
                    </div>
                  </div>
                  <div className={styles.div}>
                    <div className={styles.level}>
                      <div className={styles['text-wrapper-2']}>Distribution Type</div>
                    </div>
                    <div className={styles.answer}>
                      <div className={styles['text-wrapper-3']}>{breachInfo.distributionType}</div>
                    </div>
                  </div>
                </div>
                <div className={styles['section-wrapper']}>
                  <div className={styles['item-2']}>
                    <div className={styles['level-2']}>
                      <div className={styles['text-wrapper-4']}>Last seen</div>
                    </div>
                    <div className={styles['answer-2']}>
                      <div className={styles['text-wrapper-5']}>
                        {moment(breachInfo.timestamp).format('MM/DD/yy')}
                      </div>
                    </div>
                  </div>
                  <div className={styles['item-2']}>
                    <div className={styles['level-2']}>
                      <div className={styles['text-wrapper-4']}>Email</div>
                    </div>
                    <div className={styles['answer-2']}>
                      <div className={styles['text-wrapper-5']}>{breachInfo.email}</div>
                    </div>
                  </div>
                  <div className={styles['item-2']}>
                    <div className={styles['level-2']}>
                      <div className={styles['text-wrapper-4']}>Password/Hash</div>
                    </div>
                    <div className={styles['answer-2']}>
                      <div className={styles['text-wrapper-5']}>{breachInfo.hashedPassword}</div>
                    </div>
                  </div>
                  <div className={styles['item-2']}>
                    <div className={styles['level-2']}>
                      <div className={styles['text-wrapper-4']}>Leak Name</div>
                    </div>
                    <div className={styles['answer-2']}>
                      <div className={styles['text-wrapper-5']}>{breachInfo.leakName}</div>
                    </div>
                  </div>
                  <div className={styles['item-3']}>
                    <div className={styles['level-2']}>
                      <div className={styles['text-wrapper-4']}>Found In</div>
                    </div>
                    <div className={styles['answer-2']}>
                      <div className={styles['text-wrapper-5']}>{breachInfo.foundIn}</div>
                    </div>
                  </div>
                  <div className={styles['item-2']}>
                    <div className={styles['level-2']}>
                      <div className={styles['text-wrapper-4']}>Victim Domain</div>
                    </div>
                    <div className={styles['answer-2']}>
                      <div className={styles['text-wrapper-5']}>{breachInfo.victimDomain}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

import React from 'react'
import classes from './style.module.css'
import { Box } from '@mui/material'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'

// const mockData = [
//   {
//     assessmentNumber: 'BTHJ0001805',
//     vendorName: 'Slack',
//     residualRisk: 'High',
//     department: 'Data Privacy',
//     completedOn: '2023/04/16',
//     initiatedOn: '2023/04/06',
//   },
//   {
//     assessmentNumber: 'BTHJ0001808',
//     vendorName: 'Microsoft Teams',
//     residualRisk: 'High',
//     department: 'Sales',
//     completedOn: '2023/04/19',
//     initiatedOn: '2023/04/11',
//   },
//   {
//     assessmentNumber: 'BTHJ0001811',
//     vendorName: 'Asana',
//     residualRisk: 'High',
//     department: 'Engineering',
//     completedOn: '2023/04/22',
//     initiatedOn: '2023/04/13',
//   },
//   {
//     assessmentNumber: 'BTHJ0001814',
//     vendorName: 'Zeon',
//     residualRisk: 'High',
//     department: 'Security',
//     completedOn: '2023/04/25',
//     initiatedOn: '2023/04/16',
//   },
//   {
//     assessmentNumber: 'BTHJ0001817',
//     vendorName: 'Marketo',
//     residualRisk: 'High',
//     department: 'Sales',
//     completedOn: '2023/04/28',
//     initiatedOn: '2023/04/19',
//   },
//   {
//     assessmentNumber: 'BTHJ0001820',
//     vendorName: 'Constant Contact',
//     residualRisk: 'High',
//     department: 'Sales',
//     completedOn: '2023/05/01',
//     initiatedOn: '2023/04/22',
//   },
//   {
//     assessmentNumber: 'BTHJ0001823',
//     vendorName: 'Sailthru',
//     residualRisk: 'High',
//     department: 'Marketing',
//     completedOn: '2023/05/04',
//     initiatedOn: '2023/04/22',
//   },
//   {
//     assessmentNumber: 'BTHJ0001832',
//     vendorName: 'Delta Labs',
//     residualRisk: 'High',
//     department: 'Marketing',
//     completedOn: '2023/05/04',
//     initiatedOn: '2023/04/22',
//   },
//   {
//     assessmentNumber: 'BTHJ0001833',
//     vendorName: 'Zenco',
//     residualRisk: 'High',
//     department: 'Marketing',
//     completedOn: '2023/05/04',
//     initiatedOn: '2023/04/22',
//   },
//   {
//     assessmentNumber: 'BTHJ0001835',
//     vendorName: 'Genesis',
//     residualRisk: 'High',
//     department: 'Finance',
//     completedOn: '2023/05/04',
//     initiatedOn: '2023/04/22',
//   },
// ]

export const HighRiskVendors = ({ highRiskVendors }) => {
  return (
    <div className={`${classes['high-risk-vendors']} high-risk-vendor-section`}>
      <div className={classes.section}>
        <div className={classes.title}>
          <div className={classes['title-data']}>
            Top 10 <span className={classes['highlight']}>High Risk</span> Vendors
          </div>
        </div>
        <p className={classes['order-info']}>Based on Residual Risk</p>
      </div>
      <Box className={classes.content}>
        {!!highRiskVendors &&
          highRiskVendors.length > 0 &&
          (highRiskVendors ?? []).map((row, index) => (
            <div
              className={`${classes.div} ${index !== 9 ? classes['div-border'] : ''}`}
              key={index}
            >
              <div className={classes['content-6']}>
                <div className={classes['title-6']}>
                  {row.logoUrl && (
                    <img className={classes['company-logo-4']} alt="Avatar" src={row.logoUrl} />
                  )}
                  <div className={classes['TITLE-ICON-5']}>
                    <div className={classes['figma-4']}>{row.thirdPartyName}</div>
                  </div>
                </div>
                <div className={classes['section-4']}>
                  <div className={classes['frame-5']}>
                    <div className={classes['level-5']}>
                      <div className={classes['text-wrapper-11']}>{row.clientSubmissionNo}</div>
                    </div>
                  </div>
                </div>
              </div>
              <ArrowForwardOutlinedIcon className={classes['icon-placeholder-4']} />
            </div>
          ))}
      </Box>
    </div>
  )
}

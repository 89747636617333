/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import styles from './Header.module.css'
import SecurityIncidentsIcon from '../../icons/SecIncidentsIcon.svg'
import { useAuthState } from 'src/context/login'

const Header = ({ className = '' }) => {
  const { user } = useAuthState()

  return (
    <div className={[styles.header, className].join(' ')}>
      <div className={styles.titleParent}>
        <h2 className={styles.title}>
          Hello,{' '}
          {`${user?.firstName?.charAt(0)?.toUpperCase() + user?.firstName?.slice(1)} ${
            user?.lastName?.charAt(0)?.toUpperCase() + user?.lastName?.slice(1)
          }`}
          !
        </h2>
        <div className={styles.title1}>
          Here are your operational insights and take action on priority items.
        </div>
      </div>
      <div className={styles.headerInner}>
        <div className={styles.frameParent}>
          {/* <div className={styles.iconParent}>
            <div className={styles.icon}>
              <div className={styles.iconPlaceholder}>
                <img className={styles.icons} loading="lazy" alt="" src={RefreshIcon} />
              </div>
            </div>
            <div className={styles.lastUpdatedOn}>Last Updated On: 18 Jun 2024, 13:20</div>
          </div> */}
          {/* <div className={styles.rectangleWrapper}>
            <div className={styles.frameChild} />
          </div> */}
          <div className={styles.icon1}>
            <div className={styles.iconPlaceholder1}>
              <img className={styles.icons1} loading="lazy" alt="" src={SecurityIncidentsIcon} />
            </div>
            <div className={styles.securityLabel}>
              <a className={styles.securityIncidents}>Security Incidents</a>
            </div>
            <div className={styles.iconChild} />
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header

import React from 'react'

export const AssestManagement = ({ className }) => {
  return (
    <svg
      className={`icons-10 ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="28"
        id="mask0_1248_3137"
        maskUnits="userSpaceOnUse"
        width="28"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="28" width="28" />
      </mask>
      <g className="g" mask="url(#mask0_1248_3137)">
        <path
          className="path"
          d="M4.3458 21.8164C4.07844 21.8164 3.85123 21.7226 3.66418 21.535C3.47693 21.3475 3.3833 21.1198 3.3833 20.8519C3.3833 20.584 3.47693 20.3569 3.66418 20.1709C3.85123 19.9846 4.07844 19.8914 4.3458 19.8914H6.1833V8.05881C6.1833 7.54295 6.36695 7.10137 6.73426 6.73406C7.10156 6.36676 7.54315 6.18311 8.05901 6.18311H22.4583C22.6732 6.18311 22.8532 6.25524 22.9985 6.39952C23.1439 6.5438 23.2166 6.72249 23.2166 6.93561C23.2166 7.14891 23.1439 7.32955 22.9985 7.47752C22.8532 7.62569 22.6732 7.69977 22.4583 7.69977H8.05901C7.9542 7.69977 7.86816 7.73341 7.80088 7.80069C7.73361 7.86797 7.69997 7.95401 7.69997 8.05881V19.8914H12.7347C13.0021 19.8914 13.2293 19.9853 13.4163 20.1729C13.6034 20.3603 13.6969 20.588 13.6969 20.856C13.6969 21.1239 13.6034 21.3509 13.4163 21.537C13.2293 21.7233 13.0021 21.8164 12.7347 21.8164H4.3458ZM17.892 21.8164C17.6266 21.8164 17.4025 21.7266 17.2197 21.5469C17.0371 21.3675 16.9458 21.1449 16.9458 20.8793V10.9705C16.9458 10.7049 17.0355 10.4808 17.215 10.2982C17.3947 10.1155 17.6171 10.0241 17.8823 10.0241H23.6818C23.9471 10.0241 24.1711 10.1139 24.3538 10.2936C24.5364 10.4732 24.6277 10.6958 24.6277 10.9612V20.8703C24.6277 21.1357 24.538 21.3597 24.3585 21.5423C24.179 21.725 23.9566 21.8164 23.6912 21.8164H17.892ZM18.4625 19.8914H23.1111V11.5407H18.4625V19.8914Z"
          fill="#A2A3A4"
        />
      </g>
    </svg>
  )
}

import React from 'react'
import classes from './RemediationListItem.module.scss'
import { BadgeStatus } from './BadgeStatus'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import { ThirdPartyRisk } from 'src/v2/icons/domainSummary/third-party-risk'
import { VulnerabilityManagement } from 'src/v2/icons/domainSummary/vulnerability-management'
import { AssestManagement } from 'src/v2/icons/domainSummary/asset-management'
import { RiskManagement } from 'src/v2/icons/domainSummary/risk-management'
import { BcpAndDr } from 'src/v2/icons/domainSummary/bcp-dr'
import { NetworkSecurity } from 'src/v2/icons/domainSummary/network-security'
import { ApplicatonSecurity } from 'src/v2/icons/domainSummary/application-security'
import { IncidentManagement } from 'src/v2/icons/domainSummary/incident-management'

export const RemediationListItem = ({ DrawerType, eachGap }) => {
  const getDomainIcon = () => {
    var icon
    var domainNameLowerCase = (eachGap.domainName ?? eachGap.domain).toLowerCase()
    if (domainNameLowerCase.includes('third party')) {
      icon = <ThirdPartyRisk className={classes['icon-instance-node']} />
    } else if (domainNameLowerCase.includes('vulnerability'))
      icon = <VulnerabilityManagement className={classes['icon-instance-node']} />
    else if (domainNameLowerCase.includes('asset'))
      icon = <AssestManagement className={classes['icon-instance-node']} />
    else if (domainNameLowerCase.includes('risk'))
      icon = <RiskManagement className={classes['icon-instance-node']} />
    else if (domainNameLowerCase.includes('bcp'))
      icon = <BcpAndDr className={classes['icon-instance-node']} />
    else if (domainNameLowerCase.includes('network'))
      icon = <NetworkSecurity className={classes['icon-instance-node']} />
    else if (domainNameLowerCase.includes('application'))
      icon = <ApplicatonSecurity className={classes['icon-instance-node']} />
    else if (domainNameLowerCase.includes('incident'))
      icon = <IncidentManagement className={classes['icon-instance-node']} />
    else if (domainNameLowerCase.includes('risk') || domainNameLowerCase.includes('access'))
      icon = <RiskManagement className={classes['icon-instance-node']} />
    return icon
  }

  // eslint-disable-next-line no-unused-vars
  const icon = getDomainIcon()

  return (
    <div className={classes['list-remediation']}>
      <div className={classes['content']}>
        <div className={classes['title']}>
          <div className={classes['TITLE-ICON']}>
            <div className={classes['level']}>
              <div className={classes['div']}>
                <IconPlaceholder
                  className={classes['icon-placeholder-instance']}
                  icon={icon}
                  size="twenty-x-20"
                />
                <div className={classes['text-wrapper']}>{eachGap.domainName}</div>
              </div>
            </div>
          </div>
          <ArrowForwardOutlinedIcon className={classes['icon-placeholder-4']} />
        </div>
        <div className={classes['description']}>
          <div className={classes['title-wrapper']}>
            <div className={classes['div']}>
              <div className={classes['text-wrapper-2']}>
                {eachGap.thirdPartyName} - {eachGap.clientSubmissionNo}
              </div>
            </div>
          </div>
          <p className={classes['p']}>{eachGap.issueIdentified}</p>
          <BadgeStatus
            className={classes['badge-status-instance']}
            property1={eachGap.issueSeverity.toLowerCase()}
            textClassName={classes['badge-status-7']}
          />
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand, rotate = 'rotate(90deg)' }) => ({
  transform: !expand ? 'rotate(0deg)' : rotate,
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

import React from 'react'

export const AngledArrrow = ({ className }) => {
  return (
    <svg
      className={`icons-16 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="16"
        id="mask0_1248_3212"
        maskUnits="userSpaceOnUse"
        width="17"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="16" width="16" x="0.333496" />
      </mask>
      <g className="g" mask="url(#mask0_1248_3212)">
        <path
          className="path"
          d="M11.6669 5.369L4.38737 12.6485C4.29504 12.7408 4.17904 12.7881 4.03937 12.7902C3.89959 12.7923 3.78143 12.7451 3.68487 12.6485C3.58832 12.5519 3.54004 12.4349 3.54004 12.2973C3.54004 12.1597 3.58832 12.0426 3.68487 11.946L10.9644 4.6665H6.83354C6.69187 4.6665 6.57309 4.61856 6.47721 4.52267C6.38143 4.42678 6.33354 4.308 6.33354 4.16634C6.33354 4.02456 6.38143 3.90584 6.47721 3.81017C6.57309 3.71439 6.69187 3.6665 6.83354 3.6665H12.0642C12.235 3.6665 12.3781 3.72428 12.4935 3.83984C12.6091 3.95528 12.6669 4.09839 12.6669 4.26917V9.49984C12.6669 9.6415 12.6189 9.76028 12.523 9.85617C12.4271 9.95195 12.3084 9.99984 12.1667 9.99984C12.0249 9.99984 11.9062 9.95195 11.8105 9.85617C11.7148 9.76028 11.6669 9.6415 11.6669 9.49984V5.369Z"
          fill="#759BEB"
        />
      </g>
    </svg>
  )
}

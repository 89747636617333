import React, { useMemo } from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { InfoIcon } from 'src/v2/icons/info'
import { EABarChart } from './ea-barchart'
import { EADoughnutChart } from 'src/v2/components/ea-donought'
import { formatKValue } from 'src/utils/commonFns'

export const ExternalAttackSurface = ({ externalAttackStats, loading }) => {
  const totalFindings = useMemo(
    () =>
      externalAttackStats.criticalFindingsCount +
        externalAttackStats.highFindingsCount +
        externalAttackStats.mediumFindingsCount +
        externalAttackStats.lowFindingsCount || 0,
    [externalAttackStats],
  )

  const calcPercentage = (part) => {
    if (totalFindings === 0) return '0%'
    return `${((part / totalFindings) * 100).toFixed(2)}%`
  }

  return (
    <div className={styles['external-attack-surface']}>
      <div className={styles['section-component-node']}>
        <div className={styles['title-3']}>
          <div className={styles['title-data-3']}>External Attack surface</div>
          <IconPlaceholder
            icon={<InfoIcon className={styles['icons-6']} color="#7F8082" />}
            size="twenty-four-x-24"
          />
        </div>
      </div>
      <div className={styles['container-wrapper']}>
        <div className={styles.div}>
          <div className={styles.chart}>
            <div className={styles['overlap-2']}>
              <div className={styles['overlap-group-wrapper']}>
                <EADoughnutChart value={externalAttackStats.riskScore || 0} loading={loading} />
              </div>
              {/* <div className={styles['label-3']}>Low</div> */}
              <div className={styles['legends-2']}>
                <div className={styles['div-2']}>
                  <div className={styles['rectangle-5']} />
                  <div className={styles['text-wrapper-10']}>{`${'Low ( < 40 )'}`}</div>
                </div>
                <div className={styles['div-2']}>
                  <div className={styles['rectangle-6']} />
                  <div className={styles['text-wrapper-10']}>{`${'Medium ( 40-70 )'}`}</div>
                </div>
                <div className={styles['div-2']}>
                  <div className={styles['rectangle-7']} />
                  <div className={styles['text-wrapper-10']}>{`${'High ( > 70 )'}`}</div>
                </div>
              </div>
            </div>
          </div>
          <EABarChart
            totalFindings={totalFindings}
            calcPercentage={calcPercentage}
            externalAttackStats={externalAttackStats}
          />
          <div className={styles['div-wrapper']}>
            <div className={styles['title-2']}>
              <div className={styles['text-wrapper-15']}>Total findings {totalFindings}</div>
            </div>
            <div className={styles['table-2']}>
              <div className={styles['col-left']}>
                <div className={styles['cell']}>
                  <div className={styles['high-2']}>
                    <div className={styles['value-13']}>
                      <div className={styles['ellipse-9']} />
                      <div className={styles['text-wrapper-16']}>Critical</div>
                    </div>
                  </div>
                </div>
                <div className={styles['cell']}>
                  <div className={styles['high-2']}>
                    <div className={styles['value-13']}>
                      <div className={styles['ellipse-10']} />
                      <div className={styles['text-wrapper-16']}>High</div>
                    </div>
                  </div>
                </div>
                <div className={styles['cell']}>
                  <div className={styles['high-2']}>
                    <div className={styles['value-13']}>
                      <div className={styles['ellipse-11']} />
                      <div className={styles['text-wrapper-16']}>Medium</div>
                    </div>
                  </div>
                </div>
                <div className={styles['cell-last']}>
                  <div className={styles['high-2']}>
                    <div className={styles['value-13']}>
                      <div className={styles['ellipse-12']} />
                      <div className={styles['text-wrapper-16']}>Low</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles['col-right']}>
                <div className={styles['cell-right']}>
                  <div className={styles['text-wrapper-17']}>
                    {formatKValue(externalAttackStats.criticalFindingsCount)}
                  </div>
                  <div className={styles['text-wrapper-18']}>
                    {calcPercentage(externalAttackStats.criticalFindingsCount)}
                  </div>
                </div>
                <div className={styles['cell-right']}>
                  <div className={styles['text-wrapper-17']}>
                    {formatKValue(externalAttackStats.highFindingsCount)}
                  </div>
                  <div className={styles['text-wrapper-18']}>
                    {calcPercentage(externalAttackStats.highFindingsCount)}
                  </div>
                </div>
                <div className={styles['cell-right']}>
                  <div className={styles['text-wrapper-17']}>
                    {formatKValue(externalAttackStats.mediumFindingsCount)}
                  </div>
                  <div className={styles['text-wrapper-18']}>
                    {calcPercentage(externalAttackStats.mediumFindingsCount)}
                  </div>
                </div>
                <div className={styles['cell-right-last']}>
                  <div className={styles['text-wrapper-17']}>
                    {formatKValue(externalAttackStats.lowFindingsCount)}
                  </div>
                  <div className={styles['text-wrapper-18']}>
                    {calcPercentage(externalAttackStats.lowFindingsCount)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

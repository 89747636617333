import * as React from 'react'
import dayjs from 'dayjs'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import { styled } from '@mui/material/styles'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import classes from './style.module.scss'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'

dayjs.extend(isBetweenPlugin)

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  borderRadius: '50%',
  '&:hover': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
  },
  ...(dayIsBetween && {
    '&:focus': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...((isFirstDay || isLastDay) && {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  }),
}))

function Day(props) {
  const { day, startDay, endDay, ...other } = props

  if (!startDay && !endDay) {
    return <PickersDay day={day} {...other} />
  }

  const start = startDay || endDay
  const end = endDay || startDay

  const dayIsBetween = day.isBetween(start, end, null, '[]')
  const isFirstDay = day.isSame(start, 'day')
  const isSunday = day.day() === 0
  const isSaturday = day.day() === 6
  const isLastDay = day.isSame(end, 'day')

  return (
    <div
      style={{
        ...(dayIsBetween && {
          backgroundColor: '#eee',
        }),
        ...((isFirstDay || isSunday) && {
          borderTopLeftRadius: '50%',
          borderBottomLeftRadius: '50%',
        }),
        ...((isLastDay || isSaturday) && {
          borderTopRightRadius: '50%',
          borderBottomRightRadius: '50%',
        }),
      }}
      className={dayIsBetween && !isFirstDay && !isLastDay ? classes.dayIsBetween : ''}
    >
      <CustomPickersDay
        {...other}
        day={day}
        sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    </div>
  )
}

function CustomTextField(props) {
  const { error, startDay, endDay } = props

  return (
    <TextField
      {...props}
      size="small"
      error={!!error}
      helperText={error && `* ${error}`}
      sx={{ minWidth: '240px' }}
      placeholder={'Start - End'}
      value={`${startDay ? startDay.format('DD/MM/YYYY') : 'Start'} - ${
        endDay ? endDay.format('DD/MM/YYYY') : 'End'
      }`}
      className={classes.dateInput}
    />
  )
}

export default function CustomDateRangePicker({ value, onChange, error, isMonthView }) {
  const [startTurn, setStartTurn] = React.useState(true)
  const [currentValue, updateValue] = React.useState(value)

  const [open, setOpen] = React.useState(false)

  const handleChange = (newValue) => {
    if (!newValue) {
      return
    }
    if (startTurn) {
      const isReverse = newValue?.isAfter(currentValue.endDate)
      if (isReverse) {
        updateValue({ startDate: newValue, endDate: null })
      } else {
        updateValue((preVal) => ({ ...preVal, startDate: newValue }))
      }
      setStartTurn(false)
    } else {
      const isReverse = newValue?.isBefore(currentValue.startDate)
      if (isReverse) {
        updateValue((preVal) => ({ ...preVal, startDate: newValue }))
        setStartTurn(false)
      } else {
        updateValue((preVal) => ({ ...preVal, endDate: newValue }))
        setStartTurn(true)
      }
    }
  }

  React.useEffect(() => {
    console.log('currentValue', currentValue)
  }, [currentValue])

  return (
    <DatePicker
      sx={{
        marginTop: '12px',
      }}
      views={isMonthView ? ['year', 'month'] : null}
      open={open}
      onClose={() => setOpen(false)}
      value={startTurn ? currentValue.startDate : currentValue.endDate}
      onChange={handleChange}
      showDaysOutsideCurrentMonth
      disableHighlightToday
      closeOnSelect={false}
      autoFocus={false}
      slots={{ day: Day, textField: CustomTextField, actionBar: CustomActionBar }}
      slotProps={{
        day: {
          startDay: currentValue.startDate,
          endDay: currentValue.endDate,
        },
        textField: {
          error: error,
          startDay: currentValue.startDate,
          endDay: currentValue.endDate,
          startTurn: startTurn,
          onClick: () => setOpen(true),
        },
        openPickerIcon: {
          onClick: () => setOpen(true),
        },
        actionBar: {
          onAccept: () => {
            onChange(currentValue)
            setStartTurn(true)
            setOpen(false)
          },
          onCancel: () => {
            updateValue(value)
            setStartTurn(true)
            setOpen(false)
          },
        },
        layout: {
          className: classes.layourpicker,
        },
        desktopPaper: {
          className: classes.calendarpopper,
        },
      }}
    />
  )
}

const CustomActionBar = ({ onAccept, onCancel }) => {
  return (
    <DialogActions
      sx={{
        color: 'var(--neutral-color-grey-950)',
        backgroundColor: 'var(--neutral-color-grey-150)',
      }}
    >
      <Button variant="text" className={classes['datepicket-action-btn']} onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="text" className={classes['datepicket-action-btn']} onClick={onAccept}>
        OK
      </Button>
    </DialogActions>
  )
}

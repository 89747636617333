import React, { useState, useEffect } from 'react'
import styles from './style.module.css'
import { DownloadIcon } from 'src/v2/icons/download-icon'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { Button, Tabs, Tab } from '@mui/material'
import { getClientSubmissionDetails } from 'src/services/clientSubmissionService'
import { ApprovalStatus, ClientSubmissionStatus } from 'src/utils/commonFns'
import moment from 'moment'
import { differenceInDays } from 'date-fns'
import { ProjectDetails } from './Tabs/ProjectDetails'
import PropTypes from 'prop-types'
import { AssessmentReport } from './Tabs/Report'
import { CircleLoader } from 'src/v2/components/CircleLoader'
import { Remediations } from './Tabs/Remediations'
import { useSnackbar } from 'notistack'
import {
  getClientSubmissionReportById,
  updateClientSubmissionReportStatus,
} from 'src/services/clientSubmissionReportService'
import Confirmation from 'src/v2/components/Confirmation'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { format } from 'date-fns'
import { AssessmentMap } from '../AssessmentMap'

function CustomTabPanel(props) {
  const { value, index, assessmentDetails } = props
  if (value === 0 && index === 0)
    return assessmentDetails && <ProjectDetails assessmentDetails={assessmentDetails} />
  else if (value === 1 && index === 1)
    return !!assessmentDetails &&
      (assessmentDetails?.status === ClientSubmissionStatus.Accepted ||
        assessmentDetails?.status === ClientSubmissionStatus.Completed) ? (
      <AssessmentReport assessmentDetails={assessmentDetails} />
    ) : (
      <AssessmentMap></AssessmentMap>
    )
  else if (value === 2 && index === 2)
    return assessmentDetails && <Remediations assessmentDetails={assessmentDetails} />
}

CustomTabPanel.propTypes = {
  assessmentDetails: PropTypes.object,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

export const AssessmentDetails = ({ assessment }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [tabValue, setTabValue] = useState(0)
  const [assessmentData, setAssessmentData] = useState()
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [reportsData, setReportsData] = useState({})
  const [isQueriesComments, setIsQueriesComments] = useState()

  const handleTabChange = (event) => {
    setTabValue(parseInt(event.target.id))
  }

  const getAssessmentDetails = async (assessmentId) => {
    var result = await getClientSubmissionDetails(assessmentId)
    var reportInfo = await getClientSubmissionReportById(assessmentId)
    setAssessmentData(result)
    if (reportInfo) {
      setReportsData(reportInfo)
    }
    setIsDataLoading(false)
  }

  useEffect(() => {
    setTabValue(0)
    setIsDataLoading(true)
    if (assessment.id) getAssessmentDetails(assessment.id)
    else setIsDataLoading(false)
  }, [assessment.id])

  const getAssessmentStatus = () => {
    var statusHtml = ''
    if (assessmentData) {
      var completedDuration = ''
      if (assessmentData.completedDate && assessmentData?.requestedDate)
        completedDuration = differenceInDays(
          new Date(assessmentData.completedDate),
          new Date(assessmentData.requestedDate),
        )

      if (completedDuration === 0) completedDuration = 1

      switch (assessmentData.status) {
        case ClientSubmissionStatus.Draft:
        case ClientSubmissionStatus.Requested:
        case ClientSubmissionStatus.Review:
        case ClientSubmissionStatus.SubmittedToVendor:
        case ClientSubmissionStatus.InProgress:
          statusHtml = (
            <>
              {' '}
              <p className={styles['text-wrapper-3']}>
                Last Updated on:{' '}
                {moment(
                  assessment?.inProgressDate ??
                    assessment?.SubmittedToVendor ??
                    assessment?.reviewDate ??
                    assessment?.requestedDate ??
                    assessment?.draftedDate,
                ).format('MMM DD, YYYY')}
              </p>
            </>
          )
          break
        // statusHtml = (
        //   <>
        //     {' '}
        //     <p className={styles['text-wrapper-3']}>
        //       Report Accepted on: {format(new Date(assessment?.acceptedDate), 'MMM dd, YYYY')}
        //     </p>
        //     <p className={styles.divider} />
        //     <p className={styles['text-wrapper-3']}>Completed in: {completedDuration} Days</p>
        //   </>
        // )
        // break
        case ClientSubmissionStatus.Accepted:
        case ClientSubmissionStatus.Completed:
          statusHtml = (
            <>
              {' '}
              <p className={styles['text-wrapper-3']}>
                Report Completed on: {format(new Date(assessment?.completedDate), 'MMM dd, yyyy')}
              </p>
              {/* <p className={styles.divider} /> */}
              {/* <p className={styles['text-wrapper-3']}>Completed in: {completedDuration} Day(s)</p> */}
            </>
          )
          break
        case ClientSubmissionStatus.Cancelled:
          statusHtml = (
            <>
              {' '}
              <p className={styles['text-wrapper-3']}>
                Report cancelled on: {format(new Date(assessment?.cancelledDate), 'MMM dd, yyyy')}
              </p>
              <p className={styles.divider} />
              <p className={styles['text-wrapper-3']}>Cancelled by: {assessmentData.cancelledBy}</p>
            </>
          )
          break
        default:
          break
      }
    }
    return statusHtml
  }

  const handleSubmit = async (status, comments) => {
    if (status === ApprovalStatus.ClientRejected && !comments) {
      enqueueSnackbar('Please Enter Comments', {
        variant: 'error',
        autoHideDuration: 5000,
        hideIconVariant: true,
      })
      return
    }
    const request = {
      reportId: reportsData.id,
      newStatus: status,
      comments: comments,
    }
    const response = await updateClientSubmissionReportStatus(request)

    if (response) {
      enqueueSnackbar(
        status === ApprovalStatus.ClientApproved
          ? 'Reports accepted Successfully'
          : 'Rejected Successfully',
        {
          variant: 'success',
          autoHideDuration: 5000,
          hideIconVariant: true,
        },
      )
      setIsQueriesComments(false)
    }
  }

  const downloadReports = async () => {
    var filesList = [
      {
        fileUrl: reportsData.summaryReportSignedUrl,
        fileName: reportsData.summaryReportName,
      },
    ]

    if (!!reportsData.detailedReportSignedUrl) {
      filesList.push({
        fileUrl: reportsData.detailedReportSignedUrl,
        fileName: reportsData.detailedReportName,
      })
    }

    if (!!reportsData.supportingDocumentSignedUrl) {
      filesList.push({
        fileUrl: reportsData.supportingDocumentSignedUrl,
        fileName: reportsData.supportingDocumentName,
      })
    }

    filesList.forEach((file) => {
      axios
        .get(file.fileUrl, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, file.fileName)
        })
    })
  }

  return (
    <>
      {isDataLoading && (
        <div className={styles['main-container-loader']}>
          <CircleLoader size={100}></CircleLoader>
        </div>
      )}
      {!isDataLoading && (
        <div className={styles['main-container']}>
          <div className={styles.header}>
            <div className={styles.title}>
              {assessmentData.logoUrl && (
                <img className={styles.avatar} alt="Avatar" src={assessmentData.logoUrl} />
              )}
              <div className={styles.left}>
                <div className={styles['text-wrapper-2']}>
                  {assessmentData?.thirdPartyName} - {assessmentData?.clientSubmissionNo}
                </div>
                <div className={styles['security-privacy']}>Security &amp; Privacy Assessment</div>
                <div className={styles.card}>{getAssessmentStatus()}</div>
              </div>
              {(assessmentData?.status === ClientSubmissionStatus.Accepted ||
                assessmentData?.status === ClientSubmissionStatus.Completed) && (
                <div className={styles.actions}>
                  <div className={styles['icon-placeholder-wrapper']}>
                    <IconPlaceholder
                      className={'menu-links'}
                      icon={<DownloadIcon className={styles['icons-2']} />}
                      size="twenty-x-20"
                      onClick={() => {
                        downloadReports()
                      }}
                    />
                  </div>

                  <Button
                    className={styles['button-3']}
                    color="secondary"
                    size="small"
                    variant="contained"
                    text="Previous"
                    disabled={assessmentData?.status === ClientSubmissionStatus.Accepted}
                    onClick={() => {
                      setIsQueriesComments(true)
                    }}
                  >
                    Raise a Query
                  </Button>

                  <Button
                    className={styles['button-3']}
                    color="primary"
                    size="small"
                    variant="contained"
                    text="Next"
                    disabled={assessmentData?.status === ClientSubmissionStatus.Accepted}
                    onClick={() => {
                      handleSubmit(ApprovalStatus.ClientApproved)
                    }}
                  >
                    Accept Report
                  </Button>
                </div>
              )}
            </div>
            <div className={styles.section}>
              <div className={styles['tab-navigation']}>
                <Tabs onClick={handleTabChange} value={tabValue}>
                  <Tab
                    className={styles['tab-nav-instance']}
                    label="Project Details"
                    id={0}
                    value={0}
                  />
                  <Tab
                    className={styles['tab-nav-instance']}
                    label="Assessment Report"
                    id={1}
                    value={1}
                  />
                  {/* {(assessmentData?.status === ClientSubmissionStatus.Accepted ||
                    assessmentData?.status === ClientSubmissionStatus.Completed) && (
                    <>
                      <Tab
                        className={styles['tab-nav-instance']}
                        label="Assessment Report"
                        id={1}
                        value={1}
                      />
                    </>
                  )} */}
                  {(assessmentData?.status === ClientSubmissionStatus.Accepted ||
                    assessmentData?.status === ClientSubmissionStatus.Completed) && (
                    <>
                      <Tab className={styles['tab-nav-instance']} label="Gaps" id={2} value={2} />
                    </>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
          <CustomTabPanel value={tabValue} index={0} assessmentDetails={assessmentData}>
            1
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1} assessmentDetails={assessmentData}>
            2
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2} assessmentDetails={assessmentData}>
            3
          </CustomTabPanel>
          <Confirmation
            open={isQueriesComments}
            handleAction={(isQuery, isClose, comments) => {
              setIsQueriesComments(false)
              if (isClose) return
              if (!isQuery) handleSubmit(ApprovalStatus.ClientRejected, comments)
            }}
            title="Raise a Query"
            message={'Please Enter Query Comments'}
            secondaryBtnText={'Raise Query'}
            primaryBtnText={'Cancel'}
            infoIcon={true}
            showReason={true}
            rowsCountForReason={6}
            placeholder={'Query Comments...'}
          />
        </div>
      )}
    </>
  )
}

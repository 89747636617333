import React from 'react'
import styles from './style.module.css'

export const MitreMetricCard = ({ name, targetScore, actualScore }) => {
  return (
    <div className={styles['metric-card']}>
      <div className={styles['text-wrapper']}>{name}</div>
      <div className={styles['progress-bar']}>
        <div className={styles['overlap-group-wrapper']}>
          <div className={styles['vendor-score']} style={{ width: actualScore + '%' }} />
          <div className={styles['industry-score']} style={{ left: targetScore + '%' }} />
        </div>
        <div className={styles['percentage']}>{actualScore}%</div>
        <div className={styles['text-wrapper-2']} style={{ left: targetScore - 10 + '%' }}>
          {targetScore}%
        </div>
      </div>
    </div>
  )
}

import React, { useCallback, useEffect, useState } from 'react'
import styles from './style.module.css'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  debounce,
  // ClickAwayListener,
  // Popper,
} from '@mui/material'
import { capitalizeFirstLetter, convertUnixTimeStampToDate, hasValue } from 'src/utils/commonFns'
// import { ActiveDropdown } from './filters/active'
// import { SortBy } from './filters/sortby'
import classes from './style.module.scss'
import { visuallyHidden } from '@mui/utils'
import { ASRiskProfileDetails } from '../details'
import { getSecurityFindingList } from 'src/services/cyberScanService'
import TableRowsLoader from 'src/v2/components/Table/TableRowsLoader'
// import { KebabMenuWhiteIcon } from 'src/v2/icons/manage-team/kebab-menu-white'

export const RiskProfileList = ({ assessmentInfo }) => {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('assessmentNumber')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [securityFindingList, setSecurityFindingList] = useState([])

  const headCells = [
    {
      name: 'SECURITY FINDING GROUP',
      disableSort: true,
    },
    {
      name: 'SEVERITY',
      disableSort: true,
    },
    {
      name: 'IMPACTED ASSETS',
      disableSort: true,
    },
    {
      name: 'FIRST DETECTED',
      disableSort: true,
    },
    {
      name: 'LAST DETECTED',
      disableSort: true,
    },
  ]

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const loadSecurityFindingList = async (entityId, page, rowsPerPage) => {
    try {
      if (!hasValue(entityId)) return
      setLoading(true)
      const data = await getSecurityFindingList(entityId, page, rowsPerPage)
      if (data?.results?.length > 0) {
        setSecurityFindingList(data.results)
        setTotalCount(data.count)
      } else {
        setSecurityFindingList([])
        setTotalCount(0)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setSelectedRow(null)
    processDebounce(assessmentInfo.coalitionEntityId, page, rowsPerPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentInfo.coalitionEntityId, page, rowsPerPage])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const processDebounce = useCallback(
    debounce((coalitionEntityId, page, rowsPerPage) => {
      loadSecurityFindingList(coalitionEntityId, page, rowsPerPage)
    }, 400), // Adjust delay as needed
    [], // Dependencies
  )

  return (
    <div className={styles['table-screen']}>
      {/* <div className={styles.frame}>
        <div className={styles['search-filter']}>
          <InputBase
            sx={{ height: 36 }}
            className={`${styles['search-bar']}`}
            startAdornment={
              <InputAdornment position="start">
                <IconPlaceholder
                  icon={<SearchIcon className={styles['icons-10']} />}
                  size="twenty-four-x-24"
                />
              </InputAdornment>
            }
            placeholder="Search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
              if (e.target.value) {
                setMockData(
                  initialData.filter((x) =>
                    x.securityFindingGroup.toLowerCase().includes(e.target.value.toLowerCase()),
                  ),
                )
              } else {
                setMockData(initialData)
              }
            }}
          ></InputBase>
        </div>
      </div> */}
      <div className={classes['table-wrapper']}>
        <Box className={classes.content}>
          <TableContainer className={classes.table}>
            <Table size={'medium'}>
              <TableHead className={classes.tablehead}>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      className={classes.tableheadcell}
                      key={headCell.name}
                      sortDirection={orderBy === headCell.name ? order : false}
                    >
                      {!headCell.disableSort ? (
                        <TableSortLabel
                          active={orderBy === headCell.name}
                          direction={orderBy === headCell.name ? order : 'asc'}
                          onClick={() => handleRequestSort(headCell.name)}
                          className={classes.tablesortlabel}
                        >
                          {headCell.name}
                          {orderBy === headCell.name ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        <>{headCell.name}</>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {/* <div className={classes.tablebody}> */}
              <TableBody className={classes.tablebody}>
                {loading ? (
                  <TableRowsLoader rows={10} columns={5} tableCellClassName={classes.tablecell} />
                ) : securityFindingList.length > 0 ? (
                  securityFindingList.map((row, index) => (
                    <TableRow
                      key={index}
                      className={`${'menu-links'} ${classes['vendor-row']}`}
                      onClick={() => {
                        setSelectedRow(row)
                        setIsDetailsOpen(true)
                      }}
                    >
                      <TableCell className={classes.tablecell}>{row.name}</TableCell>
                      <TableCell
                        className={`${classes.tablecell} ${classes[row.level.toLowerCase()]}`}
                      >
                        {capitalizeFirstLetter(row.level)}
                      </TableCell>
                      <TableCell className={classes.tablecell}>{row.assets?.length || 0}</TableCell>
                      <TableCell className={classes.tablecell}>
                        {convertUnixTimeStampToDate(row.first_seen)?.format('MMM DD, YYYY')}
                      </TableCell>
                      <TableCell className={classes.tablecell}>
                        {convertUnixTimeStampToDate(row.last_seen)?.format('MMM DD, YYYY')}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      className={classes.tablecell}
                      style={{ textAlign: 'center' }}
                    >
                      <h6 className={styles.norecords}>No Records to Display</h6>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {/* </div> */}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.tablepagination}
            classes={{
              selectLabel: classes.tablePaginationSelectLabel,
              displayedRows: classes.tablePaginationDisplayedRows,
              selectIcon: classes.tablePaginationSelectIcon,
              actions: classes.tablePaginationActions,
            }}
          />
        </Box>
      </div>
      {isDetailsOpen && (
        <ASRiskProfileDetails
          open={isDetailsOpen}
          handleAction={setIsDetailsOpen}
          profileInfo={selectedRow}
          assessmentInfo={assessmentInfo}
        />
      )}
      {/* {open && (
        <ClickAwayListener onClickAway={clickAwayHandler}>
          <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
            <div className={styles['popper-content']}>
              <div className={`${'menu-links'} ${styles['text-wrapper-1']}`}>Edit</div>
              <div className={`${'menu-links'} ${styles['text-wrapper']}`}>Remove</div>
            </div>
          </Popper>
        </ClickAwayListener>
      )} */}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import { useParams } from 'react-router-dom'
import { Button, Tab, Tabs } from '@mui/material'
import { EmployeeExposedCredentials } from './employee-exposed-credentials'
import BeaconerLogo from 'src/v2/assets/images/beaconer-logo.svg'
import { UploadIcon } from '../analytics/icons/UploadIcon'
import { ShareIcon } from '../analytics/icons/ShareIcon'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { AttackSurfaceRiskProfile } from './as-risk-profile'
import { AttackSurfaceAssets } from './as-assets'
import { LookAlikeWebsites } from './look-alike-websites'
// import { AppsAndServices } from './apps-services'
import { getCyberScanVendorDetails } from 'src/services/cyberScanService'

function CustomTabPanel(props) {
  const { value, index, cyberscanDetailInfo } = props
  if (value === 0 && index === 0)
    return <EmployeeExposedCredentials cyberscanDetailInfo={cyberscanDetailInfo} />
  else if (value === 1 && index === 1)
    return <LookAlikeWebsites cyberscanDetailInfo={cyberscanDetailInfo} />
  else if (value === 2 && index === 2)
    return <AttackSurfaceRiskProfile assessmentInfo={cyberscanDetailInfo} />
  else if (value === 3 && index === 3)
    return <AttackSurfaceAssets assessmentInfo={cyberscanDetailInfo} />
  // else if (value === 4 && index === 4)
  //   return <AppsAndServices assessmentInfo={cyberscanDetailInfo} />
}

export const CyberScanningDetails = () => {
  const [cyberscanDetailInfo, setcyberscanDetailInfo] = useState({})
  const { insecureWebScanId } = useParams()
  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event) => {
    setTabValue(parseInt(event.target.id))
  }

  useEffect(() => {
    if (insecureWebScanId) getVendorDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getVendorDetails = async () => {
    const data = await getCyberScanVendorDetails(insecureWebScanId)
    if (data) {
      setcyberscanDetailInfo(data)
    }
  }

  return (
    <div className={styles['main-container']}>
      <header className={styles.header}>
        <div className={styles['title-3']}>
          <img
            className={styles.avatar}
            alt="Avatar"
            src={cyberscanDetailInfo.logoUrl || BeaconerLogo}
          />
          <div className={styles.left}>
            <div className={styles['text-wrapper-14']}>
              {cyberscanDetailInfo.vendorName} - {cyberscanDetailInfo.assessmentNumber}
            </div>
            <div className={styles['text-wrapper-15']}>Dark web monitoring</div>
          </div>
          <div className={styles['frame-2']}>
            <Button
              variant="contained"
              color="secondary"
              className={styles['button-instance']}
              startIcon={
                <IconPlaceholder
                  icon={<UploadIcon className={styles['icons-2']} />}
                  size="twenty-four-x-24"
                />
              }
            >
              Export
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={styles['button-instance']}
              startIcon={
                <IconPlaceholder
                  icon={<ShareIcon className={styles['icons-2']} />}
                  size="twenty-four-x-24"
                  style={{ marginTop: '-4px' }}
                />
              }
            >
              Share
            </Button>
          </div>
        </div>
        <div className={styles['tab-navigation-wrapper']}>
          <div className={styles['tab-navigation']}>
            <Tabs onClick={handleTabChange} value={tabValue}>
              <Tab
                className={styles['tab-nav-instance']}
                label="Employee Exposed Credentials"
                id={0}
                value={0}
                style={{
                  flex: '0 0 auto !important',
                  textTransform: 'none',
                  padding: '8px 12px 16px !important',
                }}
              />
              <Tab
                className={styles['tab-nav-instance']}
                label="Look Alike websites"
                id={1}
                value={1}
                style={{
                  flex: '0 0 auto !important',
                  textTransform: 'none',
                  padding: '8px 12px 16px !important',
                }}
              />
              <Tab
                className={styles['tab-nav-instance']}
                label="Attack Surface Risk Profile"
                id={2}
                value={2}
                style={{
                  flex: '0 0 auto !important',
                  textTransform: 'none',
                  padding: '8px 12px 16px !important',
                }}
              />
              <Tab
                className={styles['tab-nav-instance']}
                label="Attack Surface - Assets"
                id={3}
                value={3}
                style={{
                  flex: '0 0 auto !important',
                  textTransform: 'none',
                  padding: '8px 12px 16px !important',
                }}
              />
              {/* <Tab
                className={styles['tab-nav-instance']}
                label="Apps & Services"
                id={4}
                value={4}
                style={{
                  flex: '0 0 auto !important',
                  textTransform: 'none',
                  padding: '8px 12px 16px !important',
                }}
              /> */}
            </Tabs>
          </div>
        </div>
      </header>
      {Array(4)
        .fill(0)
        .map((_, index) => (
          <CustomTabPanel
            value={tabValue}
            key={index}
            index={index}
            cyberscanDetailInfo={cyberscanDetailInfo}
          >
            index
          </CustomTabPanel>
        ))}
    </div>
  )
}

import React from 'react'
import styles from './style.module.css'

export const CircleLoader = ({ size }) => {
  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      width={size}
      height={size}
      style={{ shapeRendering: 'auto', display: 'block' }}
    >
      <g data-idx="1">
        <circle className={styles['circle-loader']} r="9" cy="25" cx="25" data-idx="2"></circle>
        <circle className={styles['circle-loader']} r="9" cy="25" cx="50" data-idx="4"></circle>
        <circle className={styles['circle-loader']} r="8" cy="25" cx="75" data-idx="6"></circle>
        <circle className={styles['circle-loader']} r="9" cy="50" cx="25" data-idx="8"></circle>
        <circle className={styles['circle-loader']} r="9" cy="50" cx="50" data-idx="10"></circle>
        <circle className={styles['circle-loader']} r="9" cy="50" cx="75" data-idx="12"></circle>
        <circle className={styles['circle-loader']} r="9" cy="75" cx="25" data-idx="14"></circle>
        <circle className={styles['circle-loader']} r="9" cy="75" cx="50" data-idx="16"></circle>
        <circle className={styles['circle-loader']} r="8" cy="75" cx="75" data-idx="18"></circle>
      </g>
    </svg>
  )
}

import React from 'react'
import SuccessIcon from './SuccessIcon'
import classes from './style.module.scss'
import { IconPlaceholder } from '../IconPlaceholder'
import Typography from '@mui/material/Typography'
import { SnackbarContent } from 'notistack'

const Snack = React.forwardRef((props, ref) => {
  const { heading, message, ...other } = props

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <div className={classes.snackcontent}>
        <div className={classes.header}>
          <IconPlaceholder icon={<SuccessIcon />} size="twenty-four-x-24" />
          <Typography variant="subtitle1" className={classes.heading}>
            {heading}
          </Typography>
        </div>
        <Typography variant="body2" className={classes.body}>
          {message}
        </Typography>
      </div>
    </SnackbarContent>
  )
})

Snack.displayName = 'Snack'

export default Snack

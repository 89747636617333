import React, { useEffect, useState } from 'react'
import classes from './style.module.scss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { debounce, hasValue } from 'src/utils/commonFns'
import { getRemediationsV2 } from 'src/services/remediationService'
import { useAuthState } from 'src/context/login'
import moment from 'moment'
import TableRowsLoader from 'src/v2/components/Table/TableRowsLoader'

export const RemediationTable = ({
  setOpenDrawer,
  selectedRemediationType,
  searchQuery,
  dateRangeValue,
  refreshTable,
}) => {
  const { user } = useAuthState()

  const [remediationsData, setRemediationsData] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const processDebounce = debounce(() => fetchData(), 500)

  const fetchData = async () => {
    if (hasValue(dateRangeValue.startDate) && hasValue(dateRangeValue.endDate)) {
      try {
        setIsLoading(true)
        const { result } = await getRemediationsV2(
          user.businessOrganizationId,
          searchQuery,
          selectedRemediationType.value,
          dateRangeValue.startDate.format('YYYY-MM-DD'),
          dateRangeValue.endDate.format('YYYY-MM-DD'),
        )
        setRemediationsData(result)
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    processDebounce()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchQuery,
    selectedRemediationType,
    dateRangeValue.startDate,
    dateRangeValue.endDate,
    refreshTable,
  ])

  return (
    <>
      <TableContainer className={classes.table}>
        <Table stickyHeader>
          <TableHead className={classes.tablehead}>
            <TableRow>
              <TableCell align="center" width={180}>
                THIRD PARTY NAME
              </TableCell>
              <TableCell align="center" width={160}>
                ISSUE CATEGORY
              </TableCell>
              <TableCell align="center">ISSUE IDENTIFIED</TableCell>
              <TableCell align="center" width={130}>
                REPORT #
              </TableCell>
              <TableCell align="center" width={90}>
                RISK
              </TableCell>
              <TableCell align="center" width={125}>
                STATUS
              </TableCell>
              <TableCell align="center" width={115}>
                INITIATED
              </TableCell>
              <TableCell align="center" width={115}>
                ACCEPTED
              </TableCell>
              <TableCell align="center" width={117}>
                ACTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tablebody}>
            {isLoading ? (
              <>
                <TableRowsLoader rows={2} columns={9} />
              </>
            ) : remediationsData.length > 0 ? (
              remediationsData.map((row, index) => {
                const riskColor =
                  row.issueSeverity.toLowerCase() === 'low'
                    ? classes.lowrisk
                    : row.issueSeverity.toLowerCase() === 'medium'
                    ? classes.mediumrisk
                    : classes.highrisk
                return (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="center">{row.vendorName}</TableCell>
                    <TableCell align="center">{row.domainName}</TableCell>
                    <TableCell align="left">{row.issueIdentified}</TableCell>
                    <TableCell align="center" className={classes.celllink}>
                      {row.clientSubmissionNo}
                    </TableCell>
                    <TableCell align="center" className={riskColor}>
                      {row.issueSeverity}
                    </TableCell>
                    <TableCell align="center">{row.displayStatus}</TableCell>
                    <TableCell align="center">
                      {moment(row.initiatedOn).format('MM/DD/YY')}
                    </TableCell>
                    <TableCell align="center">
                      {hasValue(row.acceptedOn) ? moment(row.acceptedOn).format('MM/DD/YY') : ''}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.celllink}
                      onClick={() => setOpenDrawer(row)}
                    >
                      {'View Details'}
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9}>
                  <h6 className={classes.norecords}>No Records to Display</h6>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

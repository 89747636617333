import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import classes from './style.module.scss'
import { IconPlaceholder } from '../IconPlaceholder'
import { CrossMark } from 'src/v2/icons/cross-mark'
import { InfoIcon } from 'src/v2/icons/info'
import { InputBase } from '@mui/material'
import { ErrorText } from '../ErrorText'

const Confirmation = ({
  open,
  handleAction,
  title,
  message,
  infoIcon,
  secondaryBtnText,
  primaryBtnText,
  showReason,
  rowsCountForReason = 2,
  placeholder = 'Cancel Reason...',
}) => {
  const [reason, setReason] = useState('')
  const [reasonError, setReasonError] = useState('')

  return (
    <Dialog
      open={open}
      // onClose={() => handleAction(false)}
      className={classes.modal}
      style={{ width: showReason ? '100%' : 'auto' }}
    >
      <div className={classes.modal}>
        <div className={classes.title}>
          <div className={classes['title-icon']}>
            {infoIcon && (
              <IconPlaceholder
                icon={<InfoIcon className={classes['icon-instance-node']} height={20} width={20} />}
                size="thirty-two-x-32"
              />
            )}
            <div className={classes['text-wrapper']}> {title}</div>
          </div>
          <IconPlaceholder
            icon={<CrossMark className={`${'menu-links'} ${classes['icon-instance-node']}`} />}
            size="twenty-four-x-24"
            onClick={() => handleAction(false, true, null)}
          />
        </div>
        <div className={classes.description}>
          <p className={classes['description-text']}>{message}</p>
          {showReason && (
            <>
              {' '}
              <InputBase
                placeholder={placeholder}
                multiline
                rows={rowsCountForReason}
                className={classes['reason']}
                id="reason"
                name="reason"
                onChange={(e) => {
                  setReason(e.target.value)
                }}
                value={reason}
                error={reasonError}
                required
              ></InputBase>
              <ErrorText value={reasonError} />
            </>
          )}
        </div>
        <div className={classes.div}>
          <Button
            className={classes['button-instance']}
            color="error"
            size="medium"
            variant="contained"
            text={secondaryBtnText}
            onClick={() => {
              if (showReason && !reason) {
                setReasonError('Please Enter Reason')
                return
              }
              handleAction(false, false, reason)
            }}
            style={{ textTransform: 'none' }}
          >
            {secondaryBtnText}
          </Button>
          <Button
            className={classes['button-instance']}
            color="primary"
            size="medium"
            variant="contained"
            text={primaryBtnText}
            onClick={() => handleAction(true, false, reason)}
            autoFocus
            style={{ textTransform: 'none' }}
          >
            {primaryBtnText}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default Confirmation

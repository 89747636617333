import React from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { InfoIcon } from 'src/v2/icons/info'
import { EADoughnutChart } from 'src/v2/components/ea-donought'

export const EASurface = ({ value }) => {
  return (
    <div className={styles['external-attack']}>
      <div className={styles['frame-2']}>
        <div className={styles['section-2']}>
          <div className={styles['title-2']}>
            <div className={styles['title-data-2']}>External Attack surface</div>
            <IconPlaceholder
              icon={<InfoIcon className={styles['icons-6']} color="#7F8082" />}
              size="twenty-four-x-24"
            />
          </div>
        </div>
        <div className={styles['chart-4']}>
          <div className={styles['speed-chart']}>
            <div className={styles['overlap']}>
              <EADoughnutChart value={value} />
            </div>
          </div>
          <div className={styles['legends']}>
            <div className={styles['div-2']}>
              <div className={styles['rectangle-5']} />
              <div className={styles['text-wrapper-11']}>Low (&lt;40)</div>
            </div>
            <div className={styles['div-2']}>
              <div className={styles['rectangle-6']} />
              <div className={styles['text-wrapper-11']}>Medium(40-70)</div>
            </div>
            <div className={styles['div-2']}>
              <div className={styles['rectangle-7']} />
              <div className={styles['text-wrapper-11']}>High(&gt;70)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

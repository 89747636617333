import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Toasts from '../components/common/toast/Toast'
import AxiosInterceptor from '../context/axios'
import { AuthProvider } from '../context/login'
import { ToastProvider } from '../context/toast'
import '../scss/style.scss'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-loading-skeleton/dist/skeleton.css'
import ProtectedComponent from '../components/common/ProtectedComponent'
import { SkeletonTheme } from 'react-loading-skeleton'
import './App.css'
import Remediation from './screens/remediation'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import Snack from './components/Snack'
import { Assessments } from './screens/Assessments'
import Dashboard from './screens/dashboard'
import { Settings } from './screens/settings'
import Analytics from './screens/analytics'
import { CyberScanning } from './screens/cyber-scanning'
import { CyberScanningDetails } from './screens/cyber-scanning-details'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('../views/pages/login'))

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              border: '2px solid #d32f2f',
            },
            '&.Mui-focused': {
              border: '2px solid #5885E6',
            },
            '& fieldset': {
              border: 'none',
            },
          },
          input: {
            color: 'var(--neutral-color-grey-950)',
            flex: 1,
            fontFamily: 'var(--paragraph-SB-p1-font-family)',
            fontSize: 'var(--paragraph-SB-p1-font-size)',
            fontStyle: 'var(--paragraph-SB-p1-font-style)',
            fontWight: 'var(--paragraph-SB-p1-font-weight)',
            letterSpacing: 'var(--paragraph-SB-p1-letter-spacing)',
            lineHeight: 'var(--paragraph-SB-p1-line-height)',
            marginTop: '-1px',
            position: 'relative',
            '&::placeholder': {
              color: '"var(--neutral-color-grey-450)',
              flex: 1,
              fontFamily: 'var(--paragraph-r-p2-font-family)',
              fontSize: 'var(--paragraph-r-p2-font-size)',
              fontStyle: 'var(--paragraph-r-p2-font-style)',
              fontWeight: 'var(--paragraph-r-p2-font-weight)',
              letterSpacing: 'var(--paragraph-r-p2-letter-spacing)',
              lineHeight: 'var(--paragraph-r-p2-line-height)',
              position: 'relative',
            },
            '&::-internal-autofill-selected': {
              backgroundColor: 'var(--neutral-color-grey-950)',
            },
            // '&:hover:not(.Mui-disabled, .Mui-error):before': {
            //   borderBottom: '2px solid "var(--TextField-brandBorderHoverColor)',
            // },
            // '&.Mui-focused:after': {
            //   borderBottom: '2px solid "var(--TextField-brandBorderFocusedColor)',
            // },
          },
        },
      },
      // '&.Mui-focused': {
      //   border: '2px solid blue',
      //   '& .MuiOutlinedInput-notchedOutline': {
      //     border: '2px solid blue',
      //   },
      // },
      MuiButton: {
        styleOverrides: {
          root: {
            // disabled: {
            //   color: 'rgb(255 255 255 / 38%)',
            // },
            '&.MuiButton-colorSecondary': {
              backgroundColor: 'var(--neutral-color-grey-150)',
              color: 'var(--info-color-IC-l-750)',
              '&.Mui-disabled': {
                color: 'rgb(255 255 255 / 38%)',
              },
            },
            '&.MuiButton-colorPrimary': {
              backgroundColor: 'var(--info-color-IC-base)',
              color: 'var(--info-color-IC-l-750)',
              '&.Mui-disabled': {
                color: 'rgb(255 255 255 / 38%)',
              },
            },
            '&.MuiButton-containedError': {
              backgroundColor: 'var(--neutral-color-grey-150)',
              color: '#F26C6E',
              '&.Mui-disabled': {
                color: 'rgb(255 255 255 / 38%)',
              },
            },
            // '&.Mui-disabled': {
            //   color: 'rgb(255 255 255 / 38%)',
            // },
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: '#F3F3F4',
            },
          },
          textColorPrimary: {
            color: '#A2A3A4',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: { backgroundColor: '#F3F3F4' },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: '#7F8082',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: '#7F8082',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&.MuiDialog-paper': {
              backgroundColor: 'initial',
              color: '#F3F3F4',
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            background: 'transparent !important',
            boxShadow: 'none',
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            background: 'transparent !important',
            boxShadow: 'none',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: 'var(--collection-BG-500)',
            boxShadow: 'none',
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            '&.Mui-checked': {
              color: 'rgba(255, 255, 255, 1)',
            },
          },
          track: {
            backgroundColor: 'var(--neutral-color-grey-750)',
          },
        },
      },
    },
  })

const App = () => {
  const outerTheme = useTheme()
  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <ToastProvider>
        <SnackbarProvider
          maxSnack={4}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          Components={{
            default: Snack,
          }}
        >
          <AxiosInterceptor>
            <AuthProvider>
              <SkeletonTheme baseColor="#ffffff1c" highlightColor="#656871">
                <BrowserRouter basename={'/client'}>
                  <Suspense fallback={loading}>
                    <Routes>
                      <Route exact path="/login" name="Login Page" element={<Login />} />
                      <Route
                        path="/"
                        name="Home"
                        element={
                          <ProtectedComponent>
                            <DefaultLayout />
                          </ProtectedComponent>
                        }
                      >
                        <Route index path="/dashboard" element={<Dashboard />} />
                        <Route path="/remediation" element={<Remediation />} />
                        <Route path="/assessments" element={<Assessments />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/analytics" element={<Analytics />} />
                        <Route path="/cyberscanning" element={<CyberScanning />} />
                        <Route
                          path="/cyberscanning/:insecureWebScanId"
                          element={<CyberScanningDetails />}
                        />
                        <Route path="*" element={<Navigate to="/dashboard" replace={true} />} />
                      </Route>
                    </Routes>
                  </Suspense>
                </BrowserRouter>
              </SkeletonTheme>
              <Toasts />
            </AuthProvider>
          </AxiosInterceptor>
        </SnackbarProvider>
      </ToastProvider>
    </ThemeProvider>
  )
}

export default App

import React, { useEffect, useRef, useState } from 'react'
import styles from './style.module.css'
import { Chart, ArcElement } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

Chart.register(ArcElement)

export const MitreDoughnutChart = ({ vendorCoverage, industryCoverage }) => {
  const refCanvas = useRef()
  const [stylesPosition, setStylePos] = useState({})
  const data = {
    datasets: [
      {
        data: [vendorCoverage, 100 - vendorCoverage],
        backgroundColor: ['rgba(58, 112, 226, 1)', '#3A3C3E'],
        borderWidth: 0,
        borderRadius: 0,
      },
      {
        data: [industryCoverage, 100 - industryCoverage],
        backgroundColor: ['rgba(255, 119, 56, 1)', 'rgba(38, 38, 38, 1)'],
        weight: 0.3,
        borderWidth: 0,
        spacing: 1,
      },
    ],
  }

  const options = {
    cutout: '80%',
  }

  const drawPoint = (angle, distance, label) => {
    // if (ctx) {
    //Define Variables
    var radius = 0.83
    // var point_size = 4
    var center_x = 0.83
    var center_y = 0.83
    // var font_size = '20px'
    var x = center_x + radius * Math.cos((-angle * Math.PI) / 180) * distance
    var y = center_y + radius * Math.sin((-angle * Math.PI) / 180) * distance

    // ctx.beginPath()
    // ctx.fillStyle = 'red'
    // ctx.arc(x, y, 4, 0, 2 * Math.PI)
    // ctx.fill()

    // ctx.font = '20px'
    // ctx.fillText(label, x + 10, y)

    return {
      left: x,
      top: y,
      display: 'none',
    }
    // }
  }

  useEffect(() => {
    let coverage = industryCoverage
    coverage = 45
    const angleCalc = (360 * coverage) / 100
    setStylePos(drawPoint(angleCalc, 1, coverage + '%'))
  }, [industryCoverage])

  return (
    <div style={{ display: 'flex' }}>
      <div className={styles.ranges}>
        <div className={styles['overlap-group']}>
          {/* <div className={styles['text-wrapper-100pixel']}>100%</div>
          <div className={styles['text-wrapper-40pixel']}>40%</div>
          <div className={styles['text-wrapper-70pixel']}>70%</div> */}
          <div className={styles['text-wrapper-70pixel']} style={stylesPosition}>
            {industryCoverage + '%'}
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <p className={styles.element}>
          <span className={styles['percentage-wrapper']}>{vendorCoverage}</span>
          <span className={styles['symbol-wrapper']}>%</span>
        </p>
        <div className={styles['caption-wrapper']}>COVERAGE</div>
      </div>
      <Doughnut ref={refCanvas} data={data} options={options}></Doughnut>
    </div>
  )
}

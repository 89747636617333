import React from 'react'
import styles from './style.module.css'
import { Chart, ArcElement } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { YellowTentacleIcon } from 'src/v2/icons/assessments/yellow-tentacle'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { BlueTentacleIcon } from 'src/v2/icons/assessments/blue-tentacle'

Chart.register(ArcElement)

export const PrivacyDoughnutChart = ({ privacyScore, testedControls, criticalControls }) => {
  const data = {
    datasets: [
      {
        data: [testedControls, criticalControls],
        backgroundColor: ['#5885E6', '#FEB956'],
        borderWidth: 0,
        borderRadius: 0,
      },
    ],
  }

  const options = {
    responsive: true,
    cutout: '80%',

    tooltips: {
      enabled: true,
    },
  }

  return (
    <div>
      <div className={styles['overlap-group']}>
        <IconPlaceholder className={styles.vector} alt="Vector" icon={<BlueTentacleIcon />} />
        <IconPlaceholder
          className={styles['vector-2']}
          alt="Vector"
          icon={<YellowTentacleIcon />}
        />
      </div>
      <div className={styles['text-wrapper']}>Tested Controls</div>
      <div className={styles.div}>Critical Controls</div>
      <div className={styles['text-wrapper-2']}>{criticalControls}</div>
      <div className={styles['text-wrapper-3']}>{testedControls}</div>

      <div className={styles.section}>
        <p className={styles.element}>
          <span className={styles['percentage-wrapper']}>{privacyScore}</span>
          <span className={styles['symbol-wrapper']}>%</span>
        </p>
        <div className={styles['caption-wrapper']}>PRIVACY SCORE</div>
      </div>
      <Doughnut data={data} options={options}></Doughnut>
    </div>
  )
}

import axios from 'axios'
import AppConfig from '../config'

const baseURL = `${AppConfig.API_URL}/Vendor`

const getVendorBySearch = async (searchText) => {
  try {
    let { data } = await axios.get(`${baseURL}/search/${searchText}`)
    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export { getVendorBySearch }

import React from 'react'

export const PlainTick = ({ className }) => {
  return (
    <svg
      className={`icons-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="20"
        id="mask0_1682_29248"
        maskUnits="userSpaceOnUse"
        width="21"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" x="0.299805" />
      </mask>
      <g className="g" mask="url(#mask0_1682_29248)">
        <path
          className="path"
          d="M8.40419 12.6167L14.7696 6.25129C14.8784 6.14115 15.0057 6.08476 15.1517 6.08212C15.2975 6.07948 15.4281 6.13587 15.5436 6.25129C15.659 6.3667 15.7167 6.4949 15.7167 6.63587C15.7167 6.77698 15.6617 6.90254 15.5517 7.01254L8.88981 13.684C8.75426 13.8207 8.5962 13.889 8.41564 13.889C8.23495 13.889 8.0762 13.8207 7.93939 13.684L5.06439 10.809C4.95439 10.6989 4.89676 10.5725 4.89148 10.4298C4.88606 10.2873 4.94106 10.1584 5.05648 10.043C5.17189 9.92754 5.30071 9.86983 5.44294 9.86983C5.58516 9.86983 5.71169 9.92754 5.82252 10.043L8.40419 12.6167Z"
          fill="#F3F3F4"
        />
      </g>
    </svg>
  )
}

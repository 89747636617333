import React from 'react'
import styles from './style.module.css'
import { MitreDoughnutChart } from './MitreDoughnutChart'
import { MitreMetricCard } from 'src/v2/components/MitreMetricCard'

export const MitreCoverage = ({ reportData, thirdPartyName }) => {
  var chartData = reportData?.mitreFrameworkCoverage

  var totalVendorCoverage = 0,
    totalIndustryCoverage = 0
  chartData.forEach((eachCoverage) => {
    totalVendorCoverage += parseInt(eachCoverage.vendorCoverage)
    totalIndustryCoverage += parseInt(eachCoverage.industryCoverage)
  })
  return (
    <div className={styles.card}>
      <div className={styles.chart}>
        {' '}
        <div className={styles.frame}>
          <div className={styles['half-doughnut-chart']}>
            <MitreDoughnutChart
              vendorCoverage={parseInt(totalVendorCoverage / chartData.length)}
              industryCoverage={parseInt(totalIndustryCoverage / chartData.length)}
            />
          </div>
          <div className={styles.legends}>
            <div className={styles['div-2']}>
              <div className={styles.rectangle} />
              <div className={styles['text-wrapper-32']}>
                <b>{`${thirdPartyName}`}</b> coverage
              </div>
            </div>
            <div className={styles['div-2']}>
              <div className={styles['rectangle-2']} />
              <div className={styles['text-wrapper-32']}>
                Industry coverage - {parseInt(totalIndustryCoverage / chartData.length)}%
              </div>
            </div>
          </div>
        </div>
        <div className={styles.metrics}>
          <div className={styles['sub-section']}>
            <div className={styles.list}>
              {chartData?.map((eachCoverage, index) => {
                if (index < chartData.length / 2)
                  return (
                    <MitreMetricCard
                      key={index}
                      name={eachCoverage.name}
                      targetScore={eachCoverage.industryCoverage}
                      actualScore={eachCoverage.vendorCoverage}
                    />
                  )
                else return <></>
              })}
            </div>
            <div className={styles.divider} />
            <div className={styles['list-wrapper']}>
              {chartData?.map((eachCoverage, index) => {
                if (index >= chartData.length / 2 && index < chartData.length)
                  return (
                    <MitreMetricCard
                      key={index}
                      name={eachCoverage.name}
                      targetScore={eachCoverage.industryCoverage}
                      actualScore={eachCoverage.vendorCoverage}
                    />
                  )
                else return <></>
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

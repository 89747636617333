import React from 'react'
import styles from './style.module.css'
import { Card } from '@mui/material'
import { MetricCard } from 'src/v2/components/MetricCard'
import { FailedMetric } from 'src/v2/components/FailedMetric'
import { CTDoughnutChart } from './CTDoughnutChart'

export const ControlTestingMetrics = ({ reportData }) => {
  return (
    <Card className={styles.section}>
      <div className={styles.legends}>
        <div className={styles.div}>
          <div
            className={styles.rectangle}
            style={{ backgroundColor: 'var(--error-color-EC-l-150)' }}
          />
          <div className={styles['text-wrapper']}>High (0-40%)</div>
        </div>
        <div className={styles.div}>
          <div
            className={styles.rectangle}
            style={{ backgroundColor: 'var(--warning-color-2-WC-l-150)' }}
          />
          <div className={styles['text-wrapper']}>Medium (40 - 70%)</div>
        </div>
        <div className={styles.div}>
          <div
            className={styles.rectangle}
            style={{ backgroundColor: 'var(--confirmation-color-CC-l-150)' }}
          />
          <div className={styles['text-wrapper']}>Low (70-95%)</div>
        </div>
      </div>
      <div className={styles.headerborder} />
      <div className={styles.controltestingchart}>
        <div className={styles.metrics}>
          <div className={styles['sub-section']}>
            <MetricCard
              color={'error'}
              title={reportData?.controlTestingMetrics?.risk?.high}
              caption="HIGH"
            ></MetricCard>
            <div className={styles.divider} />
            <MetricCard
              color={'warning'}
              title={reportData?.controlTestingMetrics?.risk?.medium}
              caption="MEDIUM"
            ></MetricCard>
            <div className={styles.divider} />
            <MetricCard
              color={'success'}
              title={reportData?.controlTestingMetrics?.risk?.low}
              caption="LOW"
            ></MetricCard>
          </div>
          <div className={styles['sub-section-2']}>
            {' '}
            <FailedMetric
              title="FAILED IN-SCOPE CONTROL"
              failedCount={reportData?.controlTestingMetrics?.failedControls}
              totalCount={reportData?.controlTestingMetrics?.inscopeControls}
            ></FailedMetric>
            <div className={styles.divider} />
            <FailedMetric
              title="FAILED CRITICAL CONTROL"
              failedCount={reportData?.controlTestingMetrics?.failedCiriticalControls}
              totalCount={reportData?.controlTestingMetrics?.inscopeCiticalControls}
            ></FailedMetric>
          </div>
        </div>
        <div className={styles['half-doughnut-chart-wrapper']}>
          <div className={styles['half-doughnut-chart']}>
            <CTDoughnutChart
              secureScore={parseInt(reportData?.controlTestingMetrics?.overallSecureScore)}
            ></CTDoughnutChart>
          </div>
        </div>
      </div>
    </Card>
  )
}

import React from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { InfoIcon } from 'src/v2/icons/info'
import { RightArrow } from './icons/right-arrow'
import { formatKValue } from 'src/utils/commonFns'

export const ExternalAssets = ({
  impactedDomins,
  totalDomains,
  impactedIPAddresses,
  totalIPAddresses,
}) => {
  return (
    <div className={styles['external-assets']}>
      <div className={styles['frame-3']}>
        <div className={styles['section-3']}>
          <div className={styles['title-3']}>
            <div className={styles['title-data-3']}>External Assets</div>
            <IconPlaceholder
              icon={<InfoIcon className={styles['icons-6']} color="#7F8082" />}
              size="twenty-four-x-24"
            />
          </div>
        </div>
        <div className={styles['frame-4']}>
          <div className={styles.card}>
            <div className={styles['content-2']}>
              <div className={styles['value-icon']}>
                <div className={styles['text-wrapper-12']}>Domain</div>
                <IconPlaceholder
                  icon={<RightArrow className={styles['icon-instance-node']} />}
                  size="twenty-four-x-24"
                />
              </div>
              <div className={styles.metrics}>
                <div className={styles['sub-section']}>
                  <div className={styles.IMPACTED}>
                    <div className={styles['text-wrapper-13']}>IMPACTED</div>
                    <div className={styles['span-2']}>
                      <div className={styles['text-wrapper-14']}>
                        {formatKValue(impactedDomins)}
                      </div>
                    </div>
                  </div>
                  <div className={styles['divider-3']} />
                  <div className={styles['IP-address']}>
                    <div className={styles['text-wrapper-13']}>TOTAL</div>
                    <div className={styles['span-2']}>
                      <div className={styles['text-wrapper-15']}>{formatKValue(totalDomains)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['content-2']}>
            <div className={styles['value-icon']}>
              <div className={styles['text-wrapper-12']}>IP Addresses</div>
              <IconPlaceholder
                icon={<RightArrow className={styles['icon-instance-node']} />}
                size="twenty-four-x-24"
              />
            </div>
            <div className={styles.metrics}>
              <div className={styles['sub-section']}>
                <div className={styles.IMPACTED}>
                  <div className={styles['text-wrapper-13']}>IMPACTED</div>
                  <div className={styles['span-2']}>
                    <div className={styles['text-wrapper-14']}>
                      {formatKValue(impactedIPAddresses)}
                    </div>
                  </div>
                </div>
                <div className={styles['divider-3']} />
                <div className={styles['IP-address']}>
                  <div className={styles['text-wrapper-13']}>TOTAL</div>
                  <div className={styles['span-2']}>
                    <div className={styles['text-wrapper-15']}>
                      {formatKValue(totalIPAddresses)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import Button from '@mui/material/Button'
import classes from './style.module.css'
import { Icons9 } from '../../../../icons/Icons9'
import { Icons10 } from '../../../../icons/Icons10'
import './custom-theme.css'
import Menu from '@mui/material/Menu'
import { styled, alpha } from '@mui/material/styles'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Radio from '@mui/material/Radio'
import ButtonGroup from '@mui/material/ButtonGroup'
import dayjs from 'dayjs'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import CustomDateRangePicker from 'src/v2/components/DateRangePicker/CustomDateRangePicker'
import { definedDays } from 'src/utils/constants'

const StyledMenu = styled((props) => (
  <Menu
    {...props}
    elevation={0}
    MenuListProps={{
      sx: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }}
    anchorOrigin={
      !!props.anchorOrigin
        ? props.anchorOrigin
        : {
            vertical: 'bottom',
            horizontal: 'right',
          }
    }
    transformOrigin={
      !!props.transformOrigin
        ? props.transformOrigin
        : {
            vertical: 'top',
            horizontal: 'right',
          }
    }
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: 'var(--BG---100, #2C2C2C)',
    color: 'var(--Grey-900, #E8E8E8)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      gap: '8px',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

const defaultRadioOptions = [
  {
    label: 'Today',
    range: () => ({
      startDate: dayjs(definedDays.startOfToday),
      endDate: dayjs(definedDays.endOfToday),
    }),
  },
  {
    label: 'This Week',
    range: () => ({
      startDate: dayjs(definedDays.startOfWeek),
      endDate: dayjs(definedDays.endOfWeek),
    }),
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: dayjs(definedDays.startOfMonth),
      endDate: dayjs(definedDays.endOfMonth),
    }),
  },
  {
    label: 'This Year',
    range: () => ({
      startDate: dayjs(definedDays.startOfYear),
      endDate: dayjs(definedDays.endOfToday),
    }),
  },
  {
    label: 'Custom date',
  },
]

const DateRangeFilter = ({
  dateRangeValue,
  setDateRangeValue,
  anchorOrigin,
  transformOrigin,
  overrideRadioOptions,
  isMonthView,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const radioOptions = overrideRadioOptions ? overrideRadioOptions : defaultRadioOptions

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (event, checked) => {
    if (checked) {
      const item = radioOptions.find((item) => item.label === event.target.value)
      setDateRangeValue((prevVal) => ({
        ...(event.target.value !== 'Custom date' ? item.range() : prevVal),
        selectedOption: event.target.value,
      }))
      setAnchorEl(null)
    }
  }

  const btnGroupRef = React.useRef(null)

  const handleChange = (newValue) => {
    setDateRangeValue((preVal) => ({ ...preVal, ...newValue }))
  }

  return (
    <>
      <ButtonGroup variant="contained" ref={btnGroupRef}>
        <Button
          className={classes['date-filter-btn']}
          startIcon={<Icons9 className={classes['icon-instance-node']} />}
          endIcon={<Icons10 className={classes['icon-instance-node']} />}
          variant="outlined"
          onClick={(e) => {
            setAnchorEl(e.currentTarget)
          }}
        >
          {dateRangeValue.selectedOption}
        </Button>
        {dateRangeValue.selectedOption === 'Custom date' && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CustomDateRangePicker
              value={dateRangeValue}
              onChange={handleChange}
              error=""
              isMonthView={isMonthView}
            />
          </LocalizationProvider>
        )}
      </ButtonGroup>
      <StyledMenu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={dateRangeValue.selectedOption}
          >
            {radioOptions.map((option, index) => (
              <>
                <FormControlLabel
                  className={index !== radioOptions.length - 1 ? classes.radiobtnlabel : ''}
                  value={option.label}
                  control={
                    <Radio
                      onChange={handleMenuItemClick}
                      className={classes.radiobtn}
                      size="small"
                    />
                  }
                  label={option.label}
                />
              </>
            ))}
          </RadioGroup>
        </FormControl>
      </StyledMenu>
    </>
  )
}

export default DateRangeFilter

import React, { useEffect, useState } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SendIcon from '../../../../assets/images/send-icon.svg'
import CircularProgress from '@mui/material/CircularProgress'
import ChatMsg from './ChatMsg'
import { EnumRemediationGapStatus } from 'src/utils/constants'
import { hasValue } from 'src/utils/commonFns'
import { getQuery, postNewQuery } from 'src/services/remediationService'

const Chat = ({ remediation }) => {
  const [msgs, setMsgs] = useState([])
  const [newMsg, setNewMsg] = useState('')

  const [loading, setLoading] = useState(false)

  const handleSend = async () => {
    if (!hasValue(newMsg) || loading) return

    try {
      setLoading(true)

      const request = new FormData()
      request.append('RemediationGapID', remediation.remediationGapId)
      request.append('Message', newMsg.trim())
      const { result } = await postNewQuery(request)
      if (result) {
        setNewMsg('')
        getQueries()
      }
    } finally {
      setLoading(false)
    }
  }

  const getQueries = async () => {
    const { result } = await getQuery(remediation.remediationGapId)
    if (result) {
      setMsgs(result)
    }
  }

  useEffect(() => {
    if (hasValue(remediation.remediationGapId)) getQueries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remediation.remediationGapId])

  return (
    <>
      {([EnumRemediationGapStatus.ClientReview, EnumRemediationGapStatus.Closed].includes(
        remediation.remediationGapStatus,
      ) ||
        remediation.hasQueries) && (
        <div
          style={{
            width: '100%',
            padding: '16px',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            background: '#1B1C1E',
          }}
        >
          <div>
            {msgs.map((msg, index) => (
              <ChatMsg key={index} msg={msg} />
            ))}
          </div>
          <OutlinedInput
            sx={{
              marginTop: 'auto',
              padding: '12px',
              borderRadius: '12px',
              color: '#E8E8E8',
              border: '1px solid #FFFFFF14',
              background: '#0000001A',
              '& fieldset': {
                border: 'none',
              },
            }}
            value={newMsg}
            onChange={(e) => setNewMsg(e.target.value)}
            placeholder="Send a query"
            fullWidth
            multiline
            rows={4}
            maxRows={4}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  style={{ bottom: '4px', position: 'absolute', right: '14px' }}
                  onClick={handleSend}
                >
                  {loading ? (
                    <CircularProgress color="secondary" size={24} />
                  ) : (
                    <img alt="beaconer logo" src={SendIcon} />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
      )}
    </>
  )
}

export default Chat

import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import { CrossMark } from 'src/v2/icons/cross-mark'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import PropTypes from 'prop-types'
import { Modal, Tab, Tabs } from '@mui/material'
import { DomainDetails } from './DomainDetails'
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles'

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: 'var(--info-color-IC-base)',
            },
            fontFamily: 'var(--paragraph-r-p1-font-family)',
            fontStyle: 'var(--paragraph-r-p1-font-style)',
            textAlign: 'start',
            alignItems: 'start',
            fontSize: '13px',
            // fontSize: 'var(--paragraph-r-p1-font-size)',
            // fontWeight: 'var(--paragraph-r-p1-font-weight)',
            // letterSpacing: 'var(--paragraph-r-p1-letter-spacing)',
            // lineHeight: 'var(--paragraph-r-p1-line-height)',
          },
          textColorPrimary: {
            color: 'var(--neutral-color-grey-600)',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: { backgroundColor: 'var(--info-color-IC-base)', left: '0px' },
        },
      },
    },
  })

function CustomTabPanel(props) {
  const { children, value, index } = props
  return (
    value === index && (
      <div
        style={{ overflowY: 'scroll', display: 'flex', flexDirection: 'column', maxHeight: '75vh' }}
      >
        {children}
      </div>
    )
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

const DomainSummaryDetails = ({ open, handleAction, domainInfo, reportData }) => {
  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event) => {
    setTabValue(parseInt(event.target.id))
  }

  useEffect(() => {
    if (!!domainInfo && !!domainInfo.id) {
      setTabValue(domainInfo.id)
    }
  }, [domainInfo])

  const outerTheme = useTheme()

  return (
    <Modal open={open} className={styles.modal}>
      <div className={styles.modal}>
        <div className={styles.title}>
          <div className={styles['title-wrapper']}>Domain Summary</div>
          <IconPlaceholder
            icon={<CrossMark className={styles['icons-crossmark']} />}
            size="twenty-four-x-24"
            onClick={() => {
              handleAction(false)
            }}
          />
        </div>
        <div className={styles['div-wrapper']}>
          <div className={styles['tab-navigation']}>
            <ThemeProvider theme={customTheme(outerTheme)}>
              <Tabs orientation="vertical" onClick={handleTabChange} value={tabValue}>
                {reportData?.domains?.map((eachDomain, index) => (
                  <Tab
                    key={index}
                    className={styles['tabs-instance']}
                    label={eachDomain?.domainInfo?.domainName ?? eachDomain?.domainName}
                    id={eachDomain?.id}
                    value={eachDomain?.id}
                  />
                ))}
              </Tabs>
            </ThemeProvider>
          </div>
          {reportData?.domains?.map((eachDomain, index) => (
            <CustomTabPanel key={index} value={tabValue} index={eachDomain?.id}>
              <DomainDetails key={index} eachDomain={eachDomain} />
            </CustomTabPanel>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default DomainSummaryDetails

const styles = ({ palette, spacing }) => {
  return {
    leftRow: {
      textAlign: 'left',
      maxWidth: '75%',
    },
    rightRow: {
      textAlign: 'left',
      maxWidth: '75%',
      float: 'right',
    },
    msg: {
      padding: '12px',
      borderRadius: '12px',
      marginBottom: 4,
      display: 'inline-block',
      wordBreak: 'break-word',
      fontSize: '14px',
    },
    left: {
      backgroundColor: '#3a70e233',
      color: '#E8E8E8',
      borderTopLeftRadius: '0px',
    },
    right: {
      backgroundColor: '#262626',
      textAlign: 'left',
      color: '#E8E8E8',
      borderBottomRightRadius: '0px',
    },
    timestamp: {
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '16px',
      color: '#A2A3A4',
      textTransform: 'uppercase',
      display: 'inline-block !important',
      float: 'inherit',
    },
    timestamprow: {
      padding: '0px !important',
      float: 'right',
    },
    timestamprowleft: {
      paddingTop: '0px !important',
    },
    beaconerlogo: {
      display: 'inline-flex !important',
      flex: '0 0 auto !important',
      height: 'unset !important',
      width: 'unset !important',
      marginRight: '8px',
      marginLeft: '2px',
    },
    attachments: {
      textAlign: 'left',
      maxWidth: '75%',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    attachmentLink: {
      backgroundColor: '#3A4B6D',
      padding: '8px',
      borderRadius: '4px',
      display: 'block',
    },
  }
}

export default styles

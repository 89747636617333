import React, { useState } from 'react'
import styles from './style.module.css'
import { Modal, Tab, Tabs } from '@mui/material'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { CrossMark } from 'src/v2/icons/cross-mark'
import BeaconerLogo from 'src/v2/assets/images/beaconer-logo.svg'
import { RiskProfileDetailOverview } from './overview'
import PropTypes from 'prop-types'
import { RiskProfileDetailImpactedAssets } from './impacted-assets'
import { convertUnixTimeStampToDate } from 'src/utils/commonFns'

function CustomTabPanel(props) {
  const { value, index, assessmentInfo, profileInfo } = props
  if (value === 0 && index === 0)
    return <RiskProfileDetailOverview assessmentInfo={assessmentInfo} profileInfo={profileInfo} />
  if (value === 1 && index === 1)
    return (
      <RiskProfileDetailImpactedAssets assessmentInfo={assessmentInfo} profileInfo={profileInfo} />
    )
}

CustomTabPanel.propTypes = {
  assessmentDetails: PropTypes.object,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

export const ASRiskProfileDetails = ({ open, handleAction, profileInfo, assessmentInfo }) => {
  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (event) => {
    setTabValue(parseInt(event.target.id))
  }

  return (
    <Modal open={open} className={styles.modal}>
      <div className={styles.modal}>
        <div className={styles.title}>
          <div className={styles.vendor}>
            <img
              className={styles.avatar}
              alt="Avatar"
              src={assessmentInfo.logoUrl || BeaconerLogo}
            />
            <div className={styles['text-wrapper']}>
              {assessmentInfo.vendorName} - {assessmentInfo.assessmentNumber}
            </div>
          </div>
          <IconPlaceholder
            icon={<CrossMark className={styles['icon-instance-node']} />}
            size="twenty-four-x-24"
            onClick={() => {
              handleAction(false)
            }}
          />
        </div>
        <div className={styles.frame}>
          <div className={styles['control-metrics']}>
            <div className={styles.innerframe}>
              <div className={styles['text-wrapper']}>Security Finding Group</div>
              <div className={styles.div}>{profileInfo.name}</div>
            </div>
            <div className={styles.metrics}>
              <div className={styles['sub-section']}>
                <div className={styles['metric-card']}>
                  <div className={styles['text-wrapper-2']}>SEVERITY</div>
                  <div className={styles.span}>
                    <div
                      className={`${styles['text-wrapper-3']} ${
                        styles[profileInfo.level?.toLowerCase()]
                      }`}
                    >
                      {profileInfo.level}
                    </div>
                  </div>
                </div>
                <div className={styles.divider} />
                <div className={styles['metric-card']}>
                  <div className={styles['text-wrapper-2']}>IMPACTED ASSETS</div>
                  <div className={styles.span}>
                    <div className={styles['text-wrapper-4']}>
                      {profileInfo.assets?.length || 0}
                    </div>
                  </div>
                </div>
                <div className={styles.divider} />
                <div className={styles['metric-card']}>
                  <div className={styles['text-wrapper-2']}>CATEGORY</div>
                  <div className={styles.span}>
                    <div className={styles['text-wrapper-4']}>{profileInfo.category}</div>
                  </div>
                </div>
                <div className={styles.divider} />
                <div className={styles['metric-card']}>
                  <div className={styles['text-wrapper-2']}>FIRST DETECTED</div>
                  <div className={styles.span}>
                    <div className={styles['text-wrapper-4']}>
                      {convertUnixTimeStampToDate(profileInfo.first_seen)?.format('MMM DD, YYYY')}
                    </div>
                  </div>
                </div>
                <div className={styles.divider} />
                <div className={styles['metric-card']}>
                  <div className={styles['text-wrapper-2']}>LAST DETECTED</div>
                  <div className={styles.span}>
                    <div className={styles['text-wrapper-4']}>
                      {convertUnixTimeStampToDate(profileInfo.last_seen)?.format('MMM DD, YYYY')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.actions}>
                {/* <Button
                  className={styles['button-instance']}
                  size="medium"
                  state="active"
                  color="primary"
                >
                  Mark As Resolve
                </Button>
                <Button
                  className={styles['button-instance']}
                  size="medium"
                  state="active"
                  color="secondary"
                  text="Mark As Exception"
                >
                  Mark As Exception
                </Button> */}
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.section}>
              <div className={styles['tab-navigation']}>
                <Tabs onClick={handleTabChange} value={tabValue}>
                  <Tab
                    className={styles['tab-nav-instance']}
                    label="Overview"
                    id={0}
                    value={0}
                    style={{
                      flex: '0 0 auto !important',
                      textTransform: 'none',
                      padding: '8px 12px 16px !important',
                    }}
                  />
                  <Tab
                    className={styles['tab-nav-instance']}
                    label={'Impacted Assets (' + (profileInfo.assets?.length || 0) + ')'}
                    id={1}
                    value={1}
                    style={{
                      flex: '0 0 auto !important',
                      textTransform: 'none',
                      padding: '8px 12px 16px !important',
                    }}
                  />
                </Tabs>
              </div>
            </div>
            <CustomTabPanel
              value={tabValue}
              index={0}
              assessmentInfo={assessmentInfo}
              profileInfo={profileInfo}
            >
              1
            </CustomTabPanel>
            <CustomTabPanel
              value={tabValue}
              index={1}
              assessmentInfo={assessmentInfo}
              profileInfo={profileInfo}
            >
              2
            </CustomTabPanel>
          </div>
        </div>
      </div>
    </Modal>
  )
}

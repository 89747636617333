import PropTypes from 'prop-types'
import React from 'react'
import styles from './style.module.css'

export const FailedMetric = ({ title, failedCount, totalCount }) => {
  return (
    <div className={styles['failedmetric']}>
      <div className={styles['title-wrapper']}>{title}</div>
      <div className={styles['spandiv']}>
        <p className={styles['element-of']}>
          <span className={styles['failed-count-wrapper']}>{failedCount}</span>
          <span className={styles['total-count-wrapper']}> of {totalCount}</span>
        </p>
      </div>
    </div>
  )
}

FailedMetric.propTypes = {
  title: PropTypes.string,
  failedCount: PropTypes.string,
  totalCount: PropTypes.string,
}

import React from 'react'

export const AdminIcon = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <g id="person_search">
        <mask
          id="mask0_657_34630"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="30"
          height="30"
        >
          <rect id="Bounding box" width="30" height="30" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_657_34630)">
          <path
            id="person_search_2"
            d="M14.4014 15.1794C13.4348 15.1794 12.6153 14.8433 11.9431 14.1711C11.2709 13.4989 10.9348 12.6794 10.9348 11.7128C10.9348 10.7461 11.2709 9.92665 11.9431 9.25443C12.6153 8.5822 13.4348 8.24609 14.4014 8.24609C15.3681 8.24609 16.1875 8.5822 16.8598 9.25443C17.532 9.92665 17.8681 10.7461 17.8681 11.7128C17.8681 12.6794 17.532 13.4989 16.8598 14.1711C16.1875 14.8433 15.3681 15.1794 14.4014 15.1794ZM14.4084 14.1128C15.0704 14.1128 15.6348 13.8771 16.1014 13.4058C16.5681 12.9344 16.8014 12.3678 16.8014 11.7058C16.8014 11.0438 16.5658 10.4794 16.0944 10.0128C15.6231 9.54609 15.0564 9.31276 14.3944 9.31276C13.7324 9.31276 13.1681 9.54854 12.7014 10.0201C12.2348 10.4914 12.0014 11.0581 12.0014 11.7201C12.0014 12.3819 12.2371 12.9461 12.7084 13.4128C13.1798 13.8794 13.7464 14.1128 14.4084 14.1128ZM6.13477 22.6361V22.0668C6.13477 21.7045 6.24377 21.3785 6.46177 21.0888C6.67977 20.799 6.97332 20.5463 7.34243 20.3308C8.55777 19.6316 9.95732 19.086 11.5411 18.6938C13.1249 18.3013 14.7014 18.2018 16.2708 18.3951C16.4161 18.4462 16.537 18.5214 16.6334 18.6208C16.7301 18.7199 16.7784 18.8396 16.7784 18.9801C16.7784 19.0856 16.7301 19.1782 16.6334 19.2578C16.537 19.3373 16.4161 19.3711 16.2708 19.3591C14.6348 19.2326 13.0869 19.3591 11.6271 19.7384C10.1673 20.118 8.94254 20.6052 7.95277 21.2001C7.71677 21.3021 7.53254 21.4284 7.4001 21.5791C7.26765 21.7298 7.20143 21.8923 7.20143 22.0668V22.6874H15.2604C15.4115 22.6874 15.5382 22.7379 15.6404 22.8388C15.7427 22.9399 15.7938 23.0651 15.7938 23.2144C15.7938 23.3638 15.7427 23.491 15.6404 23.5961C15.5382 23.7014 15.4115 23.7541 15.2604 23.7541H7.25277C6.93454 23.7541 6.66865 23.6472 6.4551 23.4334C6.24154 23.2199 6.13477 22.9541 6.13477 22.6361Z"
            fill="#3A70E2"
          />
          <path
            id="shield"
            d="M20.7996 25.1022C20.8145 25.107 20.8294 25.1118 20.8443 25.1165L20.7996 25.2597C21.6153 25.0049 22.2898 24.4954 22.8231 23.7311C23.3565 22.9668 23.6231 22.1176 23.6231 21.1835V19.2512C23.6231 19.1904 23.6066 19.1357 23.5734 19.0872C23.5402 19.0385 23.4935 19.002 23.4331 18.9778M20.7996 25.1022C21.5607 24.8538 22.1939 24.3707 22.7001 23.6453C23.2158 22.9062 23.4731 22.087 23.4731 21.1835V19.2512C23.4731 19.2195 23.4651 19.1946 23.4495 19.1718L23.4494 19.1717C23.4349 19.1503 23.4133 19.1317 23.3782 19.1174M20.7996 25.1022C20.0385 24.8538 19.4053 24.3707 18.8991 23.6453C18.3834 22.9062 18.1261 22.087 18.1261 21.1835V19.2512C18.1261 19.2195 18.1341 19.1946 18.1497 19.1718L18.1498 19.1717C18.1644 19.1503 18.1859 19.1317 18.221 19.1174L20.7538 18.1462L20.7538 18.1462L20.7563 18.1452C20.7666 18.1411 20.7804 18.1379 20.7996 18.1379C20.8188 18.1379 20.8326 18.1411 20.843 18.1452L20.843 18.1453L20.8454 18.1462L23.3782 19.1174M20.7996 25.1022L20.8991 18.0062L23.4331 18.9778M23.3782 19.1174L23.3794 19.1178L23.4331 18.9778M23.3782 19.1174C23.3778 19.1172 23.3775 19.1171 23.3772 19.117L23.4331 18.9778M17.9694 18.4813L17.9694 18.4813L17.9687 18.4816C17.81 18.5434 17.682 18.6463 17.5886 18.788C17.4956 18.9291 17.4496 19.0879 17.4496 19.2603V21.1835C17.4496 22.18 17.7275 23.0958 18.2821 23.9274C18.8363 24.7584 19.5758 25.3512 20.4977 25.7024C20.5496 25.7259 20.6061 25.7383 20.664 25.7432C20.7139 25.7473 20.7593 25.7496 20.7996 25.7496C20.8399 25.7496 20.8853 25.7473 20.9352 25.7432C20.9932 25.7383 21.0497 25.7259 21.1015 25.7024C22.0234 25.3512 22.7629 24.7584 23.3171 23.9274C23.8717 23.0958 24.1496 22.18 24.1496 21.1835V19.2603C24.1496 19.0879 24.1036 18.9291 24.0106 18.788C23.9172 18.6463 23.7892 18.5434 23.6305 18.4816L23.6298 18.4813L21.0968 17.51C20.9983 17.4709 20.8989 17.4496 20.7996 17.4496C20.7003 17.4496 20.6009 17.4709 20.5025 17.51L17.9694 18.4813Z"
            fill="#3A70E2"
            stroke="#3A70E2"
            strokeWidth="0.3"
          />
        </g>
      </g>
    </svg>
  )
}

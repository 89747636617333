/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import styles from './PendingActions.module.scss'
import ActionItem from './ActionItem'
import InfoIcon from '../../../icons/InfoIcon.svg'
import RemediationsPendingIcon from '../../../icons/RemediationsPendingIcon.svg'
import ReportsPendingIcon from '../../../icons/ReportsPendingIcon.svg'
import Paper from '@mui/material/Paper'
import { EnumAssessmentTypes } from 'src/utils/commonFns'

const PendingActions = ({ handleDrawerOpen, pendingCounts }) => {
  return (
    <Paper className={styles.actionRequired} elevation={3}>
      <div className={styles.actionParent}>
        <div className={styles.actionChild}>
          <div className={styles.actionGrandchild}>
            {/* <a className={styles.titleData2}>Pending Actions</a> */}
            <h3 className={styles.titleData2}>{`Pending Actions `}</h3>
          </div>
          <div className={styles.iconPlaceholder7}>
            <img className={styles.icons7} loading="lazy" alt="" src={InfoIcon} />
          </div>
        </div>
      </div>
      <div className={styles.actionItemParent}>
        <ActionItem
          heading="Reports \n pending approval"
          icons={ReportsPendingIcon}
          prop={pendingCounts?.pendingApproval ?? 0}
          propAlignSelf="unset"
          propFlex="1"
          handleDrawerOpen={() => handleDrawerOpen(EnumAssessmentTypes.ReportsPendingApproval)}
        />
        <ActionItem
          heading="Remediations \n pending approval"
          icons={RemediationsPendingIcon}
          prop={pendingCounts?.pendingApprovalRemediations ?? 0}
          propAlignSelf="unset"
          propFlex="1"
          handleDrawerOpen={() => handleDrawerOpen(EnumAssessmentTypes.RemediationsPendingApproval)}
        />
      </div>
    </Paper>
  )
}

export default PendingActions

import React from 'react'
import styles from './style.module.css'
import { Chart, ArcElement } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

Chart.register(ArcElement)

export const DSDoughnutChart = ({ percentage }) => {
  const color = percentage > 70 ? '#30AC79' : percentage > 40 ? '#FF7738' : '#EF4D4F'

  const data = {
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [color, '#3A3C3E'],
        borderWidth: 0,
        borderRadius: 0,
      },
      {
        data: [40, 30, 30],
        backgroundColor: ['#EF4D4F', '#FF7738', '#30AC79'],
        weight: 0.3,
        borderWidth: 2,
        spacing: 1,
        borderRadius: 0.5,
        borderColor: 'var(--collection-BG-500) !important',
      },
    ],
  }

  const options = {
    responsive: true,
    cutout: '80%',
    tooltips: {
      enabled: true,
    },
  }

  return (
    <div>
      <div className={styles.ranges}>
        <div className={styles['overlap-group']}>
          <div className={styles['text-wrapper-100pixel']}>100%</div>
          <div className={styles['text-wrapper-40pixel']}>40%</div>
          <div className={styles['text-wrapper-70pixel']}>70%</div>
        </div>
      </div>
      <div className={styles.section}>
        <p className={styles.element}>
          <span className={styles['percentage-wrapper']}>{percentage}</span>
          <span className={styles['symbol-wrapper']}>%</span>
        </p>
        {/* <div className={styles['caption-wrapper']}>SECURE SCORE</div> */}
      </div>
      <Doughnut data={data} options={options}></Doughnut>
    </div>
  )
}

import React from 'react'
import classes from './style.module.css'
import { Button } from '@mui/material'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { UploadIcon } from '../../icons/UploadIcon'
import { ShareIcon } from '../../icons/ShareIcon'

export const AnalyticsHeader = () => {
  return (
    <div className={classes.header}>
      <div className={classes['tab-navigation']}>
        <div className={`${classes['tab-nav']} ${classes['design-component-instance-node']}`}>
          <div className={classes['project-details']}>{'Risk Posture'}</div>
          <div className={`${classes['selection-line']} ${classes['tab-nav-instance']}`} />
        </div>
      </div>
      <div className={classes.frame}>
        <Button variant="outlined" className={classes['button-instance']}>
          Create Custom Report
        </Button>
        <Button
          variant="outlined"
          className={classes['button-instance']}
          startIcon={
            <IconPlaceholder
              icon={<UploadIcon className={classes['icons-2']} />}
              size="twenty-four-x-24"
            />
          }
        >
          Export
        </Button>
        <Button
          variant="outlined"
          className={classes['button-instance']}
          startIcon={
            <IconPlaceholder
              icon={<ShareIcon className={classes['icons-2']} />}
              size="twenty-four-x-24"
              className={classes['share-icon']}
            />
          }
        >
          Share
        </Button>
      </div>
    </div>
  )
}

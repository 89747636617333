import React from 'react'
import { CToaster, CToast, CToastBody, CToastHeader } from '@coreui/react'
import { useToast } from '../../../context/toast'

const Toasts = () => {
  const { toastList, removeToast } = useToast()

  const onShow = (toast) => {
    if (toast.options && toast.options.autoClose === false) return
    setTimeout(
      (toast) => {
        removeToast(toast)
      },
      toast.options?.closeTimeOut || 5 * 1000,
      toast,
    )
  }

  return (
    <CToaster>
      {toastList.map((toast) => (
        <CToast
          animation
          autohide={false}
          visible={true}
          onShow={() => onShow(toast)}
          onClose={() => removeToast(toast)}
          key={toast.key}
        >
          <CToastHeader closeButton>
            <div className="fw-bold me-auto">{toast.header}</div>
          </CToastHeader>
          <CToastBody>{toast.message}</CToastBody>
        </CToast>
      ))}
    </CToaster>
  )
}

export default Toasts

import React from 'react'
import styles from './style.module.css'
import { Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { CrossMark } from 'src/v2/icons/cross-mark'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

export const AssessmentFramework = ({ open, handleAction, assessmentFramework }) => {
  return (
    <Modal open={open} className={styles.modal}>
      <div className={styles.modal}>
        <div className={styles.title}>
          <div className={styles['text-wrapper-22']}>Assessment Framework</div>
          <IconPlaceholder
            icon={<CrossMark className={styles['icons-1']} />}
            size="twenty-four-x-24"
            onClick={() => {
              handleAction(false)
            }}
          />
        </div>
        <div className={styles.assessment}>
          <div className={styles['beaconer-has']}>
            <ReactMarkdown
              // eslint-disable-next-line react/no-children-prop
              children={assessmentFramework}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            />
          </div>
          {/* <div className={styles.rectangle} /> */}
          <div className={styles['section-wrapper']}>
            <div className={styles['title-2']}>
              <div className={styles['text-wrapper-10']}>Data Classification</div>
            </div>
            <Table className={styles['table-3']}>
              <TableHead style={{ width: '100%' }}>
                <TableRow style={{ width: '100%', justifyContent: 'evenly' }}>
                  <TableCell className={styles['cell-14']} style={{ width: '31%' }}>
                    <div className={styles['text-wrapper-11']}>DATA CLASSIFICATION CATEGORIES</div>
                  </TableCell>
                  <TableCell className={styles['cell-14']} style={{ width: '37%' }}>
                    <div className={styles['text-wrapper-11']}>DESCRIPTION</div>
                  </TableCell>
                  <TableCell className={styles['cell-14']} style={{ width: '37%' }}>
                    <div className={styles['text-wrapper-11']}>EXAMPLE DATA TYPES</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['ellipse-9']} />
                      <div className={styles['text-wrapper-12']}>Public</div>
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>
                      Data that if breached owing to accidental or malicious activity would have an
                      insignificant on the organisation activities and impact objectives.
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>Marketing Data</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['ellipse-10']} />
                      <div className={styles['text-wrapper-12']}>Internal</div>
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>
                      Data that if breached owing to Accidental or malicious activity would have a
                      on low impact the organisations activities and objectives.
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>
                      Company internal Data, Business Data, Employee Directory, Policy/procedures,
                      Employee Business contact
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['ellipse-11']} />
                      <div className={styles['text-wrapper-12']}>Confidential</div>
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>
                      Data that if breached owing to Accidental or malicious activity would have a
                      on low impact the organisations activities and objectives.
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>
                      Employee Confidential records, Financial/commercial data, PI Data
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['ellipse-12']} />
                      <div className={styles['text-wrapper-12']}>Secret</div>
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>
                      Data that if breached owing to Accidental or malicious activity would have a
                      on low impact the organisations activities and objectives.
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>
                      Regulatory/compliance Data, SPI/PHI, Intellectual Property Data, Sensitive
                      Security related Data
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          <div className={styles['section-wrapper']}>
            <div className={styles['title-2']}>
              <div className={styles['text-wrapper-10']}>Control Effectiveness Matrix</div>
            </div>
            <Table className={styles['table-3']}>
              <TableHead style={{ width: '100%' }}>
                <TableRow style={{ width: '100%', justifyContent: 'evenly' }}>
                  <TableCell className={styles['cell-14']} style={{ width: '20%' }}>
                    <div className={styles['text-wrapper-11']}>EFFECTIVENESS</div>
                  </TableCell>
                  <TableCell className={styles['cell-14']} style={{ width: '20%' }}>
                    <div className={styles['text-wrapper-11']}>CONTROL TESTING METRICS</div>
                  </TableCell>
                  <TableCell className={styles['cell-14']} style={{ width: '20%' }}>
                    <div className={styles['text-wrapper-11']}>GAPS</div>
                  </TableCell>
                  <TableCell className={styles['cell-14']} style={{ width: '20%' }}>
                    <div className={styles['text-wrapper-11']}>GAP SEVERITY</div>
                  </TableCell>
                  <TableCell className={styles['cell-14']} style={{ width: '20%' }}>
                    <div className={styles['text-wrapper-11']}>CRITICAL GAPS</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div className={styles['text-wrapper-12']}>Not effective</div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['ellipse-12']} />
                      <div className={styles['text-wrapper-12']}>0-49%</div>
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>-</div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>-</div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>More than 5 High</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div className={styles['text-wrapper-12']}>Partially effective</div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['ellipse-11']} />
                      <div className={styles['text-wrapper-12']}>50-75%</div>
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>5-8 Gaps</div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>Mediums & Lows</div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>4 or less High</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div className={styles['text-wrapper-12']}>Mostly effective</div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['ellipse-10']} />
                      <div className={styles['text-wrapper-12']}>76-100%</div>
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>1-4 Gaps</div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>Mediums & Lows</div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>No High</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div className={styles['text-wrapper-12']}>Fully effective</div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['ellipse-9']} />
                      <div className={styles['text-wrapper-12']}>Above 95%</div>
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>No gaps</div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>No gaps</div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>No High</div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          <div className={styles['section-wrapper']}>
            <Table className={styles['table-3']}>
              <TableHead style={{ width: '100%' }}>
                <TableRow style={{ width: '100%', justifyContent: 'evenly' }}>
                  <TableCell className={styles['cell-14']} style={{ width: '20%' }}>
                    <div className={styles['text-wrapper-11']}>RATING</div>
                  </TableCell>
                  <TableCell className={styles['cell-14']} style={{ width: '80%' }}>
                    <div className={styles['text-wrapper-11']}>DESCRIPTION</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['ellipse-12']} />
                      <div className={styles['text-wrapper-12']}>Not effective</div>
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-11']}>
                    <div className={styles['text-wrapper-7']}>
                      <ul>
                        <li>
                          Significant Issues have been identified in the control environment and
                          require immediate management attention
                        </li>
                        <li>
                          Key controls provide insufficient/little assurance that the risk event
                          will not occur, or if it does it will be detected timely to minimize the
                          impact
                        </li>
                      </ul>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['ellipse-11']} />
                      <div className={styles['text-wrapper-12']}>Partially effective</div>
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-11']}>
                    <div className={styles['text-wrapper-7']}>
                      <ul>
                        <li>
                          Couple of Issues have been identified in the control environment and
                          require attention
                        </li>
                        <li>
                          Key controls provide some assurance that the risk event will not occur, or
                          if it does it will be detected timely to minimise the impact
                        </li>
                        <li>
                          The key controls are generally detective rather than preventive in nature
                        </li>
                      </ul>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['ellipse-10']} />
                      <div className={styles['text-wrapper-12']}>Mostly effective</div>
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-11']}>
                    <div className={styles['text-wrapper-7']}>
                      <ul>
                        <li>Few Minor Issues have been identified in the control environment.</li>
                        <li>
                          Key controls provide Reasonable assurance that the risk event will not
                          occur, or if it does it will be detected timely to minimise the impact
                        </li>
                        <li>{'The majority key controls (>75%) are effective'}</li>
                        <li>
                          {' '}
                          {'No key control is rated as "needs Improvement" or "In effective"'}
                        </li>{' '}
                      </ul>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['ellipse-9']} />
                      <div className={styles['text-wrapper-12']}>Fully effective</div>
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-11']}>
                    <div className={styles['text-wrapper-7']}>
                      <ul>
                        <li> No Issues have been identified in the control environment.</li>
                        <li>
                          Key controls provide complete assurance that the risk event will not
                          occur, or if it does it will be detected timely to minimize the impact
                        </li>
                        <li> {'The majority of key controls (>95%) are effective'}</li>
                        <li>
                          {' '}
                          {'No key control is rated as "needs Improvement" or "In effective"'}
                        </li>{' '}
                      </ul>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          <div className={styles['section-wrapper']}>
            <div className={styles['title-2']}>
              <div className={styles['text-wrapper-10']}>Risk Description</div>
            </div>
            <Table className={styles['table-3']}>
              <TableHead style={{ width: '100%' }}>
                <TableRow style={{ width: '100%', justifyContent: 'evenly' }}>
                  <TableCell className={styles['cell-14']} style={{ width: '20%' }}>
                    <div className={styles['text-wrapper-11']}>RISK</div>
                  </TableCell>
                  <TableCell className={styles['cell-14']} style={{ width: '80%' }}>
                    <div className={styles['text-wrapper-11']}>DESCRIPTION</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div
                      className={styles['text-wrapper-12']}
                      style={{ color: 'var(--confirmation-color-CC-l-150)' }}
                    >
                      Low
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>
                      Acceptable risk and no further action required if the risk has been minimized
                      as far as possible. Risk needs to be reviewed periodically
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div
                      className={styles['text-wrapper-12']}
                      style={{ color: 'var(--warning-color-2-WC-l-150)' }}
                    >
                      Medium
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>
                      Tolerable with further action required to minimize Risk. Risk needs to be
                      reviewed periodically
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles['cell-13']}>
                    <div
                      className={styles['text-wrapper-12']}
                      style={{ color: 'var(--error-color-EC-l-150)' }}
                    >
                      High
                    </div>
                  </TableCell>
                  <TableCell className={styles['cell-15']}>
                    <div className={styles['text-wrapper-13']}>
                      Unacceptable Risk and further action required immediately to minimize it
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </Modal>
  )
}

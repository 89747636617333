import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Toasts from './components/common/toast/Toast'
import AxiosInterceptor from './context/axios'
import { AuthProvider } from './context/login'
import { ToastProvider } from './context/toast'
import './scss/style.scss'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-loading-skeleton/dist/skeleton.css'
import ProtectedComponent from './components/common/ProtectedComponent'
import { SkeletonTheme } from 'react-loading-skeleton'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login'))

const App = () => {
  return (
    <ToastProvider>
      <AxiosInterceptor>
        <AuthProvider>
          <SkeletonTheme baseColor="#414247" highlightColor="#656871">
            <BrowserRouter basename={'/client'}>
              <Suspense fallback={loading}>
                <Routes>
                  <Route exact index path="/" name="Login Page" element={<Login />} />
                  <Route
                    path="*"
                    name="Home"
                    element={
                      <ProtectedComponent>
                        <DefaultLayout />
                      </ProtectedComponent>
                    }
                  />
                </Routes>
              </Suspense>
            </BrowserRouter>
          </SkeletonTheme>
          <Toasts />
        </AuthProvider>
      </AxiosInterceptor>
    </ToastProvider>
  )
}

export default App

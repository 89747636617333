import React from 'react'

export const RemediationIcon = ({ className }) => {
  return (
    <svg
      className={`icons-7 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="21"
        id="mask0_523_1345"
        maskUnits="userSpaceOnUse"
        width="20"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" y="0.5" />
      </mask>
      <g className="g" mask="url(#mask0_523_1345)">
        <path
          className="path"
          d="M8.10417 13.1167L14.4696 6.75129C14.5783 6.64115 14.7057 6.58476 14.8517 6.58212C14.9975 6.57948 15.1281 6.63587 15.2435 6.75129C15.359 6.8667 15.4167 6.9949 15.4167 7.13587C15.4167 7.27698 15.3617 7.40254 15.2517 7.51254L8.58979 14.184C8.45424 14.3207 8.29618 14.389 8.11563 14.389C7.93493 14.389 7.77618 14.3207 7.63938 14.184L4.76438 11.309C4.65438 11.1989 4.59674 11.0725 4.59146 10.9298C4.58604 10.7873 4.64104 10.6584 4.75646 10.543C4.87188 10.4275 5.0007 10.3698 5.14292 10.3698C5.28514 10.3698 5.41167 10.4275 5.5225 10.543L8.10417 13.1167Z"
          fill="#30AC79"
        />
      </g>
    </svg>
  )
}

import React, { useCallback, useEffect, useState } from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { TotalUsersIcon } from 'src/v2/icons/manage-team/total-users'
import { DeleteUser, getUsersByRole } from 'src/services/UserService'
import { AdminIcon } from 'src/v2/icons/manage-team/admin'
import { RequestorIcon } from 'src/v2/icons/manage-team/requestor'
import {
  Avatar,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ClickAwayListener,
  Popper,
} from '@mui/material'
import { Table } from 'reactstrap'
// import { SortIcon } from 'src/v2/icons/manage-team/sort'
import { capitalizeFirstLetter } from 'src/utils/commonFns'
import { KebabMenuWhiteIcon } from 'src/v2/icons/manage-team/kebab-menu-white'
import Confirmation from 'src/v2/components/Confirmation'
import { useSnackbar } from 'notistack'
import AddEditTeam from './add-team'
import TableRowsLoader from 'src/v2/components/Table/TableRowsLoader'

export const ManageTeam = () => {
  const [teamUsers, setTeamUsers] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [isNewTeamModal, setIsNewTeamModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const getUsersList = useCallback(async () => {
    try {
      setIsLoading(true)
      const roles = ['BusinessAdmin', 'BusinessRequestor']
      const data = await getUsersByRole(roles)
      setSelectedUser(null)
      setTeamUsers(data)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const clickAwayHandler = () => {
    setOpen(false)
  }

  useEffect(() => {
    getUsersList()
  }, [getUsersList])

  const handleConfirmationClose = async (isCancel, isClose) => {
    setOpenConfirmation(false)
    if (isClose) return

    if (!isCancel) {
      try {
        var result = await DeleteUser(selectedUser.id)
        if (result) {
          enqueueSnackbar('User deleted successfully', {
            variant: 'success',
            autoHideDuration: 5000,
            hideIconVariant: true,
          })
        }
      } finally {
        getUsersList()
      }
    }
  }

  const handleSave = (isReload) => {
    setIsNewTeamModalOpen(false)
    if (isReload) getUsersList()
  }

  useEffect(() => {
    document.addEventListener('newTeamUser', () => {
      setSelectedUser(null)
      setIsNewTeamModalOpen(true)
    })
    return () => {
      document.removeEventListener('newTeamUser', () => {
        setSelectedUser(null)
        setIsNewTeamModalOpen(true)
      })
    }
  }, [])

  return (
    <div className={styles['manage-team']}>
      <div className={styles.cards}>
        <div className={styles.content}>
          <div className={styles['value-icon']}>
            <div className={styles['text-wrapper-3']}>Total Users</div>
            <div className={styles['icon-11']}>
              <IconPlaceholder
                className={styles['icon-placeholder-11']}
                icon={<TotalUsersIcon className={styles['icons-7']} />}
                size="twenty-four-x-24"
              />
            </div>
          </div>
          <div className={styles['value-4']}>
            <div className={styles['text-wrapper-4']}>{teamUsers?.length ?? 0}</div>
          </div>
          <div className={styles.rectangle} />
        </div>
        <div className={styles.content}>
          <div className={styles['value-icon']}>
            <div className={styles['text-wrapper-3']}>Admins</div>
            <div className={styles['icon-11']}>
              <IconPlaceholder
                className={styles['icon-placeholder-11']}
                icon={<AdminIcon className={styles['icons-7']} />}
                size="twenty-four-x-24"
              />
            </div>
          </div>
          <div className={styles['value-4']}>
            <div className={styles['text-wrapper-4']}>
              {teamUsers?.filter((x) => x.roles === 'BusinessAdmin')?.length ?? 0}
            </div>
          </div>
          <div className={styles['rectangle-2']} />
        </div>
        <div className={styles.content}>
          <div className={styles['value-icon']}>
            <div className={styles['text-wrapper-3']}>Requestors</div>
            <div className={styles['icon-11']}>
              <IconPlaceholder
                className={styles['icon-placeholder-11']}
                icon={<RequestorIcon className={styles['icons-7']} />}
                size="twenty-four-x-24"
              />
            </div>
          </div>
          <div className={styles['value-4']}>
            <div className={styles['text-wrapper-4']}>
              {teamUsers?.filter((x) => x.roles === 'BusinessRequestor')?.length ?? 0}
            </div>
          </div>
          <div className={styles['rectangle-3']} />
        </div>
      </div>
      <div className={styles['table-wrapper']}>
        <div className={styles.section}>
          <div className={styles.title}>
            <div className={styles['title-data']}>Team Member List</div>
          </div>
        </div>
        <div className={styles['table']}>
          <TableContainer sx={{ maxHeight: 650 }}>
            <Table stickyHeader>
              <TableHead style={{ width: '100%' }}>
                <TableRow className={styles.headerrow}>
                  <TableCell className={styles.cell} align="center" width={260}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['heading']}>NAME</div>
                      {/* <IconPlaceholder
                        className={styles.icon}
                        icon={<SortIcon className={styles['icons']} />}
                        size="twenty-four-x-24"
                      /> */}
                    </div>
                  </TableCell>
                  <TableCell className={styles.cell} align="center" width={260}>
                    <div className={styles['heading']}>EMAIL ID</div>
                  </TableCell>
                  <TableCell className={styles.cell} align="center" width={180}>
                    <div className={styles['heading']}>ROLE</div>
                  </TableCell>
                  <TableCell className={styles.cell} align="center" width={180}>
                    <div className={styles['heading']}>BUSINESS UNIT</div>
                  </TableCell>
                  <TableCell className={styles.cell} align="center" width={100}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles['heading']}>ADDED ON</div>
                      {/* <IconPlaceholder
                        className={styles.icon}
                        icon={<SortIcon className={styles['icons']} />}
                        size="twenty-four-x-24"
                      /> */}
                    </div>
                  </TableCell>
                  <TableCell className={styles.cell} align="center" width={50}>
                    <div className={styles['heading']}>ACTION</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={styles.tablebody}>
                {isLoading ? (
                  <>
                    <TableRowsLoader rows={2} columns={6} tableCellClassName={styles['cell-15']} />
                  </>
                ) : !!teamUsers && teamUsers?.length > 0 ? (
                  teamUsers.map((eachUser, index) => {
                    // const riskColor =
                    //   row.issueSeverity.toLowerCase() === 'low'
                    //     ? styles.lowrisk
                    //     : row.issueSeverity.toLowerCase() === 'medium'
                    //     ? styles.mediumrisk
                    //     : styles.highrisk
                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? 'var(--collection-BG-500)' : '#0000001a',
                        }}
                      >
                        <TableCell className={styles['cell-15']}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '16px',
                              alignItems: 'center',
                            }}
                          >
                            <Avatar src="/broken-image.jpg" sx={{ width: 32, height: 32 }} />
                            <div className={styles['text-wrapper-2']}>
                              {eachUser.firstName} {eachUser.lastName}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className={styles['cell-15']}>
                          <div className={styles['text-wrapper-email']}>{eachUser.email}</div>
                        </TableCell>
                        <TableCell className={styles['cell-15']}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '12px',
                              alignItems: 'center',
                            }}
                          >
                            <IconPlaceholder
                              className={styles['icon-placeholder-11']}
                              icon={
                                eachUser.roles === 'BusinessAdmin' ? (
                                  <AdminIcon className={styles['icons-7']} />
                                ) : (
                                  <RequestorIcon className={styles['icons-7']} />
                                )
                              }
                              size="twenty-four-x-24"
                            />
                            <div className={styles['text-wrapper-2']}>
                              {eachUser.roles === 'BusinessAdmin' ? 'Admin' : 'Requestor'}
                            </div>
                            {/* <div className={styles.roleicon}>
                              <IconPlaceholder
                                className={styles['icon-placeholder-instance']}
                                icon={<ExpandIcon className={styles['icon-instance-node']} />}
                                size="twenty-x-20"
                              />
                            </div> */}
                          </div>
                        </TableCell>
                        <TableCell className={styles['cell-15']}>
                          <div className={styles['text-wrapper-2']}>
                            {capitalizeFirstLetter(eachUser.department)}
                          </div>
                        </TableCell>
                        <TableCell className={styles['cell-15']}>
                          <div className={styles['text-wrapper-2']}>{eachUser.createdOn}</div>
                        </TableCell>
                        <TableCell className={styles['cell-15']}>
                          <IconPlaceholder
                            icon={<KebabMenuWhiteIcon />}
                            size="twenty-x-24"
                            onClick={(e) => {
                              setSelectedUser(eachUser)
                              handleClick(e)
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <h6 className={styles.norecords}>No Records to Display</h6>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {open && (
        <ClickAwayListener onClickAway={clickAwayHandler}>
          <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
            <div className={styles['popper-content']}>
              <div
                className={`${'menu-links'} ${styles['text-wrapper-1']}`}
                onClick={() => {
                  setIsNewTeamModalOpen(true)
                }}
              >
                Edit
              </div>
              <div
                className={`${'menu-links'} ${styles['text-wrapper']}`}
                onClick={() => {
                  setOpenConfirmation(true)
                }}
              >
                Remove
              </div>
            </div>
          </Popper>
        </ClickAwayListener>
      )}
      {openConfirmation && (
        <Confirmation
          open={openConfirmation}
          handleAction={handleConfirmationClose}
          title={'Cancel Request'}
          message={`${'Are you sure you want to remove'} "${selectedUser?.firstName} ${
            selectedUser?.lastName
          }" ${'from the team members list'}`}
          secondaryBtnText={'Remove'}
          primaryBtnText={'Cancel'}
          infoIcon={true}
          showReason={false}
        />
      )}
      {isNewTeamModal && (
        <AddEditTeam open={isNewTeamModal} handleAction={handleSave} {...selectedUser} />
      )}
    </div>
  )
}

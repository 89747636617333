import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { convertUnixTimeStampToDate } from 'src/utils/commonFns'
import classes from './styles.module.scss'

export const TechnologyTableType = {
  Assets: 1,
  CVEs: 2,
}

export const SimpleTable = ({ assetInfo, type }) => {
  const data = type === TechnologyTableType.Assets ? assetInfo.assets : assetInfo.cves

  const headCells =
    type === TechnologyTableType.Assets ? ['Asset', 'Source', 'Last Detected'] : ['CVE']
  return (
    <div className={classes.frametab2}>
      <div className={classes['table-wrapper']}>
        <Box className={classes.content}>
          <TableContainer className={classes.table}>
            <Table size={'medium'}>
              <TableHead className={classes.tablehead}>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell className={classes.tableheadcell} key={headCell}>
                      <>{headCell}</>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tablebody}>
                {data.length > 0 ? (
                  data.map((row, index) => (
                    <TableRow key={index} className={`${'menu-links'} ${classes['vendor-row']}`}>
                      {type === TechnologyTableType.Assets ? (
                        <>
                          <TableCell className={classes.tablecell}>{row.asset}</TableCell>
                          <TableCell className={classes.tablecell}>{row.source}</TableCell>
                          <TableCell className={classes.tablecell}>
                            {convertUnixTimeStampToDate(row.found)?.format('MMM DD, YYYY')}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell className={classes.tablecell}>{row}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={TechnologyTableType.Assets ? 3 : 1}
                      className={classes.tablecell}
                      style={{ textAlign: 'center' }}
                    >
                      <h6 className={classes.norecords}>No Records to Display</h6>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {/* </div> */}
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import styles from './style.module.css'
import { Button, Tab, Tabs } from '@mui/material'
import { PlusIcon } from 'src/v2/assets/icons/plus-icon'
import PropTypes from 'prop-types'
import { ManageTeam } from './manage-team'

function CustomTabPanel(props) {
  const { value, index } = props
  if (value === 0 && index === 0) return <ManageTeam />
}

CustomTabPanel.propTypes = {
  assessmentDetails: PropTypes.object,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

export const Settings = () => {
  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (event) => {
    setTabValue(parseInt(event.target.id))
  }
  return (
    <div className={styles['table-screen']}>
      <div className={styles['section-wrapper']}>
        <div className={styles['tab-navigation']}>
          <div className={styles['left-section']}>
            <Tabs onClick={handleTabChange} value={tabValue}>
              <Tab
                className={styles['design-component-instance-node-2']}
                label="Manage Team"
                id={0}
                value={0}
              />
            </Tabs>
          </div>
          <div className={styles['right-section']}>
            {tabValue === 0 && (
              <Button
                className={styles['button-instance']}
                startIcon={<PlusIcon className={styles['icons-2']} />}
                size="medium"
                variant="contained"
                text="  Add Member"
                onClick={() => {
                  const newTeamUser = new Event('newTeamUser')
                  document.dispatchEvent(newTeamUser)
                }}
              >
                Add Member
              </Button>
            )}
          </div>
        </div>
      </div>
      <CustomTabPanel value={tabValue} index={0}>
        1
      </CustomTabPanel>
    </div>
  )
}

import React from 'react'

export const IssueIdentifiedIcon = ({ className }) => {
  return (
    <svg
      className={`icons-5 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="20"
        id="mask0_523_11625"
        maskUnits="userSpaceOnUse"
        width="20"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" />
      </mask>
      <g className="g" mask="url(#mask0_523_11625)">
        <path
          className="path"
          d="M7.93271 13.0124C6.50966 13.0124 5.30452 12.5189 4.31729 11.5318C3.33021 10.5445 2.83667 9.33941 2.83667 7.91635C2.83667 6.4933 3.33021 5.28816 4.31729 4.30094C5.30452 3.31385 6.50966 2.82031 7.93271 2.82031C9.35577 2.82031 10.5609 3.31385 11.5481 4.30094C12.5352 5.28816 13.0288 6.4933 13.0288 7.91635C13.0288 8.51149 12.9289 9.0799 12.7292 9.62156C12.5293 10.1632 12.2627 10.6343 11.9294 11.0349L16.7244 15.8299C16.8398 15.9452 16.8988 16.0902 16.9015 16.2649C16.9041 16.4396 16.8451 16.5873 16.7244 16.708C16.6037 16.8287 16.4573 16.8891 16.2852 16.8891C16.1133 16.8891 15.9669 16.8287 15.8463 16.708L11.0513 11.913C10.6346 12.257 10.1554 12.5263 9.61375 12.7207C9.07209 12.9152 8.51174 13.0124 7.93271 13.0124ZM7.93271 11.7626C9.00646 11.7626 9.91591 11.39 10.661 10.6447C11.4063 9.89955 11.779 8.9901 11.779 7.91635C11.779 6.8426 11.4063 5.93316 10.661 5.18802C9.91591 4.44274 9.00646 4.0701 7.93271 4.0701C6.85896 4.0701 5.94952 4.44274 5.20438 5.18802C4.4591 5.93316 4.08646 6.8426 4.08646 7.91635C4.08646 8.9901 4.4591 9.89955 5.20438 10.6447C5.94952 11.39 6.85896 11.7626 7.93271 11.7626Z"
          fill="#7F8082"
        />
      </g>
    </svg>
  )
}

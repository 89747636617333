import React from 'react'
import styles from './style.module.css'
import { DomainSummaryCard } from './DomainSummaryCard'

export const DomainSummary = ({ reportData, handleDomainSummaryDetails }) => {
  const count = reportData?.domains?.length
  const sectionsCount = (count / 3) % 1 === 0 ? count / 3 : parseInt(count / 3) + 1

  const mapDomains = (index) => {
    var startdataIndex = index * 3
    var endIndex = startdataIndex + 3
    const cards = []
    for (let index = startdataIndex; index < endIndex; index++) {
      if (index < count)
        cards.push(
          <DomainSummaryCard
            key={index}
            className={styles.card}
            eachDomainData={reportData?.domains[index]}
            handleDomainSummaryDetails={handleDomainSummaryDetails}
          />,
        )
      else cards.push(<div className={styles.emptycard}></div>)
    }
    return cards
  }

  const mapSections = () => {
    const sections = []
    for (let index = 0; index < sectionsCount; index++) {
      sections.push(
        <div className={styles.section}>
          <div style={{ width: '100%', display: 'flex', gap: '20px' }}>{mapDomains(index)}</div>
        </div>,
      )
    }
    return sections
  }

  return (
    <>
      <div className={styles['section-screen']}>{mapSections()}</div>
    </>
  )
}

import React from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { DraftIcon } from 'src/v2/icons/draft-icon'

export const DraftCard = ({
  key,
  id,
  draftNumber,
  onClick,
  bgcolor = 'var(--collection-BG-100)',
  thirdPartyName,
}) => {
  return (
    <div
      className={styles.draft}
      id={id}
      onClick={() => {
        onClick(id)
      }}
      data-draft="1"
      style={{ backgroundColor: bgcolor }}
    >
      <div className={styles['assessment-card-1']}>
        <div className={styles['icon-placeholder-instance-wrapper']}>
          <IconPlaceholder
            className={styles['icon-placeholder-4']}
            icon={<DraftIcon className={styles['icons-25']} />}
            size="twenty-eight-x-28"
          />
        </div>
        <div className={styles.content - 3}>
          <div className={styles['level-5']}>
            <div className={styles['title-4']}>
              <div className={styles['left-3']}>
                {!thirdPartyName && (
                  <div className={styles['text-wrapper-14']}>
                    New Assessment Request {draftNumber}
                  </div>
                )}
                {thirdPartyName && (
                  <div className={styles['text-wrapper-14']}>{thirdPartyName}</div>
                )}
              </div>
            </div>
            <div className={styles['level-6']}>
              <div className={styles['text-wrapper-15']}>Draft</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

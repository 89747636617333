import React, { useEffect, useRef, useState } from 'react'
import styles from './style.module.css'

export const NistLineChart = ({ nistRating }) => {
  const vendorCircleRef = useRef(null)
  const [offsetWidth, setOffsetWidth] = useState('0px')

  useEffect(() => {
    setOffsetWidth(vendorCircleRef?.current?.offsetWidth)
  }, [vendorCircleRef])
  return (
    <>
      <div className={styles['section-wrapper']}>
        <div className={styles['y-axis']}>
          {nistRating?.map((eachCategory, index) => {
            return (
              <div key={index} className={styles['div']}>
                {eachCategory.categoryName}
              </div>
            )
          })}
        </div>
        <div className={styles['axis-line']} ref={vendorCircleRef}>
          {nistRating?.map((eachCategory, index) => {
            return (
              <div key={index} className={styles['metric-card']}>
                <div
                  className={styles['text-wrapper']}
                  style={{
                    left:
                      eachCategory.score <= 5
                        ? eachCategory.score * 20 + '%'
                        : eachCategory.score + '%',
                  }}
                >
                  {parseFloat(eachCategory.score)}
                </div>
                {eachCategory.targetScore !== eachCategory.score ? (
                  <div
                    className={styles['text-wrapper']}
                    style={{
                      left:
                        eachCategory.targetScore <= 5
                          ? eachCategory.targetScore * 20 + '%'
                          : eachCategory.targetScore + '%',
                    }}
                  >
                    {parseFloat(eachCategory.targetScore)}
                  </div>
                ) : null}
                <div className={styles['progress-bar']}>
                  {eachCategory.targetScore > eachCategory.score ? (
                    <div
                      className={styles['industry-score-gap']}
                      style={{
                        width:
                          eachCategory.targetScore <= 5
                            ? eachCategory.targetScore * 20 + '%'
                            : eachCategory.targetScore + '%',
                      }}
                    />
                  ) : null}
                  <div className={styles['overlap-group-wrapper']}>
                    <div
                      className={styles['vendor-score-circle']}
                      style={{
                        left:
                          eachCategory.score <= 5
                            ? eachCategory.score * 20 + '%'
                            : eachCategory.score + '%',
                      }}
                    />
                    <div
                      className={styles['vendor-score']}
                      style={{
                        width:
                          eachCategory.score <= 5
                            ? eachCategory.score * 20 + '%'
                            : eachCategory.score + '%',
                      }}
                    />
                    {eachCategory.targetScore === eachCategory.score ? (
                      <div
                        className={styles['industry-score-circle']}
                        style={{
                          left: (offsetWidth / 5) * eachCategory.targetScore + 3,
                        }}
                      />
                    ) : (
                      <div
                        className={styles['industry-score-circle']}
                        style={{
                          left:
                            eachCategory.targetScore <= 5
                              ? eachCategory.targetScore * 20 + '%'
                              : eachCategory.targetScore + '%',
                        }}
                      />
                    )}
                  </div>
                  {index === nistRating.length - 1 ? (
                    <>
                      <div className={styles['text-wrapper-2']} style={{ left: 0 + '%' }}>
                        0
                      </div>
                      <div className={styles['text-wrapper-2']} style={{ left: 20 + '%' }}>
                        1
                      </div>
                      <div className={styles['text-wrapper-2']} style={{ left: 40 + '%' }}>
                        2
                      </div>
                      <div className={styles['text-wrapper-2']} style={{ left: 60 + '%' }}>
                        3
                      </div>
                      <div className={styles['text-wrapper-2']} style={{ left: 80 + '%' }}>
                        4
                      </div>
                      <div className={styles['text-wrapper-2']} style={{ left: 100 + '%' }}>
                        5
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

import axios from 'axios'
import AppConfig from '../config'

const baseURL = `${AppConfig.API_URL}/ClientSubmissionReport`

const getClientSubmissionReportById = async (id) => {
  try {
    let { data } = await axios.get(`${baseURL}/${id}`)
    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const updateClientSubmissionReportStatus = async (request) => {
  try {
    let { data } = await axios.put(`${baseURL}/update-status`, request)
    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export { getClientSubmissionReportById, updateClientSubmissionReportStatus }

import React, { useRef } from 'react'
import { IconPlaceholder } from '../../../../components/IconPlaceholder'
import { SearchBar } from '../../../../components/SearchBar'
import { Icons6 } from '../../../../icons/Icons6'

import classes from './style.module.css'

import donotDisturb from '../../../../assets/icons/remediation/dropdown/do-not-disturb.png'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { styled, alpha } from '@mui/material/styles'
import { CollapseIcon } from 'src/v2/assets/icons/remediation/header/collapse'
import { ExpandIcon } from 'src/v2/assets/icons/remediation/header/expand'

import DateRangeFilter from './DateRangeFilter'
import { SortBy } from './SortBy'
import { remediationMenuItems } from 'src/utils/constants'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: 'var(--BG---100, #2C2C2C)',
    color: 'var(--Grey-900, #E8E8E8)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      gap: '8px',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

export const Frame = ({
  selectedRemediationType,
  setSelectedRemediationType,
  searchQuery,
  setSearchQuery,
  dateRangeValue,
  setDateRangeValue,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const titleRef = useRef(null)
  const open = Boolean(anchorEl)

  const handleClick = () => {
    setAnchorEl(titleRef.current)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuClickHandler = (type) => {
    setSelectedRemediationType(type)
    handleClose()
  }

  return (
    <div className={classes.frame}>
      <div className={classes.title} ref={titleRef}>
        {selectedRemediationType.name === 'Un-responded' ? (
          <img className={classes['do-not-disturb-on']} alt="Do not disturb" src={donotDisturb} />
        ) : (
          <IconPlaceholder
            className={classes['icon-placeholder-instance']}
            icon={<selectedRemediationType.icon className={classes['icon-instance-node']} />}
            size="thirty-two-x-32"
          />
        )}
        <div className={classes['title-2']} onClick={handleClick}>
          {selectedRemediationType.label}
        </div>
        <IconPlaceholder
          className={classes['design-component-instance-node']}
          icon={
            open ? (
              <CollapseIcon className={classes['icon-instance-node']} />
            ) : (
              <ExpandIcon className={classes['icon-instance-node']} />
            )
          }
          size="twenty-eight-x-28"
          onClick={handleClick}
        />
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className={classes.dropdown}
        >
          {remediationMenuItems.map((item, index) => (
            <>
              <MenuItem onClick={() => menuClickHandler(item)} className={classes['menu-item']}>
                {item.icon && (
                  <IconPlaceholder
                    className={classes['icon-placeholder-instance']}
                    icon={<item.icon className={classes['icon-instance-node']} />}
                    size="thirty-two-x-32"
                  />
                )}
                {item.name === 'Un-responded' && (
                  <img
                    className={classes['do-not-disturb-on']}
                    alt="Do not disturb"
                    src={donotDisturb}
                  />
                )}
                {item.label}
              </MenuItem>
              {index !== remediationMenuItems.length - 1 && <Divider className={classes.divider} />}
            </>
          ))}
        </StyledMenu>
      </div>
      <div className={classes['search-filter']}>
        <SearchBar
          className={classes['search-bar-instance']}
          iconPlaceholderIcon={<Icons6 className={classes['icon-instance-node']} />}
          placeholder="Search Vendor"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <div className={classes.button}>
          <SortBy />
        </div>
        <div className={classes.button}>
          <DateRangeFilter {...{ dateRangeValue, setDateRangeValue }} />
        </div>
      </div>
    </div>
  )
}

import React from 'react'

export const DownloadIcon = ({ className }) => {
  return (
    <svg
      className={`icons-2 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="16"
        id="mask0_815_19774"
        maskUnits="userSpaceOnUse"
        width="16"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="16" width="16" />
      </mask>
      <g className="g" mask="url(#mask0_815_19774)">
        <path
          className="path"
          d="M8 10.2743C7.91967 10.2743 7.84489 10.2615 7.77567 10.2358C7.70644 10.2103 7.64061 10.1663 7.57817 10.1038L5.50517 8.03083C5.40606 7.93161 5.35711 7.81556 5.35833 7.68267C5.35967 7.54978 5.40861 7.43161 5.50517 7.32817C5.60861 7.22483 5.72739 7.17144 5.8615 7.168C5.99572 7.16456 6.11456 7.21456 6.218 7.318L7.5 8.6V3.5C7.5 3.35811 7.54789 3.23933 7.64367 3.14367C7.73933 3.04789 7.85811 3 8 3C8.14189 3 8.26067 3.04789 8.35633 3.14367C8.45211 3.23933 8.5 3.35811 8.5 3.5V8.6L9.782 7.318C9.88122 7.21889 9.99894 7.16994 10.1352 7.17117C10.2715 7.1725 10.3914 7.22483 10.4948 7.32817C10.5914 7.43161 10.6414 7.54872 10.6448 7.6795C10.6483 7.81028 10.5983 7.92739 10.4948 8.03083L8.42183 10.1038C8.35939 10.1663 8.29356 10.2103 8.22433 10.2358C8.15511 10.2615 8.08033 10.2743 8 10.2743ZM4.20517 13C3.86839 13 3.58333 12.8833 3.35 12.65C3.11667 12.4167 3 12.1316 3 11.7948V10.4872C3 10.3453 3.04789 10.2265 3.14367 10.1308C3.23933 10.0351 3.35811 9.98717 3.5 9.98717C3.64189 9.98717 3.76067 10.0351 3.85633 10.1308C3.95211 10.2265 4 10.3453 4 10.4872V11.7948C4 11.8462 4.02139 11.8932 4.06417 11.9358C4.10683 11.9786 4.15383 12 4.20517 12H11.7948C11.8462 12 11.8932 11.9786 11.9358 11.9358C11.9786 11.8932 12 11.8462 12 11.7948V10.4872C12 10.3453 12.0479 10.2265 12.1437 10.1308C12.2393 10.0351 12.3581 9.98717 12.5 9.98717C12.6419 9.98717 12.7607 10.0351 12.8563 10.1308C12.9521 10.2265 13 10.3453 13 10.4872V11.7948C13 12.1316 12.8833 12.4167 12.65 12.65C12.4167 12.8833 12.1316 13 11.7948 13H4.20517Z"
          fill="#CEDBF8"
        />
      </g>
    </svg>
  )
}

import React, { createRef } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import styles from './style.module.css'
import { CrossMark } from 'src/v2/icons/cross-mark'
import Dropzone from 'react-dropzone'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'

const UploadNdaDocument = ({ open, handleAction }) => {
  const dropzoneRef = createRef()
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }
  return (
    <Dialog open={open} className={styles.modal}>
      <div className={styles.modal}>
        <div className={styles.title}>
          <div className={styles['title-icon']}>
            <div className={styles['text-wrapper']}>Upload Documents</div>
          </div>
          <IconPlaceholder
            icon={<CrossMark className={`${'menu-links'} ${styles['icon-instance-node']}`} />}
            size="twenty-four-x-24"
            onClick={() => handleAction(null)}
          />
        </div>
        <Dropzone ref={dropzoneRef} noClick noKeyboard>
          {({ getRootProps, getInputProps, acceptedFiles }) => {
            if (acceptedFiles.length > 0) {
              handleAction(acceptedFiles)
            }
            return (
              <>
                <div className={styles.description}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <div className={styles.section}>
                      <div className={styles['description-text']}>Drag and Drop file</div>
                      <div className={styles.div}>OR</div>
                      <Button
                        className={styles['button-instance']}
                        color="secondary"
                        size="medium"
                        variant="contained"
                        text={'Browse file'}
                        onClick={openDialog}
                        autoFocus
                        style={{ textTransform: 'none' }}
                      >
                        {'Browse file'}
                      </Button>
                    </div>
                    <p className={styles.p}>
                      Accepted files formats *.dox, *.pd, *.txt
                      <br />
                      File size should not exceed 2MB
                    </p>
                  </div>
                </div>
              </>
            )
          }}
        </Dropzone>
        <div className={styles['button-wrapper']}>
          <Button
            className={styles['button-instance']}
            color="secondary"
            size="large"
            variant="contained"
            text={'Cancel'}
            onClick={() => handleAction(null)}
            autoFocus
            style={{ textTransform: 'none' }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default UploadNdaDocument

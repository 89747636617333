import React from 'react'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import InfoIcon from 'src/v2/screens/dashboard/icons/InfoIcon.svg'
import styles from './style.module.scss'
import Chart from 'react-apexcharts'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

const ResidualRisk = ({ residualRiskDataCounts }) => {
  const labels = ['High', 'Medium', 'Low']
  const colors = ['#EF4D4F', '#FF7738', '#30AC79']
  const chartOptions = useChartOptions(labels, colors)
  const chartSeries = [
    residualRiskDataCounts?.high ?? 0,
    residualRiskDataCounts?.medium ?? 0,
    residualRiskDataCounts?.low ?? 0,
  ]
  const totalSum = chartSeries.reduce((acc, val) => acc + val, 0)

  return (
    <Paper className={`${styles.section} residual-risk-section`} elevation={3}>
      <Stack direction="column" spacing={2} sx={{ width: '100%', height: '100%' }}>
        <div className={styles.section1}>
          <div className={styles.dataContainer}>
            <h3 className={styles.titleData}>{`Residual Risk `}</h3>
          </div>
          <div className={styles.iconPlaceholder}>
            <img className={styles.icons} loading="lazy" alt="" src={InfoIcon} />
          </div>
        </div>
        {/* <div className={styles.subHeading}>
          <p>Jun 2022 - Jun 2024</p>
        </div> */}
        <Stack spacing={2} sx={{ height: '100%', justifyContent: 'center' }}>
          <div className={styles.apexChartWrapper}>
            <Chart
              options={chartOptions}
              series={chartSeries}
              type="donut"
              width="100%"
              height={256}
              className={styles.apexChart}
            />
          </div>
          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: 'center', justifyContent: 'center' }}
          >
            {chartSeries.map((item, index) => {
              const label = labels[index]
              return (
                <Stack key={label} spacing={1} className={styles.legenedBox}>
                  <Grid container>
                    <Grid item xs={3} className={styles.legendDot}>
                      <span style={{ backgroundColor: colors[index] }} />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography className={styles.legenedHeading} variant="h6">
                        {label}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9} className={styles.legenedValue}>
                      <Typography variant="subtitle2">{item}</Typography>
                      <Typography variant="body2">{`(${parseInt(
                        (item / totalSum) * 100,
                      )}%)`}</Typography>
                    </Grid>
                  </Grid>
                </Stack>
              )
            })}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}

function useChartOptions(labels, colors) {
  const theme = useTheme()

  return {
    chart: {
      background: 'transparent',
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    colors: colors,
    dataLabels: { enabled: false },
    labels,
    legend: { show: false },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '75%',
          labels: {
            show: true,
            value: {
              color: '#FAFAFA',
              fontWeight: 600,
              fontSize: '36px',
              offsetY: -28,
              fontFamily: '"SF Pro Display", Helvetica',
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total Risk',
              color: '#A2A3A4',
              fontFamily: '"SF Pro Text", Helvetica',
              fontWeight: 600,
              fontSize: '12px',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                })
              },
            },
          },
        },
      },
    },
    states: {
      active: { filter: { type: 'none' } },
      hover: { filter: { type: 'none' } },
    },
    stroke: { width: 4, colors: ['#2c2c2c'] },
    theme: { mode: theme.palette.mode },
    tooltip: { fillSeriesColor: false },
    className: 'risk-metrics-chart',
  }
}

export default ResidualRisk

import React from 'react'
import { CloseIcon } from '../../../../icons/CloseIcon'
import { Box, Typography } from '@material-ui/core'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'

export const Header = ({ handleDrawerClose, DrawerType, total }) => {
  return (
    <Box className={styles.header}>
      <Box className={styles['title-close']}>
        <Box className={styles['content-8']}>
          <Typography className={styles['title-8']}>{DrawerType}</Typography>
          <Typography className={styles['text-wrapper-17']}>Total ({total ?? 0})</Typography>
        </Box>
        <IconPlaceholder
          className={styles['icon-placeholder-7']}
          icon={<CloseIcon className={styles['icons-12']} />}
          size="twenty-eight-x-28"
          onClick={handleDrawerClose}
        />
      </Box>
    </Box>
  )
}

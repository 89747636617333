import React, { useState, useEffect, useCallback, useRef } from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { InputBase, InputAdornment } from '@mui/material'
import { DraftCard } from './DraftCard'
import { AssessmentCard } from './AssessmentCard'
import { SearchIcon } from 'src/v2/icons/search-icon'
import { AssessmentRequest } from './AssessmentRequest'
import { v4 as uuidv4 } from 'uuid'
import { getClientSubmissions } from 'src/services/clientSubmissionService'
import { AssessmentDetails } from './AssessmentDetails'
import { CircleLoader } from 'src/v2/components/CircleLoader'
import { ClientSubmissionStatus } from 'src/utils/commonFns'
import { useSnackbar } from 'notistack'

const iconPlaceholderIcon = <SearchIcon className={styles['search-bar-icon']} />

var selectedDraftData = []

export const Assessments = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [draftData, setDraftData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [showNewRequest, setShowNewRequest] = useState(false)
  // const [showSavedDraft, setShowSavedDraft] = useState(false)
  const [selectedAssessment, setSelectedAssessment] = useState(null)
  const [assessmentsData, setAssessmentsData] = useState([])
  const [showAssessmentDetails, setShowAssessmentDetails] = useState(false)
  const [isListLoading, setIsListLoading] = useState(false)
  const [assessmentCount, setAssessmentCount] = useState(0)
  // const [selectedDraftData, setSelectedDraftData] = useState()

  const refList = useRef()

  const [quickFilters, setQuickFilters] = useState({
    all: 'selected-filter',
    allText: 'text-wrapper-selected',
    pending: 'not-selected-filter',
    pendingText: 'text-wrapper-not-selected',
    highRisk: 'not-selected-filter',
    highRiskText: 'text-wrapper-not-selected',
  })

  const quickFilterClick = (selectedFilter) => {
    switch (selectedFilter) {
      case 2:
        setQuickFilters({
          all: 'not-selected-filter',
          allText: 'text-wrapper-not-selected',
          pending: 'selected-filter',
          pendingText: 'text-wrapper-selected',
          highRisk: 'not-selected-filter',
          highRiskText: 'text-wrapper-not-selected',
        })
        getAssessments('PendingApproval', false)
        break
      case 3:
        setQuickFilters({
          all: 'not-selected-filter',
          allText: 'text-wrapper-not-selected',
          pending: 'not-selected-filter',
          pendingText: 'text-wrapper-not-selected',
          highRisk: 'selected-filter',
          highRiskText: 'text-wrapper-selected',
        })
        getAssessments('all', true)
        break
      default:
        setQuickFilters({
          all: 'selected-filter',
          allText: 'text-wrapper-selected',
          pending: 'not-selected-filter',
          pendingText: 'text-wrapper-not-selected',
          highRisk: 'not-selected-filter',
          highRiskText: 'text-wrapper-not-selected',
        })
        getAssessments('all', false, true)
        break
    }
  }

  const newRequest = useCallback(() => {
    if (draftData.length > 0) {
      enqueueSnackbar('Request already Exists, Please save as Draft to create new Request', {
        // heading: 'Accepted',
        variant: 'error',
        autoHideDuration: 5000,
        hideIconVariant: true,
      })

      return
    }
    var newData = {
      key: uuidv4(),
      draftNumber: draftData.length + 1,
      data: {},
      status: ClientSubmissionStatus.Draft,
    }
    var addedData = draftData.concat(newData)
    refList?.current?.querySelector('[data-assessment]')?.scrollIntoView(false)
    setDraftData(addedData)
    setSelectedAssessment(newData)

    setShowAssessmentDetails(false)
    setShowNewRequest(true)
  }, [draftData, enqueueSnackbar])

  useEffect(() => {
    function beforeUnload(e) {
      if (!draftData || draftData.length <= 0) return

      e.preventDefault()
      e.returnValue = 'Test'
    }

    window.addEventListener('beforeunload', beforeUnload)

    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [draftData])

  useEffect(() => {
    document.addEventListener('newAssesRequest', newRequest)
    return () => {
      document.removeEventListener('newAssesRequest', newRequest)
    }
  }, [newRequest])

  useEffect(() => {
    getAssessments('all', false, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reloadData = (draftNumber) => {
    if (draftNumber > 0) {
      draftData.splice(draftNumber - 1, 1)
      setDraftData(draftData)
    }
    getAssessments('all', false, true)
  }

  const getAssessments = async (
    displayStatus,
    highRisk,
    resetCount = false,
    quickSearchText = '',
  ) => {
    setIsListLoading(true)
    var result = await getClientSubmissions(
      quickSearchText ? quickSearchText : searchText,
      displayStatus,
      undefined,
      highRisk,
    )
    setIsListLoading(false)
    if (result && result.length > 0) {
      if (resetCount) setAssessmentCount(result.length)
      // setAssessmentsData(result.filter((x) => x.id === 29))
      setAssessmentsData(result)
      if (draftData && draftData.length > 0) getSelectedAssessment(draftData[0])
      else getSelectedAssessment(result[0])
    } else {
      setSelectedAssessment(null)
      setAssessmentsData(null)
      setShowNewRequest(false)
      setShowAssessmentDetails(false)
    }
  }

  const getSelectedAssessment = (assessment, isFromDraft = false) => {
    // if (
    //   assessment.status === ClientSubmissionStatus.Completed ||
    //   assessment.status === ClientSubmissionStatus.Accepted
    // ) {
    //   setShowNewRequest(false)
    //   setShowAssessmentDetails(true)
    // } else {
    //   if (isFromDraft) {
    //     selectedDraftData.splice(assessment.draftNumber - 1, 1)

    //     selectedDraftData.push({
    //       draftNumber: assessment.draftNumber,
    //       id: null,
    //       data: assessment.data,
    //     })
    //   }
    //   setShowAssessmentDetails(false)
    //   setShowNewRequest(true)
    // }

    if (isFromDraft || assessment.status === ClientSubmissionStatus.Draft) {
      if (isFromDraft) {
        selectedDraftData.splice(assessment.draftNumber - 1, 1)

        selectedDraftData.push({
          draftNumber: assessment.draftNumber,
          id: null,
          data: assessment.data,
        })
      }
      setShowAssessmentDetails(false)
      setShowNewRequest(true)
    } else {
      setShowNewRequest(false)
      setShowAssessmentDetails(true)
    }

    setSelectedAssessment(assessment)
  }

  const saveDraftData = (draftNumber, id, assessmentData) => {
    var currentDraftData = null
    if (draftNumber > 0) {
      currentDraftData = draftData.find((x) => x.draftNumber === draftNumber)
      if (currentDraftData) {
        draftData.splice(draftData.indexOf(currentDraftData), 1)
        currentDraftData.data = assessmentData
        currentDraftData.thirdPartyName = assessmentData.thirdPartyName
        var addedData = draftData.concat(currentDraftData)
        setDraftData(addedData)
      }
    } else if (id > 0) {
      currentDraftData = selectedDraftData.find((x) => x.id === id)
      if (currentDraftData) selectedDraftData.splice(selectedDraftData.indexOf(currentDraftData), 1)
      selectedDraftData.push({
        draftNumber: null,
        id: id,
        data: assessmentData,
      })
    }
  }

  return (
    <div className={styles['assessment-form']}>
      {/* <div className={styles['overlap-wrapper']}>
        <div className={styles.overlap}> */}
      <div className={styles['assessment-list']}>
        {/* Quick Filter */}
        <div className={styles['quick-filter']}>
          <div className={styles['segmented-buttons']}>
            <div
              className={`${'menu-links'} ${styles[quickFilters.all]}`}
              onClick={() => {
                quickFilterClick(1)
              }}
            >
              <div className={styles[quickFilters.allText]}>ALL</div>
            </div>
            <div
              className={`${'menu-links'} ${styles[quickFilters.pending]}`}
              onClick={() => {
                quickFilterClick(2)
              }}
            >
              <div className={styles[quickFilters.pendingText]}>PENDING APPROVAL</div>
            </div>
            <div
              className={`${'menu-links'} ${styles[quickFilters.highRisk]}`}
              onClick={() => {
                quickFilterClick(3)
              }}
            >
              <div className={styles[quickFilters.highRiskText]}>HIGH RISK</div>
            </div>
          </div>
          {/* <IconPlaceholder
            icon={<KebabMenuIcon className={styles['icons-21']} />}
            size="twenty-four-x-24"
            onClick={() => {}}
          /> */}
        </div>
        {/* Title with Search */}
        <div className={styles.title}>
          <div className={styles['text-wrapper-31']}>All Assessments ({assessmentCount}/120)</div>
          {/* <IconPlaceholder
            icon={<ReverseStairsMenuIcon className={styles['icons-22']} />}
            size="twenty-four-x-24"
          /> */}
        </div>
        {/* Search Bar */}
        <div className={styles.level}>
          <InputBase
            sx={{ height: 36 }}
            className={`${styles['search-bar']}`}
            startAdornment={
              <InputAdornment position="start">
                <IconPlaceholder icon={iconPlaceholderIcon} size="twenty-four-x-24" />
              </InputAdornment>
            }
            placeholder="Search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
              getAssessments('all', false, false, e.target.value)
            }}
          ></InputBase>
        </div>
        {/* List */}

        {isListLoading && (
          <div className={styles.loader}>
            <CircleLoader size={100}></CircleLoader>
          </div>
        )}
        {!isListLoading && (
          <div className={styles.list} ref={refList}>
            {draftData &&
              draftData.length > 0 &&
              draftData.map((eachDraftData, index) => (
                <DraftCard
                  key={index}
                  id={eachDraftData.key}
                  draftNumber={eachDraftData.draftNumber}
                  thirdPartyName={eachDraftData.thirdPartyName}
                  onClick={(id) => {
                    getSelectedAssessment(
                      draftData.find((x) => x.key === id),
                      true,
                    )
                  }}
                  bgcolor={selectedAssessment.id === eachDraftData.id ? '#3A3C3E' : ''}
                />
              ))}
            {assessmentsData &&
              assessmentsData.map((eachAssessment, index) => (
                <AssessmentCard
                  key={index}
                  assessment={eachAssessment}
                  onClick={(id) => {
                    getSelectedAssessment(assessmentsData.find((x) => x.id === id))
                  }}
                  bgcolor={selectedAssessment.id === eachAssessment.id ? '#3A3C3E' : ''}
                />
              ))}
            {(!draftData || draftData?.length <= 0) &&
              (!assessmentsData || assessmentsData?.length <= 0) && (
                <div style={{ marginTop: '49%' }}>No Data Available</div>
              )}
          </div>
        )}
      </div>
      <div>
        {!isListLoading && showNewRequest ? (
          <AssessmentRequest
            draftNumber={selectedAssessment?.draftNumber}
            id={selectedAssessment?.id}
            reloadData={reloadData}
            saveDraftData={saveDraftData}
            draftData={
              selectedAssessment?.draftNumber > 0
                ? selectedDraftData.find((x) => x.draftNumber === selectedAssessment.draftNumber)
                : selectedAssessment?.id > 0
                ? selectedDraftData.find((x) => x.id === selectedAssessment.id)
                : null
            }
          />
        ) : null}
        {!isListLoading && showAssessmentDetails && selectedAssessment && (
          <AssessmentDetails assessment={selectedAssessment} />
        )}
        {!isListLoading &&
          (!draftData || draftData?.length <= 0) &&
          (!assessmentsData || assessmentsData?.length <= 0) && (
            <div className={styles['main-container-loader']}>No Data Available</div>
          )}
      </div>
    </div>
  )
}

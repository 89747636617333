import React from 'react'
import Drawer from '@mui/material/Drawer'
import classes from './style.module.css'
import { Header } from './Header'
import { Content } from './Content'
import { hasValue } from 'src/utils/commonFns'
import Chat from './Chat'

const SideDrawer = ({
  openDrawer,
  setOpenDrawer,
  setRefreshTable,
  isFromReport,
  clientSubmissionID,
}) => {
  return (
    <Drawer
      anchor={'right'}
      open={hasValue(openDrawer)}
      onClose={() => setOpenDrawer(null)}
      slotProps={{ backdrop: { invisible: true } }}
      PaperProps={{
        className: classes['remediations-side-drawer'],
        sx: {
          height: 'calc(100% - 54px)',
          top: '65px',
        },
      }}
    >
      <div className={classes['container']}>
        <div className={classes['content-wrapper']}>
          <Header
            remediation={openDrawer}
            setOpenDrawer={setOpenDrawer}
            setRefreshTable={setRefreshTable}
            isFromReport={isFromReport}
            clientSubmissionID={clientSubmissionID}
          />
          <Content remediation={openDrawer} />
          <Chat remediation={openDrawer} />
        </div>
      </div>
    </Drawer>
  )
}

export default SideDrawer

import axios from 'axios'
import { useEffect } from 'react'
import { hasValue } from '../../utils/commonFns'
import { useToast } from '../toast'
import { isV2App } from 'src/utils/constants'

const AxiosInterceptor = ({ children }) => {
  const { addToast } = useToast()

  const isV2 = isV2App()

  useEffect(() => {
    axios.interceptors.request.use(
      function (config) {
        // spinning start to show
        // UPDATE: Add this code to show global loading indicator
        if (!config.url.endsWith('SendEmail')) {
          if (!isV2) {
            document.body.classList.add('loading-indicator')
            document.getElementById('loader').classList.add('loader')
          }
          if (!hasValue(window.apiCounter)) window.apiCounter = 0
          window.apiCounter += 1
        }
        let user = window.localStorage.getItem('currentUser')
        if (user && !config.url.toString().includes('s3.amazonaws.com')) {
          user = JSON.parse(user)
          config.headers.Authorization = `Bearer ${user.token}`
        }
        if (!config.headers['Content-Type']) {
          config.headers['Content-Type'] = 'application/json'
          config.headers.Accept = 'application/json'
        }

        return config
      },
      function (error) {
        return Promise.reject(error)
      },
    )

    axios.interceptors.response.use(
      function (response) {
        if (!response.config.url.endsWith('SendEmail')) {
          window.apiCounter -= 1
        }

        if (window.apiCounter === 0 && !isV2) {
          document.body.classList.remove('loading-indicator')
          document.getElementById('loader').classList.remove('loader')
        }
        if (!response.data.success && hasValue(response.data.error)) {
          addToast('ERROR', `Error - ${JSON.stringify(response.data.error)}`)
        }
        return response
      },
      function (error) {
        if (!error.config.url.endsWith('SendEmail')) {
          window.apiCounter -= 1
        }
        if (window.apiCounter === 0 && !isV2) {
          document.body.classList.remove('loading-indicator')
          document.getElementById('loader').classList.remove('loader')
        }
        if (error?.response?.data?.error && typeof error?.response?.data?.error === 'string') {
          addToast('ERROR', `${error?.response?.data?.error}`)
        } else {
          const errors = Object.values(error?.response?.data?.errors || {})?.join('\n\n')
          addToast('ERROR', `Error - ${error.message}\n${error?.response?.data?.title}\n${errors}`)
        }
        return Promise.reject(error)
      },
    )
    return () => {
      axios.interceptors.request.clear()
      axios.interceptors.response.clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}

export default AxiosInterceptor

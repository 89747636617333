import React from 'react'

export const ExpandIcon = ({ className }) => {
  return (
    <svg
      className={`icons-5 ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="22"
        id="mask0_423_10585"
        maskUnits="userSpaceOnUse"
        width="23"
        x="-1"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="22" width="22" x="-0.000976562" />
      </mask>
      <g className="g" mask="url(#mask0_423_10585)">
        <path
          className="path"
          d="M10.9991 14.5537C10.8887 14.5537 10.7858 14.536 10.6907 14.5007C10.5955 14.4654 10.505 14.4049 10.4191 14.3192L6.29938 10.1995C6.17257 10.0725 6.10764 9.91296 6.10459 9.72076C6.10168 9.52872 6.16661 9.36624 6.29938 9.23333C6.43229 9.10056 6.59332 9.03418 6.78246 9.03418C6.9716 9.03418 7.13263 9.10056 7.26554 9.23333L10.9991 12.9671L14.7327 9.23333C14.8597 9.10652 15.0192 9.04159 15.2114 9.03853C15.4035 9.03563 15.566 9.10056 15.6989 9.23333C15.8316 9.36624 15.898 9.52727 15.898 9.71641C15.898 9.90555 15.8316 10.0666 15.6989 10.1995L11.5791 14.3192C11.4933 14.4049 11.4028 14.4654 11.3076 14.5007C11.2124 14.536 11.1096 14.5537 10.9991 14.5537Z"
          fill="#E8E8E8"
        />
      </g>
    </svg>
  )
}

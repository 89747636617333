import React from 'react'

export const RequestorIcon = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <g id="person">
        <mask
          id="mask0_657_34643"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="30"
          height="30"
        >
          <rect id="Bounding box" width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_657_34643)">
          <path
            id="person_2"
            d="M15.9997 15.5889C14.8497 15.5889 13.8734 15.1876 13.0707 14.3849C12.2679 13.582 11.8664 12.6056 11.8664 11.4556C11.8664 10.3056 12.2679 9.32927 13.0707 8.5266C13.8734 7.72371 14.8497 7.32227 15.9997 7.32227C17.1497 7.32227 18.1261 7.72371 18.9287 8.5266C19.7316 9.32927 20.1331 10.3056 20.1331 11.4556C20.1331 12.6056 19.7316 13.582 18.9287 14.3849C18.1261 15.1876 17.1497 15.5889 15.9997 15.5889ZM7.06641 22.9173V22.2453C7.06641 21.8368 7.18818 21.4433 7.43174 21.0646C7.6753 20.6859 8.01596 20.3582 8.45374 20.0813C9.61596 19.413 10.8375 18.9007 12.1184 18.5443C13.3993 18.1878 14.6907 18.0096 15.9927 18.0096C17.2947 18.0096 18.5885 18.1878 19.8741 18.5443C21.1596 18.9007 22.3835 19.413 23.5457 20.0813C23.9835 20.3359 24.3242 20.6582 24.5677 21.0479C24.8113 21.4377 24.9331 21.8368 24.9331 22.2453V22.9173C24.9331 23.4062 24.7621 23.8216 24.4201 24.1636C24.0779 24.5054 23.6621 24.6763 23.1727 24.6763H8.81607C8.32652 24.6763 7.91252 24.5054 7.57407 24.1636C7.23563 23.8216 7.06641 23.4062 7.06641 22.9173ZM8.79974 22.9429H23.1997V22.2453C23.1997 22.1046 23.1491 21.9723 23.0477 21.8483C22.9462 21.7243 22.8063 21.619 22.6281 21.5326C21.657 20.9446 20.6079 20.4993 19.4807 20.1966C18.3534 19.8942 17.1931 19.7429 15.9997 19.7429C14.8064 19.7429 13.6461 19.8942 12.5187 20.1966C11.3916 20.4993 10.3425 20.9446 9.37141 21.5326C9.19207 21.6557 9.05196 21.7736 8.95107 21.8863C8.85018 21.9989 8.79974 22.1186 8.79974 22.2453V22.9429ZM16.0067 13.8556C16.6687 13.8556 17.2331 13.6199 17.6997 13.1486C18.1664 12.6773 18.3997 12.1106 18.3997 11.4486C18.3997 10.7866 18.1641 10.2223 17.6927 9.7556C17.2214 9.28893 16.6547 9.0556 15.9927 9.0556C15.3307 9.0556 14.7664 9.29138 14.2997 9.76293C13.8331 10.2343 13.5997 10.8009 13.5997 11.4629C13.5997 12.1247 13.8354 12.6889 14.3067 13.1556C14.7781 13.6223 15.3447 13.8556 16.0067 13.8556Z"
            fill="#269393"
          />
        </g>
      </g>
    </svg>
  )
}

import React from 'react'

export const YellowTentacleIcon = ({ size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="33" viewBox="0 0 50 33" fill="none">
      <path
        d="M31.3458 3L31.7692 2.73393L31.6222 2.5H31.3458V3ZM6.23893 3C6.23893 1.52724 5.04502 0.333332 3.57227 0.333332C2.09951 0.333332 0.905602 1.52724 0.905602 3C0.905602 4.47276 2.09951 5.66667 3.57227 5.66667C5.04502 5.66667 6.23893 4.47276 6.23893 3ZM49.9956 31.7339L31.7692 2.73393L30.9225 3.26606L49.1489 32.2661L49.9956 31.7339ZM31.3458 2.5H3.57227V3.5H31.3458V2.5Z"
        fill="#FEB956"
      />
    </svg>
  )
}

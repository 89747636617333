import React from "react";

export const Icons7 = ({ className }) => {
  return (
    <svg
      className={`icons-7 ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask className="mask" height="22" id="mask0_423_2909" maskUnits="userSpaceOnUse" width="22" x="0" y="0">
        <rect className="rect" fill="#3A70E2" height="21.2308" width="21.2308" x="0.384766" y="0.384766" />
      </mask>
      <g className="g" mask="url(#mask0_423_2909)">
        <path
          className="path"
          d="M4.58672 15.3352C4.4238 15.3352 4.28728 15.2805 4.17714 15.1711C4.06686 15.0618 4.01172 14.9263 4.01172 14.7647C4.01172 14.6029 4.06686 14.466 4.17714 14.3538C4.28728 14.2414 4.4238 14.1852 4.58672 14.1852H8.19153C8.35444 14.1852 8.49097 14.2399 8.6011 14.3493C8.71138 14.4587 8.76653 14.5942 8.76653 14.7558C8.76653 14.9176 8.71138 15.0545 8.6011 15.1667C8.49097 15.2791 8.35444 15.3352 8.19153 15.3352H4.58672ZM4.59313 11.5756C4.43036 11.5756 4.29281 11.5209 4.18046 11.4115C4.06797 11.3021 4.01172 11.1666 4.01172 11.0051C4.01172 10.8433 4.06679 10.7063 4.17692 10.5941C4.28706 10.4818 4.42343 10.4256 4.58606 10.4256H12.2612C12.424 10.4256 12.5615 10.4803 12.6739 10.5897C12.7864 10.6991 12.8426 10.8346 12.8426 10.9962C12.8426 11.1579 12.7875 11.2949 12.6774 11.4071C12.5673 11.5195 12.4308 11.5756 12.2681 11.5756H4.59313ZM4.58672 7.81602C4.4238 7.81602 4.28728 7.76132 4.17714 7.65192C4.06686 7.54252 4.01172 7.40703 4.01172 7.24544C4.01172 7.0837 4.06686 6.94673 4.17714 6.83453C4.28728 6.72219 4.4238 6.66602 4.58672 6.66602H17.4136C17.5766 6.66602 17.7131 6.72071 17.8232 6.83011C17.9335 6.93951 17.9886 7.075 17.9886 7.23659C17.9886 7.39833 17.9335 7.5353 17.8232 7.6475C17.7131 7.75984 17.5766 7.81602 17.4136 7.81602H4.58672Z"
          fill="#A2A3A4"
        />
      </g>
    </svg>
  );
};

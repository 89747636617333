import PropTypes from 'prop-types'
import React from 'react'
import styles from './style.module.css'

export const MetricCard = ({ color, title, caption, height }) => {
  return (
    <div className={styles[color]} style={{ height: height ?? 'auto' }}>
      <div className={styles.content}>
        <div className={styles.span}>
          <div className={styles['text-wrapper-' + color]}>{title}</div>
        </div>
        <div className={styles['text-wrapper-caption']}>{caption}</div>
      </div>
    </div>
  )
}

MetricCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  caption: PropTypes.string,
}

import React from 'react'

export const OverDueFlagIcon = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        d="M9.91454 20.9431V13.8288L19.8003 10.9431C20.0003 10.8859 20.1431 10.7145 20.1431 10.5145C20.1431 10.3145 20.0288 10.1145 19.8574 10.0288L9.62882 5.85736C9.48597 5.80022 9.31454 5.80022 9.17168 5.9145C9.02882 6.00022 8.97168 6.14307 8.97168 6.3145V20.9431H9.91454Z"
        fill="#EF4D4F"
      />
    </svg>
  )
}

import React from 'react'

export const PlusIcon = ({ className }) => {
  return (
    <svg
      className={`icons-7 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="20"
        id="mask0_361_2414"
        maskUnits="userSpaceOnUse"
        width="21"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" x="0.666992" />
      </mask>
      <g className="g" mask="url(#mask0_361_2414)">
        <path
          className="path"
          d="M10.0417 10.6252H5.875C5.69792 10.6252 5.54951 10.5652 5.42979 10.4454C5.30993 10.3255 5.25 10.177 5.25 9.99995C5.25 9.82273 5.30993 9.67433 5.42979 9.55475C5.54951 9.43502 5.69792 9.37516 5.875 9.37516H10.0417V5.2085C10.0417 5.03141 10.1016 4.88301 10.2215 4.76329C10.3413 4.64343 10.4898 4.5835 10.6669 4.5835C10.8441 4.5835 10.9925 4.64343 11.1121 4.76329C11.2318 4.88301 11.2917 5.03141 11.2917 5.2085V9.37516H15.4583C15.6354 9.37516 15.7838 9.43509 15.9035 9.55495C16.0234 9.67482 16.0833 9.82329 16.0833 10.0004C16.0833 10.1776 16.0234 10.326 15.9035 10.4456C15.7838 10.5653 15.6354 10.6252 15.4583 10.6252H11.2917V14.7918C11.2917 14.9689 11.2317 15.1173 11.1119 15.237C10.992 15.3569 10.8435 15.4168 10.6665 15.4168C10.4892 15.4168 10.3408 15.3569 10.2212 15.237C10.1015 15.1173 10.0417 14.9689 10.0417 14.7918V10.6252Z"
          fill="#F3F3F4"
        />
      </g>
    </svg>
  )
}

import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import { hasValue, readLinkTitleFromText } from 'src/utils/commonFns'
import { getImpactedAssetsList } from 'src/services/cyberScanService'

export const RiskProfileDetailOverview = ({ assessmentInfo, profileInfo }) => {
  const [recentImpactedAssets, setRecentImpactedAssets] = useState({})

  useEffect(() => {
    const loadRecentImpactedAssets = async () => {
      const data = await getImpactedAssetsList(
        assessmentInfo.coalitionEntityId,
        profileInfo.datapoint,
        0,
        5,
      )
      if (data) {
        setRecentImpactedAssets(JSON.parse(data.json_Response))
      }
    }
    loadRecentImpactedAssets()
  }, [assessmentInfo.coalitionEntityId, profileInfo.datapoint])

  return (
    <div className={styles.frametab}>
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.div}>
            <div className={styles['title-2']}>
              <div className={styles['text-wrapper']}>Description</div>
            </div>
          </div>
        </div>
        <div className={styles.description}>
          <p className={styles.p}>{profileInfo.description}</p>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.div}>
            <div className={styles['title-2']}>
              <div className={styles['text-wrapper']}>Recommendations</div>
            </div>
          </div>
        </div>
        <div className={styles.description}>
          <ul>
            {profileInfo.recommendation?.map((recommendation, index) => (
              <li key={index}>
                <p className={styles.p}>{recommendation}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.div}>
            <div className={styles['title-2']}>
              <div className={styles['text-wrapper']}>References</div>
            </div>
          </div>
        </div>
        <div className={styles.description}>
          <ul className={styles['wordpress-cloudflare']}>
            {profileInfo.references?.map((reference, index) => {
              const linkContent = readLinkTitleFromText(reference)
              return (
                <li key={index}>
                  <a href={linkContent[1]}>{linkContent[0]}</a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.div}>
            <div className={styles['title-2']}>
              <div className={styles['text-wrapper']}>Risk eveidence</div>
            </div>
          </div>
        </div>
        <div className={styles.description}>
          <p className={styles.p}>
            See the evidence for the most recent asset below. To see all the assets respective
            evidence go to the Impacted Assets tab.
          </p>
        </div>
      </div>
      <div className={styles['div-wrapper']}>
        <p className={styles['text-wrapper-2']}>
          {hasValue(recentImpactedAssets?.results?.[0]?.evidences?.[0])
            ? JSON.stringify(recentImpactedAssets?.results?.[0]?.evidences?.[0], null, 4)
            : ''}
        </p>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.div}>
            <div className={styles['title-2']}>
              <div className={styles['text-wrapper']}>Matched Evidence</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['content-wrapper']}>
        <div className={styles['div-wrapper']}>
          <p className={styles['text-wrapper-2']}>
            {hasValue(recentImpactedAssets?.results?.[0]?.matches)
              ? JSON.stringify(recentImpactedAssets?.results?.[0]?.matches, null, 4)
              : ''}
          </p>
        </div>
      </div>
    </div>
  )
}

import { All } from 'src/v2/assets/icons/remediation/dropdown/All'
import { Initiated } from 'src/v2/assets/icons/remediation/dropdown/Initiated'
import { Accepted } from 'src/v2/assets/icons/remediation/dropdown/Accepted'
import { Ignored } from 'src/v2/assets/icons/remediation/dropdown/Ignored'
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  addYears,
} from 'date-fns'

export const Roles = {
  ADMIN: 'Business Admin',
  CLIENT: 'Business Requestor',
}

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const anubisControlGaps = [
  {
    domain: 'Encryption',
    issueIdentified: 'Anubis does not encrypt Customer data inrest',
    issueSeverity: 'High',
    risk: 'Lack of encryption for data in rest may lead to unauthorized individuals having access to sensitive Customer data resulting in data leakage.',
    remediation: 'Anubis must encrypt customer data in rest',
    evidence: 'Screenshot showing that the data stored in the Anubis should be encrypted',
  },
  {
    domain: 'Email Security',
    issueIdentified:
      'Anubis does not have MDM solution in order to access corporate emails through their own device',
    issueSeverity: 'High',
    risk: 'Lack of adequate security controls on personal devices which connect to the corporate network may result in data leakage if these devices are lost or stolen.',
    remediation: 'Anubis must have MDM solution in order to secure their devices.',
    evidence: 'Anubis must provide a screenshot showing that the MDM solution is in place.',
  },
  {
    domain: 'Asset Management',
    issueIdentified:
      'Anubis does not have a decommissioning process for systems and devices storing confidential Client data prior to disposal.',
    issueSeverity: 'High',
    risk: 'Lack of a decommissioning process for systems and devices prior to disposal might allow unauthorized individuals access to confidential data that may still be on these devices.  This can result in data leakage. ',
    remediation:
      'Anubis must have a decommissioning process for systems and devices storing confidential Customer data prior to disposal.',
    evidence:
      'Evidence can be decommissioning certificates of devices and systems from 3rd party. Evidence can also be decommissioning devices or tools within firm premises.',
  },
  {
    domain: 'Vulnerability Management',
    issueIdentified: 'Anubis does not have an IDS/IPS solution in place',
    issueSeverity: 'High',
    risk: 'Lack of intrusion detection systems might result in attacks that might go undetected. These attacks may possibly result in sensitive Customer data being leaked.',
    remediation:
      'Anubis must have an IDS/IPS in place. Anubis must perform periodic signature updates.',
    evidence: `a) Screenshot to show when last signature update for IDS/IPS was done.
              b) Sample alert received from IDS/IPS`,
  },
  {
    domain: 'Cloud Security',
    issueIdentified: 'Anubis has not configured a network based DLP.',
    issueSeverity: 'High',
    risk: 'Lack of DLP controls to prevent confidential information from being sent externally can lead to data leakage.',
    remediation:
      'Anubis must implement a network- based DLP to detect CSC personally identifiable information (PII) at the perimeter of cloud service environment.',
    evidence: 'Evidence of DLP implementation.',
  },
  {
    domain: 'Risk Assessment',
    issueIdentified: 'Anubis does not review and approve the Risk register on a periodic basis.',
    issueSeverity: 'Low',
    risk: 'Lack of a periodic execution of a risk assessment can lead to an inconsistent and ineffective risk/control management program which lead to prolonged exposure to attacks from internal or external threats.',
    remediation:
      'Anubis must conduct annual risk assessments and must document the results of the same in the form of a report or risk register.',
    evidence: 'Report of updated reviewed and approved risk registered.',
  },
  {
    domain: 'Third Party Management',
    issueIdentified:
      'Anubis does not perform a due diligence/periodic risk assessment of vendors or subcontractors.',
    issueSeverity: 'High',
    risk: 'Lack of background check might result in inappropriate individuals being hired. These individuals can possibly gain access to confidential Customer data and potentially leak the information for monetary gain.',
    remediation:
      "Anubis must have a documented BGV in place and also conduct employee's BGV during the time of hire",
    evidence:
      "A documented policy of Anubis conducting BGV on their employees during the time of hire and also a sample employee's BGV conducting address check, criminal check and educational check.",
  },
  {
    domain: 'Network Security',
    issueIdentified: 'The firm does not have a process in place to modify firewall rules. ',
    issueSeverity: 'Medium',
    risk: 'Lack of process to modify firewall rules may result in unapproved modification to firewall rules by unauthorized individual. This can result in data leakage. ',
    remediation: 'Anubis does not have a process in place to modify firewall rules.',
    evidence: 'Documented process around modification of firewall rules.',
  },
  {
    domain: 'Change Management',
    issueIdentified:
      'Anubis does not have a documented Emergency change control policy and procedure in place.',
    issueSeverity: 'Low',
    risk: 'Lack of emergency change control policy or procedure can lead to inconsistent implementation of emergency changes for systems/applications which contain Customer data. This can expose these applications to vulnerabilities which can lead to Customer data being leaked.',
    remediation:
      'Anubis must have a documented emergency change control policy and procedure in place',
    evidence: 'Evidence can be documented policies, standards, and procedures',
  },
  {
    domain: 'Physical and Environmental Security',
    issueIdentified:
      'The Firm does not have a documented procedure for the removal of asset from the premises.',
    issueSeverity: 'Low',
    risk: 'Lack of Procedure to remove an asset may lead to expired assets exposing sensitive or confidential Customer data to unauthorized individuals, resulting in data leakage.',
    remediation:
      'Anubis must document a procedure for the removal of the asset including the process, and approval required.',
    evidence:
      'Documented and reviewed asset management policy showing the procedure for removal of asset.',
  },
]

export const tryParseInt = (val) => {
  if (!isNaN(parseInt(val))) {
    return parseInt(val)
  } else {
    return 0
  }
}

export const BusinessDomainTypes = {
  Sections: '1',
  Table: '2',
  List: '3',
}

export const remediationMenuItems = [
  {
    label: 'All Remediations',
    name: 'All',
    icon: All,
    value: 1,
  },
  {
    label: 'Initiated Remediations',
    name: 'Initiated',
    icon: Initiated,
    value: 2,
  },
  {
    label: 'Accepted Remediations',
    name: 'Accepted',
    icon: Accepted,
    value: 3,
  },
  {
    label: 'Ignored Remediations',
    name: 'Ignored',
    icon: Ignored,
  },
]

export const EnumRemediationGapStatus = {
  Initiated: 'Initiated',
  Draft: 'Draft',
  SubmittedToBeaconer: 'SubmittedToBeaconer',
  ClientReview: 'ClientReview',
  Closed: 'Closed',
  OnHold: 'OnHold',
}

export const isV2App = () =>
  window.location.hostname.includes('localhost') ||
  window.location.hostname.includes('app-dev.beaconer.io') ||
  window.location.hostname.includes('app.beaconer.io')

export const definedDays = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  startOfLast2Years: startOfYear(addYears(new Date(), -2)),
  startOfLast3Years: startOfYear(addYears(new Date(), -3)),
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './style.module.css'
import {
  // InputAdornment,
  // InputBase,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  debounce,
} from '@mui/material'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
// import { SearchIcon } from 'src/v2/icons/search-icon'
import { hasValue } from 'src/utils/commonFns'
import classes from './style.module.scss'
import { visuallyHidden } from '@mui/utils'
import { InfoIcon } from 'src/v2/icons/info'
import { TypesOfImpersonationChart } from './types-of-impersonation-chart'
// import { GreenArrowDown } from './icons/green-arrow-down'
// import { RedArrowDown } from './icons/red-arrow-down'
import moment from 'moment'
// import { TooltipLarge } from 'src/v2/components/TooltipLarge'
import ThreatDetail from './threat-detail'
import { getCompanyThreatsListByVendorId } from 'src/services/insecureWebScanService'
import TableRowsLoader from 'src/v2/components/Table/TableRowsLoader'

export const LookAlikeWebsites = ({ cyberscanDetailInfo }) => {
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('Timestamp')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [lookAlikeWebsiteData, setLookAlikeWebsiteData] = useState([])

  const headCells = [
    {
      name: 'FOUND DATE',
      sort: 'Timestamp',
      disableSort: false,
    },
    {
      name: 'DOMAIN',
      sort: 'OrganizationDomain',
      disableSort: false,
    },
    {
      name: 'THREAT',
      sort: 'Domain',
      disableSort: false,
    },

    {
      name: 'PHASH',
      sort: 'Phash',
      disableSort: false,
    },
    {
      name: 'FUZZER',
      sort: 'Fuzzer',
      disableSort: false,
    },
    {
      name: 'DNS NS',
      sort: 'DnsNs',
      disableSort: false,
    },
    {
      name: 'MAIL EXCHANGE',
      sort: 'DnsMailExchangeList',
      disableSort: false,
    },
    {
      name: 'IPV4',
      sort: 'DnsIPv4List',
      disableSort: false,
    },
  ]

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getLookalikeWebsites = async (clientSubmissionId, order, orderBy, page, rowsPerPage) => {
    try {
      if (!hasValue(clientSubmissionId)) return
      setLoading(true)
      const [data, totalCount] = await getCompanyThreatsListByVendorId(
        clientSubmissionId,
        order,
        orderBy,
        page,
        rowsPerPage,
      )
      if (data?.length > 0) {
        setLookAlikeWebsiteData(data)
        setTotalCount(totalCount)
      } else {
        setLookAlikeWebsiteData([])
        setTotalCount(0)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setSelectedRow(null)
    processDebounce(cyberscanDetailInfo.clientSubmissionId, order, orderBy, page, rowsPerPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cyberscanDetailInfo.clientSubmissionId, order, orderBy, page, rowsPerPage])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const processDebounce = useCallback(
    debounce((clientSubmissionId, order, orderBy, page, rowsPerPage) => {
      getLookalikeWebsites(clientSubmissionId, order, orderBy, page, rowsPerPage)
    }, 400), // Adjust delay as needed
    [], // Dependencies
  )

  const typesOfImpersonations = useMemo(() => {
    const data = cyberscanDetailInfo?.cyberScanCompanyImpersonationThreatCounts?.threatCounts
    const colorCodes = [
      '#FFC300',
      '#FF5733',
      '#C70039',
      '#900C3F',
      '#ff8a33',
      '#ff33d4',
      '#da33ff',
      '#33f3ff',
      '#5abeb9',
      '#7f947e',
      '#1a8a17',
      '#3c9c7b',
      '#bcc5c2',
      '#812789',
      '#988b99',
      '#d114e1',
    ]
    if (data) {
      return Object.keys(data)
        .slice(0, 16)
        .map((eachKey, index) => {
          return { name: eachKey, value: data[eachKey], color: colorCodes[index] }
        })
    } else {
      return {}
    }
  }, [cyberscanDetailInfo?.cyberScanCompanyImpersonationThreatCounts?.threatCounts])

  return (
    <div className={styles.report}>
      <div className={styles['section-2']}>
        <div className={styles.row}>
          <div className={styles['dark-web-score']}>
            <div className={styles['section-wrapper']}>
              <div className={styles.title}>
                <div className={styles['title-data']}>Types of Impersonation</div>
                <IconPlaceholder
                  icon={<InfoIcon className={styles['icons-6']} color="#7F8082" />}
                  size="twenty-four-x-24"
                />
              </div>
              <div className={styles.data}>
                <TypesOfImpersonationChart typesOfImpersonations={typesOfImpersonations} />
                <div className={styles['look-alike-website']}>
                  <div className={styles.table}>
                    <div className={styles.col}>
                      {typesOfImpersonations.map((eachCategory, index) => {
                        if (index % 2 === 0)
                          return (
                            <div key={index} className={styles['cell-2']}>
                              <div className={styles['domain-2']}>
                                <div className={styles['value-wrapper']}>
                                  <div className={styles['value-3']}>
                                    <div
                                      className={styles['ellipse-7']}
                                      style={{ backgroundColor: eachCategory.color }}
                                    />
                                    <div className={styles['text-wrapper-9']}>
                                      {eachCategory.name}
                                    </div>
                                  </div>
                                </div>
                                <div className={styles['score-2']}>
                                  <div className={styles['text-wrapper-10']}>
                                    {eachCategory.value}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        else return ''
                      })}
                    </div>
                    <div className={styles.col}>
                      {typesOfImpersonations?.map((eachCategory, index) => {
                        if (index % 2 !== 0)
                          return (
                            <div key={index} className={styles['cell-2']}>
                              <div className={styles['domain-2']}>
                                <div className={styles['value-wrapper']}>
                                  <div className={styles['value-3']}>
                                    <div
                                      className={styles['ellipse-7']}
                                      style={{ backgroundColor: eachCategory.color }}
                                    />
                                    <div className={styles['text-wrapper-9']}>
                                      {eachCategory.name}
                                    </div>
                                  </div>
                                </div>
                                <div className={styles['score-2']}>
                                  <div className={styles['text-wrapper-10']}>
                                    {eachCategory.value}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        else return ''
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['table-1']}>
          <div className={styles.frame}>
            <div className={styles.title}>
              <div className={styles['title-2']}>
                Threats (
                {
                  cyberscanDetailInfo?.cyberScanCompanyImpersonationThreatCounts
                    ?.totalCompanyImpersonationThreatCount
                }
                )
              </div>
            </div>
          </div>
          <div className={classes['domain-list']}>
            <Box className={classes.content}>
              <TableContainer className={classes.table}>
                <Table size={'medium'}>
                  <TableHead className={classes.tablehead}>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          className={classes.tableheadcell}
                          key={headCell.name}
                          sortDirection={orderBy === headCell.sort ? order : false}
                        >
                          {!headCell.disableSort ? (
                            <TableSortLabel
                              active={orderBy === headCell.sort}
                              direction={orderBy === headCell.sort ? order : 'asc'}
                              onClick={() => handleRequestSort(headCell.sort)}
                              className={classes.tablesortlabel}
                            >
                              {headCell.name}
                              {orderBy === headCell.sort ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            <>{headCell.name}</>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tablebody}>
                    {loading ? (
                      <TableRowsLoader
                        rows={25}
                        columns={8}
                        tableCellClassName={classes.tablecell}
                      />
                    ) : lookAlikeWebsiteData.length > 0 ? (
                      lookAlikeWebsiteData.map((row, index) => (
                        <TableRow
                          key={index}
                          className={`${'menu-links'} ${classes['domain-row']}`}
                          onClick={() => {
                            setSelectedRow(row)
                            setOpenDrawer(true)
                          }}
                        >
                          <TableCell className={classes.tablecell}>
                            {moment(row.timestamp).format('MM/DD/yy')}
                          </TableCell>
                          <TableCell className={classes.tablecell}>
                            {row.organizationDomain}
                          </TableCell>
                          <TableCell className={classes.tablecell}>{row.domain}</TableCell>
                          <TableCell className={classes.tablecell}>{row.phash}%</TableCell>
                          <TableCell className={classes.tablecell}>
                            <div className={`${classes.fuzzer} ${classes.tooltip}`}>
                              <div className={classes['text-wrapper']}>{row.fuzzer}</div>
                            </div>
                          </TableCell>

                          <TableCell className={classes.tablecell}>{row.dnsNs?.length}</TableCell>
                          <TableCell className={classes.tablecell}>
                            {row.dnsMailExchangeList?.length}
                          </TableCell>
                          <TableCell className={classes.tablecell}>
                            {row.dnsIPv4List?.length}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          className={classes.tablecell}
                          style={{ textAlign: 'center' }}
                        >
                          <h6 className={styles.norecords}>No Records to Display</h6>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className={classes.tablepagination}
                classes={{
                  selectLabel: classes.tablePaginationSelectLabel,
                  displayedRows: classes.tablePaginationDisplayedRows,
                  selectIcon: classes.tablePaginationSelectIcon,
                  actions: classes.tablePaginationActions,
                }}
              />
            </Box>
          </div>
        </div>
      </div>
      {!!openDrawer && (
        <ThreatDetail
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          cyberscanDetailInfo={cyberscanDetailInfo}
          threatInfo={selectedRow}
        />
      )}
    </div>
  )
}

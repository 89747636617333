import React, { useState } from 'react'
import classes from './style.module.css'
import DateRangeFilter from 'src/v2/screens/remediation/sections/Frame/DateRangeFilter'
import dayjs from 'dayjs'
import { Department } from './Departments'
import { SearchBar } from 'src/v2/components/SearchBar'
import { Icons6 } from 'src/v2/icons/Icons6'
import { definedDays } from 'src/utils/constants'

const defaultRadioOptions = [
  {
    label: 'This Year',
    range: () => ({
      startDate: dayjs(definedDays.startOfYear),
      endDate: dayjs(definedDays.endOfToday),
    }),
  },
  {
    label: 'Past 1 Years',
    range: () => ({
      startDate: dayjs(definedDays.startOfLastYear),
      endDate: dayjs(definedDays.endOfToday),
    }),
  },
  {
    label: 'Past 2 Years',
    range: () => ({
      startDate: dayjs(definedDays.startOfLast2Years),
      endDate: dayjs(definedDays.endOfToday),
    }),
  },
  {
    label: 'Past 3 Years',
    range: () => ({
      startDate: dayjs(definedDays.startOfLast3Years),
      endDate: dayjs(definedDays.endOfToday),
    }),
  },
  {
    label: 'Custom date',
  },
  {
    label: 'All Time',
    range: () => ({
      startDate: dayjs(new Date(2020, 0, 1)),
      endDate: dayjs(definedDays.endOfToday),
    }),
  },
]

export const AnalyticsFilter = () => {
  const [dateRangeValue, setDateRangeValue] = React.useState({
    startDate: dayjs(new Date(2020, 0, 1)),
    endDate: dayjs(definedDays.endOfToday),
    selectedOption: 'All Time',
  })

  const [searchQuery, setSearchQuery] = useState('')

  return (
    <div className={classes.filter}>
      <div className={classes['search-filter']}>
        <div className={classes.list}>
          <div className={classes.button}>
            <DateRangeFilter
              {...{ dateRangeValue, setDateRangeValue }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              overrideRadioOptions={defaultRadioOptions}
              isMonthView={true}
            />
          </div>
          <div className={classes.button}>
            <Department />
          </div>
        </div>
        <div className={classes['search-bar']}>
          <SearchBar
            className={classes['filter-input']}
            iconPlaceholderIcon={<Icons6 className={classes['icon-instance-node']} />}
            placeholder="Search"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
    </div>
  )
}

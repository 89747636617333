import React from 'react'

export const CloseIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_213_195258" maskUnits="userSpaceOnUse" x="2" y="2" width="24" height="24">
        <rect x="2" y="2" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_213_195258)">
        <path
          d="M14 15.0533L8.927 20.1265C8.7885 20.2649 8.61441 20.3357 8.40475 20.339C8.19525 20.3422 8.018 20.2714 7.873 20.1265C7.72816 19.9815 7.65575 19.8059 7.65575 19.5995C7.65575 19.3932 7.72816 19.2175 7.873 19.0725L12.9462 13.9995L7.873 8.92652C7.73466 8.78802 7.66383 8.61394 7.6605 8.40427C7.65733 8.19477 7.72816 8.01752 7.873 7.87252C8.018 7.72769 8.19366 7.65527 8.4 7.65527C8.60633 7.65527 8.782 7.72769 8.927 7.87252L14 12.9458L19.073 7.87252C19.2115 7.73419 19.3856 7.66336 19.5952 7.66002C19.8047 7.65686 19.982 7.72769 20.127 7.87252C20.2718 8.01752 20.3442 8.19319 20.3442 8.39952C20.3442 8.60586 20.2718 8.78152 20.127 8.92652L15.0537 13.9995L20.127 19.0725C20.2653 19.211 20.3362 19.3851 20.3395 19.5948C20.3427 19.8043 20.2718 19.9815 20.127 20.1265C19.982 20.2714 19.8063 20.3438 19.6 20.3438C19.3937 20.3438 19.218 20.2714 19.073 20.1265L14 15.0533Z"
          fill="#A2A3A4"
        />
      </g>
    </svg>
  )
}

import React from 'react'

export const UploadIcon = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_1907_166" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1907_166)">
      <path
        d="M6.74967 15.5836C6.5962 15.5836 6.46759 15.5321 6.36384 15.429C6.25995 15.326 6.20801 15.1983 6.20801 15.0461C6.20801 14.8938 6.25995 14.7647 6.36384 14.659C6.46759 14.5532 6.5962 14.5003 6.74967 14.5003H13.2497C13.4031 14.5003 13.5318 14.5518 13.6355 14.6549C13.7394 14.7579 13.7913 14.8856 13.7913 15.0378C13.7913 15.1901 13.7394 15.3192 13.6355 15.4249C13.5318 15.5307 13.4031 15.5836 13.2497 15.5836H6.74967ZM10.0038 12.8401C9.85148 12.8401 9.72245 12.7881 9.61676 12.6842C9.51092 12.5805 9.45801 12.4519 9.45801 12.2984V5.48258L7.4228 7.52591C7.30738 7.64133 7.17967 7.69633 7.03967 7.69091C6.89981 7.68563 6.7744 7.62799 6.66342 7.51799C6.54898 7.40438 6.49176 7.27716 6.49176 7.13633C6.49176 6.9955 6.54842 6.86834 6.66176 6.75487L9.53488 3.88174C9.60461 3.81758 9.67822 3.76952 9.75572 3.73758C9.83322 3.70549 9.91627 3.68945 10.0049 3.68945C10.0935 3.68945 10.1762 3.70549 10.253 3.73758C10.3298 3.76952 10.4003 3.81758 10.4645 3.88174L13.3455 6.76279C13.459 6.87626 13.5144 6.99952 13.5118 7.13258C13.509 7.26549 13.4526 7.38744 13.3426 7.49841C13.229 7.61286 13.1018 7.67008 12.9609 7.67008C12.8201 7.67008 12.692 7.61244 12.5765 7.49716L10.5413 5.48258V12.2984C10.5413 12.4519 10.4898 12.5805 10.3868 12.6842C10.2837 12.7881 10.1561 12.8401 10.0038 12.8401Z"
        fill="#F3F3F4"
      />
    </g>
  </svg>
)

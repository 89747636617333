import React from 'react'

export const RightArrow = ({ className }) => {
  return (
    <svg
      className={`icons-5 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="20"
        id="mask0_2327_31427"
        maskUnits="userSpaceOnUse"
        width="20"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" />
      </mask>
      <g className="g" mask="url(#mask0_2327_31427)">
        <path
          className="path"
          d="M13.8558 10.6235H4.375C4.19764 10.6235 4.04917 10.5637 3.92958 10.444C3.80986 10.3244 3.75 10.1759 3.75 9.99854C3.75 9.82118 3.80986 9.67271 3.92958 9.55312C4.04917 9.4334 4.19764 9.37354 4.375 9.37354H13.8558L9.54812 5.06583C9.42424 4.94194 9.36306 4.79694 9.36458 4.63083C9.36625 4.46472 9.43167 4.31701 9.56083 4.18771C9.69014 4.06701 9.83653 4.00451 10 4.00021C10.1635 3.9959 10.3099 4.0584 10.4392 4.18771L15.7227 9.47125C15.8008 9.54931 15.8558 9.6316 15.8877 9.71812C15.9198 9.80465 15.9358 9.89812 15.9358 9.99854C15.9358 10.099 15.9198 10.1924 15.8877 10.279C15.8558 10.3655 15.8008 10.4478 15.7227 10.5258L10.4392 15.8094C10.3237 15.9248 10.1808 15.9838 10.0104 15.9865C9.84 15.9891 9.69014 15.9301 9.56083 15.8094C9.43167 15.6801 9.36708 15.5316 9.36708 15.364C9.36708 15.1962 9.43167 15.0476 9.56083 14.9183L13.8558 10.6235Z"
          fill="#E8E8E8"
        />
      </g>
    </svg>
  )
}

import axios from 'axios'
import AppConfig from '../config'

const baseURL = `${AppConfig.API_URL}/RiskPosture`

const getRemediationCounts = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/remediation-counts?orgId=${orgId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getResidualRiskData = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/residual-risk-counts?orgId=${orgId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getResidualRiskVendors = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/residual-risk-vendors?orgId=${orgId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getResidualRiskHighRiskVendors = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/high-risk-vendors?orgId=${orgId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getSecurityScore = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/security-score?orgId=${orgId}`)
    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export {
  getRemediationCounts,
  getResidualRiskData,
  getResidualRiskVendors,
  getResidualRiskHighRiskVendors,
  getSecurityScore,
}

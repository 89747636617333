import React, { useState } from 'react'
import styles from './style.module.css'
import { CardContent, Collapse } from '@mui/material'
import { ExpandMore } from 'src/v2/components/ExpandMore'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { RightArrow } from 'src/v2/icons/right-arrow'

export const CompanyDataElements = ({ questionaireData }) => {
  const [businessData, setBusinessData] = useState(true)
  const [publicData, setPublicData] = useState(true)
  const [regulatoryComplianceData, setRegulatoryComplianceData] = useState(true)
  const [financialCommercialData, setFinancialCommercialData] = useState(true)
  const [marketData, setMarketData] = useState(true)
  const [intellectualPropertyData, setIntellectualPropertyData] = useState(true)
  const [securityData, setSecurityData] = useState(true)
  const [isPSIInvolvedExpand, setIsPSIInvolvedExpand] = useState(true)
  const [noOfPSIExpand, setNoOfPSIExpand] = useState(true)
  const [pIData, setPIData] = useState(true)
  const [sPIData, setSPIData] = useState(true)
  const [pHIData, setPHIData] = useState(true)
  const [detailedData, setDetailedData] = useState(false)
  return (
    <CardContent>
      <>
        {/* Business Data */}
        {!!questionaireData?.companyBusinessData &&
          questionaireData?.companyBusinessData.length > 0 && (
            <div className={styles['div-2']}>
              <>
                <div className={styles.level}>
                  <ExpandMore
                    expand={businessData}
                    onClick={() => {
                      setBusinessData(!businessData)
                    }}
                    aria-expanded={businessData}
                    aria-label="show more"
                  >
                    <IconPlaceholder
                      icon={<RightArrow className={styles['design-component-instance-node']} />}
                      size="twenty-four-x-24"
                    />
                  </ExpandMore>
                  <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                    Business Data
                  </p>
                </div>
                <Collapse in={businessData} timeout="auto" unmountOnExit>
                  <CardContent style={{ padding: 0 }}>
                    {' '}
                    <div className={styles['answer-2']}>
                      {questionaireData?.companyBusinessData.map((eachData, index) => {
                        return (
                          <div key={index} className={styles.chips}>
                            <div className={styles['text-wrapper-6']}>{eachData}</div>
                          </div>
                        )
                      })}
                    </div>
                  </CardContent>
                </Collapse>
              </>
            </div>
          )}

        {/* Public Data */}
        {!!questionaireData?.companyPublicData &&
          questionaireData?.companyPublicData.length > 0 && (
            <div className={styles['div-2']}>
              <>
                <div className={styles.level}>
                  <ExpandMore
                    expand={publicData}
                    onClick={() => {
                      setPublicData(!publicData)
                    }}
                    aria-expanded={publicData}
                    aria-label="show more"
                  >
                    <IconPlaceholder
                      icon={<RightArrow className={styles['design-component-instance-node']} />}
                      size="twenty-four-x-24"
                    />
                  </ExpandMore>
                  <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                    Public Data
                  </p>
                </div>
                <Collapse in={publicData} timeout="auto" unmountOnExit>
                  <CardContent style={{ padding: 0 }}>
                    {' '}
                    <div className={styles['answer-2']}>
                      {questionaireData?.companyPublicData.map((eachData, index) => {
                        return (
                          <div key={index} className={styles.chips}>
                            <div className={styles['text-wrapper-6']}>{eachData}</div>
                          </div>
                        )
                      })}
                    </div>
                  </CardContent>
                </Collapse>
              </>
            </div>
          )}

        {/* Regulatory compliance Data */}
        {!!questionaireData?.companyRegulatoryComplianceData &&
          questionaireData?.companyRegulatoryComplianceData.length > 0 && (
            <div className={styles['div-2']}>
              <>
                <div className={styles.level}>
                  <ExpandMore
                    expand={regulatoryComplianceData}
                    onClick={() => {
                      setRegulatoryComplianceData(!regulatoryComplianceData)
                    }}
                    aria-expanded={regulatoryComplianceData}
                    aria-label="show more"
                  >
                    <IconPlaceholder
                      icon={<RightArrow className={styles['design-component-instance-node']} />}
                      size="twenty-four-x-24"
                    />
                  </ExpandMore>
                  <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                    Regulatory Compliance Data
                  </p>
                </div>
                <Collapse in={regulatoryComplianceData} timeout="auto" unmountOnExit>
                  <CardContent style={{ padding: 0 }}>
                    {' '}
                    <div className={styles['answer-2']}>
                      {questionaireData?.companyRegulatoryComplianceData.map((eachData, index) => {
                        return (
                          <div key={index} className={styles.chips}>
                            <div className={styles['text-wrapper-6']}>{eachData}</div>
                          </div>
                        )
                      })}
                    </div>
                  </CardContent>
                </Collapse>
              </>
            </div>
          )}

        {/* Financial/commercial data */}
        {!!questionaireData?.companyFinancialCommercialData &&
          questionaireData?.companyFinancialCommercialData.length > 0 && (
            <div className={styles['div-2']}>
              <>
                <div className={styles.level}>
                  <ExpandMore
                    expand={financialCommercialData}
                    onClick={() => {
                      setFinancialCommercialData(!financialCommercialData)
                    }}
                    aria-expanded={financialCommercialData}
                    aria-label="show more"
                  >
                    <IconPlaceholder
                      icon={<RightArrow className={styles['design-component-instance-node']} />}
                      size="twenty-four-x-24"
                    />
                  </ExpandMore>
                  <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                    Financial/commercial data
                  </p>
                </div>
                <Collapse in={financialCommercialData} timeout="auto" unmountOnExit>
                  <CardContent style={{ padding: 0 }}>
                    {' '}
                    <div className={styles['answer-2']}>
                      {questionaireData?.companyFinancialCommercialData.map((eachData, index) => {
                        return (
                          <div key={index} className={styles.chips}>
                            <div className={styles['text-wrapper-6']}>{eachData}</div>
                          </div>
                        )
                      })}
                    </div>
                  </CardContent>
                </Collapse>
              </>
            </div>
          )}

        {/* Market Data */}
        {!!questionaireData?.companyMarketingData &&
          questionaireData?.companyMarketingData === 'Y' && (
            <>
              <div className={styles.level}>
                <ExpandMore
                  expand={marketData}
                  onClick={() => {
                    setMarketData(!marketData)
                  }}
                  aria-expanded={marketData}
                  aria-label="show more"
                >
                  <IconPlaceholder
                    icon={<RightArrow className={styles['design-component-instance-node']} />}
                    size="twenty-four-x-24"
                  />
                </ExpandMore>
                <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                  Market Data involved in this project?
                </p>
              </div>
              <Collapse in={marketData} timeout="auto" unmountOnExit>
                <CardContent style={{ padding: 0 }}>
                  {' '}
                  <ul className={styles.answer}>
                    <li className={styles['text-wrapper-4']}>Yes</li>
                  </ul>
                </CardContent>
              </Collapse>
            </>
          )}

        {/* Intellectual property Data */}
        {!!questionaireData?.companyIntellectualPropertyData &&
          questionaireData?.companyIntellectualPropertyData.length > 0 && (
            <div className={styles['div-2']}>
              <>
                <div className={styles.level}>
                  <ExpandMore
                    expand={intellectualPropertyData}
                    onClick={() => {
                      setIntellectualPropertyData(!intellectualPropertyData)
                    }}
                    aria-expanded={intellectualPropertyData}
                    aria-label="show more"
                  >
                    <IconPlaceholder
                      icon={<RightArrow className={styles['design-component-instance-node']} />}
                      size="twenty-four-x-24"
                    />
                  </ExpandMore>
                  <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                    Intellectual property Data
                  </p>
                </div>
                <Collapse in={intellectualPropertyData} timeout="auto" unmountOnExit>
                  <CardContent style={{ padding: 0 }}>
                    {' '}
                    <div className={styles['answer-2']}>
                      {questionaireData?.companyIntellectualPropertyData.map((eachData, index) => {
                        return (
                          <div key={index} className={styles.chips}>
                            <div className={styles['text-wrapper-6']}>{eachData}</div>
                          </div>
                        )
                      })}
                    </div>
                  </CardContent>
                </Collapse>
              </>
            </div>
          )}

        {/* Security data */}
        {!!questionaireData?.companySecurityData &&
          questionaireData?.companySecurityData.length > 0 && (
            <div className={styles['div-2']}>
              <>
                <div className={styles.level}>
                  <ExpandMore
                    expand={securityData}
                    onClick={() => {
                      setSecurityData(!securityData)
                    }}
                    aria-expanded={securityData}
                    aria-label="show more"
                  >
                    <IconPlaceholder
                      icon={<RightArrow className={styles['design-component-instance-node']} />}
                      size="twenty-four-x-24"
                    />
                  </ExpandMore>
                  <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                    Security data
                  </p>
                </div>
                <Collapse in={securityData} timeout="auto" unmountOnExit>
                  <CardContent style={{ padding: 0 }}>
                    {' '}
                    <div className={styles['answer-2']}>
                      {questionaireData?.companySecurityData.map((eachData, index) => {
                        return (
                          <div key={index} className={styles.chips}>
                            <div className={styles['text-wrapper-6']}>{eachData}</div>
                          </div>
                        )
                      })}
                    </div>
                  </CardContent>
                </Collapse>
              </>
            </div>
          )}

        {/* Market Data */}
        {!!questionaireData?.isTherePISPIInvolvedInThisCompanyProject &&
          questionaireData?.isTherePISPIInvolvedInThisCompanyProject === 'Y' && (
            <>
              <div className={styles.level}>
                <ExpandMore
                  expand={isPSIInvolvedExpand}
                  onClick={() => {
                    setIsPSIInvolvedExpand(!isPSIInvolvedExpand)
                  }}
                  aria-expanded={isPSIInvolvedExpand}
                  aria-label="show more"
                >
                  <IconPlaceholder
                    icon={<RightArrow className={styles['design-component-instance-node']} />}
                    size="twenty-four-x-24"
                  />
                </ExpandMore>
                <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                  PI/SPI/PHI involved in this project?
                </p>
              </div>
              <Collapse in={isPSIInvolvedExpand} timeout="auto" unmountOnExit>
                <CardContent style={{ padding: 0 }}>
                  {' '}
                  <ul className={styles.answer}>
                    <li className={styles['text-wrapper-4']}>Yes</li>
                  </ul>
                </CardContent>
              </Collapse>
              {/* NO OF PSI */}
              <div className={styles.level}>
                <ExpandMore
                  expand={noOfPSIExpand}
                  onClick={() => {
                    setNoOfPSIExpand(!noOfPSIExpand)
                  }}
                  aria-expanded={noOfPSIExpand}
                  aria-label="show more"
                >
                  <IconPlaceholder
                    icon={<RightArrow className={styles['design-component-instance-node']} />}
                    size="twenty-four-x-24"
                  />
                </ExpandMore>
                <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                  Number of PI/SPI records
                </p>
              </div>
              <Collapse in={noOfPSIExpand} timeout="auto" unmountOnExit>
                <CardContent style={{ padding: 0 }}>
                  {' '}
                  <ul className={styles.answer}>
                    {!!questionaireData?.noOfPISPIRecordsCompany ? (
                      <li className={styles['text-wrapper-4']}>
                        {questionaireData.noOfPISPIRecordsCompany}
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </CardContent>
              </Collapse>
              {/* PI (Personal information) */}
              {!!questionaireData?.companyPIData && questionaireData?.companyPIData.length > 0 && (
                <div className={styles['div-2']}>
                  <>
                    <div className={styles.level}>
                      <ExpandMore
                        expand={pIData}
                        onClick={() => {
                          setPIData(!pIData)
                        }}
                        aria-expanded={pIData}
                        aria-label="show more"
                      >
                        <IconPlaceholder
                          icon={<RightArrow className={styles['design-component-instance-node']} />}
                          size="twenty-four-x-24"
                        />
                      </ExpandMore>
                      <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                        PI (Personal information)
                      </p>
                    </div>
                    <Collapse in={pIData} timeout="auto" unmountOnExit>
                      <CardContent style={{ padding: 0 }}>
                        {' '}
                        <div className={styles['answer-2']}>
                          {questionaireData?.companyPIData.map((eachData, index) => {
                            return (
                              <div key={index} className={styles.chips}>
                                <div className={styles['text-wrapper-6']}>{eachData}</div>
                              </div>
                            )
                          })}
                        </div>
                      </CardContent>
                    </Collapse>
                  </>
                </div>
              )}

              {/*SPI (Sensitive personal information) */}
              {!!questionaireData?.companySPIData &&
                questionaireData?.companySPIData.length > 0 && (
                  <div className={styles['div-2']}>
                    <>
                      <div className={styles.level}>
                        <ExpandMore
                          expand={sPIData}
                          onClick={() => {
                            setSPIData(!sPIData)
                          }}
                          aria-expanded={sPIData}
                          aria-label="show more"
                        >
                          <IconPlaceholder
                            icon={
                              <RightArrow className={styles['design-component-instance-node']} />
                            }
                            size="twenty-four-x-24"
                          />
                        </ExpandMore>
                        <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                          SPI (Sensitive personal information)
                        </p>
                      </div>
                      <Collapse in={sPIData} timeout="auto" unmountOnExit>
                        <CardContent style={{ padding: 0 }}>
                          {' '}
                          <div className={styles['answer-2']}>
                            {questionaireData?.companySPIData.map((eachData, index) => {
                              return (
                                <div key={index} className={styles.chips}>
                                  <div className={styles['text-wrapper-6']}>{eachData}</div>
                                </div>
                              )
                            })}
                          </div>
                        </CardContent>
                      </Collapse>
                    </>
                  </div>
                )}

              {/*  PHI (Protected health information) */}
              {!!questionaireData?.companyPHIData &&
                questionaireData?.companyPHIData.length > 0 && (
                  <div className={styles['div-2']}>
                    <>
                      <div className={styles.level}>
                        <ExpandMore
                          expand={pHIData}
                          onClick={() => {
                            setPHIData(!pHIData)
                          }}
                          aria-expanded={pHIData}
                          aria-label="show more"
                        >
                          <IconPlaceholder
                            icon={
                              <RightArrow className={styles['design-component-instance-node']} />
                            }
                            size="twenty-four-x-24"
                          />
                        </ExpandMore>
                        <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                          PHI (Protected health information)
                        </p>
                      </div>
                      <Collapse in={pHIData} timeout="auto" unmountOnExit>
                        <CardContent style={{ padding: 0 }}>
                          {' '}
                          <div className={styles['answer-2']}>
                            {questionaireData?.companyPHIData.map((eachData, index) => {
                              return (
                                <div key={index} className={styles.chips}>
                                  <div className={styles['text-wrapper-6']}>{eachData}</div>
                                </div>
                              )
                            })}
                          </div>
                        </CardContent>
                      </Collapse>
                    </>
                  </div>
                )}

              {/* DETAILED DATA ELEMENTS */}
              {!!questionaireData?.detailedDataElementsCompanyData && (
                <>
                  <div className={styles.level}>
                    <ExpandMore
                      expand={detailedData}
                      onClick={() => {
                        setDetailedData(!detailedData)
                      }}
                      aria-expanded={detailedData}
                      aria-label="show more"
                    >
                      <IconPlaceholder
                        icon={<RightArrow className={styles['design-component-instance-node']} />}
                        size="twenty-four-x-24"
                      />
                    </ExpandMore>
                    <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                      Additional data elements
                    </p>
                  </div>
                  <Collapse in={detailedData} timeout="auto" unmountOnExit>
                    <CardContent style={{ padding: 0 }}>
                      {' '}
                      <div className={styles['answer-2']}>
                        {!!questionaireData?.detailedDataElementsCompanyData
                          ? questionaireData?.detailedDataElementsCompanyData
                              .split(',')
                              .map((eachData, index) => {
                                return (
                                  <div key={index} className={styles.chips}>
                                    <div className={styles['text-wrapper-6']}>{eachData}</div>
                                  </div>
                                )
                              })
                          : ''}
                      </div>
                    </CardContent>
                  </Collapse>
                </>
              )}
            </>
          )}
      </>
    </CardContent>
  )
}

import React from 'react'
import Chart from 'react-apexcharts'

export const ReportTypesOfImpersonationChart = ({ typesOfImpersonations, loading = false }) => {
  const series = loading
    ? []
    : [
        {
          data: typesOfImpersonations?.map((x) => x.value).flat() || [],
        },
      ]

  const useChartOptions = (series, loading) => {
    return {
      chart: {
        type: 'bar',
        width: '100%',
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false, // Disable zooming
        },
      },

      tooltip: {
        theme: 'dark',
        cssClass: 'c-apexcharts-tooltip',
        style: {
          fontSize: '12px',
          fontFamily: '"SF Pro Text", Helvetica',
        },
        y: {
          title: {
            formatter(seriesName) {
              return ''
            },
          },
        },
        x: {
          title: {
            formatter(seriesName) {
              return seriesName
            },
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30px', // Adjust column thickness (width)
          barHeight: '5px', // Adjust the height of the bar (affects gap between stacks)
          borderRadius: 2, // Add some border radius if you want rounded edges
          dataLabels: {
            position: 'top', // Align data labels at the top of the bars
          },
          distributed: true,
        },
      },
      xaxis: {
        categories: typesOfImpersonations?.map((x) => x.name).flat() || [],
        tickPlacement: 'between',
        range: 2.5,
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
          borderType: 'solid',
          color: '#5D5E60',
          height: 6,
        },
        axisBorder: {
          show: false,
          color: '#5D5E60',
          offsetX: 0,
        },
      },
      yaxis: {
        stepSize: 10,
        labels: {
          style: {
            colors: '#E8E8E8',
            fontSize: '12px',
            fontFamily: '"SF Pro Text", Helvetica',
          },
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#5D5E60',
          height: 6,
          // offsetY: 0.6,
        },
        axisBorder: {
          show: true,
          color: '#5D5E60',
          offsetX: 0,
        },
      },
      grid: {
        show: true,
        borderColor: '#3A3C3E',
        xaxis: {
          lines: {
            show: false,
            offsetX: 0,
          },
        },
        yaxis: {
          lines: {
            show: true,
            offsetX: 0,
          },
        },
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        formatter: function (val) {
          return val
        },
        style: {
          colors: ['#1B1C1E'],
          // background: '#1B1C1E',
          fontSize: '12px',
          fontFamily: '"SF Pro Text", Helvetica',
          lineHeight: '18px',
        },
        background: {
          enabled: true,
          borderRadius: 2,
          borderColor: 'transparent',
          foreColor: '#E8E8E8',
        },
      },
      colors: typesOfImpersonations?.map((x) => x.color).flat() || [],
      noData: {
        text: loading ? 'Loading...' : 'No Data Available',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 8,
        offsetY: -10,
        style: {
          color: '#fff',
          fontSize: '14px',
          fontFamily: '"SF Pro Text-Regular", Helvetica',
        },
      },
    }
  }
  const chartOptions = useChartOptions(series, loading)

  return (
    <div style={{ width: '100%' }}>
      <Chart options={chartOptions} series={series} type="bar" height={265} width={'100%'} />
    </div>
  )
}

import React from 'react'
import styles from './style.module.css'
import { Chart, ArcElement } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

Chart.register(ArcElement)

export const DarkWebDoughnutChart = ({
  lookalikeWebsites,
  totalVendors,
  domain,
  email,
  ipAddress,
  userName,
  phone,
  tootltipEnabled = false,
  left = '65px',
  top = '82px',
  loading,
}) => {
  const labels = [
    'Look Alike Websites',
    '',
    '',
    'Domain',
    '',
    'P.Email',
    '',
    'IP Address',
    '',
    'Username',
    '',
    'Phone',
    '',
    '',
  ]

  const data = {
    datasets: loading
      ? [
          {
            data: [99],
            backgroundColor: ['rgb(65, 65, 65)'],
            weight: 0.1,
            spacing: 0,
            borderWidth: 0,
            borderRadius: 0,
          },
        ]
      : [
          {
            data: [
              lookalikeWebsites,
              20,
              20,
              domain,
              10,
              email,
              10,
              ipAddress,
              10,
              userName,
              10,
              phone,
              20,
              20,
            ],
            backgroundColor: [
              '#2C2C2C',
              '#2C2C2C',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#2C2C2C',
            ],
            weight: 0.1,
            spacing: 0,
            borderWidth: 0,
            borderRadius: 0,
          },
          {
            data: [
              lookalikeWebsites,
              20,
              20,
              domain,
              10,
              email,
              10,
              ipAddress,
              10,
              userName,
              10,
              phone,
              20,
              20,
            ],
            backgroundColor: [
              '#50B19E',
              '#2C2C2C',
              '#727272',
              '#E23670',
              '#727272',
              '#AF56DB',
              '#727272',
              '#E88D30',
              '#727272',
              '#2EB88A',
              '#727272',
              '#2761D9',
              '#727272',
              '#2C2C2C',
            ],
            weight: 0.3,
            // spacing: 5,
            borderWidth: 0,
            borderRadius: 0,
          },
          {
            data: [
              lookalikeWebsites,
              20,
              20,
              domain,
              10,
              email,
              10,
              ipAddress,
              10,
              userName,
              10,
              phone,
              20,
              20,
            ],
            backgroundColor: [
              '#2C2C2C',
              '#2C2C2C',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#727272',
              '#2C2C2C',
            ],
            weight: 0.1,
            spacing: 0,
            borderWidth: 0,
            borderRadius: 0,
          },
        ],
  }

  const options = {
    responsive: true,
    cutout: '70%',
    plugins: {
      tooltip: {
        enabled: tootltipEnabled,
        yAlign: 'top',
        callbacks: {
          label: function (tooltipItem) {
            var label = labels[tooltipItem.dataIndex]
            if (!!label) return label + ' ' + tooltipItem.formattedValue
            else return tooltipItem.formattedValue
          },
        },
      },
    },
  }

  return (
    <div>
      <div className={styles.totalVendors} style={{ left: left, top: loading ? '100px' : top }}>
        {loading ? (
          'Loading...'
        ) : (
          <>
            <p className={styles.element}>
              <span className={styles['percentage-wrapper']}>{totalVendors}</span>
            </p>
            <div className={styles['caption-wrapper']}>Total Vendors</div>
          </>
        )}
      </div>
      <Doughnut data={data} options={options}></Doughnut>
    </div>
  )
}

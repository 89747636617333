import React from 'react'

export const Accepted = ({ className }) => {
  return (
    <svg
      className={`icons-3 ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="22"
        id="mask0_523_12537"
        maskUnits="userSpaceOnUse"
        width="22"
        x="0"
        y="0"
      >
        <rect
          className="rect"
          fill="#D9D9D9"
          height="21.2308"
          width="21.2308"
          x="0.384766"
          y="0.384766"
        />
      </mask>
      <g className="g" mask="url(#mask0_523_12537)">
        <path
          className="path"
          d="M9.74503 12.8991L7.69007 10.8439C7.56755 10.7216 7.41356 10.6589 7.22808 10.656C7.04276 10.6532 6.88596 10.7158 6.75769 10.8439C6.62957 10.9722 6.56551 11.1276 6.56551 11.3101C6.56551 11.4927 6.62957 11.6481 6.75769 11.7763L9.18529 14.2039C9.34526 14.3638 9.53184 14.4437 9.74503 14.4437C9.95823 14.4437 10.1448 14.3638 10.3048 14.2039L15.2263 9.28238C15.3487 9.15986 15.4114 9.00586 15.4143 8.82039C15.4171 8.63506 15.3545 8.47827 15.2263 8.35C15.0981 8.22188 14.9427 8.15781 14.7601 8.15781C14.5776 8.15781 14.4222 8.22188 14.2939 8.35L9.74503 12.8991ZM11.0021 19.4044C9.83969 19.4044 8.74711 19.1838 7.72435 18.7427C6.70159 18.3015 5.81196 17.7029 5.05547 16.9467C4.29897 16.1905 3.70002 15.3012 3.25859 14.2789C2.81732 13.2566 2.59668 12.1643 2.59668 11.0021C2.59668 9.83969 2.81724 8.74711 3.25837 7.72435C3.6995 6.70159 4.29816 5.81196 5.05436 5.05547C5.81056 4.29897 6.69982 3.70002 7.72214 3.25859C8.74446 2.81732 9.83674 2.59668 10.999 2.59668C12.1614 2.59668 13.2539 2.81724 14.2767 3.25837C15.2995 3.6995 16.1891 4.29816 16.9456 5.05436C17.7021 5.81056 18.301 6.69982 18.7425 7.72214C19.1837 8.74446 19.4044 9.83674 19.4044 10.999C19.4044 12.1614 19.1838 13.2539 18.7427 14.2767C18.3015 15.2995 17.7029 16.1891 16.9467 16.9456C16.1905 17.7021 15.3012 18.301 14.2789 18.7425C13.2566 19.1837 12.1643 19.4044 11.0021 19.4044ZM11.0005 18.0774C12.9762 18.0774 14.6496 17.3919 16.0207 16.0207C17.3919 14.6496 18.0774 12.9762 18.0774 11.0005C18.0774 9.02488 17.3919 7.35149 16.0207 5.98033C14.6496 4.60918 12.9762 3.9236 11.0005 3.9236C9.02488 3.9236 7.35149 4.60918 5.98033 5.98033C4.60918 7.35149 3.9236 9.02488 3.9236 11.0005C3.9236 12.9762 4.60918 14.6496 5.98033 16.0207C7.35149 17.3919 9.02488 18.0774 11.0005 18.0774Z"
          fill="#A2A3A4"
        />
      </g>
    </svg>
  )
}

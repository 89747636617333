import React, { useCallback, useEffect, useState } from 'react'
import styles from './style.module.css'
import {
  InputAdornment,
  InputBase,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  debounce,
} from '@mui/material'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { SearchIcon } from 'src/v2/icons/search-icon'
import classes from './style.module.scss'
import { visuallyHidden } from '@mui/utils'
import { EmployeeExposedCredentialDetails } from './details'
import { getBreachListByVendorId } from 'src/services/insecureWebScanService'
import TableRowsLoader from 'src/v2/components/Table/TableRowsLoader'
import { hasValue } from 'src/utils/commonFns'
import moment from 'moment'

export const EmployeeExposedCredentials = ({ cyberscanDetailInfo }) => {
  const [searchText, setSearchText] = useState('')
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('Timestamp')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [employeeExposedCredsData, setEmployeeExposedCredsData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const headCells = [
    {
      name: 'DOMAIN',
      sort: 'Domain',
      disableSort: true,
    },
    {
      name: 'NAME',
      sort: 'Name',
      disableSort: false,
    },
    {
      name: 'LAST SEEN',
      sort: 'Timestamp',
      disableSort: false,
    },
    {
      name: 'USER NAME',
      sort: 'Username',
      disableSort: false,
    },
    {
      name: 'DATABASE NAME',
      sort: 'DatabaseName',
      disableSort: false,
    },
    {
      name: 'FOUND IN',
      sort: 'FoundIn',
      disableSort: false,
    },
    {
      name: 'SOURCE',
      sort: 'Source',
      disableSort: false,
    },
    {
      name: 'BREACH ID',
      sort: 'BreachId',
      disableSort: false,
    },
  ]

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getEmployeeExposedCreds = async (
    clientSubmissionId,
    order,
    orderBy,
    page,
    rowsPerPage,
    searchText,
  ) => {
    try {
      if (!hasValue(clientSubmissionId)) return
      setLoading(true)
      const [data, totalCount] = await getBreachListByVendorId(
        clientSubmissionId,
        order,
        orderBy,
        page,
        rowsPerPage,
        searchText,
      )
      if (data?.length > 0) {
        setEmployeeExposedCredsData(data)
        setTotalCount(totalCount)
      } else {
        setEmployeeExposedCredsData([])
        setTotalCount(0)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setSelectedRow(null)
    processDebounce(
      cyberscanDetailInfo.clientSubmissionId,
      order,
      orderBy,
      page,
      rowsPerPage,
      searchText,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cyberscanDetailInfo.clientSubmissionId, order, orderBy, page, rowsPerPage, searchText])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const processDebounce = useCallback(
    debounce((clientSubmissionId, order, orderBy, page, rowsPerPage, searchText) => {
      getEmployeeExposedCreds(clientSubmissionId, order, orderBy, page, rowsPerPage, searchText)
    }, 400), // Adjust delay as needed
    [], // Dependencies
  )

  return (
    <div className={styles.report}>
      <div className={styles['section-2']}>
        <div className={styles.cards}>
          <div className={styles.content}>
            <div className={styles['value-icon']}>
              <div className={styles['text-wrapper-11']}>Total Compromises</div>
              <div className={styles['no-encryption-wrapper']}>
                <img
                  className={styles['no-encryption']}
                  alt="No encryption"
                  src="https://c.animaapp.com/0l6zMLGF/img/no-encryption@2x.png"
                />
              </div>
            </div>
            <div className={styles.value}>
              <div className={styles['text-wrapper-12']}>
                {cyberscanDetailInfo?.cyberScanDarkWebBreachCounts?.totalDarkWebBreachCount}
              </div>
            </div>
          </div>
          {hasValue(cyberscanDetailInfo?.cyberScanDarkWebBreachCounts?.domainBreachCount) && (
            <div className={styles.content}>
              <div className={styles['value-icon']}>
                <div className={styles['text-wrapper-11']}>Total Domain</div>
                <div className={styles['no-encryption-wrapper']}>
                  <img
                    className={styles['no-encryption']}
                    alt="No encryption"
                    src="https://c.animaapp.com/0l6zMLGF/img/language@2x.png"
                  />
                </div>
              </div>
              <div className={styles.value}>
                <div className={styles['text-wrapper-12']}>
                  {cyberscanDetailInfo?.cyberScanDarkWebBreachCounts?.domainBreachCount}
                </div>
              </div>
            </div>
          )}
          {hasValue(cyberscanDetailInfo?.cyberScanDarkWebBreachCounts?.EmailBreachCount) && (
            <div className={styles.content}>
              <div className={styles['value-icon']}>
                <div className={styles['text-wrapper-11']}>Total Personal emails</div>
                <div className={styles['no-encryption-wrapper']}>
                  <img
                    className={styles['no-encryption']}
                    alt="No encryption"
                    src="https://c.animaapp.com/0l6zMLGF/img/language@2x.png"
                  />
                </div>
              </div>
              <div className={styles.value}>
                <div className={styles['text-wrapper-12']}>
                  {cyberscanDetailInfo?.cyberScanDarkWebBreachCounts?.EmailBreachCount}
                </div>
              </div>
            </div>
          )}
          {hasValue(cyberscanDetailInfo?.cyberScanDarkWebBreachCounts?.PhoneBreachCount) && (
            <div className={styles.content}>
              <div className={styles['value-icon']}>
                <div className={styles['text-wrapper-11']}>Total Phones</div>
                <div className={styles['no-encryption-wrapper']}>
                  <img
                    className={styles['no-encryption']}
                    alt="No encryption"
                    src="https://c.animaapp.com/0l6zMLGF/img/language@2x.png"
                  />
                </div>
              </div>
              <div className={styles.value}>
                <div className={styles['text-wrapper-12']}>
                  {cyberscanDetailInfo?.cyberScanDarkWebBreachCounts?.PhoneBreachCount}
                </div>
              </div>
            </div>
          )}
          {hasValue(cyberscanDetailInfo?.cyberScanDarkWebBreachCounts?.IPBreachCount) && (
            <div className={styles.content}>
              <div className={styles['value-icon']}>
                <div className={styles['text-wrapper-11']}>Total IPs</div>
                <div className={styles['no-encryption-wrapper']}>
                  <img
                    className={styles['no-encryption']}
                    alt="No encryption"
                    src="https://c.animaapp.com/0l6zMLGF/img/language@2x.png"
                  />
                </div>
              </div>
              <div className={styles.value}>
                <div className={styles['text-wrapper-12']}>
                  {cyberscanDetailInfo?.cyberScanDarkWebBreachCounts?.IPBreachCount}
                </div>
              </div>
            </div>
          )}
          {hasValue(cyberscanDetailInfo?.cyberScanDarkWebBreachCounts?.UserNameBreachCount) && (
            <div className={styles.content}>
              <div className={styles['value-icon']}>
                <div className={styles['text-wrapper-11']}>Total Usernames</div>
                <div className={styles['no-encryption-wrapper']}>
                  <img
                    className={styles['no-encryption']}
                    alt="No encryption"
                    src="https://c.animaapp.com/0l6zMLGF/img/language@2x.png"
                  />
                </div>
              </div>
              <div className={styles.value}>
                <div className={styles['text-wrapper-12']}>
                  {cyberscanDetailInfo?.cyberScanDarkWebBreachCounts?.UserNameBreachCount}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles['table-1']}>
          <div className={styles.frame}>
            <div className={styles.title}>
              <div className={styles['title-2']}>
                Breaches (
                {cyberscanDetailInfo?.cyberScanDarkWebBreachCounts?.totalDarkWebBreachCount || 0})
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles['search-filter']}>
                <InputBase
                  sx={{ height: 36 }}
                  className={`${styles['search-bar']}`}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconPlaceholder
                        icon={<SearchIcon className={styles['icons-10']} />}
                        size="twenty-four-x-24"
                      />
                    </InputAdornment>
                  }
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value)
                  }}
                ></InputBase>
              </div>
            </div>
          </div>
          <div className={classes['domain-list']}>
            <Box className={classes.content}>
              <TableContainer className={classes.table}>
                <Table size={'medium'}>
                  <TableHead className={classes.tablehead}>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          className={classes.tableheadcell}
                          key={headCell.sort}
                          sortDirection={orderBy === headCell.sort ? order : false}
                        >
                          {!headCell.disableSort ? (
                            <TableSortLabel
                              active={orderBy === headCell.sort}
                              direction={orderBy === headCell.sort ? order : 'asc'}
                              onClick={() => handleRequestSort(headCell.sort)}
                              className={classes.tablesortlabel}
                            >
                              {headCell.name}
                              {orderBy === headCell.sort ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            <>{headCell.name}</>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {/* <div className={classes.tablebody}> */}
                  <TableBody className={classes.tablebody}>
                    {loading ? (
                      <TableRowsLoader
                        rows={25}
                        columns={8}
                        tableCellClassName={classes.tablecell}
                      />
                    ) : employeeExposedCredsData.length > 0 ? (
                      employeeExposedCredsData.map((row, index) => (
                        <TableRow
                          key={index}
                          className={`${'menu-links'} ${classes['domain-row']}`}
                          onClick={() => {
                            setSelectedRow(row)
                            setIsDetailsOpen(true)
                          }}
                        >
                          <TableCell className={classes.tablecell}>{row.domain}</TableCell>
                          <TableCell className={`${classes.tablecell}`}>
                            <div className={classes['label']}>
                              <div className={classes['text-wrapper-3']}>{row.name}</div>
                              <div className={classes['text-wrapper-4']}>{row.email}</div>
                            </div>
                          </TableCell>
                          <TableCell className={classes.tablecell}>
                            {moment(row.timestamp).format('MM/DD/yy')}
                          </TableCell>
                          <TableCell className={classes.tablecell}>{row.username}</TableCell>
                          <TableCell className={classes.tablecell}>{row.databaseName}</TableCell>
                          <TableCell className={classes.tablecell}>{row.foundIn}</TableCell>
                          <TableCell className={classes.tablecell}>{row.source}</TableCell>
                          <TableCell className={classes.tablecell}>{row.breachId}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={9}
                          className={classes.tablecell}
                          style={{ textAlign: 'center' }}
                        >
                          <h6 className={styles.norecords}>No Records to Display</h6>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  {/* </div> */}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className={classes.tablepagination}
                classes={{
                  selectLabel: classes.tablePaginationSelectLabel,
                  displayedRows: classes.tablePaginationDisplayedRows,
                  selectIcon: classes.tablePaginationSelectIcon,
                  actions: classes.tablePaginationActions,
                }}
              />
            </Box>
          </div>
        </div>
      </div>
      {isDetailsOpen && (
        <EmployeeExposedCredentialDetails
          open={isDetailsOpen}
          handleAction={setIsDetailsOpen}
          breachInfo={selectedRow}
        />
      )}
    </div>
  )
}

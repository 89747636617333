import React, { useCallback, useEffect, useState } from 'react'
import styles from './style.module.css'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  debounce,
  Chip,
  // ClickAwayListener,
  // Popper,
} from '@mui/material'
import { hasValue } from 'src/utils/commonFns'
import { CategoryDropDown } from './filters/active'
// import { SortBy } from './filters/sortby'
import classes from './style.module.scss'
import { visuallyHidden } from '@mui/utils'
import { AssetDetails } from '../details'
import { getAssetDomains, getAssetIPAddresses, getAssetTechs } from 'src/services/cyberScanService'
import TableRowsLoader from 'src/v2/components/Table/TableRowsLoader'
// import { KebabMenuWhiteIcon } from 'src/v2/icons/manage-team/kebab-menu-white'

const categories = {
  Applications: 0,
  Services: 1,
  Hosting: 2,
  Miscellaneous: 3,
}
export const menus = {
  Domain: 'Domain',
  'IP Addresses': 'IP Addresses',
  Technologies: 'Technologies',
}

export const AssetsList = ({ assessmentInfo, assetCounts }) => {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('assessmentNumber')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [selectedMenu, setSelectedMenu] = React.useState('Domain')
  const [selectedCategory, setSelectedCategory] = useState('Applications')
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [assetList, setAssetList] = useState([])

  const headCells = {
    Domain: ['DOMAIN', 'IP ADDRESS', 'SECURITY FINDINGS', 'SOURCE', 'DNS RECORDS'],
    'IP Addresses': ['IP ADDRESS', 'DOMAINS', 'AS', 'SECURITY FINDINGS', 'SOURCE'],
    Technologies: ['NAME', 'VERSION', 'Assets', 'Categories', 'CVEs'],
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const loadAssetList = async (entityId, page, rowsPerPage, selectedMenu, selectedCategory) => {
    try {
      if (!hasValue(entityId) || !hasValue(selectedMenu)) return
      setLoading(true)
      let data = null

      if (selectedMenu === menus.Domain) {
        data = await getAssetDomains(entityId, page, rowsPerPage)
      } else if (selectedMenu === menus['IP Addresses']) {
        data = await getAssetIPAddresses(entityId, page, rowsPerPage)
      } else if (selectedMenu === menus.Technologies) {
        data = await getAssetTechs(entityId, page, rowsPerPage, categories[selectedCategory])
      }

      if (data?.results?.length > 0) {
        setAssetList(data.results)
        setTotalCount(data.count)
      } else {
        setAssetList([])
        setTotalCount(0)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setSelectedRow(null)
    processDebounce(
      assessmentInfo.coalitionEntityId,
      page,
      rowsPerPage,
      selectedMenu,
      selectedCategory,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentInfo.coalitionEntityId, page, rowsPerPage, selectedMenu, selectedCategory])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const processDebounce = useCallback(
    debounce((coalitionEntityId, page, rowsPerPage, selectedMenu, selectedCategory) => {
      loadAssetList(coalitionEntityId, page, rowsPerPage, selectedMenu, selectedCategory)
    }, 400), // Adjust delay as needed
    [], // Dependencies
  )

  return (
    <div className={styles['table-screen']}>
      <div className={styles.frame}>
        <CategoryDropDown
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          menuItems={Object.keys(menus)}
          count={
            selectedMenu === menus.Domain
              ? assetCounts.domainCount
              : selectedMenu === menus['IP Addresses']
              ? assetCounts.ipAddressCount
              : selectedMenu === menus.Technologies
              ? assetCounts.techAppCount +
                assetCounts.techServicesCount +
                assetCounts.techHostingCount +
                assetCounts.techMiscCount
              : 0
          }
        />
        {selectedMenu === 'Technologies' && (
          <CategoryDropDown
            selectedMenu={selectedCategory}
            setSelectedMenu={setSelectedCategory}
            menuItems={Object.keys(categories)}
          />
        )}
      </div>
      <div className={classes['table-wrapper']}>
        <Box className={classes.content}>
          <TableContainer className={classes.table}>
            <Table size={'medium'}>
              <TableHead className={classes.tablehead}>
                <TableRow>
                  {headCells[selectedMenu].map((headCell) => (
                    <TableCell
                      className={classes.tableheadcell}
                      key={headCell}
                      sortDirection={orderBy === headCell ? order : false}
                    >
                      {false ? (
                        <TableSortLabel
                          active={orderBy === headCell}
                          direction={orderBy === headCell ? order : 'asc'}
                          onClick={() => handleRequestSort(headCell)}
                          className={classes.tablesortlabel}
                        >
                          {headCell}
                          {orderBy === headCell ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        <>{headCell}</>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tablebody}>
                {loading ? (
                  <TableRowsLoader rows={10} columns={5} tableCellClassName={classes.tablecell} />
                ) : assetList.length > 0 ? (
                  assetList.map((row, index) => (
                    <TableRow
                      key={index}
                      className={`${'menu-links'} ${classes['vendor-row']}`}
                      onClick={() => {
                        setSelectedRow(row)
                        setIsDetailsOpen(true)
                      }}
                    >
                      {selectedMenu === menus.Domain ? (
                        <>
                          <TableCell className={classes.tablecell}>{row.domain}</TableCell>
                          <TableCell className={classes.tablecell}>
                            {row.ip_addresses?.join(' ,')?.trim(',')}
                          </TableCell>
                          <TableCell className={classes.tablecell}>
                            {row.findings_count || '--'}
                          </TableCell>

                          <TableCell className={classes.tablecell}>{row.source}</TableCell>
                          <TableCell className={classes.tablecell}>
                            {row.dns_records?.map((r, index) => (
                              <Chip
                                label={r.type}
                                key={`type-chip-` + index}
                                variant="outlined"
                                className={classes.chip}
                              />
                            ))}
                          </TableCell>
                        </>
                      ) : selectedMenu === menus['IP Addresses'] ? (
                        <>
                          <TableCell className={classes.tablecell}>{row.ip_address}</TableCell>
                          <TableCell className={classes.tablecell}>
                            {row.domains?.join(' ,')?.trim(',')}
                          </TableCell>
                          <TableCell className={classes.tablecell}>{row.as_name}</TableCell>
                          <TableCell className={classes.tablecell}>
                            {row.findings_count || '--'}
                          </TableCell>
                          <TableCell className={classes.tablecell}>{row.source}</TableCell>
                        </>
                      ) : selectedMenu === menus.Technologies ? (
                        <>
                          <TableCell className={classes.tablecell}>{row.tech_name}</TableCell>
                          <TableCell className={classes.tablecell}>{row.version}</TableCell>
                          <TableCell className={classes.tablecell}>
                            {row.assets?.length > 1
                              ? `${row.assets[0].asset} & ${row.assets.length - 1} more`
                              : row.assets?.length === 1
                              ? row.assets[0].asset
                              : '--'}
                          </TableCell>

                          <TableCell className={classes.tablecell}>
                            {row.category?.map((r, index) => (
                              <Chip
                                label={r}
                                key={`type-chip-category-` + index}
                                variant="outlined"
                                className={classes.chip}
                              />
                            ))}
                          </TableCell>
                          <TableCell className={classes.tablecell}>
                            <Chip
                              label={row.cves?.length || 0}
                              key={`type-chip-cve-` + index}
                              className={classes.chip}
                            />
                          </TableCell>
                        </>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      className={classes.tablecell}
                      style={{ textAlign: 'center' }}
                    >
                      <h6 className={styles.norecords}>No Records to Display</h6>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {/* </div> */}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.tablepagination}
            classes={{
              selectLabel: classes.tablePaginationSelectLabel,
              displayedRows: classes.tablePaginationDisplayedRows,
              selectIcon: classes.tablePaginationSelectIcon,
              actions: classes.tablePaginationActions,
            }}
          />
        </Box>
      </div>
      {isDetailsOpen && (
        <AssetDetails
          open={isDetailsOpen}
          handleAction={setIsDetailsOpen}
          assetInfo={selectedRow}
          assessmentInfo={assessmentInfo}
          selectedMenu={selectedMenu}
          selectedCategory={selectedCategory}
        />
      )}
    </div>
  )
}

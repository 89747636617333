import React from 'react'
import styles from './style.module.css'
import '@dotlottie/player-component'
import worldMap from 'src/v2/assets/images/worldMap.lottie'

export const AssessmentMap = () => {
  return (
    <div className={styles['map-wrapper']}>
      <dotlottie-player
        src={worldMap}
        autoplay
        loop
        className={styles['lottie-img']}
        objectfit="fill"
        background="#01020f"
        speed={2}
      />
      <div className={styles['upper-div']}>
        <div className={styles['text-wrapper']}>
          We are currently working on assessment, Please check back later. <br /> You will be
          notified once Report is completed.
        </div>
      </div>
    </div>
  )
}

import React from 'react'

const SuccessIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_213_198687" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
        <rect x="2" y="2" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_213_198687)">
        <path
          d="M10.817 13.7882L8.88113 11.8522C8.76572 11.7369 8.62065 11.6779 8.44592 11.6751C8.27134 11.6725 8.12363 11.7315 8.0028 11.8522C7.88211 11.973 7.82176 12.1194 7.82176 12.2913C7.82176 12.4633 7.88211 12.6097 8.0028 12.7305L10.2897 15.0174C10.4404 15.1679 10.6161 15.2432 10.817 15.2432C11.0178 15.2432 11.1936 15.1679 11.3443 15.0174L15.9805 10.3811C16.0958 10.2657 16.1548 10.1206 16.1576 9.94592C16.1602 9.77134 16.1012 9.62363 15.9805 9.5028C15.8597 9.38211 15.7133 9.32176 15.5413 9.32176C15.3694 9.32176 15.223 9.38211 15.1022 9.5028L10.817 13.7882ZM12.0011 19.9163C10.9061 19.9163 9.8769 19.7086 8.91342 19.293C7.94995 18.8775 7.1119 18.3135 6.39926 17.6011C5.68662 16.8888 5.12238 16.0511 4.70655 15.088C4.29085 14.125 4.08301 13.096 4.08301 12.0011C4.08301 10.9061 4.29079 9.8769 4.70634 8.91342C5.1219 7.94995 5.68586 7.1119 6.39822 6.39926C7.11058 5.68662 7.94829 5.12238 8.91134 4.70655C9.8744 4.29085 10.9034 4.08301 11.9982 4.08301C13.0932 4.08301 14.1225 4.29079 15.0859 4.70634C16.0494 5.1219 16.8875 5.68586 17.6001 6.39822C18.3127 7.11058 18.877 7.94829 19.2928 8.91134C19.7085 9.8744 19.9163 10.9034 19.9163 11.9982C19.9163 13.0932 19.7086 14.1225 19.293 15.0859C18.8775 16.0494 18.3135 16.8875 17.6011 17.6001C16.8888 18.3127 16.0511 18.877 15.088 19.2928C14.125 19.7085 13.096 19.9163 12.0011 19.9163ZM11.9997 18.6663C13.8608 18.6663 15.4372 18.0205 16.7288 16.7288C18.0205 15.4372 18.6663 13.8608 18.6663 11.9997C18.6663 10.1386 18.0205 8.56217 16.7288 7.27051C15.4372 5.97884 13.8608 5.33301 11.9997 5.33301C10.1386 5.33301 8.56217 5.97884 7.27051 7.27051C5.97884 8.56217 5.33301 10.1386 5.33301 11.9997C5.33301 13.8608 5.97884 15.4372 7.27051 16.7288C8.56217 18.0205 10.1386 18.6663 11.9997 18.6663Z"
          fill="#0C9D61"
        />
      </g>
    </svg>
  )
}

export default SuccessIcon

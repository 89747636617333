import React, { useState } from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { CrossMark } from 'src/v2/icons/cross-mark'
import { useAuthState } from 'src/context/login'
import { Button, FormControlLabel, InputBase, Modal, Radio, RadioGroup } from '@mui/material'
import { useSnackbar } from 'notistack'
import { addUser, updateUser } from 'src/services/UserService'
import { hasValue } from 'src/utils/commonFns'
import { CustomLabel } from 'src/v2/components/CustomLabel'
import { ErrorText } from 'src/v2/components/ErrorText'
import { sendEmail } from 'src/services/emailService'

const AddEditTeam = ({ open, handleAction, id, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useAuthState()

  const [fields, setFields] = useState([
    {
      label: 'Title',
      key: 'title',
      feedback: '',
      options: ['Mr', 'Mrs', 'Miss', 'Ms'],
      seqNo: 1,
    },
    {
      label: 'First Name',
      key: 'firstName',
      feedback: '',
      seqNo: 2,
    },
    {
      label: 'Last Name',
      key: 'lastName',
      feedback: '',
      seqNo: 3,
    },
    {
      label: 'Business Unit / Department',
      key: 'department',
      feedback: '',
      seqNo: 4,
    },
    {
      label: 'Email',
      key: 'email',
      feedback: '',
      seqNo: 5,
    },
    {
      label: 'User Name',
      key: 'userName',
      readOnly: true,
      feedback: '',
      seqNo: 6,
    },
    {
      label: 'Role',
      key: 'role',
      feedback: '',
      options: ['Business Requestor', 'Business Admin'],
      seqNo: 7,
    },
    {
      label: 'Notes',
      key: 'notes',
      feedback: '',
      multiline: true,
      col: 12,
      notRequired: true,
      seqNo: 8,
    },
  ])

  const [newUser, setUser] = useState({
    id,
    ...rest,
  })

  const handleChange = (e) => {
    setUser({
      ...newUser,
      [e.target.name]: e.target.value,
      ...(e.target.name === 'email'
        ? {
            userName: e.target.value,
          }
        : {}),
    })

    const currentField = fields.filter((x) => x.key === e.target.name)[0]
    const userNameField = fields.filter((x) => x.key === 'userName')[0]
    const otherFields = fields.filter((x) => x.key !== e.target.name && x.key !== 'userName')

    if (!hasValue(e.target.value) && !currentField.notRequired) {
      currentField.feedback = 'Required'
    } else {
      currentField.feedback = ''
    }

    if (e.target.name === 'email') {
      userNameField.feedback = !hasValue(e.target.value) ? 'Required' : ''
    }

    setFields([...otherFields, currentField, userNameField])
  }

  const handleSave = async () => {
    let validationExists = false
    fields.forEach((item) => {
      if (!hasValue(newUser[item.key]) && !item.notRequired) {
        item.feedback = 'Required'
        validationExists = true
      }
    })
    if (validationExists) {
      setFields([...fields])
      return
    }
    const request = { ...newUser }
    let result,
      sucessMessage = 'Member added Successfully.'
    if (request.role === 'Business Admin') request.role = 'BusinessAdmin'
    else request.role = 'BusinessRequestor'
    request.userName = newUser.email
    if (id) {
      sucessMessage = 'Member updated Successfully.'
      result = await updateUser(request)
    } else {
      result = await addUser(request)
    }

    if (hasValue(result) && result.id > 0) {
      if (
        !hasValue(id) ||
        id === 0 ||
        (id > 0 && rest.email.toLowerCase() !== newUser.email.toLowerCase())
      ) {
        sendEmail({
          UserIds: [result.id],
          emailType: request.role,
          BusinessOrgId: user.businessOrganizationId,
        })
      }
      enqueueSnackbar(sucessMessage, {
        variant: 'success',
        autoHideDuration: 5000,
        hideIconVariant: true,
      })

      handleAction(true)
    }
    //if (response) setOpen(false, true)
  }

  return (
    <>
      <Modal open={open} className={styles.modal}>
        <div className={styles.modal}>
          <div className={styles.title}>
            <div className={styles['title-icon']}>
              <div className={styles['text-wrapper']}>{id ? 'Edit' : 'Add new'} user</div>
            </div>
            <IconPlaceholder
              icon={<CrossMark className={styles['icon-instance-node']} />}
              size="twenty-four-x-24"
              onClick={() => handleAction(false)}
            />
          </div>
          <div className={styles.content}>
            {fields
              .sort((a, b) => {
                if (a.seqNo < b.seqNo) return -1
                if (a.seqNo > b.seqNo) return 1
                return 0
              })
              .map((item) => (
                <>
                  <div className={styles['form-item-container']}>
                    <div className={styles.placeholder}>
                      {item.label}
                      {!item.notRequired ? (
                        <span className={styles['text-wrapper-10']}>*</span>
                      ) : (
                        ''
                      )}
                    </div>
                    {item.options && (
                      <div className={styles.level}>
                        <RadioGroup
                          row
                          id={item.key}
                          name={item.key}
                          onChange={handleChange}
                          value={newUser[item.key]}
                          error={hasValue(item.feedback)}
                          required={!item.notRequired}
                        >
                          {item.options.map((data, index) => (
                            <FormControlLabel
                              key={index}
                              value={data}
                              control={<Radio />}
                              label={
                                <CustomLabel selected={newUser[item.key] === data} value={data} />
                              }
                            />
                          ))}
                        </RadioGroup>
                        <ErrorText value={item.feedback} />
                      </div>
                    )}
                    {!item.options &&
                      (!item.multiline ? (
                        <>
                          <InputBase
                            className={styles['form-2']}
                            type={'text'}
                            id={item.key}
                            name={item.key}
                            onChange={handleChange}
                            value={newUser[item.key]}
                            error={hasValue(item.feedback)}
                            required={!item.notRequired}
                            readOnly={item.readOnly}
                          ></InputBase>
                          <ErrorText value={item.feedback} />
                        </>
                      ) : (
                        <>
                          <InputBase
                            className={styles['form-2']}
                            type={'text'}
                            id={item.key}
                            name={item.key}
                            onChange={handleChange}
                            value={newUser[item.key]}
                            error={!item.notRequired && hasValue(item.feedback)}
                            required={!item.notRequired}
                            multiline
                            rows={3}
                          ></InputBase>
                          <ErrorText value={item.feedback} />
                        </>
                      ))}
                  </div>
                </>
              ))}
          </div>
          <div className={styles['button-2']}>
            <Button
              className={styles['button-instance']}
              size="medium"
              state="active"
              color="secondary"
              text="Cancel"
              onClick={() => {
                handleAction(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className={styles['button-instance']}
              size="medium"
              state="active"
              color="primary"
              text="Save"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddEditTeam

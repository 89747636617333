import React from 'react'

export const CrossMark = ({ className }) => {
  return (
    <svg
      className={`icons-3 menu-links ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="20"
        id="mask0_1126_1425"
        maskUnits="userSpaceOnUse"
        width="20"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" />
      </mask>
      <g className="g" mask="url(#mask0_1126_1425)">
        <path
          className="path"
          d="M9.99977 10.8781L5.77227 15.1058C5.65685 15.2211 5.51178 15.2802 5.33706 15.2829C5.16247 15.2856 5.01477 15.2265 4.89393 15.1058C4.77324 14.985 4.71289 14.8386 4.71289 14.6667C4.71289 14.4947 4.77324 14.3483 4.89393 14.2275L9.12164 10L4.89393 5.77251C4.77865 5.65709 4.71963 5.51202 4.71685 5.3373C4.71421 5.16272 4.77324 5.01501 4.89393 4.89418C5.01477 4.77348 5.16115 4.71313 5.3331 4.71313C5.50504 4.71313 5.65143 4.77348 5.77227 4.89418L9.99977 9.12189L14.2273 4.89418C14.3427 4.7789 14.4878 4.71987 14.6625 4.71709C14.8371 4.71445 14.9848 4.77348 15.1056 4.89418C15.2263 5.01501 15.2866 5.1614 15.2866 5.33334C15.2866 5.50529 15.2263 5.65168 15.1056 5.77251L10.8779 10L15.1056 14.2275C15.2209 14.3429 15.2799 14.488 15.2827 14.6627C15.2853 14.8373 15.2263 14.985 15.1056 15.1058C14.9848 15.2265 14.8384 15.2869 14.6664 15.2869C14.4945 15.2869 14.3481 15.2265 14.2273 15.1058L9.99977 10.8781Z"
          fill="#A2A3A4"
        />
      </g>
    </svg>
  )
}

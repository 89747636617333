import React from 'react'

export const CollapseIcon = ({ className }) => {
  return (
    <svg
      className={`expand-icon ${className}`}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="mask0_523_11654" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
        <rect width="22" height="22" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_523_11654)">
        <path
          d="M10.9995 9.58292L7.2659 13.3167C7.13894 13.4435 6.97936 13.5085 6.78717 13.5115C6.59513 13.5144 6.43265 13.4495 6.29973 13.3167C6.16697 13.1838 6.10059 13.0228 6.10059 12.8337C6.10059 12.6445 6.16697 12.4835 6.29973 12.3506L10.4195 8.23084C10.5852 8.06523 10.7786 7.98242 10.9995 7.98242C11.2204 7.98242 11.4137 8.06523 11.5795 8.23084L15.6992 12.3506C15.826 12.4775 15.891 12.6371 15.894 12.8293C15.8969 13.0213 15.832 13.1838 15.6992 13.3167C15.5663 13.4495 15.4053 13.5159 15.2161 13.5159C15.027 13.5159 14.866 13.4495 14.7331 13.3167L10.9995 9.58292Z"
          fill="#E8E8E8"
        />
      </g>
    </svg>
  )
}

import axios from 'axios'
import AppConfig from '../config'

const baseURL = `${AppConfig.API_URL}/InsecureWeb`

const initiateDarkWebScanForAssessment = async (request) => {
  try {
    let { data } = await axios.post(`${baseURL}/initiate-dark-web-scan`, request)

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getBreachListByVendorId = async (vendorId, order, orderBy, page, rowsPerPage, searchText) => {
  try {
    let respone = await axios.get(
      `${baseURL}/get-breach-list-by-vendor-id/${vendorId}?sortBy=${orderBy || ''}&isAscending=${
        order === 'asc'
      }&pageNumber=${page || 0}&pageSize=${rowsPerPage || 25}&searchTerm=${searchText || ''}`,
    )

    let { data } = respone
    if (data.success) {
      return [data.result, parseInt(respone.headers['x-total-count'].toString())]
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getCompanyThreatsListByVendorId = async (vendorId, order, orderBy, page, rowsPerPage) => {
  try {
    let respone = await axios.get(
      `${baseURL}/get-company-threat-list-by-vendor-id/${vendorId}?sortBy=${
        orderBy || ''
      }&isAscending=${order === 'asc'}&pageNumber=${page || 0}&pageSize=${rowsPerPage || 25}`,
    )
    let { data } = respone

    if (data.success) {
      return [data.result, parseInt(respone.headers['x-total-count'].toString())]
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getDarkWebScanStats = async (businessOrgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/get-dark-web-stats/${businessOrgId}`)

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export {
  initiateDarkWebScanForAssessment,
  getCompanyThreatsListByVendorId,
  getBreachListByVendorId,
  getDarkWebScanStats,
}

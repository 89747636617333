import React, { useCallback, useEffect, useState } from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { AssestManagement } from 'src/v2/icons/domainSummary/asset-management'
import { SuccessTickIcon } from 'src/v2/icons/success-tick'
import { FailedTickIcon } from 'src/v2/icons/failed-tick'
import { Chart, ArcElement } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { AngledArrrow } from 'src/v2/icons/angled-arrow'
import { Card } from 'reactstrap'
import { RiskManagement } from 'src/v2/icons/domainSummary/risk-management'
import { ThirdPartyRisk } from 'src/v2/icons/domainSummary/third-party-risk'
import { VulnerabilityManagement } from 'src/v2/icons/domainSummary/vulnerability-management'
import { BcpAndDr } from 'src/v2/icons/domainSummary/bcp-dr'
import { NetworkSecurity } from 'src/v2/icons/domainSummary/network-security'
import { ApplicatonSecurity } from 'src/v2/icons/domainSummary/application-security'
import { IncidentManagement } from 'src/v2/icons/domainSummary/incident-management'
import { NotApplicable } from 'src/v2/icons/not-applicable'

Chart.register(ArcElement)

export const DomainSummaryCard = ({ key, eachDomainData, handleDomainSummaryDetails }) => {
  const domainName = eachDomainData?.domainInfo?.domainName ?? eachDomainData?.domainName
  const [controlsTested, setControlsTested] = useState(0)
  const [passed, setPassed] = useState(0)
  const [failed, setFailed] = useState(0)
  const [notApplicable, setNotApplicable] = useState(0)

  const getDomainIcon = () => {
    var icon
    var domainNameLowerCase = domainName.toLowerCase()
    if (domainNameLowerCase.includes('third party')) {
      icon = <ThirdPartyRisk className={styles['icon-instance-node']} />
    } else if (domainNameLowerCase.includes('vulnerability'))
      icon = <VulnerabilityManagement className={styles['icon-instance-node']} />
    else if (domainNameLowerCase.includes('asset'))
      icon = <AssestManagement className={styles['icon-instance-node']} />
    else if (domainNameLowerCase.includes('risk'))
      icon = <RiskManagement className={styles['icon-instance-node']} />
    else if (domainNameLowerCase.includes('bcp'))
      icon = <BcpAndDr className={styles['icon-instance-node']} />
    else if (domainNameLowerCase.includes('network'))
      icon = <NetworkSecurity className={styles['icon-instance-node']} />
    else if (domainNameLowerCase.includes('application'))
      icon = <ApplicatonSecurity className={styles['icon-instance-node']} />
    else if (domainNameLowerCase.includes('incident'))
      icon = <IncidentManagement className={styles['icon-instance-node']} />
    else icon = <RiskManagement className={styles['icon-instance-node']} />
    return icon
  }

  const icon = getDomainIcon()

  const getQuestionsCount = useCallback(() => {
    var questionsList
    if (eachDomainData.questionsList) {
      questionsList = eachDomainData.questionsList
    } else {
      questionsList = eachDomainData.sections.map((x) => x.questionsList).flat()
    }
    setPassed(questionsList.filter((x) => x.answer === 'Y')?.length)
    setFailed(questionsList.filter((x) => x.answer === 'N')?.length)
    setNotApplicable(questionsList.filter((x) => x.answer !== 'N' && x.answer !== 'Y')?.length)
    setControlsTested(questionsList.length)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eachDomainData.domainType])

  const color =
    eachDomainData?.percentage > 70
      ? '#30AC79'
      : eachDomainData?.percentage > 40
      ? '#FF7738'
      : '#EF4D4F'

  const data = {
    datasets: [
      {
        data: [eachDomainData?.percentage, 100 - eachDomainData?.percentage],
        backgroundColor: [color, '#3A3C3E'],
        borderWidth: 0,
      },
    ],
  }

  const options = {
    responsive: true,
    cutout: '85%',
    tooltips: {
      enabled: true,
    },
  }

  const renderTop3QuestionsList = (eachDomain) => {
    var questionsList = []

    if (eachDomain.questionsList) {
      questionsList = eachDomain.questionsList
    } else {
      questionsList = eachDomain.sections
        .map((x) => x.questionsList)
        .flat()
        .slice(0, 3)
    }

    return questionsList.map((y, index) => (
      <>
        <div className={styles.item}>
          <IconPlaceholder
            icon={
              y.checked === true || y.answer === 'Y' ? (
                <SuccessTickIcon className={styles['icons-2']} size={16} />
              ) : (
                <FailedTickIcon className={styles['icons-2']} size={16} />
              )
            }
            size="twenty-x-20"
          />
          <div className={styles.level}>
            <p className={styles.p}>{y.question || y.name}</p>
          </div>
        </div>
        {index !== 2 ? (
          <div className={styles.divider}>
            <div className={styles.line} />
          </div>
        ) : null}
      </>
    ))
  }

  useEffect(() => {
    if (!eachDomainData.controlsTested) getQuestionsCount()
  }, [eachDomainData.controlsTested, getQuestionsCount])

  return (
    <>
      {' '}
      <Card className={styles.card}>
        <div className={styles.head}>
          <div className={styles.top}>
            <div className={styles.title}>
              <div className={styles['domin-logo']}>
                <IconPlaceholder
                  className={styles['icon-placeholder-instance']}
                  icon={icon}
                  size="thirty-two-x-32"
                />
              </div>
              <div className={styles.div}>
                <div className={styles['access-control']}> {domainName}</div>
                <div className={styles['text-wrapper-2']}>
                  {eachDomainData?.controlsTested || controlsTested} Controls Tested
                </div>
              </div>
            </div>
            <div className={styles.graph}>
              <div className={styles['half-doughnut-chart-instance']}>
                <div className={styles['half-doughnut-chart-2']}>
                  <div
                    className={styles.sectioningraph}
                    style={{ left: eachDomainData?.percentage === 100 ? '17px' : '24px' }}
                  >
                    <div className={styles.valueingraph}>
                      <div className={`${styles.elementingrapgh}`}>
                        <span className={`${styles.spaningraph}`}>
                          {eachDomainData?.percentage || 0}
                        </span>
                        <span className={styles['text-wrapper-graph']}>%</span>
                      </div>
                    </div>
                  </div>
                  <Doughnut data={data} options={options}></Doughnut>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['section-wrapper']}>
            <div className={styles['section-2']}>
              <div className={styles.controls}>
                <div className={styles['icon-content']}>
                  <IconPlaceholder
                    className={styles['icon-placeholder-2']}
                    icon={<SuccessTickIcon className={styles['icon-instance-node']} />}
                    size="twenty-x-20"
                  />
                  <div className={styles['text-wrapper-3']}>Available:</div>
                </div>
                <div className={styles.score}>
                  <div className={styles['element-2']}>{eachDomainData?.passed || passed}</div>
                </div>
              </div>
              <div className={styles.rectangle} />
              <div className={styles.controls}>
                <div className={styles['icon-content']}>
                  <IconPlaceholder
                    icon={<FailedTickIcon className={styles['icon-instance-node']} />}
                    size="twenty-four-x-24"
                  />
                  <div className={styles['text-wrapper-3']}>Failed:</div>
                </div>
                <div className={styles.score}>
                  <div className={styles['element-2']}>{eachDomainData?.failed || failed}</div>
                </div>
              </div>
              <div className={styles.rectangle} />
              <div className={styles.controls}>
                <div className={styles['icon-content']}>
                  <IconPlaceholder
                    icon={<NotApplicable className={styles['icon-instance-node']} />}
                    size="twenty-four-x-24"
                  />
                  <div className={styles['text-wrapper-3']}>NA:</div>
                </div>
                <div className={styles.score}>
                  <div className={styles['element-2']}>
                    {eachDomainData?.notApplicable || notApplicable}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          {eachDomainData && renderTop3QuestionsList(eachDomainData)}
          <div className={styles['view-detail-button']}>
            <div
              className={styles.button}
              onClick={() => {
                handleDomainSummaryDetails(true, eachDomainData)
              }}
            >
              <div className={styles.text}>View details</div>
              <IconPlaceholder
                icon={<AngledArrrow className={styles['icons-2']} />}
                size="twenty-x-20"
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

import React from 'react'
import classes from './style.module.css'
import CTooltip from 'src/v2/components/Tooltip/Tooltip'
import InfoIcon from 'src/v2/screens/dashboard/icons/InfoIcon.svg'
import Typography from '@mui/material/Typography'
import { Box, Grid, Stack } from '@mui/material'
import Chart from 'react-apexcharts'

export const AssetsStatusChart = () => {
  const chartSeries = [8, 6, 7, 5]
  const labels = ['Critical', 'High', 'Medium', 'Low']
  const colors = ['#EF4D4F', '#FF7738', '#FEAA32', '#30AC79']
  const totalSum = chartSeries.reduce((acc, val) => acc + val, 0)

  const series = [
    {
      name: 'Low',
      data: [4, 1, 0],
    },
    {
      name: 'Medium',
      data: [2, 4, 1],
    },
    {
      name: 'High',
      data: [2, 4, 0],
    },
    {
      name: 'Critical',
      data: [4, 3, 1],
    },
  ]
  const chartOptions = useChartOptions(series)

  return (
    <div className={`${classes['remediation-status']}`}>
      <div className={classes.section}>
        <div className={classes.title}>
          <div className={classes['title-data']}>Assets Status</div>
          <div className={classes.iconPlaceholder}>
            <CTooltip
              arrow
              title={
                <React.Fragment>
                  <Typography variant="body2" fontWeight={500} sx={{ mb: '4px' }}>
                    Assets Status
                  </Typography>
                  <Typography variant="body2">
                    A Vendor Assessment Risk Metrics Chart is a visual tool that summarizes the
                    overall risk level of evaluated vendors. It combines various risk indicators.
                  </Typography>
                </React.Fragment>
              }
            >
              <img className={classes.icons} loading="lazy" alt="" src={InfoIcon} />
            </CTooltip>
          </div>
        </div>
        {/* <p className={classes['order-info']}>Jun 2022 - Jun 2024</p> */}
      </div>
      <Box className={classes['status-row']}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
          {chartSeries.map((item, index) => {
            const label = labels[index]
            return (
              <Stack key={label} spacing={1} className={classes.legenedBox}>
                <Grid container>
                  <Grid item xs={3} className={classes.legendDot}>
                    <span style={{ backgroundColor: colors[index] }} />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className={classes.legenedHeading} variant="h6">
                      {label}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={9} className={classes.legenedValue}>
                    <Typography variant="subtitle2">{item}</Typography>
                    <Typography variant="body2">{`(${parseInt(
                      (item / totalSum) * 100,
                    )}%)`}</Typography>
                  </Grid>
                </Grid>
              </Stack>
            )
          })}
        </Stack>
      </Box>
      <Box className={classes['chart-row']}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Chart options={chartOptions} series={series} type="bar" height={265} />
          </Grid>
          <Grid item xs={2} className={classes['legends']}>
            <Typography variant="subtitle2" className={classes['legend-text']}>
              {'Domain'}
            </Typography>
            <Typography variant="h6" className={classes['legend-value']}>
              {series.map((x) => x.data[0]).reduce((acc, current) => acc + current, 0)}
            </Typography>
            <Typography variant="subtitle2" className={classes['legend-text']}>
              {'IP Address'}
            </Typography>
            <Typography variant="h6" className={classes['legend-value']}>
              {series.map((x) => x.data[1]).reduce((acc, current) => acc + current, 0)}
            </Typography>
            <Typography variant="subtitle2" className={classes['legend-text']}>
              {'Endpoints'}
            </Typography>
            <Typography variant="h6" className={classes['legend-value']}>
              {series.map((x) => x.data[2]).reduce((acc, current) => acc + current, 0)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

const useChartOptions = (series) => {
  return {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming
      },
    },

    tooltip: {
      // enabledOnSeries: [0, 2, 4, 6],
      theme: 'dark',
      cssClass: 'c-apexcharts-tooltip',
      style: {
        fontSize: '12px',
        fontFamily: '"SF Pro Text", Helvetica',
      },
      y: {
        title: {
          formatter(seriesName) {
            return seriesName
          },
        },
      },
      x: {
        title: {
          formatter(seriesName) {
            return seriesName
          },
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30px', // Adjust column thickness (width)
        barHeight: '5px', // Adjust the height of the bar (affects gap between stacks)
        borderRadius: 2, // Add some border radius if you want rounded edges
        dataLabels: {
          position: 'top', // Align data labels at the top of the bars
        },
        distributed: false,
      },
    },
    xaxis: {
      categories: ['Domain', 'IP Address', 'Endpoints'],
      tickPlacement: 'between',
      range: 2.5,
      labels: {
        style: {
          colors: '#E8E8E8',
          fontSize: '12px',
          fontFamily: '"SF Pro Text", Helvetica',
        },
      },
      axisTicks: {
        show: false,
        borderType: 'solid',
        color: '#5D5E60',
        height: 6,
      },
      axisBorder: {
        show: true,
        color: '#5D5E60',
        offsetX: 0,
      },
    },
    yaxis: {
      // max: 40,
      // stepSize: 10,
      labels: {
        style: {
          colors: '#E8E8E8',
          fontSize: '12px',
          fontFamily: '"SF Pro Text", Helvetica',
        },
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#5D5E60',
        // height: 6,
        offsetY: 1,
      },
      axisBorder: {
        show: true,
        color: '#5D5E60',
        offsetX: 0,
      },
    },
    stroke: {
      width: 3,
      colors: ['#3A3C3E'],
    },
    grid: {
      show: true,
      borderColor: '#3A3C3E',
      xaxis: {
        lines: {
          show: true,
          offsetX: 0,
        },
      },
      yaxis: {
        lines: {
          show: true,
          offsetX: 1,
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      formatter: function (val, options) {
        if (options.seriesIndex === 3) {
          return series
            .map((x) => x.data[options.dataPointIndex])
            .reduce((acc, current) => acc + current, 0)
        } else {
          return ''
        }
      },
      style: {
        colors: ['#1B1C1E'],
        fontSize: '12px',
        fontFamily: '"SF Pro Text", Helvetica',
        lineHeight: '18px',
      },
      background: {
        enabled: true,
        borderRadius: 2,
        borderColor: 'transparent',
        foreColor: '#E8E8E8',
      },
    },
    colors: ['#30AC79', '#FEAA32', '#FF7738', '#EF4D4F'],
  }
}

import React from 'react'

export const KebabMenuIcon = ({ className }) => {
  return (
    <svg
      className={`icons-21 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="20"
        id="mask0_480_2000"
        maskUnits="userSpaceOnUse"
        width="20"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" />
      </mask>
      <g className="g" mask="url(#mask0_480_2000)">
        <path
          className="path"
          d="M10 16.0578C9.65625 16.0578 9.36201 15.9354 9.11729 15.6905C8.87243 15.4458 8.75 15.1516 8.75 14.8078C8.75 14.4641 8.87243 14.1697 9.11729 13.9249C9.36201 13.6802 9.65625 13.5578 10 13.5578C10.3438 13.5578 10.638 13.6802 10.8827 13.9249C11.1276 14.1697 11.25 14.4641 11.25 14.8078C11.25 15.1516 11.1276 15.4458 10.8827 15.6905C10.638 15.9354 10.3438 16.0578 10 16.0578ZM10 11.2501C9.65625 11.2501 9.36201 11.1277 9.11729 10.8828C8.87243 10.6381 8.75 10.3438 8.75 10.0001C8.75 9.65634 8.87243 9.36211 9.11729 9.11738C9.36201 8.87252 9.65625 8.75009 10 8.75009C10.3438 8.75009 10.638 8.87252 10.8827 9.11738C11.1276 9.36211 11.25 9.65634 11.25 10.0001C11.25 10.3438 11.1276 10.6381 10.8827 10.8828C10.638 11.1277 10.3438 11.2501 10 11.2501ZM10 6.44238C9.65625 6.44238 9.36201 6.32002 9.11729 6.0753C8.87243 5.83044 8.75 5.53613 8.75 5.19238C8.75 4.84863 8.87243 4.5544 9.11729 4.30967C9.36201 4.06481 9.65625 3.94238 10 3.94238C10.3438 3.94238 10.638 4.06481 10.8827 4.30967C11.1276 4.5544 11.25 4.84863 11.25 5.19238C11.25 5.53613 11.1276 5.83044 10.8827 6.0753C10.638 6.32002 10.3438 6.44238 10 6.44238Z"
          fill="#93B0EF"
        />
      </g>
    </svg>
  )
}

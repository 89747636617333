import React from 'react'
import classes from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { IssueIdentifiedIcon } from 'src/v2/assets/icons/remediation/sidesheet/issue-identified'
import { RiskIcon } from 'src/v2/assets/icons/remediation/sidesheet/risk'
import { RemediationIcon } from 'src/v2/assets/icons/remediation/sidesheet/remediation'
import { EvidenceIcon } from 'src/v2/assets/icons/remediation/sidesheet/evidence'

export const Content = ({ remediation }) => {
  return (
    <div className={classes.content}>
      <div className={classes['div-2']}>
        <div className={classes['single-content-text']}>
          <div className={classes.issue}>
            <IconPlaceholder
              icon={<IssueIdentifiedIcon className={classes['icon-instance-node']} />}
              size="twenty-four-x-24"
            />
            <div className={classes['body-text']}>ISSUE IDENTIFIED</div>
          </div>
          <div className={classes['body-text-wrapper']}>
            <p className={classes['text-wrapper']}>{remediation.issueIdentified}</p>
          </div>
        </div>
        <div className={classes['single-content-text']}>
          <div className={classes.issue}>
            <IconPlaceholder
              icon={<RiskIcon className={classes['icon-instance-node']} />}
              size="twenty-four-x-24"
            />
            <div className={classes['body-text-2']}>RISK</div>
          </div>
          <div className={classes['body-text-wrapper']}>
            <p className={classes['text-wrapper']}>{remediation.risk}</p>
          </div>
        </div>
        <div className={classes['single-content-text-2']}>
          <div className={classes.issue}>
            <IconPlaceholder
              icon={<RemediationIcon className={classes['icon-instance-node']} />}
              size="twenty-four-x-24"
            />
            <div className={classes['body-text-3']}>REMEDIATION</div>
          </div>
          <div className={classes['text-2']}>
            <p className={classes['p']}>{remediation.remediation}</p>
          </div>
        </div>
        <div className={classes['single-content-text']}>
          <div className={classes.issue}>
            <IconPlaceholder
              icon={<EvidenceIcon className={classes['icon-instance-node']} />}
              size="twenty-four-x-24"
            />
            <div className={classes['body-text']}>EVIDENCE TO PROVIDE</div>
          </div>
          <div className={classes['body-text-wrapper']}>
            <p className={classes['text-wrapper']}>{remediation.evidence}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

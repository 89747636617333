import React, { useState } from 'react'

const ToastContext = React.createContext()

export function useToast() {
  const context = React.useContext(ToastContext)
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider')
  }

  return context
}

// eslint-disable-next-line react/prop-types
export const ToastProvider = ({ children }) => {
  const [toastList, setToastList] = useState([])

  const addToast = (header, message, options) => {
    var toastItem = {
      key: Math.random().toString().slice(2),
      message,
      header,
      options,
    }
    setToastList((prevVal) => [toastItem, ...prevVal])
  }

  const removeToast = (toastITem) => {
    setToastList((preVVal) => preVVal.filter((x) => x.key !== toastITem.key))
  }

  return (
    <ToastContext.Provider
      value={{
        toastList,
        addToast,
        removeToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}

import React, { useReducer, useState, useEffect, useCallback } from 'react'
import styles from './style.module.css'
import {
  Button,
  FormControlLabel,
  Switch,
  Checkbox,
  Radio,
  InputBase,
  MenuItem,
  Select,
  RadioGroup,
  FormGroup,
  InputAdornment,
} from '@mui/material'
import { useAuthState } from 'src/context/login'
import {
  BusinessData,
  ClientSubmissionStatus,
  CompletionTimePeriod,
  emailRegex,
  FinancialCommercialData,
  hasValue,
  IntellectualPropertyData,
  PHI,
  PI,
  PublicData,
  RegulatoryComplianceData,
  SecurityData,
  SPI,
} from 'src/utils/commonFns'
import {
  addClientSubmission,
  deleteClientSubmission,
  getClientSubmission,
  updateClientSubmission,
} from 'src/services/clientSubmissionService'
import { ErrorText } from 'src/v2/components/ErrorText'
import { UpArrorwIcon } from 'src/v2/icons/up-arrow-icon'
import { CircleLoader } from 'src/v2/components/CircleLoader'
import Confirmation from 'src/v2/components/Confirmation'
import { useSnackbar } from 'notistack'
import UploadNdaDocument from './UploadNdaDocument'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { CrossMark } from 'src/v2/icons/cross-mark'
import { CustomLabel } from 'src/v2/components/CustomLabel'
import { PlainTick } from 'src/v2/icons/plain-tick'
import SuccessIcon from 'src/v2/components/Snack/SuccessIcon'
import { DrakWebScanIcon } from 'src/v2/icons/assessments/dark-web-scan'
import { FormIconWhite } from 'src/v2/icons/assessments/form-white'
import { SearchIcon } from 'src/v2/icons/search-icon'
import { SearchVendorList } from './search-vendor-list'
import BeaconerLogo from 'src/v2/assets/images/beaconer-logo.svg'
import { initiateDarkWebScanForAssessment } from 'src/services/insecureWebScanService'
import { getVendorBySearch } from 'src/services/vendorService'
import { CheckCircleTickIcon } from 'src/v2/icons/chcek-circle-tick'

// const vendorInfoData = [
//   {
//     vendorName: 'Slack',
//     description: 'Test Databss shjsbs sksns bkbkbsjhbbs ',
//     category: 'IT',
//     logoUrl: 'https://c.animaapp.com/gSEqlFW2/img/company-logo-4@2x.png',
//   },
// ]

const initialAssessmentState = {
  thirdPartyDesc: '',
  logoUrl: '',
  businessOrgName: '',
  businessOrgId: 0,
  businessRequestorName: '',
  businessRequestorId: 0,
  businessUnitDepartmentName: '',
  thirdPartyName: '',
  thirdPartyRepresentativeName: '',
  thirdPartyRepresentativeEmail: '',
  ndaWithThirdParty: '',
  ndaDocument: null,
  projectName: '',
  detailedDescriptionProject: '',
  applicationInvolved: '',
  typeOfCompanyData: [],
  typeOfCompanyDataRemarks: '',
  detailedDataElementsCompanyData: '',
  vendorCollectPIFromIndividuals: '',
  noOfPISPIRecordsCompany: 0,
  dataSubjectLocationCompany: '',
  puposeOfCollectingPISPICompany: '',
  willPISPITransferredCompany: false,
  typeOfCustomerData: [],
  typeOfCustomerDataRemarks: '',
  detailedDataElementsOfCustomerData: '',
  noOfPISPIRecordsCustomer: 0,
  dataSubjectLocationCustomer: '',
  puposeOfCollectingPISPICustomer: '',
  willPISPITransferredCustomer: false,
  dataStoreLocationType: '',
  dataStoreLocationRemarks: '',
  dataProcessedByThirdPartySubContractor: '',
  dataProcessedByThirdPartySubContractorRemarks: '',
  trackingOfIndividual: '',
  trackingOfIndividualRemarks: '',
  dependabilityOfThirdParty: '',
  mobileAppInvolved: '',
  vendorHaveNetworkAccess: '',
  ndaWithThirdPartybool: false,
  applicationInvolvedBool: false,
  vendorHaveNetworkAccessBool: false,
  completionTimePeriod: CompletionTimePeriod.AsPerAvailability,
  vendorTask: '',
  thirdPartyDeliverType: '',
  thirdPartyAccessToNetwork: '',
  thirdPartyWebsite: '',
  thirdPartyLinkedIn: '',
  companyDataInvolved: '',
  companyBusinessData: [],
  companyPublicData: [],
  companyPIData: [],
  companySPIData: [],
  companyPHIData: [],
  companyRegulatoryComplianceData: [],
  companyFinancialCommercialData: [],
  companyIntellectualPropertyData: [],
  companyMarketingData: '',
  companySecurityData: [],
  isTherePISPIInvolvedInThisCompanyProject: '',
  companyNoneOftheAbove: '',
  companyDataList: '',
  customerDataInvolved: '',
  customerBusinessData: [],
  customerPublicData: [],
  customerSPIData: [],
  customerPIData: [],
  customerPHIData: [],
  customerRegulatoryComplianceData: [],
  customerFinancialCommercialData: [],
  customerIntellectualPropertyData: [],
  customerMarketingData: '',
  customerSecurityData: [],
  isTherePISPIInvolvedInThisCustomerProject: '',
  customerNoneOftheAbove: '',
  customerDataList: '',
}

const assessmentReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        [action.key]: action.value,
      }
    case 'update_multiple':
      return {
        ...state,
        ...action.multiple_updates_state,
      }
    case 'reset':
      return {
        ...initialAssessmentState,
        businessOrgName: action.businessOrgName,
        businessOrgId: action.businessOrgId,
        businessRequestorName: action.businessRequestorName,
        businessRequestorId: action.businessRequestorId,
        businessUnitDepartmentName: action.businessUnitDepartmentName,
      }
    case 'clone':
      return {
        ...state,
        businessOrgName: action.businessOrgName,
        businessOrgId: action.businessOrgId,
        businessRequestorName: action.businessRequestorName,
        businessRequestorId: action.businessRequestorId,
        businessUnitDepartmentName: action.businessUnitDepartmentName,
      }
    default:
      return state
  }
}

const feedbackInitialState = {
  businessRequestorName: '',
  thirdPartyName: '',
  thirdPartyRepresentativeName: '',
  thirdPartyRepresentativeEmail: '',
  ndaWithThirdParty: '',
  projectName: '',
  detailedDescriptionProject: '',
  applicationInvolved: '',
  typeOfCompanyData: '',
  typeOfCompanyDataRemarks: '',
  detailedDataElementsCompanyData: '',
  vendorCollectPIFromIndividuals: '',
  typeOfCustomerData: '',
  typeOfCustomerDataRemarks: '',
  detailedDataElementsOfCustomerData: '',
  dataStoreLocationType: '',
  dataStoreLocationRemarks: '',
  dataProcessedByThirdPartySubContractor: '',
  dataProcessedByThirdPartySubContractorRemarks: '',
  trackingOfIndividual: '',
  trackingOfIndividualRemarks: '',
  dependabilityOfThirdParty: '',
  mobileAppInvolved: '',
  vendorHaveNetworkAccess: '',
  ndaWithThirdPartybool: false,
  applicationInvolvedBool: false,
  vendorHaveNetworkAccessBool: false,
  completionTimePeriod: CompletionTimePeriod.AsPerAvailability,
  vendorTask: '',
  thirdPartyDeliverType: '',
  thirdPartyAccessToNetwork: '',
  thirdPartyWebsite: '',
  thirdPartyLinkedIn: '',
  companyDataInvolved: '',
  companyBusinessData: [],
  companyPublicData: [],
  companyPIData: [],
  companySPIData: [],
  companyPHIData: [],
  companyRegulatoryComplianceData: [],
  companyFinancialCommercialData: [],
  companyIntellectualPropertyData: [],
  companyMarketingData: '',
  companySecurityData: [],
  isTherePISPIInvolvedInThisCompanyProject: '',
  companyNoneOftheAbove: '',
  companyDataList: '',
  customerDataInvolved: '',
  customerBusinessData: [],
  customerPublicData: [],
  customerPIData: [],
  customerSPIData: [],
  customerPHIData: [],
  customerRegulatoryComplianceData: [],
  customerFinancialCommercialData: [],
  customerIntellectualPropertyData: [],
  customerMarketingData: '',
  customerSecurityData: [],
  isTherePISPIInvolvedInThisCustomerProject: '',
  customerNoneOftheAbove: '',
  customerDataList: '',
}

const feedbackReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        [action.key]: action.value,
      }
    case 'update_multiple':
      return {
        ...state,
        ...action.multiple_updates_state,
      }
    case 'reset':
      return {
        ...initialAssessmentState,
      }
    default:
      return state
  }
}

const initialTabNavigationstyles = {
  projectDetails: 'icon-3',
  projectDetailsNumber: 'text-wrapper-7',
  projectDetailsText: 'text-wrapper-8',
  systemDetails: 'icon-3',
  systemDetailsNumber: 'text-wrapper-7',
  systemDetailsText: 'text-wrapper-8',
  companyData: 'icon-3',
  companyDataNumber: 'text-wrapper-7',
  companyDataText: 'text-wrapper-8',
  customerData: 'icon-3',
  customerDataNumber: 'text-wrapper-7',
  customerDataText: 'text-wrapper-8',
  otherDetails: 'icon-3',
  otherDetailsNumber: 'text-wrapper-7',
  otherDetailsText: 'text-wrapper-8',
  launchAssessment: 'icon-3',
  launchAssessmentNumber: 'text-wrapper-7',
  launchAssessmentText: 'text-wrapper-8',
}

export const AssessmentRequest = ({ id, draftNumber, reloadData, saveDraftData, draftData }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openSearchVendorList, setOpenSearchVendorList] = useState(false)
  const [searchVendorText, setSearchVendorText] = useState('')
  const [vendorSearchDone, setVendorSearchDone] = useState(false)
  const [assessmentState, assessmentDispatch] = useReducer(
    assessmentReducer,
    initialAssessmentState,
  )
  const [feedbackState, feedbackDispatch] = useReducer(feedbackReducer, feedbackInitialState)
  const [ndaDocInfo, setNDADocInfo] = useState({
    ndaDocName: '',
    ndaDocSignedUrl: '',
    fileslist: null,
  })
  const [currentStep, setCurrentStep] = useState(0)
  const { user } = useAuthState()
  const [isReadOnlyMode, setReadOnlyMode] = useState(false)
  const [hideCancel, setHideCancel] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [csStatus, setCsStatus] = useState(false)
  const [openUploadNDADoc, setOpenUploadNDADoc] = useState(false)
  const [tabNavigationIcons, setTabNavigationIcons] = useState({
    ...initialTabNavigationstyles,
    projectDetails: 'icon-2',
    projectDetailsNumber: 'text-wrapper-5',
    projectDetailsText: 'text-wrapper-6',
  })
  const [thirdPartNameReadOnly, setThirdPartNameReadOnly] = useState(false)
  const [vendorInfo, setVendorInfo] = useState(null)
  const [isSearchLoading, setIsSearchLoading] = useState(false)

  const handleNext = () => {
    const multiple_updates_state = performValidations()

    feedbackDispatch({ type: 'update_multiple', multiple_updates_state })

    if (!Object.keys(multiple_updates_state).some((x) => hasValue(multiple_updates_state[x]))) {
      //last step...
      if (currentStep === 5) {
        saveOrUpdateSubmission(false)
      } else {
        setCurrentStep((prevVal) => prevVal + 1)
      }
    }
  }

  const saveOrUpdateSubmission = async (isDraft) => {
    if (assessmentState.ndaWithThirdPartybool) assessmentState.ndaWithThirdParty = 'Y'
    else assessmentState.ndaWithThirdParty = 'N'

    if (assessmentState.applicationInvolvedBool) assessmentState.applicationInvolved = 'Y'
    else assessmentState.applicationInvolved = 'N'

    if (assessmentState.vendorHaveNetworkAccessBool) assessmentState.vendorHaveNetworkAccess = 'Y'
    else assessmentState.vendorHaveNetworkAccess = 'N'

    const request = new FormData()
    request.append('thirdPartyName', assessmentState.thirdPartyName)
    request.append('logoUrl', assessmentState.logoUrl)

    request.append(
      'status',
      isDraft ? ClientSubmissionStatus.Draft : ClientSubmissionStatus.Requested,
    )
    request.append('businessOrgId', assessmentState.businessOrgId)
    request.append('preAssessmentQuestionnaire', JSON.stringify(assessmentState))

    if (hasValue(ndaDocInfo) && hasValue(ndaDocInfo.fileslist))
      request.append('ndaDocument', ndaDocInfo.fileslist)
    setIsSaveLoading(true)

    if (hasValue(id)) {
      request.append('id', id)
      var result = await updateClientSubmission(request)
      if (result && !isDraft) {
        await initiateDarkWebScanForAssessment({
          ClientSubmissionId: id,
          OrganizationDomain: assessmentState.thirdPartyWebsite,
          OrganizationName: assessmentState.thirdPartyName,
        })
      }
      setIsSaveLoading(false)
      if (result) {
        reloadData(draftNumber)
        enqueueSnackbar('Request Updated Successfully', {
          variant: 'success',
          autoHideDuration: 5000,
          hideIconVariant: true,
        })
      }
    } else {
      var response = await addClientSubmission(request)
      if (response && !isDraft) {
        await initiateDarkWebScanForAssessment({
          ClientSubmissionId: response,
          OrganizationDomain: assessmentState.thirdPartyWebsite,
          OrganizationName: assessmentState.thirdPartyName,
        })
      }
      setIsSaveLoading(false)

      if (response) {
        reloadData(draftNumber)
        enqueueSnackbar(`Request ${isDraft ? 'Saved' : 'Submitted'} Successfully`, {
          variant: 'success',
          autoHideDuration: 5000,
          hideIconVariant: true,
        })
      }
    }
  }

  const handleConfirmationClose = async (isSaveDraft, isClose) => {
    setOpenConfirmation(false)
    if (isClose) return

    if (!isSaveDraft) {
      try {
        setIsSaveLoading(true)
        var result = null
        if (!id || id < 0) {
          result = true
        } else if (id > 0 && csStatus === ClientSubmissionStatus.Draft) {
          result = await deleteClientSubmission(id)
        } else {
          result = await handleCancel()
        }
        if (result) {
          var successMessage =
            !id || id < 0
              ? 'Assessment deleted successfully.'
              : id > 0 && csStatus === ClientSubmissionStatus.Draft
              ? 'Draft deleted successfully.'
              : 'Request cancelled successfully.'

          enqueueSnackbar(successMessage, {
            variant: 'success',
            autoHideDuration: 5000,
            hideIconVariant: true,
          })
        }
      } finally {
        reloadData(draftNumber)
        setIsSaveLoading(false)
      }
    } else if (!id || id < 0) {
      handleSaveAsDraft()
    }
  }

  const deleteRequest = () => {
    setOpenConfirmation(true)
  }

  const handleCancel = async (reasonToCancel) => {
    const request = new FormData()
    request.append('thirdPartyName', assessmentState.thirdPartyName)

    request.append('status', ClientSubmissionStatus.Cancelled)
    request.append('reasonToCancel', reasonToCancel)

    request.append('businessOrgId', assessmentState.businessOrgId)
    request.append('preAssessmentQuestionnaire', JSON.stringify(assessmentState))

    if (hasValue(ndaDocInfo) && hasValue(ndaDocInfo.fileslist))
      request.append('ndaDocument', ndaDocInfo.fileslist)

    if (hasValue(id)) {
      request.append('id', id)
      var result = await updateClientSubmission(request)
      if (result) {
        reloadData(draftNumber)
      }
      return result
    }
  }

  const performValidations = () => {
    let multiple_updates_state = {}

    if (currentStep === 0) {
      if (!hasValue(assessmentState.businessRequestorName)) {
        multiple_updates_state.businessRequestorName = 'Please Enter Business Requestor Name'
      }
      if (!hasValue(assessmentState.thirdPartyName)) {
        multiple_updates_state.thirdPartyName = 'Please Enter Third Party Name'
      }
      if (!hasValue(assessmentState.thirdPartyRepresentativeName)) {
        multiple_updates_state.thirdPartyRepresentativeName = 'Please Enter Name'
      }
      if (!hasValue(assessmentState.thirdPartyRepresentativeEmail)) {
        multiple_updates_state.thirdPartyRepresentativeEmail = 'Please Enter Email'
      }
      if (
        hasValue(assessmentState.thirdPartyRepresentativeEmail) &&
        !emailRegex.test(assessmentState.thirdPartyRepresentativeEmail)
      ) {
        multiple_updates_state.thirdPartyRepresentativeEmail = 'Please Enter Valid Email'
      }

      // if (!hasValue(assessmentState.ndaWithThirdParty)) {
      //   multiple_updates_state.ndaWithThirdParty = 'Please Select NDA with Third Party'
      // }

      if (assessmentState.ndaWithThirdPartybool && !hasValue(ndaDocInfo.ndaDocName)) {
        multiple_updates_state.ndaDocument = 'Please Upload NDA Document'
      } else {
        multiple_updates_state.ndaDocument = ''
      }

      if (!hasValue(assessmentState.completionTimePeriod)) {
        multiple_updates_state.completionTimePeriod = 'Please select Completion Time Period'
      }
    } else if (currentStep === 1) {
      if (!hasValue(assessmentState.projectName)) {
        multiple_updates_state.projectName = 'Please Enter Project / System / Software Name'
      }

      // if (!hasValue(assessmentState.applicationInvolved)) {
      //   multiple_updates_state.applicationInvolved = 'Please Select application / Saas'
      // }

      if (!hasValue(assessmentState.detailedDescriptionProject)) {
        multiple_updates_state.detailedDescriptionProject = 'Please Enter Description'
      }
    } else if (currentStep === 2) {
      // if (!hasValue(assessmentState.typeOfCompanyData)) {
      //   multiple_updates_state.typeOfCompanyData = 'Please Select Type(s) of company data'
      // }

      // if (
      //   assessmentState.typeOfCompanyData?.indexOf(TypesOfCompanyData.NoneOfTheAbove) > -1 &&
      //   !hasValue(assessmentState.typeOfCompanyDataRemarks)
      // ) {
      //   multiple_updates_state.typeOfCompanyDataRemarks = 'Please Enter Remarks'
      // } else {
      //   multiple_updates_state.typeOfCompanyDataRemarks = ''
      // }

      // if (
      //   !hasValue(assessmentState.detailedDataElementsCompanyData) &&
      //   assessmentState.typeOfCompanyData?.indexOf(TypesOfCompanyData.NoneOfTheAbove) < 0 &&
      //   assessmentState.typeOfCompanyData?.indexOf(TypesOfCompanyData.NoDataInvolved) < 0
      // ) {
      //   multiple_updates_state.detailedDataElementsCompanyData =
      //     'Please Enter Detailed data elements'
      // }

      if (!hasValue(assessmentState.companyDataInvolved)) {
        multiple_updates_state.companyDataInvolved = 'Please Select customer data involved or not'
      }

      if (
        assessmentState.companyNoneOftheAbove &&
        !hasValue(assessmentState.typeOfCompanyDataRemarks)
      ) {
        multiple_updates_state.typeOfCompanyDataRemarks = 'Please Enter Remarks'
      } else {
        multiple_updates_state.typeOfCompanyDataRemarks = ''
      }

      if (
        assessmentState.companyDataInvolved === 'Y' &&
        !assessmentState.companyNoneOftheAbove &&
        (!assessmentState.companyBusinessData ||
          assessmentState.companyBusinessData?.length <= 0) &&
        (!assessmentState.companyPublicData || assessmentState.companyPublicData?.length <= 0) &&
        (!assessmentState.companyPIData || assessmentState.companyPIData?.length <= 0) &&
        (!assessmentState.companySPIData || assessmentState.companySPIData?.length <= 0) &&
        (!assessmentState.companyPHIData || assessmentState.companyPHIData?.length <= 0) &&
        (!assessmentState.companyRegulatoryComplianceData ||
          assessmentState.companyRegulatoryComplianceData?.length <= 0) &&
        (!assessmentState.companyFinancialCommercialData ||
          assessmentState.companyFinancialCommercialData?.length <= 0) &&
        (!assessmentState.companyIntellectualPropertyData ||
          assessmentState.companyIntellectualPropertyData?.length <= 0) &&
        (!assessmentState.companyMarketingData ||
          assessmentState.companyMarketingData?.length <= 0) &&
        (!assessmentState.companySecurityData ||
          assessmentState.companySecurityData?.length <= 0) &&
        !assessmentState.isTherePISPIInvolvedInThiscompanyProject
      ) {
        multiple_updates_state.companyDataList = 'Please Select company Data'
      } else {
        multiple_updates_state.companyDataList = ''
      }

      if (
        (assessmentState.isTherePISPIInvolvedInThiscompanyProject === 'Y') &
        !hasValue(assessmentState.vendorCollectPIFromIndividuals)
      ) {
        multiple_updates_state.vendorCollectPIFromIndividuals =
          'Please Select PI Collected directly from individuals'
      }
    } else if (currentStep === 3) {
      // if (!hasValue(assessmentState.typeOfCustomerData)) {
      //   multiple_updates_state.typeOfCustomerData = 'Please Select Type(s) of customer data'
      // }

      if (!hasValue(assessmentState.customerDataInvolved)) {
        multiple_updates_state.customerDataInvolved = 'Please Select customer data involved or not'
      }

      if (
        assessmentState.customerNoneOftheAbove &&
        !hasValue(assessmentState.typeOfCustomerDataRemarks)
      ) {
        multiple_updates_state.typeOfCustomerDataRemarks = 'Please Enter Remarks'
      } else {
        multiple_updates_state.typeOfCustomerDataRemarks = ''
      }

      if (
        assessmentState.customerDataInvolved === 'Y' &&
        !assessmentState.customerNoneOftheAbove &&
        (!assessmentState.customerBusinessData ||
          assessmentState.customerBusinessData?.length <= 0) &&
        (!assessmentState.customerPublicData || assessmentState.customerPublicData?.length <= 0) &&
        (!assessmentState.customerPIData || assessmentState.customerPIData?.length <= 0) &&
        (!assessmentState.customerSPIData || assessmentState.customerSPIData?.length <= 0) &&
        (!assessmentState.customerPHIData || assessmentState.customerPHIData?.length <= 0) &&
        (!assessmentState.customerRegulatoryComplianceData ||
          assessmentState.customerRegulatoryComplianceData?.length <= 0) &&
        (!assessmentState.customerFinancialCommercialData ||
          assessmentState.customerFinancialCommercialData?.length <= 0) &&
        (!assessmentState.customerIntellectualPropertyData ||
          assessmentState.customerIntellectualPropertyData?.length <= 0) &&
        (!assessmentState.customerMarketingData ||
          assessmentState.customerMarketingData?.length <= 0) &&
        (!assessmentState.customerSecurityData ||
          assessmentState.customerSecurityData?.length <= 0) &&
        !assessmentState.isTherePISPIInvolvedInThisCustomerProject
      ) {
        multiple_updates_state.customerDataList = 'Please Select Customer Data'
      } else {
        multiple_updates_state.customerDataList = ''
      }

      // if (
      //   assessmentState.typeOfCustomerData?.indexOf(TypesOfCustomerData.NoneOfTheAbove) < 0 &&
      //   assessmentState.typeOfCustomerData?.indexOf(TypesOfCustomerData.NoDataInvolved) < 0 &&
      //   !hasValue(assessmentState.detailedDataElementsOfCustomerData)
      // ) {
      //   multiple_updates_state.detailedDataElementsOfCustomerData =
      //     'Please Enter Detailed data elements'
      // }
    } else if (currentStep === 4) {
      if (!hasValue(assessmentState.dataStoreLocationType)) {
        multiple_updates_state.dataStoreLocationType = 'Please Select Location'
      }

      if (!hasValue(assessmentState.dataStoreLocationRemarks)) {
        multiple_updates_state.dataStoreLocationRemarks = 'Pleasae Enter Remarks'
      }

      if (!hasValue(assessmentState.dataProcessedByThirdPartySubContractor)) {
        multiple_updates_state.dataProcessedByThirdPartySubContractor = 'Please Select Any Option'
      }

      if (
        assessmentState.dataProcessedByThirdPartySubContractor === 'NA' &&
        !hasValue(assessmentState.dataProcessedByThirdPartySubContractorRemarks)
      ) {
        multiple_updates_state.dataProcessedByThirdPartySubContractorRemarks =
          'Please Enter Remarks'
      } else {
        multiple_updates_state.dataProcessedByThirdPartySubContractorRemarks = ''
      }
      if (!hasValue(assessmentState.trackingOfIndividual)) {
        multiple_updates_state.trackingOfIndividual = 'Please Select Tracking Option'
      }

      if (
        assessmentState.trackingOfIndividual === 'NA' &&
        !hasValue(assessmentState.trackingOfIndividualRemarks)
      ) {
        multiple_updates_state.trackingOfIndividualRemarks = 'Please Enter Remarks'
      } else {
        multiple_updates_state.trackingOfIndividualRemarks = ''
      }

      if (!hasValue(assessmentState.dependabilityOfThirdParty)) {
        multiple_updates_state.dependabilityOfThirdParty = 'Please Select Dependability Option'
      }
      if (!hasValue(assessmentState.mobileAppInvolved)) {
        multiple_updates_state.mobileAppInvolved = 'Please Select Mobile App involved'
      }
      // if (!hasValue(assessmentState.vendorHaveNetworkAccess)) {
      //   multiple_updates_state.vendorHaveNetworkAccess = 'Please Select vendor have Network Access'
      // }
    } else if (currentStep === 5) {
      if (!hasValue(assessmentState.thirdPartyWebsite)) {
        multiple_updates_state.thirdPartyWebsite = 'Please Enter third Party website'
      }
    }

    return multiple_updates_state
  }

  const setTabNavigation = useCallback(() => {
    var width = 16.5 * (currentStep + 1)
    var selecionline = document.getElementById('selection-line')
    if (selecionline) {
      selecionline?.style?.setProperty('width', (currentStep === 5 ? 100 : width) + '%')
    }

    switch (currentStep) {
      case 1:
        setTabNavigationIcons({
          ...initialTabNavigationstyles,
          systemDetails: 'icon-2',
          systemDetailsNumber: 'text-wrapper-5',
          systemDetailsText: 'text-wrapper-6',
        })
        break
      case 2:
        setTabNavigationIcons({
          ...initialTabNavigationstyles,
          companyData: 'icon-2',
          companyDataNumber: 'text-wrapper-5',
          companyDataText: 'text-wrapper-6',
        })
        break
      case 3:
        setTabNavigationIcons({
          ...initialTabNavigationstyles,
          customerData: 'icon-2',
          customerDataNumber: 'text-wrapper-5',
          customerDataText: 'text-wrapper-6',
        })
        break
      case 4:
        setTabNavigationIcons({
          ...initialTabNavigationstyles,
          otherDetails: 'icon-2',
          otherDetailsNumber: 'text-wrapper-5',
          otherDetailsText: 'text-wrapper-6',
        })
        break
      case 5:
        setTabNavigationIcons({
          ...initialTabNavigationstyles,
          launchAssessment: 'icon-2',
          launchAssessmentNumber: 'text-wrapper-5',
          launchAssessmentText: 'text-wrapper-6',
        })
        break
      default:
        setTabNavigationIcons({
          ...initialTabNavigationstyles,
          projectDetails: 'icon-2',
          projectDetailsNumber: 'text-wrapper-5',
          projectDetailsText: 'text-wrapper-6',
        })
        break
    }
  }, [currentStep])

  const handleSaveAsDraft = () => {
    if (
      !hasValue(assessmentState.businessRequestorName) ||
      assessmentState.businessRequestorId <= 0
    ) {
      feedbackDispatch({
        type: 'update',
        key: 'businessRequestorName',
        value: 'Please Select Business Requestor',
      })
      return
    }
    if (!hasValue(assessmentState.thirdPartyName)) {
      feedbackDispatch({
        type: 'update',
        key: 'thirdPartyName',
        value: 'Please Enter Third Party Name',
      })
      return
    }
    saveOrUpdateSubmission(true)
  }

  const handleChange = (evt) => {
    assessmentDispatch({ type: 'update', key: evt.target.name, value: evt.target.value })
  }

  const handleCHKChange = (evt) => {
    assessmentDispatch({ type: 'update', key: evt.target.name, value: evt.target.checked })
  }

  const handleChangeOfGroupChkBox = (evt, property) => {
    evt.stopPropagation()
    let newVal = JSON.parse(JSON.stringify(assessmentState[property]))
    if (evt.target.checked) {
      if (property.includes('company')) {
        feedbackDispatch({ type: 'update', key: 'companyDataList', value: '' })
        assessmentDispatch({ type: 'update', key: 'customerNoneOftheAbove', value: '' })
      }
      if (property.includes('customer')) {
        feedbackDispatch({ type: 'update', key: 'customerDataList', value: '' })
        assessmentDispatch({ type: 'update', key: 'customerNoneOftheAbove', value: '' })
      }
      // if (
      //   evt.target.name === TypesOfCompanyData.NoneOfTheAbove ||
      //   evt.target.name === TypesOfCompanyData.NoDataInvolved
      // ) {
      //   newVal = []
      //   if (property === 'typeOfCompanyData')
      //     feedbackDispatch({ type: 'update', key: 'detailedDataElementsCompanyData', value: '' })
      //   else
      //     feedbackDispatch({ type: 'update', key: 'detailedDataElementsOfCustomerData', value: '' })
      // } else if (newVal.indexOf(TypesOfCompanyData.NoneOfTheAbove) > -1) {
      //   newVal = newVal.filter((x) => x !== TypesOfCompanyData.NoneOfTheAbove)
      // } else if (newVal.indexOf(TypesOfCompanyData.NoDataInvolved) > -1) {
      //   newVal = newVal.filter((x) => x !== TypesOfCompanyData.NoDataInvolved)
      // }
      newVal.push(evt.target.name)
      assessmentDispatch({ type: 'update', key: property, value: newVal })
    } else {
      newVal = newVal.filter((x) => x !== evt.target.name)
      assessmentDispatch({ type: 'update', key: property, value: newVal })
    }
  }

  const handleUploadNDADoc = (fileslist) => {
    setOpenUploadNDADoc(false)
    if (!fileslist || fileslist.length <= 0) return
    const file = fileslist[0]
    const fileSize = file.size / 1024 / 1024
    if (fileSize > 5) {
      feedbackDispatch({
        type: 'update',
        key: 'ndaDocument',
        value: 'File Size cannot be greater than 5 MB',
      })
      return
    }

    feedbackDispatch({ type: 'update', key: 'ndaDocument', value: '' })

    for (var index = 0; index < fileslist.length; index++) {
      setNDADocInfo({
        ndaDocName: file.name,
        ndaDocSignedUrl: '',
        fileslist: fileslist[index],
      })
    }
    assessmentDispatch({ type: 'update', key: 'ndaDocument', value: ndaDocInfo })
  }

  //clearing feedback state if assessment state has values
  useEffect(() => {
    let multiple_updates_state = {}
    Object.keys(assessmentState).forEach((x) => {
      if (
        Object.keys(feedbackState).filter((y) => y === x).length > 0 &&
        hasValue(assessmentState[x])
      ) {
        multiple_updates_state[x] = ''
      }
    })
    feedbackDispatch({ type: 'update_multiple', multiple_updates_state })
    if (draftNumber > 0 || csStatus === ClientSubmissionStatus.Draft) {
      saveDraftData(draftNumber, id, assessmentState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentState])

  const resetData = useCallback(() => {
    setCurrentStep(0)
    setReadOnlyMode(false)
    assessmentDispatch({
      type: 'reset',
      businessOrgName: user.businessOrganizationName,
      businessOrgId: user.businessOrganizationId,
      businessRequestorName: `${user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)} ${
        user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)
      }`,
      businessRequestorId: user.id,
      businessUnitDepartmentName: user.department,
    })
    feedbackDispatch({
      type: 'reset',
    })
    setNDADocInfo({
      ndaDocName: '',
      ndaDocSignedUrl: '',
      fileslist: null,
    })
  }, [user])

  // useEffect(() => {
  //   if (!isFromSavedDraft) {
  //     resetData()
  //   }
  // }, [isFromSavedDraft, resetData])

  const getAssessmentDetails = async (assessmentId) => {
    var result = await getClientSubmission(assessmentId)
    setIsDataLoading(false)
    if (result && hasValue(result?.status)) {
      setCsStatus(result.status)
      if (result.status !== 'Draft') setReadOnlyMode(true)

      if (
        result.status === 'Cancelled' ||
        result.status === 'Completed' ||
        result.status === 'Accepted'
      )
        setHideCancel(true)

      if (hasValue(result?.preAssessmentQuestionnaire)) {
        assessmentDispatch({
          type: 'update_multiple',
          multiple_updates_state: JSON.parse(result?.preAssessmentQuestionnaire),
        })
      }
      if (result?.ndaFileName && result?.ndaFileSignedUrl) {
        setNDADocInfo({
          ndaDocName: result?.ndaFileName || '',
          ndaDocSignedUrl: result?.ndaFileSignedUrl,
        })
      }

      if (draftData && draftData.id === id) setAssessmentDraftData(draftData.data)
    }
  }

  const setAssessmentDraftData = (result) => {
    if (!!result) {
      assessmentDispatch({
        type: 'update_multiple',
        multiple_updates_state: result,
      })
      if (result.ndaDocument) {
        setNDADocInfo(result.ndaDocument)
      }
    }
  }

  useEffect(() => {
    debugger
    resetData()
    if (id && id > 0) {
      setVendorSearchDone(true)
      setIsDataLoading(true)
      getAssessmentDetails(id)
    } else {
      setVendorSearchDone(false)
      setIsDataLoading(false)
      if (draftData && draftData.draftNumber === draftNumber) {
        setAssessmentDraftData(draftData.data)
        setSearchVendorText(draftData.data.thirdPartyName ?? '')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, resetData])

  useEffect(() => {
    setTabNavigation()
  }, [currentStep, setTabNavigation])

  const fetchVendorInfo = async () => {
    if (!searchVendorText) {
      enqueueSnackbar('Please Enter Vendor Name or Domain', {
        variant: 'error',
        autoHideDuration: 5000,
        hideIconVariant: true,
      })
      return
    }
    setIsSearchLoading(true)
    var result = await getVendorBySearch(searchVendorText)
    setIsSearchLoading(false)
    if (result && result.length > 0) {
      setVendorInfo(result)
      setOpenSearchVendorList(true)
    } else {
      setVendorSearchDone(true)
      setThirdPartNameReadOnly(false)
      assessmentDispatch({ type: 'update', key: 'thirdPartyName', value: searchVendorText })
    }
  }

  const handleSearchVendorList = (isVendorSelected, vendorInfo) => {
    setOpenSearchVendorList(false)

    if (isVendorSelected) {
      setVendorSearchDone(true)
      var updateInfo = {
        logoUrl: vendorInfo.logoUrl ?? BeaconerLogo,
        thirdPartyName: vendorInfo.name,
        thirdPartyDesc: vendorInfo.description,
        thirdPartyWebsite: vendorInfo.website,
        thirdPartyLinkedIn: vendorInfo.linkedinUrl,
      }
      setThirdPartNameReadOnly(true)
      assessmentDispatch({ type: 'update_multiple', multiple_updates_state: updateInfo })
    }
  }

  return (
    <>
      {(isDataLoading || isSaveLoading) && (
        <div className={styles['main-container-loader']}>
          <CircleLoader size={100}></CircleLoader>
        </div>
      )}
      {!isDataLoading && !isSaveLoading && (
        <>
          {!vendorSearchDone ? (
            <div className={styles['main-container']}>
              <header className={styles.header}>
                <div className={styles.title}>
                  <div className={styles.left}>
                    {/* <div className={styles['text-wrapper']}>
                      New Assessment Request {draftNumber}
                    </div> */}
                    {(!id || id < 0) && !assessmentState.thirdPartyName && (
                      <div className={styles['text-wrapper']}>
                        New Assessment Request {draftNumber}
                      </div>
                    )}

                    {(id > 0 || assessmentState.thirdPartyName) && (
                      <div className={styles['text-wrapper']}>{assessmentState.thirdPartyName}</div>
                    )}
                    <div className={styles['security-privacy']}>
                      SECURITY &amp; PRIVACY ASSESSMENT
                    </div>
                  </div>
                </div>
              </header>
              {isSearchLoading ? (
                <div className={styles.searchsection}>
                  <CircleLoader size={100}></CircleLoader>
                  Looking for the vendor within beconer
                </div>
              ) : (
                <div className={styles.searchsection}>
                  <div className={styles['sub-searchsection']}>
                    <div className={styles.content}>
                      <div className={styles.search}>
                        <div className={styles['div-wrapper']}>
                          <div className={styles['text-wrapper']}>Third-party Vendor</div>
                        </div>
                        <div className={styles.level}>
                          <InputBase
                            sx={{ height: 36 }}
                            className={`${styles['search-bar']}`}
                            startAdornment={
                              <InputAdornment position="start">
                                <IconPlaceholder
                                  icon={<SearchIcon className={styles['icons-8']} />}
                                  size="twenty-four-x-24"
                                />
                              </InputAdornment>
                            }
                            placeholder="Type vendor name or domain here"
                            value={searchVendorText}
                            onChange={(e) => {
                              setSearchVendorText(e.currentTarget.value)
                            }}
                          ></InputBase>
                        </div>
                      </div>
                      <Button
                        size="medium"
                        state="active"
                        color="primary"
                        text="Search"
                        onClick={() => {
                          fetchVendorInfo()
                        }}
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles['main-container']}>
              <div className={styles['header-wrapper']}>
                <div className={styles['left-wrapper']}>
                  {assessmentState.logoUrl && (
                    <img className={styles.avatar} alt="Avatar" src={assessmentState.logoUrl} />
                  )}
                  <div className={styles['left']}>
                    {(!id || id < 0) && !assessmentState.thirdPartyName && (
                      <div className={styles['text-wrapper-4']}>
                        New Assessment Request {draftNumber}
                      </div>
                    )}
                    {(id > 0 || assessmentState.thirdPartyName) && (
                      <div className={styles['text-wrapper-4']}>
                        {assessmentState.thirdPartyName}
                      </div>
                    )}
                    <div className={styles['security-privacy']}>
                      SECURITY &amp; PRIVACY ASSESSMENT
                    </div>
                  </div>
                </div>
                <div className={styles['tab-navigation-wrapper']}>
                  <div className={styles['tab-navigation']}>
                    <div className={styles['selection-line']} id="selection-line" />
                    <div className={styles['tab-nav']}>
                      {currentStep <= 0 && (
                        <div className={styles[tabNavigationIcons.projectDetails]}>
                          <div className={styles[tabNavigationIcons.projectDetailsNumber]}>1</div>
                        </div>
                      )}
                      {currentStep > 0 && (
                        <div className={styles.icon}>
                          <IconPlaceholder
                            icon={<PlainTick className={styles['icons-1']} />}
                            size="twenty-four-x-24"
                          />
                        </div>
                      )}
                      <div className={styles[tabNavigationIcons.projectDetailsText]}>
                        Project Details
                      </div>
                    </div>
                    <div className={styles['tab-nav']}>
                      {currentStep <= 1 && (
                        <div className={styles[tabNavigationIcons.systemDetails]}>
                          <div className={styles[tabNavigationIcons.systemDetailsNumber]}>2</div>
                        </div>
                      )}
                      {currentStep > 1 && (
                        <div className={styles.icon}>
                          <IconPlaceholder
                            icon={<PlainTick className={styles['icons-1']} />}
                            size="twenty-four-x-24"
                          />
                        </div>
                      )}
                      <div className={styles[tabNavigationIcons.systemDetailsText]}>
                        System Details
                      </div>
                    </div>
                    <div className={styles['tab-nav']}>
                      {currentStep <= 2 && (
                        <div className={styles[tabNavigationIcons.companyData]}>
                          <div className={styles[tabNavigationIcons.companyDataNumber]}>3</div>
                        </div>
                      )}
                      {currentStep > 2 && (
                        <div className={styles.icon}>
                          <IconPlaceholder
                            icon={<PlainTick className={styles['icons-1']} />}
                            size="twenty-four-x-24"
                          />
                        </div>
                      )}
                      <div className={styles[tabNavigationIcons.companyDataText]}>Company Data</div>
                    </div>
                    <div className={styles['tab-nav']}>
                      {currentStep <= 3 && (
                        <div className={styles[tabNavigationIcons.customerData]}>
                          <div className={styles[tabNavigationIcons.customerDataNumber]}>4</div>
                        </div>
                      )}
                      {currentStep > 3 && (
                        <div className={styles.icon}>
                          <IconPlaceholder
                            icon={<PlainTick className={styles['icons-1']} />}
                            size="twenty-four-x-24"
                          />
                        </div>
                      )}
                      <div className={styles[tabNavigationIcons.customerDataText]}>
                        Customer Data
                      </div>
                    </div>
                    <div className={styles['tab-nav']}>
                      {currentStep <= 4 && (
                        <div className={styles[tabNavigationIcons.otherDetails]}>
                          <div className={styles[tabNavigationIcons.otherDetailsNumber]}>5</div>
                        </div>
                      )}
                      {currentStep > 4 && (
                        <div className={styles.icon}>
                          <IconPlaceholder
                            icon={<PlainTick className={styles['icons-1']} />}
                            size="twenty-four-x-24"
                          />
                        </div>
                      )}
                      <div className={styles[tabNavigationIcons.otherDetailsText]}>
                        Other Details
                      </div>
                    </div>
                    <div className={styles['tab-nav']}>
                      <div className={styles[tabNavigationIcons.launchAssessment]}>
                        <div className={styles[tabNavigationIcons.launchAssessmentNumber]}>6</div>
                      </div>
                      <div className={styles[tabNavigationIcons.launchAssessmentText]}>
                        Launch Assessment
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {currentStep === 0 ? (
                <div className={styles.projectdetails}>
                  <div className={styles['title-caption']}>
                    <p className={styles['new-request-p']}>
                      Share a brief overview of your project, including its name, main goals, and
                      important milestones or deadlines
                    </p>
                  </div>
                  <div className={styles['sub-projectdetails']}>
                    <div className={styles['sub-projectdetails-wrapper']}>
                      <div className={styles['sub-projectdetails-2']}>
                        <div className={styles['form-item-container']}>
                          <div className={styles.top}>
                            <p className={styles['placeholder-2']}>
                              <span className={styles.span}>Third Party Vendor Name </span>
                              <span className={styles['text-wrapper-9']}>*</span>
                            </p>
                            {assessmentState.logoUrl && (
                              <p className={styles.div}>(Vendor found in beaconer database)</p>
                            )}
                          </div>
                          <InputBase
                            className={styles.form}
                            placeholder="Example: Google, Microsoft,..."
                            type="text"
                            id="thirdPartyName"
                            name="thirdPartyName"
                            onChange={handleChange}
                            value={assessmentState.thirdPartyName}
                            error={hasValue(feedbackState.thirdPartyName)}
                            readOnly={thirdPartNameReadOnly}
                            startAdornment={
                              assessmentState.logoUrl && (
                                <InputAdornment position="start">
                                  <img
                                    className={styles['img-3']}
                                    alt="Avatar"
                                    src={assessmentState.logoUrl}
                                  />
                                </InputAdornment>
                              )
                            }
                            endAdornment={
                              assessmentState.logoUrl && (
                                <InputAdornment position="start">
                                  <IconPlaceholder
                                    className={styles['icon-placeholder-instance']}
                                    icon={<CheckCircleTickIcon className={styles['icons-1']} />}
                                    size="twenty-eight-x-24"
                                  />
                                </InputAdornment>
                              )
                            }
                            required
                          ></InputBase>
                          <ErrorText value={feedbackState.thirdPartyName} />
                        </div>
                        <div className={styles['form-item-container']}>
                          <p className={styles['placeholder-2']}>
                            <span className={styles.span}>Business Unit or Department </span>
                            <span className={styles['text-wrapper-9']}>*</span>
                          </p>
                          <InputBase
                            className={styles.form}
                            placeholder="Example: Information Security"
                            type="text"
                            id="businessUnitDepartmentName"
                            name="businessUnitDepartmentName"
                            onChange={handleChange}
                            value={assessmentState.businessUnitDepartmentName}
                            error={hasValue(feedbackState.businessUnitDepartmentName)}
                            required
                            readOnly={true}
                          ></InputBase>
                          <ErrorText value={feedbackState.thirdPartyRepresentativeName} />
                        </div>
                        <div className={styles['form-item-container']}>
                          <p className={styles['placeholder-2']}>
                            <span className={styles.span}>Third Party POC </span>
                            <span className={styles['text-wrapper-9']}>*</span>
                          </p>
                          <InputBase
                            className={styles.form}
                            placeholder="Example: John Doe"
                            type="text"
                            id="thirdPartyRepresentativeName"
                            name="thirdPartyRepresentativeName"
                            onChange={handleChange}
                            value={assessmentState.thirdPartyRepresentativeName}
                            error={hasValue(feedbackState.thirdPartyRepresentativeName)}
                            required
                          ></InputBase>
                          <ErrorText value={feedbackState.thirdPartyRepresentativeName} />
                        </div>
                        <div className={styles['form-item-container']}>
                          <p className={styles['placeholder-2']}>
                            <span className={styles.span}>Third Party POC Email </span>
                            <span className={styles['text-wrapper-9']}>*</span>
                          </p>
                          <InputBase
                            className={styles.form}
                            placeholder="Example: john.doe@xyz.com"
                            type="email"
                            id="thirdPartyRepresentativeEmail"
                            name="thirdPartyRepresentativeEmail"
                            onChange={handleChange}
                            value={assessmentState.thirdPartyRepresentativeEmail}
                            error={hasValue(feedbackState.thirdPartyRepresentativeEmail)}
                            required
                          ></InputBase>
                          <ErrorText value={feedbackState.thirdPartyRepresentativeEmail} />
                        </div>
                        <div className={styles['form-item-container']}>
                          <p className={styles['placeholder-2']}>
                            <span className={styles.span}>
                              Please specify the priority of this assessment by selecting the
                              expected completion time period.
                            </span>
                            <span className={styles['text-wrapper-9']}>*</span>
                          </p>
                          <div className={styles['level-2']}>
                            <RadioGroup
                              row
                              id="completionTimePeriod"
                              name="completionTimePeriod"
                              onChange={handleChange}
                              value={assessmentState.completionTimePeriod}
                              error={hasValue(feedbackState.completionTimePeriod)}
                              required
                            >
                              <FormControlLabel
                                value={CompletionTimePeriod.ThreeDays}
                                control={<Radio />}
                                label={
                                  <CustomLabel
                                    selected={
                                      assessmentState.completionTimePeriod ===
                                      CompletionTimePeriod.ThreeDays
                                    }
                                    value={'within 3 days'}
                                  />
                                }
                              />
                              <FormControlLabel
                                value={CompletionTimePeriod.Week}
                                control={<Radio />}
                                label={
                                  <CustomLabel
                                    selected={
                                      assessmentState.completionTimePeriod ===
                                      CompletionTimePeriod.Week
                                    }
                                    value={'within a week'}
                                  />
                                }
                              />
                              <FormControlLabel
                                value={CompletionTimePeriod.AsPerAvailability}
                                control={<Radio />}
                                label={
                                  <CustomLabel
                                    selected={
                                      assessmentState.completionTimePeriod ===
                                      CompletionTimePeriod.AsPerAvailability
                                    }
                                    value={'as per availability'}
                                  />
                                }
                              />
                            </RadioGroup>
                          </div>
                          <ErrorText value={feedbackState.CompletionTimePeriod} />
                        </div>
                        <div className={styles.switch}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="thirdPartyNDA"
                                name="ndaWithThirdPartybool"
                                onChange={handleCHKChange}
                                checked={assessmentState.ndaWithThirdPartybool}
                              />
                            }
                            //label="Do you have a NDA with Third Party? If yes, please submit the NDA."
                          />
                          <div className={styles.label}>
                            <p className={styles.span} style={{ marginTop: '6px' }}>
                              Do you have a NDA with Third Party? If yes, please submit the NDA.
                            </p>
                            {assessmentState.ndaWithThirdPartybool && (
                              <>
                                {!ndaDocInfo?.ndaDocName && (
                                  <>
                                    <Button
                                      className={styles['button-3']}
                                      color="secondary"
                                      size="medium"
                                      variant="contained"
                                      text="Upload the document"
                                      onClick={() => {
                                        setOpenUploadNDADoc(true)
                                      }}
                                      startIcon={<UpArrorwIcon className={styles['icons-20']} />}
                                    >
                                      Upload the document
                                    </Button>
                                    <ErrorText value={feedbackState.ndaDocument} />
                                  </>
                                )}
                                {ndaDocInfo?.ndaDocName && (
                                  <div className={styles['file-change']}>
                                    <div className={styles.doc}>
                                      <div className={styles.div}>
                                        <img
                                          className={styles.group}
                                          alt="Group"
                                          src="https://c.animaapp.com/U9C0Dsj0/img/group@2x.png"
                                        />
                                        <div className={styles['text-wrapper']}>
                                          {' '}
                                          {!ndaDocInfo?.ndaDocSignedUrl ? (
                                            ndaDocInfo.ndaDocName
                                          ) : (
                                            <a href={ndaDocInfo?.ndaDocSignedUrl} target="_">
                                              {ndaDocInfo.ndaDocName}
                                            </a>
                                          )}
                                        </div>
                                      </div>
                                      <IconPlaceholder
                                        className={styles['icon-placeholder-instance']}
                                        icon={<CrossMark className={styles['icons-1']} />}
                                        size="twenty-eight-x-28"
                                        onClick={() => {
                                          setNDADocInfo({
                                            ndaDocName: '',
                                            ndaDocSignedUrl: '',
                                            fileslist: null,
                                          })
                                        }}
                                      />
                                    </div>
                                    <div className={styles['badge-statut']}>
                                      <div
                                        className={styles.text}
                                        onClick={() => {
                                          setOpenUploadNDADoc(true)
                                        }}
                                      >
                                        Change File
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {currentStep === 1 ? (
                <div className={styles.systemdetails}>
                  <div className={styles['title-caption']}>
                    <p className={styles['new-request-p']}>
                      Tell us about your system setup, including the hardware and software you use,
                      and any special security settings in place
                    </p>
                  </div>
                  <div className={styles['sub-systemdetails']}>
                    <div className={styles['sub-systemdetails-wrapper']}>
                      <div className={styles['sub-systemdetails-2']}>
                        <div className={styles['form-item-container']}>
                          <p className={styles['placeholder-2']}>
                            <span className={styles.span}>Project / System / Software Name </span>
                            <span className={styles['text-wrapper-10']}>*</span>
                          </p>
                          <InputBase
                            className={styles.form}
                            placeholder="Example: Adobe Photoshop, Figma,..."
                            id="projectName"
                            name="projectName"
                            onChange={handleChange}
                            value={assessmentState.projectName}
                            error={hasValue(feedbackState.projectName)}
                            required
                          ></InputBase>
                          <ErrorText value={feedbackState.projectName} />
                        </div>
                        <div className={styles.switch}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="applicationInvolved"
                                name="applicationInvolvedBool"
                                onChange={handleCHKChange}
                                checked={assessmentState.applicationInvolvedBool}
                              />
                            }
                            label="Is there an application / SaaS involved in this engagement?"
                          />
                        </div>
                        {/* <div className={styles['form-item-container']}>
                      <p className={styles['placeholder-2']}>
                        <span className={styles.span}>What task will the vendor be doing?</span>
                      </p>
                      <InputBase
                        className={styles.form}
                        placeholder="Type Here..."
                        id="vendorTask"
                        name="vendorTask"
                        onChange={handleChange}
                        value={assessmentState.vendorTask}
                        error={hasValue(feedbackState.vendorTask)}
                        required
                      ></InputBase>
                    </div>
                    <div className={styles['form-item-container']}>
                      <p className={styles['placeholder-2']}>
                        <span className={styles.span}>
                          How does the third party deliver these services?
                        </span>
                      </p>
                      <InputBase
                        className={styles.form}
                        placeholder="Type Here..."
                        id="thirdPartyDeliverType"
                        name="thirdPartyDeliverType"
                        onChange={handleChange}
                        value={assessmentState.thirdPartyDeliverType}
                        error={hasValue(feedbackState.thirdPartyDeliverType)}
                        required
                      ></InputBase>
                    </div>
                    <div className={styles['form-item-container']}>
                      <p className={styles['placeholder-2']}>
                        <span className={styles.span}>
                          Will the third party have access to your network, and if so, how will they
                          access it?
                        </span>
                      </p>
                      <InputBase
                        className={styles.form}
                        placeholder="Type Here..."
                        id="thirdPartyAccessToNetwork"
                        name="thirdPartyAccessToNetwork"
                        onChange={handleChange}
                        value={assessmentState.thirdPartyAccessToNetwork}
                        error={hasValue(feedbackState.thirdPartyAccessToNetwork)}
                        required
                      ></InputBase>
                    </div> */}
                        <div className={styles['form-item-container']}>
                          <p className={styles['placeholder-2']}>
                            <span className={styles.span}>
                              {' '}
                              Please write a detailed description of scope of service provided by
                              the Third party (What service Third party is providing, how they are
                              providing and how Business will use the service/software)
                            </span>
                            <span className={styles['text-wrapper-10']}>*</span>
                          </p>
                          <InputBase
                            multiline
                            rows={4}
                            className={styles['form-2']}
                            placeholder="Example: Name, Insurance,..."
                            id="projectDetailedDescription"
                            name="detailedDescriptionProject"
                            onChange={handleChange}
                            value={assessmentState.detailedDescriptionProject}
                            error={hasValue(feedbackState.detailedDescriptionProject)}
                            required
                          ></InputBase>
                          <ErrorText value={feedbackState.detailedDescriptionProject} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {currentStep === 2 ? (
                <div className={styles.systemdetails}>
                  <div className={styles['title-caption']}>
                    <p className={styles['new-request-p']}>
                      Provide some basic information about your company, like its name, address,
                      industry, and key contact details
                    </p>
                  </div>
                  <div className={styles['sub-systemdetails']}>
                    <div className={styles['sub-systemdetails-wrapper']}>
                      <div className={styles['sub-systemdetails-2']}>
                        {/* <div className={styles['form-item-container']}>
                        <p className={styles['placeholder-2']}>
                          <span className={styles.span}>
                            Which type(s) of company data will be processed?{' '}
                          </span>
                        </p>
                        <InputBase
                          style={{ display: 'none' }}
                          type="text"
                          id="typesOfCompanyDataToBeProcessed"
                          name="typeOfCompanyData"
                          error={hasValue(feedbackState.typeOfCompanyData)}
                        ></InputBase>
                        <ErrorText value={feedbackState.typeOfCompanyData} />
                        {Object.keys(TypesOfCompanyData).map((key, index) => {
                          const value = TypesOfCompanyData[key]
                          return (
                            <FormControlLabel
                              key={key}
                              control={
                                <Checkbox
                                  id={`chk-${key}`}
                                  key={key}
                                  name={value}
                                  onChange={(evt) =>
                                    handleChangeOfGroupChkBox(evt, 'typeOfCompanyData')
                                  }
                                  checked={assessmentState.typeOfCompanyData?.indexOf(value) > -1}
                                />
                              }
                              label={
                                <CustomLabel
                                  selected={assessmentState.typeOfCompanyData?.indexOf(value) > -1}
                                  value={value}
                                />
                              }
                            />
                          )
                        })}
                      </div> */}
                        <div className={styles['form-item-container-2']}>
                          <p className={styles.placeholder}>
                            Will any Company data be processed in this project?*
                            <span className={styles['text-wrapper-10']}>*</span>
                          </p>

                          <div className={styles['level-2']}>
                            <RadioGroup
                              row
                              id="companyDataInvolved"
                              name="companyDataInvolved"
                              onChange={handleChange}
                              value={assessmentState.companyDataInvolved}
                              error={hasValue(feedbackState.companyDataInvolved)}
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label={
                                  <CustomLabel
                                    selected={assessmentState.companyDataInvolved === 'Y'}
                                    value={'Yes'}
                                  />
                                }
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label={
                                  <CustomLabel
                                    selected={assessmentState.companyDataInvolved === 'N'}
                                    value={'No'}
                                  />
                                }
                              />
                            </RadioGroup>
                          </div>
                          <ErrorText value={feedbackState.companyDataInvolved} />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '25px',
                            flexDirection: 'column',
                            opacity: assessmentState.companyDataInvolved === 'Y' ? '1' : '0.3',
                            pointerEvents:
                              assessmentState.companyDataInvolved === 'Y' ? 'auto' : 'none',
                          }}
                        >
                          {feedbackState.companyDataList && (
                            <ErrorText value={feedbackState.companyDataList} />
                          )}
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Business Data</p>
                            <div className={styles['level-2']}>
                              <FormGroup row id="companyBusinessData" name="companyBusinessData">
                                {Object.keys(BusinessData).map((key, index) => {
                                  const value = BusinessData[key]
                                  return (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          id={`chk-${key}`}
                                          key={key}
                                          name={value}
                                          onChange={(evt) =>
                                            handleChangeOfGroupChkBox(evt, 'companyBusinessData')
                                          }
                                          checked={
                                            assessmentState.companyBusinessData?.indexOf(value) > -1
                                          }
                                        />
                                      }
                                      label={
                                        <CustomLabel
                                          selected={
                                            assessmentState.companyBusinessData?.indexOf(value) > -1
                                          }
                                          value={value}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </FormGroup>
                            </div>
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Public Data</p>
                            <div className={styles['level-2']}>
                              <FormGroup row id="companyPublicData" name="companyPublicData">
                                {Object.keys(PublicData).map((key, index) => {
                                  const value = PublicData[key]
                                  return (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          id={`chk-${key}`}
                                          key={key}
                                          name={value}
                                          onChange={(evt) =>
                                            handleChangeOfGroupChkBox(evt, 'companyPublicData')
                                          }
                                          checked={
                                            assessmentState.companyPublicData?.indexOf(value) > -1
                                          }
                                        />
                                      }
                                      label={
                                        <CustomLabel
                                          selected={
                                            assessmentState.companyPublicData?.indexOf(value) > -1
                                          }
                                          value={value}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </FormGroup>
                            </div>
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Regulatory compliance Data</p>
                            <div className={styles['level-2']}>
                              <FormGroup
                                row
                                id="companyRegulatoryComplianceData"
                                name="companyRegulatoryComplianceData"
                              >
                                {Object.keys(RegulatoryComplianceData).map((key, index) => {
                                  const value = RegulatoryComplianceData[key]
                                  return (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          id={`chk-${key}`}
                                          key={key}
                                          name={value}
                                          onChange={(evt) =>
                                            handleChangeOfGroupChkBox(
                                              evt,
                                              'companyRegulatoryComplianceData',
                                            )
                                          }
                                          checked={
                                            assessmentState.companyRegulatoryComplianceData?.indexOf(
                                              value,
                                            ) > -1
                                          }
                                        />
                                      }
                                      label={
                                        <CustomLabel
                                          selected={
                                            assessmentState.companyRegulatoryComplianceData?.indexOf(
                                              value,
                                            ) > -1
                                          }
                                          value={value}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </FormGroup>
                            </div>
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Financial/commercial data</p>
                            <div className={styles['level-2']}>
                              <FormGroup
                                row
                                id="companyFinancialCommercialData"
                                name="companyFinancialCommercialData"
                              >
                                {Object.keys(FinancialCommercialData).map((key, index) => {
                                  const value = FinancialCommercialData[key]
                                  return (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          id={`chk-${key}`}
                                          key={key}
                                          name={value}
                                          onChange={(evt) =>
                                            handleChangeOfGroupChkBox(
                                              evt,
                                              'companyFinancialCommercialData',
                                            )
                                          }
                                          checked={
                                            assessmentState.companyFinancialCommercialData?.indexOf(
                                              value,
                                            ) > -1
                                          }
                                        />
                                      }
                                      label={
                                        <CustomLabel
                                          selected={
                                            assessmentState.companyFinancialCommercialData?.indexOf(
                                              value,
                                            ) > -1
                                          }
                                          value={value}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </FormGroup>
                            </div>
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Marketing Data</p>
                            <div className={styles['level-2']}>
                              <RadioGroup
                                row
                                id="companyMarketingData"
                                name="companyMarketingData"
                                onChange={handleChange}
                                value={assessmentState.companyMarketingData}
                                error={hasValue(feedbackState.companyMarketingData)}
                              >
                                <FormControlLabel
                                  value="Y"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={assessmentState.companyMarketingData === 'Y'}
                                      value={'Yes'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="N"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={assessmentState.companyMarketingData === 'N'}
                                      value={'No'}
                                    />
                                  }
                                />
                              </RadioGroup>
                            </div>
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Intellectual property Data</p>
                            <div className={styles['level-2']}>
                              <FormGroup
                                row
                                id="companyIntellectualPropertyData"
                                name="companyIntellectualPropertyData"
                              >
                                {Object.keys(IntellectualPropertyData).map((key, index) => {
                                  const value = IntellectualPropertyData[key]
                                  return (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          id={`chk-${key}`}
                                          key={key}
                                          name={value}
                                          onChange={(evt) =>
                                            handleChangeOfGroupChkBox(
                                              evt,
                                              'companyIntellectualPropertyData',
                                            )
                                          }
                                          checked={
                                            assessmentState.companyIntellectualPropertyData?.indexOf(
                                              value,
                                            ) > -1
                                          }
                                        />
                                      }
                                      label={
                                        <CustomLabel
                                          selected={
                                            assessmentState.companyIntellectualPropertyData?.indexOf(
                                              value,
                                            ) > -1
                                          }
                                          value={value}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </FormGroup>
                            </div>
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Security data</p>
                            <div className={styles['level-2']}>
                              <FormGroup row id="companySecurityData" name="companySecurityData">
                                {Object.keys(SecurityData).map((key, index) => {
                                  const value = SecurityData[key]
                                  return (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          id={`chk-${key}`}
                                          key={key}
                                          name={value}
                                          onChange={(evt) =>
                                            handleChangeOfGroupChkBox(evt, 'companySecurityData')
                                          }
                                          checked={
                                            assessmentState.companySecurityData?.indexOf(value) > -1
                                          }
                                        />
                                      }
                                      label={
                                        <CustomLabel
                                          selected={
                                            assessmentState.companySecurityData?.indexOf(value) > -1
                                          }
                                          value={value}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </FormGroup>
                            </div>
                          </div>

                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>
                              Is there a PI/SPI/PHI involved in this project?
                            </p>

                            <div className={styles['level-2']}>
                              <RadioGroup
                                row
                                id="isTherePISPIInvolvedInThisCompanyProject"
                                name="isTherePISPIInvolvedInThisCompanyProject"
                                onChange={(e) => {
                                  if (e.target.value === 'Y')
                                    assessmentDispatch({
                                      type: 'update',
                                      key: 'companyNoneOftheAbove',
                                      value: '',
                                    })
                                  handleChange(e)
                                }}
                                value={assessmentState.isTherePISPIInvolvedInThisCompanyProject}
                                error={hasValue(
                                  feedbackState.isTherePISPIInvolvedInThisCompanyProject,
                                )}
                              >
                                <FormControlLabel
                                  value="Y"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={
                                        assessmentState.isTherePISPIInvolvedInThisCompanyProject ===
                                        'Y'
                                      }
                                      value={'Yes'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="N"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={
                                        assessmentState.isTherePISPIInvolvedInThisCompanyProject ===
                                        'N'
                                      }
                                      value={'No'}
                                    />
                                  }
                                />
                              </RadioGroup>
                            </div>
                            <ErrorText
                              value={feedbackState.isTherePISPIInvolvedInThisCompanyProject}
                            />
                          </div>

                          {assessmentState.isTherePISPIInvolvedInThisCompanyProject === 'Y' ? (
                            <>
                              <div className={styles['form-item-container-2']}>
                                <p className={styles.placeholder}>PI (Personal information)</p>
                                <div className={styles['level-2']}>
                                  <FormGroup row id="companyPIData" name="companyPIData">
                                    {Object.keys(PI).map((key, index) => {
                                      const value = PI[key]
                                      return (
                                        <FormControlLabel
                                          key={key}
                                          control={
                                            <Checkbox
                                              id={`chk-${key}`}
                                              key={key}
                                              name={value}
                                              onChange={(evt) =>
                                                handleChangeOfGroupChkBox(evt, 'companyPIData')
                                              }
                                              checked={
                                                assessmentState.companyPIData?.indexOf(value) > -1
                                              }
                                            />
                                          }
                                          label={
                                            <CustomLabel
                                              selected={
                                                assessmentState.companyPIData?.indexOf(value) > -1
                                              }
                                              value={value}
                                            />
                                          }
                                        />
                                      )
                                    })}
                                  </FormGroup>
                                </div>
                              </div>
                              <div className={styles['form-item-container-2']}>
                                <p className={styles.placeholder}>
                                  SPI (Sensitive personal information)
                                </p>
                                <div className={styles['level-2']}>
                                  <FormGroup row id="companySPIData" name="companySPIData">
                                    {Object.keys(SPI).map((key, index) => {
                                      const value = SPI[key]
                                      return (
                                        <FormControlLabel
                                          key={key}
                                          control={
                                            <Checkbox
                                              id={`chk-${key}`}
                                              key={key}
                                              name={value}
                                              onChange={(evt) =>
                                                handleChangeOfGroupChkBox(evt, 'companySPIData')
                                              }
                                              checked={
                                                assessmentState.companySPIData?.indexOf(value) > -1
                                              }
                                            />
                                          }
                                          label={
                                            <CustomLabel
                                              selected={
                                                assessmentState.companySPIData?.indexOf(value) > -1
                                              }
                                              value={value}
                                            />
                                          }
                                        />
                                      )
                                    })}
                                  </FormGroup>
                                </div>
                              </div>
                              <div className={styles['form-item-container-2']}>
                                <p className={styles.placeholder}>
                                  PHI (Protected health information)
                                </p>
                                <div className={styles['level-2']}>
                                  <FormGroup row id="companyPHIData" name="companyPHIData">
                                    {Object.keys(PHI).map((key, index) => {
                                      const value = PHI[key]
                                      return (
                                        <FormControlLabel
                                          key={key}
                                          control={
                                            <Checkbox
                                              id={`chk-${key}`}
                                              key={key}
                                              name={value}
                                              onChange={(evt) =>
                                                handleChangeOfGroupChkBox(evt, 'companyPHIData')
                                              }
                                              checked={
                                                assessmentState.companyPHIData?.indexOf(value) > -1
                                              }
                                            />
                                          }
                                          label={
                                            <CustomLabel
                                              selected={
                                                assessmentState.companyPHIData?.indexOf(value) > -1
                                              }
                                              value={value}
                                            />
                                          }
                                        />
                                      )
                                    })}
                                  </FormGroup>
                                </div>
                              </div>
                              <div className={styles['form-item-container']}>
                                <p className={styles['placeholder-2']}>Number of PI/SPI records</p>
                                <InputBase
                                  className={styles.form}
                                  type="number"
                                  min={1}
                                  max={10000}
                                  maxLength={5}
                                  name="noOfPISPIRecordsCompany"
                                  value={assessmentState.noOfPISPIRecordsCompany}
                                  onChange={handleChange}
                                ></InputBase>
                              </div>
                              <div className={styles['form-item-container']}>
                                <p className={styles['placeholder-2']}>Data subject location</p>
                                <Select
                                  labelId="data-subject-select-label"
                                  className={styles.form}
                                  value={assessmentState.dataSubjectLocationCompany}
                                  onChange={handleChange}
                                  name="dataSubjectLocationCompany"
                                  displayEmpty
                                >
                                  <MenuItem value={''}>-- Select --</MenuItem>
                                  <MenuItem value={'global'}>Global</MenuItem>
                                  <MenuItem value={'USA'}>USA</MenuItem>
                                  <MenuItem value={'EMEA'}>EMEA</MenuItem>
                                  <MenuItem value={'APAC'}>APAC</MenuItem>
                                  <MenuItem value={'NA'}>NA</MenuItem>
                                </Select>
                              </div>
                              <div className={styles.switch}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={assessmentState.willPISPITransferredCompany}
                                      onChange={handleCHKChange}
                                      name="willPISPITransferredCompany"
                                      id="willPISPITransferredCompany"
                                    />
                                  }
                                  label="Will the PI/SPI be transferred from project country to another country?"
                                />
                              </div>
                              <div className={styles.switch}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={assessmentState.vendorCollectPIFromIndividuals}
                                      onChange={handleCHKChange}
                                      name="vendorCollectPIFromIndividuals"
                                      id="vendorCollectPIFromIndividuals"
                                    />
                                  }
                                  label="Does the vendor collect personal information (PI) directly from individuals on behalf of your Company?"
                                />
                              </div>
                              <div className={styles['form-item-container']}>
                                <p className={styles['placeholder-2']}>
                                  Purpose of collecting/processing PI/SPI
                                </p>
                                <InputBase
                                  className={styles.form}
                                  name="puposeOfCollectingPISPICompany"
                                  value={assessmentState.puposeOfCollectingPISPICompany}
                                  onChange={handleChange}
                                ></InputBase>
                              </div>
                              <div className={styles['form-item-container']}>
                                <p className={styles['placeholder-2']}>
                                  <span className={styles.span}>
                                    Please provide any additional data elements of both PI/SPI and
                                    other information involved
                                  </span>
                                </p>
                                <InputBase
                                  multiline
                                  rows={4}
                                  className={styles['form-2']}
                                  placeholder="Type Here.."
                                  id="detailedDataElementsCompanyData"
                                  name="detailedDataElementsCompanyData"
                                  onChange={handleChange}
                                  value={assessmentState.detailedDataElementsCompanyData}
                                  error={hasValue(feedbackState.detailedDataElementsCompanyData)}
                                  required
                                ></InputBase>
                                {/* <ErrorText value={feedbackState.detailedDataElementsCompanyData} /> */}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div className={styles['form-item-container-2']}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id="companyNoneOftheAbove"
                                  name="companyNoneOftheAbove"
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      feedbackDispatch({
                                        type: 'update',
                                        key: 'companyDataList',
                                        value: '',
                                      })
                                      assessmentDispatch({
                                        type: 'update_multiple',
                                        multiple_updates_state: {
                                          companyBusinessData: [],
                                          companyPublicData: [],
                                          companyPIData: [],
                                          companySPIData: [],
                                          companyPHIData: [],
                                          companyRegulatoryComplianceData: [],
                                          companyFinancialCommercialData: [],
                                          companyIntellectualPropertyData: [],
                                          companyMarketingData: '',
                                          companySecurityData: [],
                                          isTherePISPIInvolvedInThisCompanyProject: '',
                                          companyNoneOftheAbove: '',
                                        },
                                      })
                                    }

                                    handleCHKChange(e)
                                  }}
                                  checked={assessmentState.companyNoneOftheAbove}
                                />
                              }
                              label={
                                <CustomLabel
                                  selected={assessmentState.companyNoneOftheAbove}
                                  value={'None of the above'}
                                />
                              }
                            />
                          </div>

                          {assessmentState.companyNoneOftheAbove ? (
                            <div className={styles['form-item-container']}>
                              <p className={styles['placeholder-2']}>
                                <span className={styles.span}> Remarks</span>
                              </p>
                              <InputBase
                                className={styles.form}
                                id="typeOfCompanyDataRemarks"
                                name="typeOfCompanyDataRemarks"
                                onChange={handleChange}
                                value={assessmentState.typeOfCompanyDataRemarks}
                                error={hasValue(feedbackState.typeOfCompanyDataRemarks)}
                                required
                              ></InputBase>
                              <ErrorText value={feedbackState.typeOfCompanyDataRemarks} />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {currentStep === 3 ? (
                <div className={styles.systemdetails}>
                  <div className={styles['title-caption']}>
                    <p className={styles['new-request-p']}>
                      Fill in important details about your customers, such as their names and
                      contact information, to help us understand who you serve
                    </p>
                  </div>
                  <div className={styles['sub-systemdetails']}>
                    <div className={styles['sub-systemdetails-wrapper']}>
                      <div className={styles['sub-systemdetails-2']}>
                        {/* <div className={styles['form-item-container']}>
                      <p className={styles['placeholder-2']}>
                        <span className={styles.span}>
                          Which type(s) of customer data will be processed?
                        </span>
                      </p>
                      <InputBase
                        style={{ display: 'none' }}
                        type="text"
                        id="typeOfCustomerData"
                        name="typeOfCustomerData"
                        error={hasValue(feedbackState.typeOfCustomerData)}
                      ></InputBase>
                      <ErrorText value={feedbackState.typeOfCustomerData} />
                      {Object.keys(TypesOfCustomerData).map((key, index) => {
                        const value = TypesOfCustomerData[key]
                        return (
                          <FormControlLabel
                            key={key}
                            control={
                              <Checkbox
                                id={`chk-${key}`}
                                key={key}
                                name={value}
                                onChange={(evt) =>
                                  handleChangeOfGroupChkBox(evt, 'typeOfCustomerData')
                                }
                                checked={assessmentState.typeOfCustomerData?.indexOf(value) > -1}
                              />
                            }
                            label={
                              <CustomLabel
                                selected={assessmentState.typeOfCustomerData?.indexOf(value) > -1}
                                value={value}
                              />
                            }
                          />
                        )
                      })}
                    </div> */}
                        <div className={styles['form-item-container-2']}>
                          <p className={styles.placeholder}>
                            Will any Customer data be processed in this project?*
                            <span className={styles['text-wrapper-10']}>*</span>
                          </p>

                          <div className={styles['level-2']}>
                            <RadioGroup
                              row
                              id="customerDataInvolved"
                              name="customerDataInvolved"
                              onChange={handleChange}
                              value={assessmentState.customerDataInvolved}
                              error={hasValue(feedbackState.customerDataInvolved)}
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label={
                                  <CustomLabel
                                    selected={assessmentState.customerDataInvolved === 'Y'}
                                    value={'Yes'}
                                  />
                                }
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label={
                                  <CustomLabel
                                    selected={assessmentState.customerDataInvolved === 'N'}
                                    value={'No'}
                                  />
                                }
                              />
                            </RadioGroup>
                          </div>
                          {feedbackState.customerDataInvolved && (
                            <ErrorText value={feedbackState.customerDataInvolved} />
                          )}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '25px',
                            flexDirection: 'column',
                            opacity: assessmentState.customerDataInvolved === 'Y' ? '1' : '0.3',
                            pointerEvents:
                              assessmentState.customerDataInvolved === 'Y' ? 'auto' : 'none',
                          }}
                        >
                          {feedbackState.customerDataList && (
                            <ErrorText value={feedbackState.customerDataList} />
                          )}
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Business Data</p>
                            <div className={styles['level-2']}>
                              <FormGroup row id="customerBusinessData" name="customerBusinessData">
                                {Object.keys(BusinessData).map((key, index) => {
                                  const value = BusinessData[key]
                                  return (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          id={`chk-${key}`}
                                          key={key}
                                          name={value}
                                          onChange={(evt) =>
                                            handleChangeOfGroupChkBox(evt, 'customerBusinessData')
                                          }
                                          checked={
                                            assessmentState.customerBusinessData?.indexOf(value) >
                                            -1
                                          }
                                        />
                                      }
                                      label={
                                        <CustomLabel
                                          selected={
                                            assessmentState.customerBusinessData?.indexOf(value) >
                                            -1
                                          }
                                          value={value}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </FormGroup>
                            </div>
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Public Data</p>
                            <div className={styles['level-2']}>
                              <FormGroup row id="customerPublicData" name="customerPublicData">
                                {Object.keys(PublicData).map((key, index) => {
                                  const value = PublicData[key]
                                  return (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          id={`chk-${key}`}
                                          key={key}
                                          name={value}
                                          onChange={(evt) =>
                                            handleChangeOfGroupChkBox(evt, 'customerPublicData')
                                          }
                                          checked={
                                            assessmentState.customerPublicData?.indexOf(value) > -1
                                          }
                                        />
                                      }
                                      label={
                                        <CustomLabel
                                          selected={
                                            assessmentState.customerPublicData?.indexOf(value) > -1
                                          }
                                          value={value}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </FormGroup>
                            </div>
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Regulatory compliance Data</p>
                            <div className={styles['level-2']}>
                              <FormGroup
                                row
                                id="customerRegulatoryComplianceData"
                                name="customerRegulatoryComplianceData"
                              >
                                {Object.keys(RegulatoryComplianceData).map((key, index) => {
                                  const value = RegulatoryComplianceData[key]
                                  return (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          id={`chk-${key}`}
                                          key={key}
                                          name={value}
                                          onChange={(evt) =>
                                            handleChangeOfGroupChkBox(
                                              evt,
                                              'customerRegulatoryComplianceData',
                                            )
                                          }
                                          checked={
                                            assessmentState.customerRegulatoryComplianceData?.indexOf(
                                              value,
                                            ) > -1
                                          }
                                        />
                                      }
                                      label={
                                        <CustomLabel
                                          selected={
                                            assessmentState.customerRegulatoryComplianceData?.indexOf(
                                              value,
                                            ) > -1
                                          }
                                          value={value}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </FormGroup>
                            </div>
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Financial/commercial data</p>
                            <div className={styles['level-2']}>
                              <FormGroup
                                row
                                id="customerFinancialCommercialData"
                                name="customerFinancialCommercialData"
                              >
                                {Object.keys(FinancialCommercialData).map((key, index) => {
                                  const value = FinancialCommercialData[key]
                                  return (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          id={`chk-${key}`}
                                          key={key}
                                          name={value}
                                          onChange={(evt) =>
                                            handleChangeOfGroupChkBox(
                                              evt,
                                              'customerFinancialCommercialData',
                                            )
                                          }
                                          checked={
                                            assessmentState.customerFinancialCommercialData?.indexOf(
                                              value,
                                            ) > -1
                                          }
                                        />
                                      }
                                      label={
                                        <CustomLabel
                                          selected={
                                            assessmentState.customerFinancialCommercialData?.indexOf(
                                              value,
                                            ) > -1
                                          }
                                          value={value}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </FormGroup>
                            </div>
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Marketing Data</p>
                            <div className={styles['level-2']}>
                              <RadioGroup
                                row
                                id="customerMarketingData"
                                name="customerMarketingData"
                                onChange={handleChange}
                                value={assessmentState.customerMarketingData}
                                error={hasValue(feedbackState.customerMarketingData)}
                              >
                                <FormControlLabel
                                  value="Y"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={assessmentState.customerMarketingData === 'Y'}
                                      value={'Yes'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="N"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={assessmentState.customerMarketingData === 'N'}
                                      value={'No'}
                                    />
                                  }
                                />
                              </RadioGroup>
                            </div>
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Intellectual property Data</p>
                            <div className={styles['level-2']}>
                              <FormGroup
                                row
                                id="customerIntellectualPropertyData"
                                name="customerIntellectualPropertyData"
                              >
                                {Object.keys(IntellectualPropertyData).map((key, index) => {
                                  const value = IntellectualPropertyData[key]
                                  return (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          id={`chk-${key}`}
                                          key={key}
                                          name={value}
                                          onChange={(evt) =>
                                            handleChangeOfGroupChkBox(
                                              evt,
                                              'customerIntellectualPropertyData',
                                            )
                                          }
                                          checked={
                                            assessmentState.customerIntellectualPropertyData?.indexOf(
                                              value,
                                            ) > -1
                                          }
                                        />
                                      }
                                      label={
                                        <CustomLabel
                                          selected={
                                            assessmentState.customerIntellectualPropertyData?.indexOf(
                                              value,
                                            ) > -1
                                          }
                                          value={value}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </FormGroup>
                            </div>
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>Security data</p>
                            <div className={styles['level-2']}>
                              <FormGroup row id="customerSecurityData" name="customerSecurityData">
                                {Object.keys(SecurityData).map((key, index) => {
                                  const value = SecurityData[key]
                                  return (
                                    <FormControlLabel
                                      key={key}
                                      control={
                                        <Checkbox
                                          id={`chk-${key}`}
                                          key={key}
                                          name={value}
                                          onChange={(evt) =>
                                            handleChangeOfGroupChkBox(evt, 'customerSecurityData')
                                          }
                                          checked={
                                            assessmentState.customerSecurityData?.indexOf(value) >
                                            -1
                                          }
                                        />
                                      }
                                      label={
                                        <CustomLabel
                                          selected={
                                            assessmentState.customerSecurityData?.indexOf(value) >
                                            -1
                                          }
                                          value={value}
                                        />
                                      }
                                    />
                                  )
                                })}
                              </FormGroup>
                            </div>
                          </div>

                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>
                              Is there a PI/SPI/PHI involved in this project?
                            </p>

                            <div className={styles['level-2']}>
                              <RadioGroup
                                row
                                id="isTherePISPIInvolvedInThiscustomerProject"
                                name="isTherePISPIInvolvedInThiscustomerProject"
                                onChange={(e) => {
                                  if (e.target.value === 'Y')
                                    assessmentDispatch({
                                      type: 'update',
                                      key: 'customerNoneOftheAbove',
                                      value: '',
                                    })
                                  handleChange(e)
                                }}
                                value={assessmentState.isTherePISPIInvolvedInThiscustomerProject}
                                error={hasValue(
                                  feedbackState.isTherePISPIInvolvedInThiscustomerProject,
                                )}
                              >
                                <FormControlLabel
                                  value="Y"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={
                                        assessmentState.isTherePISPIInvolvedInThiscustomerProject ===
                                        'Y'
                                      }
                                      value={'Yes'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="N"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={
                                        assessmentState.isTherePISPIInvolvedInThiscustomerProject ===
                                        'N'
                                      }
                                      value={'No'}
                                    />
                                  }
                                />
                              </RadioGroup>
                            </div>
                            <ErrorText
                              value={feedbackState.isTherePISPIInvolvedInThiscustomerProject}
                            />
                          </div>

                          {assessmentState.isTherePISPIInvolvedInThiscustomerProject === 'Y' ? (
                            <>
                              <div className={styles['form-item-container-2']}>
                                <p className={styles.placeholder}>PI (Personal information)</p>
                                <div className={styles['level-2']}>
                                  <FormGroup row id="customerPIData" name="customerPIData">
                                    {Object.keys(PI).map((key, index) => {
                                      const value = PI[key]
                                      return (
                                        <FormControlLabel
                                          key={key}
                                          control={
                                            <Checkbox
                                              id={`chk-${key}`}
                                              key={key}
                                              name={value}
                                              onChange={(evt) =>
                                                handleChangeOfGroupChkBox(evt, 'customerPIData')
                                              }
                                              checked={
                                                assessmentState.customerPIData?.indexOf(value) > -1
                                              }
                                            />
                                          }
                                          label={
                                            <CustomLabel
                                              selected={
                                                assessmentState.customerPIData?.indexOf(value) > -1
                                              }
                                              value={value}
                                            />
                                          }
                                        />
                                      )
                                    })}
                                  </FormGroup>
                                </div>
                              </div>
                              <div className={styles['form-item-container-2']}>
                                <p className={styles.placeholder}>
                                  SPI (Sensitive personal information)
                                </p>
                                <div className={styles['level-2']}>
                                  <FormGroup row id="customerSPIData" name="customerSPIData">
                                    {Object.keys(SPI).map((key, index) => {
                                      const value = SPI[key]
                                      return (
                                        <FormControlLabel
                                          key={key}
                                          control={
                                            <Checkbox
                                              id={`chk-${key}`}
                                              key={key}
                                              name={value}
                                              onChange={(evt) =>
                                                handleChangeOfGroupChkBox(evt, 'customerSPIData')
                                              }
                                              checked={
                                                assessmentState.customerSPIData?.indexOf(value) > -1
                                              }
                                            />
                                          }
                                          label={
                                            <CustomLabel
                                              selected={
                                                assessmentState.customerSPIData?.indexOf(value) > -1
                                              }
                                              value={value}
                                            />
                                          }
                                        />
                                      )
                                    })}
                                  </FormGroup>
                                </div>
                              </div>
                              <div className={styles['form-item-container-2']}>
                                <p className={styles.placeholder}>
                                  PHI (Protected health information)
                                </p>
                                <div className={styles['level-2']}>
                                  <FormGroup row id="customerPHIData" name="customerPHIData">
                                    {Object.keys(PHI).map((key, index) => {
                                      const value = PHI[key]
                                      return (
                                        <FormControlLabel
                                          key={key}
                                          control={
                                            <Checkbox
                                              id={`chk-${key}`}
                                              key={key}
                                              name={value}
                                              onChange={(evt) =>
                                                handleChangeOfGroupChkBox(evt, 'customerPHIData')
                                              }
                                              checked={
                                                assessmentState.customerPHIData?.indexOf(value) > -1
                                              }
                                            />
                                          }
                                          label={
                                            <CustomLabel
                                              selected={
                                                assessmentState.customerPHIData?.indexOf(value) > -1
                                              }
                                              value={value}
                                            />
                                          }
                                        />
                                      )
                                    })}
                                  </FormGroup>
                                </div>
                              </div>
                              <div className={styles['form-item-container']}>
                                <p className={styles['placeholder-2']}>Number of PI/SPI records</p>
                                <InputBase
                                  className={styles.form}
                                  type="number"
                                  min={1}
                                  max={10000}
                                  maxLength={5}
                                  name="noOfPISPIRecordsCustomer"
                                  value={assessmentState.noOfPISPIRecordsCustomer}
                                  onChange={handleChange}
                                ></InputBase>
                              </div>
                              <div className={styles['form-item-container']}>
                                <p className={styles['placeholder-2']}>Data subject location</p>
                                <Select
                                  className={styles.form}
                                  value={assessmentState.dataSubjectLocationCustomer}
                                  onChange={handleChange}
                                  name="dataSubjectLocationCustomer"
                                  displayEmpty
                                >
                                  <MenuItem value={''}>-- Select --</MenuItem>
                                  <MenuItem value={'global'}>Global</MenuItem>
                                  <MenuItem value={'USA'}>USA</MenuItem>
                                  <MenuItem value={'EMEA'}>EMEA</MenuItem>
                                  <MenuItem value={'APAC'}>APAC</MenuItem>
                                  <MenuItem value={'NA'}>NA</MenuItem>
                                </Select>
                              </div>
                              <div className={styles.switch}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={assessmentState.willPISPITransferredCustomer}
                                      onChange={handleCHKChange}
                                      name="willPISPITransferredCustomer"
                                    />
                                  }
                                  label="Will the PI/SPI be transferred from project country to another country?"
                                />
                              </div>

                              <div className={styles['form-item-container']}>
                                <p className={styles['placeholder-2']}>
                                  Purpose of collecting/processing PI/SPI
                                </p>
                                <InputBase
                                  className={styles.form}
                                  value={assessmentState.puposeOfCollectingPISPICustomer}
                                  onChange={handleChange}
                                  name="puposeOfCollectingPISPICustomer"
                                ></InputBase>
                              </div>
                              <div className={styles['form-item-container']}>
                                <p className={styles['placeholder-2']}>
                                  <span className={styles.span}>
                                    Please provide any additional data elements of both PI/SPI and
                                    other information involved
                                  </span>
                                </p>
                                <InputBase
                                  id="detailedDataElementsOfCustomerData"
                                  name="detailedDataElementsOfCustomerData"
                                  multiline
                                  rows={4}
                                  className={styles['form-2']}
                                  placeholder="Type here..."
                                  onChange={handleChange}
                                  value={assessmentState.detailedDataElementsOfCustomerData}
                                  error={hasValue(feedbackState.detailedDataElementsOfCustomerData)}
                                ></InputBase>
                                {/* <ErrorText value={feedbackState.detailedDataElementsOfCustomerData} /> */}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div className={styles['form-item-container-2']}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id="customerNoneOftheAbove"
                                  name="customerNoneOftheAbove"
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      feedbackDispatch({
                                        type: 'update',
                                        key: 'customerDataList',
                                        value: '',
                                      })
                                      assessmentDispatch({
                                        type: 'update_multiple',
                                        multiple_updates_state: {
                                          customerBusinessData: [],
                                          customerPublicData: [],
                                          customerSPIData: [],
                                          customerPIData: [],
                                          customerPHIData: [],
                                          customerRegulatoryComplianceData: [],
                                          customerFinancialCommercialData: [],
                                          customerIntellectualPropertyData: [],
                                          customerMarketingData: '',
                                          customerSecurityData: [],
                                          isTherePISPIInvolvedInThisCustomerProject: '',
                                          customerNoneOftheAbove: '',
                                        },
                                      })
                                    }
                                    handleCHKChange(e)
                                  }}
                                  checked={assessmentState.customerNoneOftheAbove}
                                />
                              }
                              label={
                                <CustomLabel
                                  selected={assessmentState.customerNoneOftheAbove}
                                  value={'None of the above'}
                                />
                              }
                            />
                          </div>

                          {assessmentState.customerNoneOftheAbove ? (
                            <div className={styles['form-item-container']}>
                              <p className={styles['placeholder-2']}>
                                <span className={styles.span}>Remarks</span>
                              </p>
                              <InputBase
                                className={styles.form}
                                id="typeOfCustomerDataRemarks"
                                name="typeOfCustomerDataRemarks"
                                onChange={handleChange}
                                value={assessmentState.typeOfCustomerDataRemarks}
                                error={hasValue(feedbackState.typeOfCustomerDataRemarks)}
                                required
                              ></InputBase>
                              <ErrorText value={feedbackState.typeOfCustomerDataRemarks} />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {currentStep === 4 ? (
                <>
                  <div className={styles['section-wrapper']}>
                    <div className={styles['sub-section']}>
                      <div className={styles['title-caption']}>
                        <div className={styles.level}>
                          <div className={styles['text-wrapper-2']}>DATA PROCESSING / HOSTING</div>
                        </div>
                        <p className={styles.p}>
                          The scan will initiate a real-time Deep/Dark web and external IP
                          vulnerability assessment to identify open vulnerabilities and detect dark
                          web mentions, including zero-day or critical threats.
                        </p>
                      </div>
                      <div className={styles.section}>
                        <div className={styles['sub-section-2']}>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>
                              Will the data be stored on-site at your company or at a vendor&#39;s
                              location?
                            </p>
                            <div className={styles['level-2']}>
                              <RadioGroup
                                row
                                id="dataStoreLocationType"
                                name="dataStoreLocationType"
                                onChange={handleChange}
                                value={assessmentState.dataStoreLocationType}
                                error={hasValue(feedbackState.dataStoreLocationType)}
                              >
                                <FormControlLabel
                                  value="Company"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={assessmentState.dataStoreLocationType === 'Company'}
                                      value={'Company'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="VendorLocation"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={
                                        assessmentState.dataStoreLocationType === 'VendorLocation'
                                      }
                                      value={'Vendor location'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="NoHosting"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={
                                        assessmentState.dataStoreLocationType === 'NoHosting'
                                      }
                                      value={'No hosting'}
                                    />
                                  }
                                />
                              </RadioGroup>
                            </div>
                            <ErrorText value={feedbackState.dataStoreLocationType} />
                          </div>
                          <div className={styles['form-item-container-5']}>
                            <p className={styles['placeholder-2']}>
                              Remarks<span className={styles['text-wrapper-9']}>*</span>
                            </p>

                            <InputBase
                              className={styles.form}
                              id="dataStoreLocationRemarks"
                              name="dataStoreLocationRemarks"
                              onChange={handleChange}
                              value={assessmentState.dataStoreLocationRemarks}
                              error={hasValue(feedbackState.dataStoreLocationRemarks)}
                              required
                            ></InputBase>
                            <ErrorText value={feedbackState.dataStoreLocationRemarks} />
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>
                              Will the data be processed by a third-party subcontractor?
                            </p>
                            <div className={styles['level-2']}>
                              <RadioGroup
                                row
                                id="dataProcessedByThirdPartySubContractor"
                                name="dataProcessedByThirdPartySubContractor"
                                onChange={handleChange}
                                value={assessmentState.dataProcessedByThirdPartySubContractor}
                                error={hasValue(
                                  feedbackState.dataProcessedByThirdPartySubContractor,
                                )}
                                required
                              >
                                <FormControlLabel
                                  value="Y"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={
                                        assessmentState.dataProcessedByThirdPartySubContractor ===
                                        'Y'
                                      }
                                      value={'Yes'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="N"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={
                                        assessmentState.dataProcessedByThirdPartySubContractor ===
                                        'N'
                                      }
                                      value={'No'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="NA"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={
                                        assessmentState.dataProcessedByThirdPartySubContractor ===
                                        'NA'
                                      }
                                      value={'Not Applicable'}
                                    />
                                  }
                                />
                              </RadioGroup>
                            </div>
                            <ErrorText
                              value={feedbackState.dataProcessedByThirdPartySubContractor}
                            />
                          </div>
                          {assessmentState.dataProcessedByThirdPartySubContractor === 'NA' ? (
                            <>
                              <div className={styles['form-item-container-5']}>
                                <p className={styles['placeholder-2']}>Remarks</p>
                                <InputBase
                                  className={styles.form}
                                  id="dataProcessedByThirdPartySubContractorRemarks"
                                  name="dataProcessedByThirdPartySubContractorRemarks"
                                  onChange={handleChange}
                                  value={
                                    assessmentState.dataProcessedByThirdPartySubContractorRemarks
                                  }
                                  error={hasValue(
                                    feedbackState.dataProcessedByThirdPartySubContractorRemarks,
                                  )}
                                  required
                                ></InputBase>
                                <ErrorText
                                  value={
                                    feedbackState.dataProcessedByThirdPartySubContractorRemarks
                                  }
                                />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className={styles['sub-section']}>
                      <div className={styles['title-caption']}>
                        <div className={styles.level}>
                          <div className={styles['text-wrapper-2']}>
                            LOCATION TRACKING & DEPENDABILITY
                          </div>
                        </div>
                        <p className={styles.p}>
                          {
                            "Explain how you track location data and ensure it's accurate and reliable, highlighting any tools or methods you use."
                          }
                        </p>
                      </div>
                      <div className={styles.section}>
                        <div className={styles['sub-section-2']}>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>
                              Is there a Mobile app involved in this engagement that will contains
                              scoped data?
                            </p>
                            <div className={styles['level-2']}>
                              <RadioGroup
                                row
                                id="mobileAppInvolved"
                                name="mobileAppInvolved"
                                onChange={handleChange}
                                value={assessmentState.mobileAppInvolved}
                                error={hasValue(feedbackState.mobileAppInvolved)}
                                required
                              >
                                <FormControlLabel
                                  value="Y"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={assessmentState.mobileAppInvolved === 'Y'}
                                      value={'Yes'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="N"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={assessmentState.mobileAppInvolved === 'N'}
                                      value={'No'}
                                    />
                                  }
                                />
                              </RadioGroup>
                            </div>
                            <ErrorText value={feedbackState.mobileAppInvolved} />
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>
                              Does this project/system/software continuously monitor the location of
                              individuals, either virtually or physically? For example, does it
                              track employee locations using mobile work devices?
                            </p>
                            <div className={styles['level-2']}>
                              <RadioGroup
                                row
                                id="trackingOfIndividual"
                                name="trackingOfIndividual"
                                onChange={handleChange}
                                value={assessmentState.trackingOfIndividual}
                                error={hasValue(feedbackState.trackingOfIndividual)}
                                required
                              >
                                <FormControlLabel
                                  value="Y"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={assessmentState.trackingOfIndividual === 'Y'}
                                      value={'Yes'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="N"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={assessmentState.trackingOfIndividual === 'N'}
                                      value={'No'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="NA"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={assessmentState.trackingOfIndividual === 'NA'}
                                      value={'Not Applicable'}
                                    />
                                  }
                                />
                              </RadioGroup>
                            </div>
                            <ErrorText value={feedbackState.mobileAppInvolved} />
                          </div>
                          {assessmentState.trackingOfIndividual === 'NA' ? (
                            <>
                              <div className={styles['form-item-container-5']}>
                                <p className={styles['placeholder-2']}>Remarks</p>
                                <InputBase
                                  className={styles.form}
                                  id="trackingOfIndividualRemarks"
                                  name="trackingOfIndividualRemarks"
                                  onChange={handleChange}
                                  value={assessmentState.trackingOfIndividualRemarks}
                                  error={hasValue(feedbackState.trackingOfIndividualRemarks)}
                                  required
                                ></InputBase>
                                <ErrorText value={feedbackState.trackingOfIndividualRemarks} />
                              </div>
                            </>
                          ) : null}
                          <div className={styles['form-item-container-2']}>
                            <p className={styles.placeholder}>
                              How do you define the dependability of a third party in your
                              project/organization? Dependability will be determined by the extent
                              of the consequences if the third party is no longer available.
                            </p>
                            <div className={styles['level-2']}>
                              <RadioGroup
                                row
                                id="dependabilityOfThirdParty"
                                name="dependabilityOfThirdParty"
                                onChange={handleChange}
                                value={assessmentState.dependabilityOfThirdParty}
                                error={hasValue(feedbackState.dependabilityOfThirdParty)}
                                required
                              >
                                <FormControlLabel
                                  value="Low"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={assessmentState.dependabilityOfThirdParty === 'Low'}
                                      value={'Low'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="Medium"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={
                                        assessmentState.dependabilityOfThirdParty === 'Medium'
                                      }
                                      value={'Medium'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="High"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={
                                        assessmentState.dependabilityOfThirdParty === 'High'
                                      }
                                      value={'High'}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value="Critical"
                                  control={<Radio />}
                                  label={
                                    <CustomLabel
                                      selected={
                                        assessmentState.dependabilityOfThirdParty === 'Critical'
                                      }
                                      value={'Critical'}
                                    />
                                  }
                                />{' '}
                              </RadioGroup>
                            </div>
                            <ErrorText value={feedbackState.dependabilityOfThirdParty} />
                          </div>
                          <div className={styles['form-item-container-2']}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id="vendorHaveNetworkAccessBool"
                                  name="vendorHaveNetworkAccessBool"
                                  onChange={handleCHKChange}
                                  checked={assessmentState.vendorHaveNetworkAccessBool}
                                />
                              }
                              label={
                                <CustomLabel
                                  selected={assessmentState.vendorHaveNetworkAccessBool}
                                  value={'Will vendor have Network access to your company?'}
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {currentStep === 5 ? (
                <>
                  <div className={styles.launchAssessment}>
                    <div className={styles.div}>
                      <div className={styles['sub-section-wrapper']}>
                        <div className={styles['sub-section']}>
                          <div className={styles.frame}>
                            <div className={styles.title}>
                              <div className={styles['text-wrapper']}>
                                DOCUMENT ANALYSIS ASSESSMENT
                              </div>
                              <IconPlaceholder
                                icon={<FormIconWhite className={styles['icon-instance-node']} />}
                                size="thirty-two-x-32"
                              />
                            </div>
                            <div className={styles.info}>
                              <IconPlaceholder
                                icon={<SuccessIcon className={styles['icon-instance-node']} />}
                                size="twenty-four-x-24"
                              />
                              <div className={styles.p}>Successfully captured all the inputs</div>
                            </div>
                          </div>
                          <div className={styles['frame-2']}>
                            <div className={styles['div-2']}>
                              <div className={styles['form-item-container']}>
                                <div className={styles.placeholder}>Third Party Vendor</div>
                                {/* <div className={styles.form}> */}
                                <div className={styles.search}>
                                  <div className={styles['div-3']}>
                                    {assessmentState.logoUrl && (
                                      <img
                                        className={styles['img-3']}
                                        alt="Avatar"
                                        src={assessmentState.logoUrl}
                                      />
                                    )}
                                    <div className={styles.left}>
                                      <div className={styles['text-wrapper-2']}>
                                        {assessmentState.thirdPartyName}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* </div> */}
                              </div>
                            </div>
                            <div className={styles['div-2']}>
                              <div className={styles['form-item-container']}>
                                <div className={styles['place-holder']}>
                                  <div className={styles['placeholder-wrapper']}>
                                    <div className={styles['placeholder-2']}>
                                      {assessmentState.businessUnitDepartmentName}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles['div-4']}>
                        <div className={styles['sub-section-2']}>
                          <div className={styles.title}>
                            <p className={styles['DARK-WEB-ATTACK']}>
                              DARK WEB &amp; ATTACK SURFACE
                            </p>
                            <IconPlaceholder
                              icon={<DrakWebScanIcon className={styles['icon-instance-node']} />}
                              size="thirty-two-x-32"
                            />
                          </div>
                          <div className={styles['div-2']}>
                            <div className={styles['form-item-container']}>
                              <div className={styles['placeholder-3']}>
                                Enter third party website
                                <span className={styles['text-wrapper-10']}>*</span>
                              </div>
                              <InputBase
                                id="thirdPartyWebsite"
                                name="thirdPartyWebsite"
                                className={styles['div-wrapper']}
                                placeholder="Type Here..."
                                onChange={handleChange}
                                value={assessmentState.thirdPartyWebsite}
                                error={hasValue(feedbackState.thirdPartyWebsite)}
                              ></InputBase>
                              <ErrorText value={feedbackState.thirdPartyWebsite} />
                              {/*
                          <div className={styles['div-wrapper']}>
                            <div className={styles['placeholder-4']}>https://figma.com/</div>
                          </div> */}
                            </div>
                            <div className={styles['form-item-container']}>
                              <p className={styles['placeholder-3']}>
                                Enter third party LinkedIn page
                              </p>
                              <InputBase
                                id="thirdPartyLinkedIn"
                                name="thirdPartyLinkedIn"
                                className={styles['div-wrapper']}
                                placeholder="Type Here..."
                                onChange={handleChange}
                                value={assessmentState.thirdPartyLinkedIn}
                                // error={hasValue(feedbackState.thirdPartyLinkedIn)}
                              ></InputBase>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {/* <SystemDetails /> */}
              <div className={styles.actions}>
                <div className={styles['button-2']}>
                  {currentStep === 0 ? (
                    <Button
                      className={styles['button-3']}
                      color="secondary"
                      size="small"
                      variant="contained"
                      text="Previous"
                      disabled={currentStep !== 0}
                      onClick={() => {
                        setSearchVendorText(assessmentState.thirdPartyName)
                        setVendorSearchDone(false)
                      }}
                    >
                      Back to Search
                    </Button>
                  ) : (
                    <Button
                      className={styles['button-3']}
                      color="secondary"
                      size="small"
                      variant="contained"
                      text="Previous"
                      disabled={currentStep === 0}
                      onClick={() => {
                        var width = 205 * currentStep
                        var selecionline = document.getElementById('selection-line')
                        selecionline.style.setProperty('width', width + 'px')
                        setCurrentStep(currentStep - 1)
                      }}
                    >
                      Previous
                    </Button>
                  )}

                  {currentStep !== 5 && (
                    <>
                      <Button
                        className={styles['button-3']}
                        color="primary"
                        size="small"
                        variant="contained"
                        text="Next"
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                      {/* <Button
                className={styles['button-3']}
                color="primary"
                size="small"
                variant="contained"
                text="Next"
                onClick={handleNext}
              >
                Save as Draft
              </Button> */}
                    </>
                  )}
                  {currentStep === 5 && (
                    <Button
                      className={styles['button-3']}
                      color="primary"
                      size="small"
                      variant="contained"
                      text="Submit"
                      disabled={isReadOnlyMode}
                      onClick={handleNext}
                    >
                      Launch Assessment
                    </Button>
                  )}
                </div>
                {!hideCancel && (!id || id < 0) && (
                  <div className={styles['div-2']}>
                    <Button
                      className={styles['button-3']}
                      color="primary"
                      size="small"
                      variant="contained"
                      text="Submit"
                      onClick={handleSaveAsDraft}
                    >
                      Save Draft
                    </Button>
                    <Button
                      className={styles['button-3']}
                      color="error"
                      size="small"
                      variant="contained"
                      text="Discard"
                      onClick={deleteRequest}
                    >
                      Discard
                    </Button>
                  </div>
                )}
                {!hideCancel && id > 0 && csStatus === ClientSubmissionStatus.Draft && (
                  <div className={styles['div-2']}>
                    <Button
                      className={styles['button-3']}
                      color="primary"
                      size="small"
                      variant="contained"
                      text="Submit"
                      onClick={handleSaveAsDraft}
                    >
                      Save Draft
                    </Button>
                    <Button
                      className={styles['button-3']}
                      color="error"
                      size="small"
                      variant="contained"
                      text="Discard"
                      onClick={deleteRequest}
                    >
                      Delete Draft
                    </Button>
                  </div>
                )}
                {!hideCancel && id > 0 && csStatus !== ClientSubmissionStatus.Draft && (
                  <div className={styles['div-2']}>
                    <Button
                      className={styles['button-3']}
                      color="error"
                      size="small"
                      variant="contained"
                      text="Discard"
                      onClick={deleteRequest}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {openSearchVendorList && (
        <SearchVendorList
          open={openSearchVendorList}
          handleAction={handleSearchVendorList}
          vendorInfo={vendorInfo}
        />
      )}
      <Confirmation
        open={openConfirmation}
        handleAction={handleConfirmationClose}
        title={
          !id || id < 0
            ? 'Discard Request'
            : id > 0 && csStatus === ClientSubmissionStatus.Draft
            ? 'Delete Draft'
            : 'Cancel Request'
        }
        message={
          !id || id < 0
            ? 'Would you prefer to save the form in draft mode for later use?'
            : id > 0 && csStatus === ClientSubmissionStatus.Draft
            ? 'Do you want to Delete Draft?'
            : 'Do you want to Cancel Assessment?'
        }
        secondaryBtnText={!id || id < 0 ? 'Delete' : 'Yes'}
        primaryBtnText={!id || id < 0 ? 'Save as Draft' : 'No'}
        infoIcon={true}
        showReason={id > 0 && csStatus !== ClientSubmissionStatus.Draft ? true : false}
      />
      <UploadNdaDocument open={openUploadNDADoc} handleAction={handleUploadNDADoc} />
    </>
  )
}

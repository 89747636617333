import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import AssetsChart from './assets-chart'
import { AssetsStatusChart } from './assets-status-chart'
import { AssetsList } from './assets-list'
import { getAssetStats } from 'src/services/cyberScanService'
import { hasValue } from 'src/utils/commonFns'

export const AttackSurfaceAssets = ({ assessmentInfo }) => {
  const [assetCounts, setAssetCounts] = useState({})

  useEffect(() => {
    if (!hasValue(assessmentInfo.coalitionEntityId)) return
    const loadAssetStats = async () => {
      const data = await getAssetStats(assessmentInfo.coalitionEntityId)
      setAssetCounts(data)
    }
    loadAssetStats()
  }, [assessmentInfo.coalitionEntityId])

  return (
    <div className={styles.report}>
      <div className={styles['section-3']}>
        <div className={styles.row}>
          <AssetsChart assetCounts={assetCounts} />
          <AssetsStatusChart />
        </div>
        <AssetsList assetCounts={assetCounts} assessmentInfo={assessmentInfo} />
      </div>
    </div>
  )
}

import React from "react";

export const Icons6 = ({ className }) => {
  return (
    <svg
      className={`icons-6 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask className="mask" height="20" id="mask0_423_12217" maskUnits="userSpaceOnUse" width="20" x="0" y="0">
        <rect className="rect" fill="#D9D9D9" height="20" width="20" />
      </mask>
      <g className="g" mask="url(#mask0_423_12217)">
        <path
          className="path"
          d="M7.93295 13.0124C6.5099 13.0124 5.30476 12.5189 4.31754 11.5318C3.33046 10.5445 2.83691 9.33941 2.83691 7.91635C2.83691 6.4933 3.33046 5.28816 4.31754 4.30094C5.30476 3.31385 6.5099 2.82031 7.93295 2.82031C9.35601 2.82031 10.5611 3.31385 11.5484 4.30094C12.5355 5.28816 13.029 6.4933 13.029 7.91635C13.029 8.51149 12.9291 9.0799 12.7294 9.62156C12.5296 10.1632 12.263 10.6343 11.9296 11.0349L16.7246 15.8299C16.84 15.9452 16.8991 16.0902 16.9017 16.2649C16.9043 16.4396 16.8453 16.5873 16.7246 16.708C16.6039 16.8287 16.4575 16.8891 16.2855 16.8891C16.1135 16.8891 15.9672 16.8287 15.8465 16.708L11.0515 11.913C10.6348 12.257 10.1557 12.5263 9.614 12.7207C9.07233 12.9152 8.51198 13.0124 7.93295 13.0124ZM7.93295 11.7626C9.0067 11.7626 9.91615 11.39 10.6613 10.6447C11.4066 9.89955 11.7792 8.9901 11.7792 7.91635C11.7792 6.8426 11.4066 5.93316 10.6613 5.18802C9.91615 4.44274 9.0067 4.0701 7.93295 4.0701C6.8592 4.0701 5.94976 4.44274 5.20462 5.18802C4.45934 5.93316 4.08671 6.8426 4.08671 7.91635C4.08671 8.9901 4.45934 9.89955 5.20462 10.6447C5.94976 11.39 6.8592 11.7626 7.93295 11.7626Z"
          fill="#A2A3A4"
        />
      </g>
    </svg>
  );
};

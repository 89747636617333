import React from 'react'
import styles from './style.module.css'
import { BadgeStatus } from './BadgeStatus'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import moment from 'moment'
import { EnumAssessmentTypes } from 'src/utils/commonFns'

export const ListItem = ({ DrawerType, row }) => {
  return (
    <div className={styles.div}>
      <div className={styles['content-6']}>
        <div className={styles['title-6']}>
          <div className={styles['TITLE-ICON-5']}>
            <div className={styles['figma-4']}>{row?.thirdPartyName}</div>
          </div>
          <ArrowForwardOutlinedIcon className={styles['icon-placeholder-4']} />
        </div>
        <div className={styles['section-4']}>
          <div className={styles['frame-5']}>
            <div className={styles['level-5']}>
              <div className={styles['text-wrapper-11']}>{row?.clientSubmissionNo}</div>
            </div>
            <div className={styles['rectangle-5']} />
            <div className={styles['date-5']}>
              <div className={styles['text-wrapper-12']}>
                {moment(row?.requestedDate).format('DD MMM YYYY')}
              </div>
            </div>
          </div>
        </div>
        {DrawerType !== EnumAssessmentTypes.Inprogress && (
          <BadgeStatus
            className={styles['badge-status-6']}
            property1={row?.riskRating?.toLowerCase()}
            textClassName={styles['badge-status-7']}
          />
        )}
      </div>
    </div>
  )
}

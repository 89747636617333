import React from 'react'
import styles from './style.module.scss'
import Chart from 'react-apexcharts'
import { useTheme } from '@mui/material/styles'

const LeakedEmployeeCredChart = ({ chartSeries, loading = false }) => {
  const labels = ['Domain', 'P.Email', 'IP Address', 'Username', 'Phone']
  const colors = ['#e23670', '#af56db', '#e88d30', '#2eb88a', '#2761d9']
  const chartOptions = useChartOptions(labels, colors, loading)
  return (
    <div className={styles.apexChartWrapper}>
      <Chart
        options={chartOptions}
        series={loading ? [100] : chartSeries}
        type="donut"
        width="100%"
        height={200}
        className={styles.apexChart}
      />
    </div>
  )
}

function useChartOptions(labels, colors, loading) {
  const theme = useTheme()

  return {
    chart: {
      background: 'transparent',
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    colors: colors,
    dataLabels: { enabled: false },
    labels,
    legend: { show: false },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '80%',
          labels: {
            show: true,
            value: {
              color: '#FAFAFA',
              fontWeight: 600,
              fontSize: '36px',
              offsetY: -30,
              fontFamily: '"SF Pro Display", Helvetica',
            },
            total: {
              show: true,
              showAlways: true,
              label: 'TOTAL BREACH',
              color: '#A2A3A4',
              fontFamily: '"SF Pro Text", Helvetica',
              fontWeight: 600,
              fontSize: '12px',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                })
              },
            },
          },
        },
      },
    },
    states: {
      active: { filter: { type: 'none' } },
      hover: { filter: { type: 'none' } },
    },
    stroke: { width: 0, colors: ['#2c2c2c'] },
    theme: { mode: theme.palette.mode },
    tooltip: { fillSeriesColor: false },
    className: 'risk-metrics-chart',
    noData: {
      text: loading ? 'Loading...' : 'No Data Available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 8,
      offsetY: -10,
      style: {
        color: '#fff',
        fontSize: '14px',
        fontFamily: '"SF Pro Text-Regular", Helvetica',
      },
    },
  }
}

export default LeakedEmployeeCredChart

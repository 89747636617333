import React, { useEffect, useRef } from 'react'
import classes from './style.module.scss'
import * as d3 from 'd3'

export const EADoughnutChart = ({ value = 0, color, loading = false }) => {
  const svgRef = useRef(null)

  useEffect(() => {
    const svg = d3.select(svgRef.current)

    const parentElement = svgRef.current.getBoundingClientRect()

    const width = parentElement.width
    const height = parentElement.height / 1.5

    const radius = 123
    const innerRadius = 107
    const innerThickness = 30
    const thickness = 10
    const trackColor = '#414141' // Default track color
    const arrowSize = 14 // Size of the arrow
    const pointerRadius = 7 // Radius of the circular pointer

    // Define the ranges and colors
    const ranges = [
      { range: [0, 40], color: '#09CA95' }, // Low (Green)
      { range: [40, 70], color: '#FEAA32' }, // Medium (Orange)
      { range: [70, 100], color: '#FF7738' }, // High (Red)
    ]

    const maxAngle = (4 / 3) * Math.PI // Semi-circle from -90 degrees to +90 degrees
    const angleBase = (2 * Math.PI) / 3

    // Clear the previous SVG content
    svg.selectAll('*').remove()

    // Function to generate the arc
    const arcGenerator = (startAngle, endAngle) =>
      d3
        .arc()
        .innerRadius(radius - thickness)
        .outerRadius(radius)
        .startAngle(startAngle)
        .endAngle(endAngle)
        .cornerRadius(10) // Rounded edges
        .padAngle(0.03) // Padding between ranges

    // Draw the background arcs for all ranges in track color
    ranges.forEach(({ range }) => {
      const startAngle = -angleBase + (maxAngle * range[0]) / 100
      const endAngle = -angleBase + (maxAngle * range[1]) / 100

      svg
        .append('path')
        .attr('d', arcGenerator(startAngle, endAngle)())
        .attr('fill', trackColor)
        .attr('transform', `translate(${width / 2}, ${height})`)
    })

    // Draw the filled arcs based on the value
    ranges.forEach(({ range, color }) => {
      const startAngle = -angleBase + (maxAngle * range[0]) / 100
      const endAngle = -angleBase + (maxAngle * Math.min(value, range[1])) / 100

      if (value >= range[0]) {
        svg
          .append('path')
          .attr('d', arcGenerator(startAngle, endAngle)())
          .attr('fill', color)
          .attr('transform', `translate(${width / 2}, ${height})`)
      }
    })

    // Draw the inner arc
    const innerArcGenerator = d3
      .arc()
      .innerRadius(innerRadius - innerThickness)
      .outerRadius(innerRadius)
      .startAngle(-angleBase)
      .endAngle(angleBase)

    svg
      .append('path')
      .attr('d', innerArcGenerator())
      .attr('fill', '#222222')
      .attr('transform', `translate(${width / 2}, ${height})`)

    // Draw the center circle for the label and percentage
    svg
      .append('circle')
      .attr('cx', width / 2)
      .attr('cy', height)
      .attr('r', innerRadius - innerThickness)
      .attr('fill', '#222222')
      .attr('stroke', trackColor)
      .attr('stroke-width', 6)

    // Add the percentage text inside the circle
    svg
      .append('text')
      .attr('x', width / 2)
      .attr('y', height - 5)
      .attr('text-anchor', 'middle')
      .attr('fill', '#FAFAFA')
      .attr('font-size', '20px')
      .attr('font-family', 'var(--heading-m-h4-font-family)')
      .attr('font-weight', 600)
      .text(loading ? 'Loading...' : `${value}%`)

    // Add the label text below the percentage
    svg
      .append('text')
      .attr('x', width / 2)
      .attr('y', height + 15)
      .attr('text-anchor', 'middle')
      .attr('fill', ranges.find(({ range }) => value >= range[0] && value <= range[1]).color)
      .attr('font-size', '12px')
      .attr('font-weight', 500)
      .attr('font-family', 'var(--heading-m-h5-font-family)')
      .text(loading ? '' : value >= 70 ? 'High' : value >= 40 ? 'Medium' : 'Low')

    const pointerAngle = -1.8 * angleBase + (maxAngle * value) / 100

    const pointerX = radius - thickness - 22
    // Adjust the position of the triangular arrow
    svg
      .append('polygon')
      .attr(
        'points',
        `
        ${(width / 2 + (pointerX - arrowSize) * Math.cos(pointerAngle - maxAngle / 40)).toFixed(
          2,
        )},${(height + (pointerX - arrowSize) * Math.sin(pointerAngle - maxAngle / 40)).toFixed(2)}

        ${(width / 2 + (pointerX - arrowSize) * Math.cos(pointerAngle + maxAngle / 40)).toFixed(2)},
        ${(height + (pointerX - arrowSize) * Math.sin(pointerAngle + maxAngle / 40)).toFixed(2)}

         ${(width / 2 + pointerX * Math.cos(pointerAngle)).toFixed(2)},
         ${(height + pointerX * Math.sin(pointerAngle)).toFixed(2)}
      `,
      )
      .attr('fill', '#fff')

    // Circular pointer animation
    const pointerGroup = svg.append('g').attr('transform', `translate(${width / 2}, ${height})`)

    const circlePointer = pointerGroup
      .append('circle')
      .attr('r', pointerRadius)
      .attr('fill', '#FF7738')
      .attr('class', 'circle-pointer')

    const totalDuration = 1500

    circlePointer
      .transition()
      .duration(totalDuration)
      .ease(d3.easeCubicOut)
      .attrTween('transform', () => {
        const interpolate = d3.interpolate(-angleBase, pointerAngle)
        return (t) => {
          const currentAngle = interpolate(t)
          const x = (radius - thickness / 2) * Math.cos(currentAngle)
          const y = (radius - thickness / 2) * Math.sin(currentAngle)
          return `translate(${x},${y})`
        }
      })

    // Add "Low" and "High" labels
    const lowLabelX = width / 2 - radius + 20 // 10 units offset from the far left
    const highLabelX = width / 2 + radius - 20 // 10 units offset from the far right
    const labelY = height + 83 // Adjust as needed for vertical positioning

    svg
      .append('text')
      .attr('x', lowLabelX)
      .attr('y', labelY)
      .attr('text-anchor', 'middle')
      .attr('fill', '#A2A3A4')
      .attr('font-size', '12px')
      .attr('font-family', 'var(--heading-m-h5-font-family)')
      .attr('font-weight', 500)
      .text('Low')

    svg
      .append('text')
      .attr('x', highLabelX)
      .attr('y', labelY)
      .attr('text-anchor', 'middle')
      .attr('fill', '#A2A3A4')
      .attr('font-size', '12px')
      .attr('font-family', 'var(--heading-m-h5-font-family)')
      .attr('font-weight', 500)
      .text('High')
  }, [value, loading])

  return <svg ref={svgRef} width={'100%'} height={'100%'} className={classes['gauge-chart']}></svg>
}

import PropTypes from 'prop-types'
import React from 'react'
import { Box } from '@material-ui/core'
import styles from './BadgeStatus.module.css'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const BadgeStatus = ({ property1, className, textClassName }) => {
  return (
    <Box
      className={`${styles['badge-status']} ${className}`}
      display="flex"
      alignItems="center"
      bgcolor="#0000001f"
      borderRadius="99px"
      justifyContent="center"
      padding="2px 12px 2px 2px"
      position="relative"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={16}
        width={16}
        position="relative"
        style={{ marginTop: '-3px' }}
      >
        <InfoOutlinedIcon fontSize={'inherit'} className={`${styles[property1]}`} />
      </Box>
      <Box
        className={`${styles.text} ${styles[property1]}`}
        fontFamily="var(--paragraph-SB-p3-font-family)"
        // fontSize="var(--paragraph-SB-p3-font-size)"
        fontStyle="var(--paragraph-SB-p3-font-style)"
        fontWeight="var(--paragraph-SB-p3-font-weight)"
        letterSpacing="var(--paragraph-SB-p3-letter-spacing)"
        lineHeight="var(--paragraph-SB-p3-line-height)"
        position="relative"
        textAlign="center"
        whiteSpace="nowrap"
        width="fit-content"
      >
        {property1 === 'high' && <>High</>}

        {property1 === 'medium' && <>Medium</>}

        {property1 === 'low' && <>Low</>}
      </Box>
    </Box>
  )
}

BadgeStatus.propTypes = {
  property1: PropTypes.oneOf(['medium', 'high', 'low']),
}

import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import { DarkWebScore } from './darkweb-score'
import { OverallStatus } from './overall-status'
import { ExternalAttackSurface } from './external-attack-surface'
import { VendorsList } from './vendor-list'
import { useAuthState } from 'src/context/login'
import { getDarkWebScanStats } from 'src/services/insecureWebScanService'
import { getExternalAttackSurfaceStats } from 'src/services/cyberScanService'

export const CyberScanning = () => {
  const [darkWebStats, setDarkWebStats] = useState({})
  const [externalAttackStats, setExternalAttackStats] = useState({})
  const [darkWebLoading, setDarkWebLoading] = useState(false)
  const [externalAttackLoading, setExternalAttackLoading] = useState(false)
  const { user } = useAuthState()

  useEffect(() => {
    const fetchDarkWebStats = async () => {
      try {
        setDarkWebLoading(true)
        const data = await getDarkWebScanStats(user.businessOrganizationId)
        setDarkWebStats(data)
      } finally {
        setDarkWebLoading(false)
      }
    }

    const fetchExternalAttackSurfaceStats = async () => {
      try {
        setExternalAttackLoading(true)
        const data = await getExternalAttackSurfaceStats(user.businessOrganizationId)
        setExternalAttackStats(data)
      } finally {
        setExternalAttackLoading(false)
      }
    }

    fetchDarkWebStats()
    fetchExternalAttackSurfaceStats()
  }, [user.businessOrganizationId])

  return (
    <div className={styles['cyber-scanning']}>
      <div className={styles['section-4']}>
        <ExternalAttackSurface
          externalAttackStats={externalAttackStats}
          loading={externalAttackLoading}
        />
        <div className={styles.row}>
          <DarkWebScore darkWebStats={darkWebStats} loading={darkWebLoading} />
          <OverallStatus darkWebStats={darkWebStats} />
        </div>
        <VendorsList />
      </div>
    </div>
  )
}

import React from 'react'

export const DueIcon = ({ className }) => {
  return (
    <svg
      className={`overdue-icon ${className}`}
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="12"
        id="mask0_480_1611"
        maskUnits="userSpaceOnUse"
        width="12"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="12" width="12" />
      </mask>
      <g className="g" mask="url(#mask0_480_1611)">
        <path
          className="path"
          d="M6 8.36538C6.11442 8.36538 6.21033 8.32667 6.28775 8.24925C6.36517 8.17183 6.40387 8.07592 6.40387 7.9615C6.40387 7.84708 6.36517 7.75117 6.28775 7.67375C6.21033 7.59642 6.11442 7.55775 6 7.55775C5.88558 7.55775 5.78967 7.59642 5.71225 7.67375C5.63483 7.75117 5.59613 7.84708 5.59613 7.9615C5.59613 8.07592 5.63483 8.17183 5.71225 8.24925C5.78967 8.32667 5.88558 8.36538 6 8.36538ZM6.00013 6.5385C6.10646 6.5385 6.1955 6.50254 6.26725 6.43063C6.33908 6.35879 6.375 6.26975 6.375 6.1635V3.9135C6.375 3.80725 6.33904 3.71817 6.26713 3.64625C6.19521 3.57442 6.10612 3.5385 5.99988 3.5385C5.89354 3.5385 5.8045 3.57442 5.73275 3.64625C5.66092 3.71817 5.625 3.80725 5.625 3.9135V6.1635C5.625 6.26975 5.66096 6.35879 5.73288 6.43063C5.80479 6.50254 5.89388 6.5385 6.00013 6.5385ZM6.00087 10.75C5.34387 10.75 4.72633 10.6253 4.14825 10.376C3.57017 10.1267 3.06733 9.78829 2.63975 9.36088C2.21217 8.93346 1.87362 8.43083 1.62412 7.853C1.37471 7.27517 1.25 6.65779 1.25 6.00087C1.25 5.34387 1.37467 4.72633 1.624 4.14825C1.87333 3.57017 2.21171 3.06733 2.63913 2.63975C3.06654 2.21217 3.56917 1.87362 4.147 1.62412C4.72483 1.37471 5.34221 1.25 5.99913 1.25C6.65613 1.25 7.27367 1.37467 7.85175 1.624C8.42983 1.87333 8.93267 2.21171 9.36025 2.63913C9.78783 3.06654 10.1264 3.56917 10.3759 4.147C10.6253 4.72483 10.75 5.34221 10.75 5.99913C10.75 6.65613 10.6253 7.27367 10.376 7.85175C10.1267 8.42983 9.78829 8.93267 9.36088 9.36025C8.93346 9.78783 8.43083 10.1264 7.853 10.3759C7.27517 10.6253 6.65779 10.75 6.00087 10.75ZM6 10C7.11667 10 8.0625 9.6125 8.8375 8.8375C9.6125 8.0625 10 7.11667 10 6C10 4.88333 9.6125 3.9375 8.8375 3.1625C8.0625 2.3875 7.11667 2 6 2C4.88333 2 3.9375 2.3875 3.1625 3.1625C2.3875 3.9375 2 4.88333 2 6C2 7.11667 2.3875 8.0625 3.1625 8.8375C3.9375 9.6125 4.88333 10 6 10Z"
          fill="#F26C6E"
        />
      </g>
    </svg>
  )
}

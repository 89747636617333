import React from 'react'
import classes from './style.module.css'
import CTooltip from 'src/v2/components/Tooltip/Tooltip'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import InfoIcon from 'src/v2/screens/dashboard/icons/InfoIcon.svg'
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps'
import { scaleSqrt } from 'd3-scale'

// World map topojson file
const geoUrl = 'https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json'

// Country heat data (replace with your data)
const countryData = [
  {
    name: 'USA',
    coordinates: [-99.1332, 19.4326],
    intensity: 1,
    minColor: '#EF4D4F',
    maxColor: '#0C9D611A',
  },
  {
    name: 'India',
    coordinates: [78.9629, 20.5937],
    intensity: 0.6,
    minColor: '#0C9D61',
    maxColor: '#EF4D4F',
  },
  {
    name: 'China',
    coordinates: [104.1954, 35.8617],
    intensity: 0.7,
    minColor: '#EF4D4F',
    maxColor: '#EF4D4F',
  },
  {
    name: 'Brazil',
    coordinates: [-51.9253, -14.235],
    intensity: 1,
    minColor: '#0C9D61',
    maxColor: '#FE9B0E',
  },
  {
    name: 'Australia',
    coordinates: [133.7751, -25.2744],
    intensity: 0.4,
    minColor: '#0C9D61',
    maxColor: '#0C9D611A',
  },
  {
    name: 'Canada',
    coordinates: [-106.3468, 56.1304],
    intensity: 0.3,
    minColor: '#EF4D4F',
    maxColor: '#FE9B0E',
  },
  // Add more countries as needed
]
// Color scale for heatmap
const colorScale = (minColor, maxColor) => {
  return scaleSqrt()
    .domain([0, 1]) // Min and max intensity
    .range([minColor, maxColor])
}

const sizeScale = scaleSqrt().domain([0, 1]).range([5, 20]) // Adjust the range for bubble size

export const GeographicDistribution = () => {
  return (
    <div className={classes['geographic-distribution']}>
      <div className={classes.section}>
        <div className={classes.title}>
          <div className={classes['title-data']}>Geographic Vendor Distribution</div>
          <div className={classes.iconPlaceholder}>
            <CTooltip
              arrow
              title={
                <React.Fragment>
                  <Typography variant="body2" fontWeight={500} sx={{ mb: '4px' }}>
                    Remediation Status
                  </Typography>
                  <Typography variant="body2">
                    A Vendor Assessment Risk Metrics Chart is a visual tool that summarizes the
                    overall risk level of evaluated vendors. It combines various risk indicators.
                  </Typography>
                </React.Fragment>
              }
            >
              <img className={classes.icons} loading="lazy" alt="" src={InfoIcon} />
            </CTooltip>
          </div>
          <div className={classes.statuscontainer}>
            <span className={`${classes.statusdot} ${classes.statushigh}`} />
            <div className={classes.statuslabel}>High</div>
            <span className={`${classes.statusdot} ${classes.statusmedium}`} />
            <div className={classes.statuslabel}>Meidum</div>
            <span className={`${classes.statusdot} ${classes.statuslow}`} />
            <div className={classes.statuslabel}>Low</div>
          </div>
        </div>
      </div>
      <Grid container spacing={4} sx={{ height: '100%' }} alignItems={'center'}>
        <Grid item xs={7}>
          <ComposableMap projection="geoMercator" width={980} height={551}>
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={'#7F8082'}
                    // stroke="#D6D6DA"
                    style={{
                      default: { outline: 'none' },
                      hover: { outline: 'none' },
                      pressed: { outline: 'none' },
                    }}
                  />
                ))
              }
            </Geographies>
            {countryData.map(({ name, coordinates, intensity, minColor, maxColor }) => (
              <Marker key={name} coordinates={coordinates}>
                <circle
                  r={sizeScale(intensity) * 1.5}
                  fill={colorScale(minColor, maxColor)(intensity)}
                  fillOpacity={0.3} // Middle bubble with slightly higher opacity
                  stroke="none"
                  filter="blur(4px)"
                />
                <circle
                  r={sizeScale(intensity)}
                  fill={colorScale(minColor, maxColor)(intensity)}
                  fillOpacity={0.5} // Inner bubble with higher opacity
                  stroke="none"
                  filter="blur(6px)"
                />
              </Marker>
            ))}
          </ComposableMap>
        </Grid>
        <Grid item xs={5}>
          <TableContainer className={classes.table}>
            <Table size={'medium'}>
              <TableHead className={classes.tablehead}>
                <TableRow>
                  <TableCell className={classes.tableheadcell}>COUNTRY NAME</TableCell>
                  <TableCell className={classes.tableheadcell}>RISK RATIO</TableCell>
                  <TableCell className={classes.tableheadcell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tablebody}>
                {[
                  {
                    cname: 'United States',
                    cratio: 24,
                    text: '24 of 120',
                  },
                  {
                    cname: 'India',
                    cratio: 32,
                    text: '45 of 120',
                  },
                  {
                    cname: 'Australia',
                    cratio: 6,
                    text: '02 of 120',
                  },
                  {
                    cname: 'Germany',
                    cratio: 80,
                    text: '51 of 120',
                  },
                ].map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      className={classes.tablecell}
                      sx={{ color: 'var(--neutral-color-grey-750) !important' }}
                    >
                      {row.cname}
                    </TableCell>
                    <TableCell
                      className={classes.tablecell}
                      sx={{ color: 'var(--neutral-color-grey-900) !important' }}
                    >
                      {row.cratio}%
                    </TableCell>
                    <TableCell className={classes.tablecell} sx={{ fontSize: '12px !important' }}>
                      {row.text}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  )
}

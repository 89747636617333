import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SecurityScore } from './SecurityScore/SecurityScore'
import { RemediationStatus } from './RemediationStatus/RemediationStatus'
import ResidualRisk from './ResidualRisk/ResidualRisk'
import { HighRiskVendors } from './HighRiskVendors/HighRiskVendors'
import { CyberScore } from './CyberScore/CyberScore'
import { GeographicDistribution } from './GeogrpahicDistribution/GeographicDistribution'
import { VendorList } from './VendorList/VendorList'
import './style.scss'
import {
  getRemediationCounts,
  getResidualRiskData,
  getResidualRiskHighRiskVendors,
  getSecurityScore,
} from 'src/services/riskPostureService'
import { useAuthState } from 'src/context/login'

const AnalyticsContent = () => {
  const { user } = useAuthState()

  const [remediationCounts, setRemediationCounts] = useState({})
  const loadRemediationCounts = async () => {
    const result = await getRemediationCounts(user.businessOrganizationId)
    setRemediationCounts(result)
  }

  const [residualRiskDataCounts, setResidualRiskDataCounts] = useState({})
  const loadResidualRiskDataCounts = async () => {
    const result = await getResidualRiskData(user.businessOrganizationId)
    setResidualRiskDataCounts(result)
  }

  const [highRiskVendors, setHighRiskVendors] = useState({})
  const loadHighRiskVendors = async () => {
    const result = await getResidualRiskHighRiskVendors(user.businessOrganizationId)
    setHighRiskVendors(result)
  }

  const [secureScore, setSecureScore] = useState(null)
  const loadSecureScore = async () => {
    const result = await getSecurityScore(user.businessOrganizationId)
    setSecureScore(result)
  }

  useEffect(() => {
    loadSecureScore()
    loadRemediationCounts()
    loadResidualRiskDataCounts()
    loadHighRiskVendors()
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [])
  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={2.5}
      sx={{ margin: '0px 0px 0px -20px !important' }}
    >
      <Grid item sm={12}>
        <div className="first-row">
          {secureScore > 0 && <SecurityScore secureScore={secureScore} />}
          {(!secureScore || secureScore < 0) && <SecurityScore secureScore={0} />}
          <RemediationStatus remediationCounts={remediationCounts} />
          <ResidualRisk residualRiskDataCounts={residualRiskDataCounts} />
        </div>
      </Grid>
      <Grid item sm={12}>
        <div className="first-row">
          {/* <Grid container xs={12} rowGap={3} sx={{ height: '100%' }} className="cyber-score-row">

          </Grid> */}
          <div className="cyber-score-row">
            <Grid item xs={12}>
              <CyberScore />
            </Grid>
            <Grid item xs={12}>
              <GeographicDistribution />
            </Grid>
          </div>

          <HighRiskVendors highRiskVendors={highRiskVendors} />
        </div>
      </Grid>
      <Grid item sm={12}>
        <VendorList businessOrganizationId={user.businessOrganizationId} />
      </Grid>
    </Grid>
  )
}

export default AnalyticsContent

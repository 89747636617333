import React, { useEffect, useState } from 'react'
import Header from './sections/Header/Header'
import classes from './style.module.css'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import PendingActions from './sections/Content/PendingActions/PendingActions'
import AssessmentsPipeline from './sections/Content/AssessmentPipeline/AssessmentsPipeline'
import RiskHeatMap from './sections/Content/Risk/RiskHeatMap'
import RiskMetrics from './sections/Content/Risk/RiskMetrics'
import BusinessUnits from './sections/Content/BusinessUnits/BusinessUnits'
import AssessmentOverView from './sections/Content/AssessmentOverview/AssessmentOverview'
import { DashboardDrawer } from './sections/Drawer/Drawer'
import { useAuthState } from 'src/context/login'
import {
  getAssessmentPipelineCounts,
  getBusinessUnits,
  getAssessmentOverviewCounts,
  getPendingCounts,
  getRiskMetricsData,
} from 'src/services/dashboardService'

const Dashboard = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [DrawerType, setDrawerType] = useState(undefined)

  const handleDrawerOpen = (type) => {
    setDrawerOpen(true)
    setDrawerType(type)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
    setDrawerType(undefined)
  }

  const { user } = useAuthState()

  const [widgetsData, setWidgetsData] = useState({})
  const loadWidgetsData = async () => {
    const result = await getAssessmentOverviewCounts(user.businessOrganizationId)
    setWidgetsData(result)
  }

  const [riskMetricsData, setRiskMetricsData] = useState({})
  const loadRiskMetrics = async () => {
    const result = await getRiskMetricsData(user.businessOrganizationId)
    setRiskMetricsData(result)
  }

  const [businessUnits, setBusinessUnits] = useState([])
  const loadBusinessUnits = async () => {
    const result = await getBusinessUnits(user.businessOrganizationId)
    setBusinessUnits(result)
  }

  const [assessmentPipelineData, setAssessmentPipelineData] = useState({})
  const loadAssessmentPipelineCounts = async () => {
    const result = await getAssessmentPipelineCounts(user.businessOrganizationId)
    setAssessmentPipelineData(result)
  }

  const [pendingCounts, setPendingCounts] = useState({})
  const loadPendingCounts = async () => {
    const result = await getPendingCounts(user.businessOrganizationId)
    setPendingCounts(result)
  }

  useEffect(() => {
    loadAssessmentPipelineCounts()
    loadRiskMetrics()
    loadWidgetsData()
    loadPendingCounts()
    loadBusinessUnits()
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={classes.section}>
      <Header />
      <Grid container rowSpacing={3} columnSpacing={2.5}>
        <Grid item xs={8}>
          <AssessmentsPipeline
            handleDrawerOpen={handleDrawerOpen}
            assessmentPipelineData={assessmentPipelineData}
          />
        </Grid>
        <Grid item xs={4}>
          <PendingActions handleDrawerOpen={handleDrawerOpen} pendingCounts={pendingCounts} />
        </Grid>
        <Grid item xs={8}>
          <RiskHeatMap orgId={user.businessOrganizationId} />
        </Grid>
        <Grid item xs={4}>
          <RiskMetrics riskMetricsData={riskMetricsData} />
        </Grid>
        <Grid item xs={4}>
          <BusinessUnits businessUnitsData={businessUnits} />
        </Grid>
        <Grid item xs={8}>
          <AssessmentOverView widgetsData={widgetsData} />
        </Grid>
      </Grid>
      {isDrawerOpen && (
        <DashboardDrawer
          {...{
            isDrawerOpen,
            handleDrawerClose,
            DrawerType,
            businessOrganizationId: user.businessOrganizationId,
          }}
        />
      )}
    </Box>
  )
}

export default Dashboard

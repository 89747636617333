import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthState } from 'src/context/login'
import { Roles } from 'src/utils/constants'

const ProtectedComponent = ({ children, isAdminRoute }) => {
  const userDetails = useAuthState()
  const validRoute = isAdminRoute
    ? userDetails?.user?.roleDesc?.toLowerCase() === Roles.ADMIN.toLowerCase() &&
      Boolean(userDetails.token)
    : Boolean(userDetails.token)
  return !validRoute ? <Navigate to="/login" replace /> : <>{children}</>
}
export default ProtectedComponent

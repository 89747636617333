import React from 'react'
import styles from './style.module.css'
import { format } from 'date-fns'

export const StatusCard = ({ assessmentData }) => {
  return (
    <div className={styles.content}>
      <div className={styles['tool-tips']}>
        <div className={styles.rectangle} />
        <div className={styles.request}>
          <div className={styles.icon}>
            <div
              className={
                styles[!!assessmentData?.requestedDate ? 'check-points' : 'check-points-2']
              }
            />
          </div>
          <div className={styles.div}>
            <div className={styles['text-wrapper']}>Requested</div>
            <div className={styles['text-wrapper-2']}>
              {!!assessmentData?.requestedDate
                ? format(new Date(assessmentData?.requestedDate), 'dd MMM yyyy, kk:mm') + ' hrs'
                : ''}
            </div>
          </div>
        </div>
        <div className={styles.request}>
          <div className={styles.icon}>
            <div
              className={
                styles[!!assessmentData?.submittedToVendorDate ? 'check-points' : 'check-points-2']
              }
            />
          </div>
          <div className={styles.div}>
            <div className={styles['text-wrapper-3']}>Submitted to vendor</div>
            <div className={styles['text-wrapper-2']}>
              {!!assessmentData?.submittedToVendorDate
                ? format(new Date(assessmentData?.submittedToVendorDate), 'dd MMM yyyy, kk:mm') +
                  ' hrs'
                : ''}
            </div>
          </div>
        </div>
        <div className={styles.request}>
          <div className={styles.icon}>
            <div
              className={
                styles[!!assessmentData?.inProgressDate ? 'check-points' : 'check-points-2']
              }
            />
          </div>
          <div className={styles.div}>
            <div className={styles['text-wrapper']}>In progress</div>
            <div className={styles['text-wrapper-2']}>
              {!!assessmentData?.inProgressDate
                ? format(new Date(assessmentData?.inProgressDate), 'dd MMM yyyy, kk:mm') + ' hrs'
                : ''}
            </div>
          </div>
        </div>
        <div className={styles.request}>
          <div className={styles.icon}>
            <div
              className={
                styles[!!assessmentData?.completedDate ? 'check-points' : 'check-points-2']
              }
            />
          </div>
          <div className={styles.div}>
            <div className={styles['text-wrapper']}>Completed</div>
            <div className={styles['text-wrapper-2']}>
              {!!assessmentData?.completedDate
                ? format(new Date(assessmentData?.completedDate), 'dd MMM yyyy, kk:mm') + ' hrs'
                : ''}
            </div>
          </div>
        </div>
        <div className={styles.request}>
          <div className={styles.icon}>
            <div
              className={styles[!!assessmentData?.acceptedDate ? 'check-points' : 'check-points-2']}
            />
          </div>
          <div className={styles.div}>
            <div className={styles['text-wrapper']}>Accepted</div>
            <div className={styles['text-wrapper-2']}>
              {!!assessmentData?.acceptedDate
                ? format(new Date(assessmentData?.acceptedDate), 'dd MMM yyyy, kk:mm') + ' hrs'
                : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

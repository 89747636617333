import React from 'react'
// import { Title } from './sections/Title'
import { Box } from '@material-ui/core'
import { ListItem } from './sections/Content/ListItem'
import styles from './style.module.css'
import { RemediationListItem } from './sections/Content/RemediationListItem'
import { EnumAssessmentTypes } from 'src/utils/commonFns'

export const List = ({ DrawerType, listData }) => {
  console.log(listData)
  return (
    <Box className={styles.list}>
      {/* <Title /> */}
      <Box className={styles.content}>
        {(listData ?? []).map((row, index) => {
          return DrawerType === EnumAssessmentTypes.RemediationsPendingApproval ? (
            <RemediationListItem
              key={index + 'remediationitem'}
              DrawerType={DrawerType}
              eachGap={row}
            />
          ) : (
            <ListItem key={index + 'listitem'} DrawerType={DrawerType} row={row} />
          )
        })}
      </Box>
    </Box>
  )
}

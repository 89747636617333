import React from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { InfoIcon } from 'src/v2/icons/info'
import { formatKValue } from 'src/utils/commonFns'

export const OverallStatus = ({ darkWebStats }) => {
  return (
    <div className={styles['overall-status']}>
      <div className={styles['section-2']}>
        <div className={styles['title-data-2']}>Overall status</div>
        <IconPlaceholder
          icon={<InfoIcon className={styles['icons-6']} color="#7F8082" />}
          size="twenty-four-x-24"
        />
      </div>
      <div className={styles['frame-2']}>
        <div className={styles['section-3']}>
          <div className={styles['card']}>
            <div className={styles['content']}>
              <div className={styles['value-icon']}>
                <div className={styles['text-wrapper-7']}>No of Compromized endpoints</div>
                <img
                  className={styles['img-2']}
                  alt="No encryption"
                  src="https://c.animaapp.com/gSEqlFW2/img/no-encryption@2x.png"
                />
              </div>
              <div className={styles['value-9']}>
                <div className={styles['text-wrapper-8']}>NA</div>
              </div>
            </div>
            <div className={styles['rectangle-4']} />
          </div>
          <div className={styles['card']}>
            <div className={styles['content']}>
              <div className={styles['value-icon']}>
                <div className={styles['text-wrapper-9']}>No of Darkweb mentions</div>
                <img
                  className={styles['img-2']}
                  alt="Alternate email"
                  src="https://c.animaapp.com/gSEqlFW2/img/alternate-email@2x.png"
                />
              </div>
              <div className={styles['value-9']}>
                <div className={styles['text-wrapper-8']}>NA</div>
              </div>
            </div>
            <div className={styles['rectangle-4']} />
          </div>
        </div>
        <div className={styles['section-3']}>
          <div className={styles['card']}>
            <div className={styles['content']}>
              <div className={styles['value-icon']}>
                <p className={styles['text-wrapper-7']}>No of Employee leaked credentials</p>
                <img
                  className={styles['img-2']}
                  alt="Password"
                  src="https://c.animaapp.com/gSEqlFW2/img/password@2x.png"
                />
              </div>
              <div className={styles['value-9']}>
                <div className={styles['text-wrapper-8']}>
                  {formatKValue(darkWebStats.breachCount)}
                </div>
              </div>
            </div>
            <div className={styles['rectangle-4']} />
          </div>
          <div className={styles['card']}>
            <div className={styles['content']}>
              <div className={styles['value-icon']}>
                <p className={styles['p']}>No of Look alike websites</p>
                <img
                  className={styles['img-2']}
                  alt="Captive portal"
                  src="https://c.animaapp.com/gSEqlFW2/img/captive-portal@2x.png"
                />
              </div>
              <div className={styles['value-9']}>
                <div className={styles['text-wrapper-8']}>
                  {formatKValue(darkWebStats.lookAlikeWebsitesCount)}
                </div>
              </div>
            </div>
            <div className={styles['rectangle-4']} />
          </div>
        </div>
      </div>
    </div>
  )
}

import axios from 'axios'
import AppConfig from '../config'
import { hasValue } from '../utils/commonFns'

const baseURL = `${AppConfig.API_URL}/ClientSubmissions`

const getClientSubmissions = async (nameFilter, displayStatus, recent, highRisk) => {
  try {
    var url = `${baseURL}?`

    var queryParams = new URLSearchParams()

    if (hasValue(nameFilter)) queryParams.set('name', nameFilter)

    if (hasValue(displayStatus)) queryParams.set('displayStatus', displayStatus)

    if (hasValue(recent)) queryParams.set('recent', recent)

    if (hasValue(highRisk)) queryParams.set('isFromHighRisk', highRisk)

    let { data } = await axios.get(`${url}${queryParams.toString()}`)
    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getClientSubmission = async (id) => {
  try {
    let { data } = await axios.get(`${baseURL}/${id}`)
    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getClientSubmissionDetails = async (id) => {
  try {
    let { data } = await axios.get(`${baseURL}/details/${id}`)
    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const addClientSubmission = async (request) => {
  try {
    //let { data } = await axios.post(`${baseURL}`, JSON.stringify(request));

    let { data } = await axios.post(`${baseURL}`, request, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const updateClientSubmission = async (request) => {
  try {
    // let { data } = await axios.put(`${baseURL}`, JSON.stringify(request));

    let { data } = await axios.put(`${baseURL}`, request, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const deleteClientSubmission = async (id) => {
  try {
    let { data } = await axios.delete(`${baseURL}/${id}`)
    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export {
  getClientSubmissions,
  addClientSubmission,
  getClientSubmission,
  updateClientSubmission,
  getClientSubmissionDetails,
  deleteClientSubmission,
}

import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import {
  InputAdornment,
  InputBase,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from '@mui/material'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { SearchIcon } from 'src/v2/icons/search-icon'
import classes from './style.module.scss'
import { getComparator, stableSort } from 'src/utils/commonFns'
import { visuallyHidden } from '@mui/utils'
import BeaconerLogo from 'src/v2/assets/images/beaconer-logo.svg'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'src/context/login'
import { getCyberScanVendorList } from 'src/services/cyberScanService'
import TableRowsLoader from 'src/v2/components/Table/TableRowsLoader'

export const VendorsList = () => {
  const { user } = useAuthState()

  const [searchText, setSearchText] = useState('')
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('assessmentNumber')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [vendorList, setVendorList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  let navigate = useNavigate()

  const headCells = [
    {
      name: 'VENDOR NAME',
      disableSort: false,
    },
    {
      name: 'RISK SCORE',
      disableSort: false,
    },
    {
      name: 'EMPLOYEE LEAKED CREDENTIALS',
      disableSort: true,
    },
    {
      name: 'LOOK ALIKE WEBSITES',
      disableSort: false,
    },
    {
      name: 'SECURITY FINDINGS',
      disableSort: false,
    },
    {
      name: 'IMPACTED ASSETS',
      disableSort: false,
    },
  ]

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const visibleRows = React.useMemo(
    () =>
      stableSort(vendorList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [vendorList, order, orderBy, page, rowsPerPage],
  )

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    getVendorsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getVendorsList = async () => {
    try {
      setIsLoading(true)
      const data = await getCyberScanVendorList(user.businessOrganizationId)
      setVendorList(data)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles['table-1']}>
      <div className={styles.frame}>
        <div className={styles['title-4']}>
          <div className={styles['title-5']}>Vendor list ({vendorList.length})</div>
        </div>
        <div className={styles['search-filter']}>
          <InputBase
            sx={{ height: 36 }}
            className={`${styles['search-bar']}`}
            startAdornment={
              <InputAdornment position="start">
                <IconPlaceholder
                  icon={<SearchIcon className={styles['icons-10']} />}
                  size="twenty-four-x-24"
                />
              </InputAdornment>
            }
            placeholder="Search Vendor"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
              // if (e.target.value) {
              //   setVendorList(
              //     initialData.filter((x) =>
              //       x.vendorName.toLowerCase().includes(e.target.value.toLowerCase()),
              //     ),
              //   )
              // } else {
              //   setVendorList(initialData)
              // }
            }}
          ></InputBase>
        </div>
      </div>
      <div className={classes['vendor-list']}>
        <Box className={classes.content}>
          <TableContainer className={classes.table}>
            <Table size={'medium'}>
              <TableHead className={classes.tablehead}>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      className={classes.tableheadcell}
                      key={headCell.name}
                      sortDirection={orderBy === headCell.name ? order : false}
                    >
                      {!headCell.disableSort ? (
                        <TableSortLabel
                          active={orderBy === headCell.name}
                          direction={orderBy === headCell.name ? order : 'asc'}
                          onClick={() => handleRequestSort(headCell.name)}
                          className={classes.tablesortlabel}
                        >
                          {headCell.name}
                          {orderBy === headCell.name ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        <>{headCell.name}</>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {/* <div className={classes.tablebody}> */}
              <TableBody className={classes.tablebody}>
                {isLoading ? (
                  <>
                    <TableRowsLoader rows={2} columns={6} tableCellClassName={classes.tablecell} />
                  </>
                ) : visibleRows.length > 0 ? (
                  visibleRows.map((row, index) => (
                    <TableRow
                      key={index}
                      className={`${'menu-links'} ${classes['vendor-row']}`}
                      onClick={() => {
                        navigate(`/cyberscanning/${row.insecureWebScanId}`)
                      }}
                    >
                      <TableCell className={`${classes.tablecell}`}>
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          {row.logoUrl && (
                            <img className={classes['img-3']} alt="Avatar" src={row.logoUrl} />
                          )}
                          {!row.logoUrl && (
                            <img className={classes['img-3']} alt="Avatar" src={BeaconerLogo} />
                          )}
                          {row.vendorName}
                        </div>
                      </TableCell>
                      <TableCell
                        className={`${classes.tablecell} ${
                          row.riskScore < 40
                            ? classes['statuslow']
                            : row.riskScore > 40 && row.riskScore < 70
                            ? classes['statusmedium']
                            : classes['statushigh']
                        }`}
                        style={{ textAlign: 'center' }}
                      >
                        {row.riskScore}
                      </TableCell>
                      <TableCell className={classes.tablecell} style={{ alignItems: 'center' }}>
                        <div className={classes['leaked-credentials']}>
                          {/* <span className={classes['text-wrapper-20']}>Leaked Credentials: </span> */}
                          <span className={classes['text-wrapper-21']}>
                            {row.employeeLeakedCredsCount}
                          </span>
                          {/* <span className={classes['text-wrapper-20']}>&nbsp;</span>
                          <span className={classes['text-wrapper-22']}>
                            of {row.totalEmployeeCredentials}
                          </span> */}
                        </div>
                      </TableCell>
                      <TableCell className={classes.tablecell} style={{ textAlign: 'center' }}>
                        {row.lookAlikeWebsitesCount}
                      </TableCell>
                      <TableCell className={classes.tablecell}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'center',
                          }}
                        >
                          {row.securityFindingCount > 15 ? (
                            <img
                              className={classes['arrow-drop-down']}
                              alt="Arrow drop down"
                              src="https://c.animaapp.com/gSEqlFW2/img/arrow-drop-down-8@2x.png"
                            />
                          ) : row.securityFindingCount > 0 ? (
                            <img
                              className={classes['arrow-drop-down']}
                              alt="Arrow drop down"
                              src="https://c.animaapp.com/gSEqlFW2/img/arrow-drop-down-7@2x.png"
                            />
                          ) : null}
                          <div>{row.securityFindingCount}</div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.tablecell}>{row.impactedAssetsCount}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      className={classes.tablecell}
                      style={{ textAlign: 'center' }}
                    >
                      <h6 className={styles.norecords}>No Records to Display</h6>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {/* </div> */}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={vendorList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.tablepagination}
            classes={{
              selectLabel: classes.tablePaginationSelectLabel,
              displayedRows: classes.tablePaginationDisplayedRows,
              selectIcon: classes.tablePaginationSelectIcon,
              actions: classes.tablePaginationActions,
            }}
          />
        </Box>
      </div>
    </div>
  )
}

import React, { useCallback, useEffect, useState } from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { InfoIcon } from 'src/v2/icons/info'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  debounce,
} from '@mui/material'
import classes from './style.module.scss'
import { capitalizeFirstLetter, hasValue } from 'src/utils/commonFns'
import { getEntityAssetFindings } from 'src/services/cyberScanService'
import { menus } from '../../assets-list'
import TableRowsLoader from 'src/v2/components/Table/TableRowsLoader'
import moment from 'moment'

export const AssetDetailsSecurityFindings = ({ assessmentInfo, assetInfo, selectedMenu }) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [findings, setFindings] = useState([])
  const [loading, setLoading] = useState(false)

  const headCells = [
    {
      name: 'SECURITY FINIDS',
      disableSort: true,
    },
    {
      name: 'SEVERITY',
      disableSort: true,
    },
    {
      name: 'LAST DETECTED',
      disableSort: true,
    },
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const loadSecurityFindingList = async (entityId, domain, ip_address, page, rowsPerPage) => {
    try {
      if (!hasValue(entityId)) return
      setLoading(true)
      // let data = null
      const data = await getEntityAssetFindings(
        entityId,
        selectedMenu === menus.Domain ? domain : ip_address,
        page,
        rowsPerPage,
      )

      // if () {
      // } else if (selectedMenu === menus['IP Addresses']) {
      //   data = await getEntityDomainIPAddress(entityId, ip_address, page, rowsPerPage)
      // }
      if (data?.results?.length > 0) {
        setFindings(data.results)
      } else {
        setFindings([])
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    processDebounce(
      assessmentInfo.coalitionEntityId,
      assetInfo.domain,
      assetInfo.ip_address,
      page,
      rowsPerPage,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentInfo.coalitionEntityId, assetInfo.domain, assetInfo.ip_address, page, rowsPerPage])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const processDebounce = useCallback(
    debounce((coalitionEntityId, domain, ip_address, page, rowsPerPage) => {
      loadSecurityFindingList(coalitionEntityId, domain, ip_address, page, rowsPerPage)
    }, 400), // Adjust delay as needed
    [], // Dependencies
  )

  return (
    <div className={styles.frametab2}>
      <div className={styles.section}>
        <div className={styles.div}>
          <div className={styles['title-data']}>Security findings</div>
          <IconPlaceholder
            icon={
              <InfoIcon
                className={styles['icon-instance-node']}
                color="#7F8082"
                height={18}
                width={18}
              />
            }
            size="twenty-four-x-24"
          />
        </div>
        <div className={styles.pagination}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={findings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.tablepagination}
            classes={{
              selectLabel: classes.tablePaginationSelectLabel,
              displayedRows: classes.tablePaginationDisplayedRows,
              selectIcon: classes.tablePaginationSelectIcon,
              actions: classes.tablePaginationActions,
            }}
          />
        </div>
      </div>
      <div className={classes['table-wrapper']}>
        <Box className={classes.content}>
          <TableContainer className={classes.table}>
            <Table size={'medium'}>
              <TableHead className={classes.tablehead}>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell className={classes.tableheadcell} key={headCell.name}>
                      <>{headCell.name}</>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tablebody}>
                {loading ? (
                  <TableRowsLoader rows={5} columns={3} tableCellClassName={classes.tablecell} />
                ) : findings.length > 0 ? (
                  findings.map((row, index) => (
                    <TableRow key={index} className={`${'menu-links'} ${classes['vendor-row']}`}>
                      <TableCell className={classes.tablecell}>{row.description}</TableCell>
                      <TableCell
                        className={`${classes.tablecell} ${classes[row.level.toLowerCase()]}`}
                      >
                        {capitalizeFirstLetter(row.level)}
                      </TableCell>
                      <TableCell className={classes.tablecell}>
                        {moment(row.updated).format('MMM DD, YYYY')}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      className={classes.tablecell}
                      style={{ textAlign: 'center' }}
                    >
                      <h6 className={styles.norecords}>No Records to Display</h6>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  )
}

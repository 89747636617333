import axios from 'axios'
import AppConfig from '../config'

const baseURL = `${AppConfig.API_URL}/Remediation`

const initiateRemediation = async (request) => {
  try {
    let { data } = await axios.post(`${baseURL}`, request)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getRemediations = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/${orgId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getRemediationsV2 = async (orgId, searchQuery, status, startDate, endDate) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/v2/${orgId}?searchQuery=${searchQuery}&status=${status}&startDate=${startDate}&endDate=${endDate}`,
    )
    return data
  } catch (error) {
    console.log(error)
  }
}

const getRemediationsByClientSubmissionIdV2 = async (clientSubmissionId) => {
  try {
    let { data } = await axios.get(`${baseURL}/client-submission/${clientSubmissionId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getGapsByRemediationID = async (remediationId) => {
  try {
    let { data } = await axios.get(`${baseURL}/get-gaps-by-remediation/${remediationId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getEvidence = async (remediationGapID) => {
  try {
    let { data } = await axios.get(`${baseURL}/get-evidence/${remediationGapID}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const updateRemediationGapStatus = async (request) => {
  try {
    let { data } = await axios.put(`${baseURL}/update-remediation-gap-status`, request)
    return data
  } catch (error) {
    console.log(error)
  }
}

const postNewQuery = async (request) => {
  try {
    let { data } = await axios.post(`${baseURL}/post-query`, request, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (error) {
    console.log(error)
  }
}

const getQuery = async (remediationGapID) => {
  try {
    let { data } = await axios.get(`${baseURL}/get-query?remediationGapID=${remediationGapID}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

export {
  initiateRemediation,
  getRemediations,
  getGapsByRemediationID,
  getEvidence,
  updateRemediationGapStatus,
  getRemediationsV2,
  postNewQuery,
  getQuery,
  getRemediationsByClientSubmissionIdV2,
}

import React, { useEffect, useState } from 'react'
import classes from './style.module.scss'
import CTooltip from 'src/v2/components/Tooltip/Tooltip'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from '@mui/material'
import InfoIcon from 'src/v2/screens/dashboard/icons/InfoIcon.svg'
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import moment from 'moment'
import { visuallyHidden } from '@mui/utils'
import { getResidualRiskVendors } from 'src/services/riskPostureService'
import TableRowsLoader from 'src/v2/components/Table/TableRowsLoader'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

// const mockData = [
//   {
//     assessmentNumber: 'BTHJ0001805',
//     vendorName: 'Slack',
//     residualRisk: 'High',
//     department: 'Data Privacy',
//     completedOn: '2023/04/16',
//     initiatedOn: '2023/04/06',
//   },
//   {
//     assessmentNumber: 'BTHJ0001806',
//     vendorName: 'Zoom',
//     residualRisk: 'Medium',
//     department: 'IT',
//     completedOn: '2023/04/17',
//     initiatedOn: '2023/04/09',
//   },
//   {
//     assessmentNumber: 'BTHJ0001807',
//     vendorName: 'Google Drive',
//     residualRisk: 'Low',
//     department: 'Marketing',
//     completedOn: '2023/04/18',
//     initiatedOn: '2023/04/08',
//   },
//   {
//     assessmentNumber: 'BTHJ0001808',
//     vendorName: 'Microsoft Teams',
//     residualRisk: 'High',
//     department: 'Sales',
//     completedOn: '2023/04/19',
//     initiatedOn: '2023/04/11',
//   },
//   {
//     assessmentNumber: 'BTHJ0001809',
//     vendorName: 'Dropbox',
//     residualRisk: 'Medium',
//     department: 'HR',
//     completedOn: '2023/04/20',
//     initiatedOn: '2023/04/10',
//   },
//   {
//     assessmentNumber: 'BTHJ0001810',
//     vendorName: 'Trello',
//     residualRisk: 'Low',
//     department: 'Product',
//     completedOn: '2023/04/21',
//     initiatedOn: '2023/04/12',
//   },
//   {
//     assessmentNumber: 'BTHJ0001811',
//     vendorName: 'Asana',
//     residualRisk: 'High',
//     department: 'Engineering',
//     completedOn: '2023/04/22',
//     initiatedOn: '2023/04/13',
//   },
//   {
//     assessmentNumber: 'BTHJ0001812',
//     vendorName: 'Airtable',
//     residualRisk: 'Medium',
//     department: 'Finance',
//     completedOn: '2023/04/23',
//     initiatedOn: '2023/04/14',
//   },
//   {
//     assessmentNumber: 'BTHJ0001813',
//     vendorName: 'Notion',
//     residualRisk: 'Low',
//     department: 'Operations',
//     completedOn: '2023/04/24',
//     initiatedOn: '2023/04/15',
//   },
//   {
//     assessmentNumber: 'BTHJ0001814',
//     vendorName: 'Zeon',
//     residualRisk: 'High',
//     department: 'Security',
//     completedOn: '2023/04/25',
//     initiatedOn: '2023/04/16',
//   },
//   {
//     assessmentNumber: 'BTHJ0001815',
//     vendorName: 'Salesforce',
//     residualRisk: 'Medium',
//     department: 'Customer Support',
//     completedOn: '2023/04/26',
//     initiatedOn: '2023/04/17',
//   },
//   {
//     assessmentNumber: 'BTHJ0001816',
//     vendorName: 'Hubspot',
//     residualRisk: 'Low',
//     department: 'Marketing',
//     completedOn: '2023/04/27',
//     initiatedOn: '2023/04/18',
//   },
//   {
//     assessmentNumber: 'BTHJ0001817',
//     vendorName: 'Marketo',
//     residualRisk: 'High',
//     department: 'Sales',
//     completedOn: '2023/04/28',
//     initiatedOn: '2023/04/19',
//   },
//   {
//     assessmentNumber: 'BTHJ0001818',
//     vendorName: 'Pardot',
//     residualRisk: 'Medium',
//     department: 'IT',
//     completedOn: '2023/04/29',
//     initiatedOn: '2023/04/20',
//   },
//   {
//     assessmentNumber: 'BTHJ0001819',
//     vendorName: 'Mailchimp',
//     residualRisk: 'Low',
//     department: 'Marketing',
//     completedOn: '2023/04/30',
//     initiatedOn: '2023/04/21',
//   },
//   {
//     assessmentNumber: 'BTHJ0001820',
//     vendorName: 'Constant Contact',
//     residualRisk: 'High',
//     department: 'Sales',
//     completedOn: '2023/05/01',
//     initiatedOn: '2023/04/22',
//   },
//   {
//     assessmentNumber: 'BTHJ0001821',
//     vendorName: 'Campaign Monitor',
//     residualRisk: 'Medium',
//     department: 'Marketing',
//     completedOn: '2023/05/02',
//     initiatedOn: '2023/04/20',
//   },
//   {
//     assessmentNumber: 'BTHJ0001822',
//     vendorName: 'Klaviyo',
//     residualRisk: 'Low',
//     department: 'Ecommerce',
//     completedOn: '2023/05/03',
//     initiatedOn: '2023/04/22',
//   },
//   {
//     assessmentNumber: 'BTHJ0001823',
//     vendorName: 'Sailthru',
//     residualRisk: 'High',
//     department: 'Marketing',
//     completedOn: '2023/05/04',
//     initiatedOn: '2023/04/22',
//   },
//   {
//     assessmentNumber: 'BTHJ0001824',
//     vendorName: 'Adobe Campaign',
//     residualRisk: 'Medium',
//     department: 'Sales',
//     completedOn: '2023/05/05',
//     initiatedOn: '2023/04/22',
//   },
//   {
//     assessmentNumber: 'BTHJ0001832',
//     vendorName: 'Delta Labs',
//     residualRisk: 'High',
//     department: 'Marketing',
//     completedOn: '2023/05/04',
//     initiatedOn: '2023/04/22',
//   },
//   {
//     assessmentNumber: 'BTHJ0001833',
//     vendorName: 'Zenco',
//     residualRisk: 'High',
//     department: 'Marketing',
//     completedOn: '2023/05/04',
//     initiatedOn: '2023/04/22',
//   },
//   {
//     assessmentNumber: 'BTHJ0001835',
//     vendorName: 'Genesis',
//     residualRisk: 'High',
//     department: 'Finance',
//     completedOn: '2023/05/04',
//     initiatedOn: '2023/04/22',
//   },
// ]

const headCells = [
  {
    name: 'ASSESSMENT REPORT',
    disableSort: false,
  },
  {
    name: 'VENDOR NAME',
    disableSort: false,
  },
  {
    name: 'RESIDUAL RISK',
    disableSort: false,
  },
  {
    name: 'DEPARTMENT',
    disableSort: false,
  },
  {
    name: 'INITIATED ON',
    disableSort: false,
  },
  {
    name: 'COMPLETED ON',
    disableSort: false,
  },
  // {
  //   name: 'REPORT CARD',
  //   disableSort: true,
  // },
]

export const VendorList = ({ businessOrganizationId }) => {
  const getRiskStatusCls = (risk) => {
    if (risk === 'Low') {
      return classes.statuslow
    } else if (risk === 'Medium') {
      return classes.statusmedium
    } else if (risk === 'High') {
      return classes.statushigh
    }
  }

  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('assessmentNumber')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [isListLoading, setIsListLoading] = useState(false)
  const [assessmentCount, setAssessmentCount] = useState(0)
  const [assessmentsData, setAssessmentsData] = useState([])

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const visibleRows = React.useMemo(
    () =>
      stableSort(assessmentsData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [assessmentsData, order, orderBy, page, rowsPerPage],
  )

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const loadAssessments = async () => {
    setIsListLoading(true)
    var result = await getResidualRiskVendors(businessOrganizationId)
    setIsListLoading(false)
    if (result && result.length > 0) {
      setAssessmentCount(result.length)
      setAssessmentsData(result)
    } else {
      setAssessmentsData([])
    }
  }

  useEffect(() => {
    loadAssessments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes['vendor-list']}>
      <div className={classes.section}>
        <div className={classes.title}>
          <div className={classes['title-data']}>Vendor List ({assessmentCount})</div>
          <div className={classes.iconPlaceholder}>
            <CTooltip
              arrow
              title={
                <React.Fragment>
                  <Typography variant="body2" fontWeight={500} sx={{ mb: '4px' }}>
                    Remediation Status
                  </Typography>
                  <Typography variant="body2">
                    A Vendor Assessment Risk Metrics Chart is a visual tool that summarizes the
                    overall risk level of evaluated vendors. It combines various risk indicators.
                  </Typography>
                </React.Fragment>
              }
            >
              <img className={classes.icons} loading="lazy" alt="" src={InfoIcon} />
            </CTooltip>
          </div>
        </div>
      </div>
      <Box className={classes.content}>
        <TableContainer className={classes.table}>
          <Table size={'medium'}>
            <TableHead className={classes.tablehead}>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    className={classes.tableheadcell}
                    key={headCell.name}
                    sortDirection={orderBy === headCell.name ? order : false}
                  >
                    {!headCell.disableSort ? (
                      <TableSortLabel
                        active={orderBy === headCell.name}
                        direction={orderBy === headCell.name ? order : 'asc'}
                        onClick={() => handleRequestSort(headCell.name)}
                        className={classes.tablesortlabel}
                      >
                        {headCell.name}
                        {orderBy === headCell.name ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      <>{headCell.name}</>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tablebody}>
              {isListLoading ? (
                <>
                  <TableRowsLoader rows={2} columns={6} />
                </>
              ) : !!visibleRows && visibleRows.length > 0 ? (
                visibleRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={`${classes.tablecell} ${classes.linkcell}`}>
                      {row.clientSubmissionNo}
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        {row.logoUrl && (
                          <img className={classes['img-3']} alt="Avatar" src={row.logoUrl} />
                        )}
                        {row.thirdPartyName}
                      </div>
                    </TableCell>
                    <TableCell
                      className={`${classes.tablecell} ${getRiskStatusCls(row.residualRisk)}`}
                    >
                      {row.residualRisk}
                    </TableCell>
                    <TableCell className={classes.tablecell}>{row.department}</TableCell>
                    <TableCell className={classes.tablecell}>
                      {moment(row.requestedDate).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                      {moment(row.completedDate).format('MM/DD/YYYY')}
                    </TableCell>
                    {/* <TableCell className={`${classes.tablecell} ${classes.linkcell}`}>
                    <VisibilityOutlinedIcon style={{ marginRight: '4px', marginTop: '-1px' }} />
                    <span>View</span>
                  </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    className={classes.tablecell}
                    colSpan={6}
                    style={{ textAlign: 'center' }}
                  >
                    No Records to Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={assessmentsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.tablepagination}
          classes={{
            selectLabel: classes.tablePaginationSelectLabel,
            displayedRows: classes.tablePaginationDisplayedRows,
            selectIcon: classes.tablePaginationSelectIcon,
            actions: classes.tablePaginationActions,
          }}
        />
      </Box>
    </div>
  )
}

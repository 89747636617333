import React, { useState } from 'react'
import styles from './style.module.css'
import { Modal, Tab, Tabs } from '@mui/material'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { CrossMark } from 'src/v2/icons/cross-mark'
import BeaconerLogo from 'src/v2/assets/images/beaconer-logo.svg'
import { AssetDetailOverview } from './overview'
import { AssetDetailsSecurityFindings } from './security-findings'
import moment from 'moment'
import { menus } from '../assets-list'
import { SimpleTable, TechnologyTableType } from './technologies/simple-table'

function CustomTabPanel(props) {
  const { value, index, assessmentInfo, assetInfo, selectedMenu } = props
  if ([menus.Domain, menus['IP Addresses']].includes(selectedMenu)) {
    if (value === 0 && index === 0)
      return (
        <AssetDetailOverview
          assessmentInfo={assessmentInfo}
          assetInfo={assetInfo}
          selectedMenu={selectedMenu}
        />
      )
    if (value === 1 && index === 1) {
      return (
        <AssetDetailsSecurityFindings
          assessmentInfo={assessmentInfo}
          assetInfo={assetInfo}
          selectedMenu={selectedMenu}
        />
      )
    }
  } else {
    if (value === 0 && index === 0)
      return <SimpleTable assetInfo={assetInfo} type={TechnologyTableType.Assets} />
    if (value === 1 && index === 1) {
      return <SimpleTable assetInfo={assetInfo} type={TechnologyTableType.CVEs} />
    }
  }
}

export const AssetDetails = ({
  open,
  handleAction,
  assetInfo,
  assessmentInfo,
  selectedMenu,
  selectedCategory,
}) => {
  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (event) => {
    setTabValue(parseInt(event.target.id))
  }

  return (
    <Modal open={open} className={styles.modal}>
      <div className={styles.modal}>
        <div className={styles.title}>
          <div className={styles.vendor}>
            <img
              className={styles.avatar}
              alt="Avatar"
              src={assessmentInfo.logoUrl || BeaconerLogo}
            />
            <div className={styles['text-wrapper']}>
              {assessmentInfo.vendorName} - {assessmentInfo.assessmentNumber}
            </div>
          </div>
          <IconPlaceholder
            icon={<CrossMark className={styles['icon-instance-node']} />}
            size="twenty-four-x-24"
            onClick={() => {
              handleAction(false)
            }}
          />
        </div>
        <div className={styles.frame}>
          <div className={styles['control-metrics']}>
            <div className={styles.innerframe}>
              <div className={styles['text-wrapper']}>
                {[menus.Domain, menus['IP Addresses']].includes(selectedMenu)
                  ? selectedMenu
                  : selectedCategory}
              </div>
              <div className={styles.div}>
                {selectedMenu === menus.Domain
                  ? assetInfo.domain
                  : selectedMenu === menus['IP Addresses']
                  ? assetInfo.ip_address
                  : assetInfo.tech_name}
              </div>
            </div>
            <div className={styles.metrics}>
              <div className={styles['sub-section']}>
                {selectedMenu === menus.Technologies ? (
                  <>
                    <div className={styles['metric-card']}>
                      <div className={styles['text-wrapper-2']}>Version</div>
                      <div className={styles.span}>
                        <div className={`${styles['text-wrapper-4']}`}>{assetInfo.version}</div>
                      </div>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles['metric-card']}>
                      <div className={styles['text-wrapper-2']}>Assets</div>
                      <div className={styles.span}>
                        <div className={`${styles['text-wrapper-4']}`}>
                          {assetInfo.assets?.length > 1
                            ? `${assetInfo.assets[0].asset} & ${assetInfo.assets.length - 1} more`
                            : assetInfo.assets?.length === 1
                            ? assetInfo.assets[0].asset
                            : '--'}
                        </div>
                      </div>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles['metric-card']}>
                      <div className={styles['text-wrapper-2']}>CVEs</div>
                      <div className={styles.span}>
                        <div className={`${styles['text-wrapper-4']}`}>
                          {assetInfo.cves?.length}
                        </div>
                      </div>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles['metric-card']}>
                      <div className={styles['text-wrapper-2']}>Categories</div>
                      <div className={styles.span}>
                        <div className={`${styles['text-wrapper-4']}`}>
                          {assetInfo.category?.join(', ')}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles['metric-card']}>
                      <div className={styles['text-wrapper-2']}>SOURCE</div>
                      <div className={styles.span}>
                        <div className={`${styles['text-wrapper-4']}`}>{assetInfo.source}</div>
                      </div>
                    </div>
                    <div className={styles.divider} />
                    {selectedMenu === menus['IP Addresses'] && (
                      <>
                        <div className={styles['metric-card']}>
                          <div className={styles['text-wrapper-2']}>AS</div>
                          <div className={styles.span}>
                            <div className={styles['text-wrapper-4']}>{assetInfo.as_name}</div>
                          </div>
                        </div>
                        <div className={styles.divider} />
                      </>
                    )}
                    <div className={styles['metric-card']}>
                      <div className={styles['text-wrapper-2']}>SECURITY FINDINGS</div>
                      <div className={styles.span}>
                        <div className={styles['text-wrapper-4']}>
                          {assetInfo.findings_count || 0}
                        </div>
                      </div>
                    </div>
                    {selectedMenu === menus['IP Addresses'] && (
                      <>
                        <div className={styles.divider} />
                        <div className={styles['metric-card']}>
                          <div className={styles['text-wrapper-2']}>Version</div>
                          <div className={styles.span}>
                            <div
                              className={styles['text-wrapper-4']}
                            >{`IPv${assetInfo.version}`}</div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className={styles.divider} />
                    <div className={styles['metric-card']}>
                      <div className={styles['text-wrapper-2']}>FIRST DETECTED</div>
                      <div className={styles.span}>
                        <div className={styles['text-wrapper-4']}>
                          {moment(assetInfo.first_detected).format('MMM DD, YYYY')}
                        </div>
                      </div>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles['metric-card']}>
                      <div className={styles['text-wrapper-2']}>LAST DETECTED</div>
                      <div className={styles.span}>
                        <div className={styles['text-wrapper-4']}>
                          {moment(assetInfo.updated).format('MMM DD, YYYY')}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.section}>
              <div className={styles['tab-navigation']}>
                <Tabs onClick={handleTabChange} value={tabValue}>
                  <Tab
                    className={styles['tab-nav-instance']}
                    label={
                      [menus.Domain, menus['IP Addresses']].includes(selectedMenu)
                        ? 'Details'
                        : 'Assets (' + (assetInfo.assets.length || 0) + ')'
                    }
                    id={0}
                    value={0}
                    style={{
                      flex: '0 0 auto !important',
                      textTransform: 'none',
                      padding: '8px 12px 16px !important',
                    }}
                  />
                  <Tab
                    className={styles['tab-nav-instance']}
                    label={
                      [menus.Domain, menus['IP Addresses']].includes(selectedMenu)
                        ? 'Security Findings (' + (assetInfo.findings_count || 0) + ')'
                        : 'CVEs (' + (assetInfo.cves.length || 0) + ')'
                    }
                    id={1}
                    value={1}
                    style={{
                      flex: '0 0 auto !important',
                      textTransform: 'none',
                      padding: '8px 12px 16px !important',
                    }}
                  />
                </Tabs>
              </div>
            </div>
            <CustomTabPanel
              value={tabValue}
              index={0}
              assessmentInfo={assessmentInfo}
              assetInfo={assetInfo}
              selectedMenu={selectedMenu}
            >
              1
            </CustomTabPanel>

            <CustomTabPanel
              value={tabValue}
              index={1}
              assessmentInfo={assessmentInfo}
              assetInfo={assetInfo}
              selectedMenu={selectedMenu}
            >
              2
            </CustomTabPanel>
          </div>
        </div>
      </div>
    </Modal>
  )
}

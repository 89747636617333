import React, { useEffect, useState } from 'react'
import classes from './style.module.css'
import CTooltip from 'src/v2/components/Tooltip/Tooltip'
import { Typography } from '@mui/material'
import InfoIcon from 'src/v2/screens/dashboard/icons/InfoIcon.svg'
import { EADoughnutChart } from 'src/v2/components/ea-donought'
import { DarkWebDoughnutChart } from 'src/v2/components/darkweb-donught'
import { useAuthState } from 'src/context/login'
import { getDarkWebScanStats } from 'src/services/insecureWebScanService'
import { getExternalAttackSurfaceStats } from 'src/services/cyberScanService'
import { formatKValue } from 'src/utils/commonFns'

export const CyberScore = () => {
  const [darkWebStats, setDarkWebStats] = useState({})
  const [externalAttackStats, setExternalAttackStats] = useState({})
  const [darWebLoading, setDarkWebLoading] = useState(false)
  const [externalAttackLoading, setExternalAttackLoading] = useState(false)
  const { user } = useAuthState()

  useEffect(() => {
    const fetchDarkWebStats = async () => {
      try {
        setDarkWebLoading(true)
        const data = await getDarkWebScanStats(user.businessOrganizationId)
        setDarkWebStats(data)
      } finally {
        setDarkWebLoading(false)
      }
    }

    const fetchExternalAttackSurfaceStats = async () => {
      try {
        setExternalAttackLoading(true)
        const data = await getExternalAttackSurfaceStats(user.businessOrganizationId)
        setExternalAttackStats(data)
      } finally {
        setExternalAttackLoading(false)
      }
    }

    fetchDarkWebStats()
    fetchExternalAttackSurfaceStats()
  }, [user.businessOrganizationId])

  return (
    <div className={classes['cyber-score']}>
      <div className={classes.section}>
        <div className={classes.title}>
          <div className={classes['title-data']}>Cyber Scanning score</div>
          <div className={classes.iconPlaceholder}>
            <CTooltip
              arrow
              title={
                <React.Fragment>
                  <Typography variant="body2" fontWeight={500} sx={{ mb: '4px' }}>
                    Remediation Status
                  </Typography>
                  <Typography variant="body2">
                    A Vendor Assessment Risk Metrics Chart is a visual tool that summarizes the
                    overall risk level of evaluated vendors. It combines various risk indicators.
                  </Typography>
                </React.Fragment>
              }
            >
              <img className={classes.icons} loading="lazy" alt="" src={InfoIcon} />
            </CTooltip>
          </div>
        </div>
        <p className={classes['order-info']}>Jun 2022 - Jun 2024</p>
      </div>
      <div className={classes.row}>
        <div className={classes.darkwebscore}>
          <div className={classes['speed-chart']}>
            <div className={classes['overlap-wrapper']}>
              <DarkWebDoughnutChart
                lookalikeWebsites={darkWebStats.lookAlikeWebsitesCount}
                totalVendors={darkWebStats.totalVendors}
                domain={darkWebStats.domainCount}
                email={darkWebStats.emailCount}
                ipAddress={darkWebStats.ipaddressCount}
                userName={darkWebStats.userNameCount}
                phone={darkWebStats.phoneCount}
                tootltipEnabled={true}
                left={'80px'}
                top={'100px'}
                loading={darWebLoading}
              />
            </div>
          </div>
          <div className="label">
            <div className="text-wrapper">Dark web Intelligence</div>
          </div>
          <div className={classes['legends']}>
            <div className={classes['div-2']}>
              <div className={classes['rectangle']} />
              <div className={classes['text-wrapper-11']}>
                Look-alike websites - {formatKValue(darkWebStats.lookAlikeWebsitesCount)}{' '}
              </div>
            </div>
            <div className={classes['div-2']}>
              <div className={classes['rectangle-2']} />
              <div className={classes['text-wrapper-11']}>
                Breach - {formatKValue(darkWebStats.breachCount)}{' '}
              </div>
            </div>
          </div>
        </div>
        <div className={classes['chart-4']}>
          <div className={classes['speed-chart']}>
            <div className={classes['overlap']}>
              <EADoughnutChart
                value={externalAttackStats.riskScore || 0}
                loading={externalAttackLoading}
              />
            </div>
          </div>
          <div className="label">
            <div className="text-wrapper">Attack surface management</div>
          </div>
          <div className={classes['legends']}>
            <div className={classes['div-2']}>
              <div className={classes['rectangle-5']} />
              <div className={classes['text-wrapper-11']}>Low (&lt;40)</div>
            </div>
            <div className={classes['div-2']}>
              <div className={classes['rectangle-6']} />
              <div className={classes['text-wrapper-11']}>Medium (40-70)</div>
            </div>
            <div className={classes['div-2']}>
              <div className={classes['rectangle-7']} />
              <div className={classes['text-wrapper-11']}>High (&gt;70)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

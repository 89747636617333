import React from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { InfoIcon } from 'src/v2/icons/info'
import { DarkWebDoughnutChart } from 'src/v2/components/darkweb-donught'
import { formatKValue } from 'src/utils/commonFns'

export const DarkWebScore = ({ darkWebStats, loading = false }) => {
  return (
    <div className={styles['dark-web-score']}>
      <div className={styles['section-wrapper']}>
        <div className={styles.title}>
          <div className={styles['title-data']}>Darkweb Intelligence</div>
          <IconPlaceholder
            icon={<InfoIcon className={styles['icons-6']} color="#7F8082" />}
            size="twenty-four-x-24"
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles['overlap-wrapper']}>
            <DarkWebDoughnutChart
              lookalikeWebsites={darkWebStats.lookAlikeWebsitesCount}
              totalVendors={darkWebStats.totalVendors}
              domain={darkWebStats.domainCount}
              email={darkWebStats.emailCount}
              ipAddress={darkWebStats.ipaddressCount}
              userName={darkWebStats.userNameCount}
              phone={darkWebStats.phoneCount}
              loading={loading}
            />
          </div>
          <div className={styles.cards}>
            <div className={styles['value-2']}>
              <div className={styles.rectangle} />
              <div className={styles['value-3']}>
                <div className={styles['value-4']}>
                  <div className={styles['text-wrapper2']}>Look-alike websites</div>
                </div>
                <div className={styles['value-5']}>
                  <div className={styles['text-wrapper3']}>
                    {formatKValue(darkWebStats.lookAlikeWebsitesCount)}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['value-2']}>
              <div className={styles['rectangle-2']} />
              <div className={styles['value-6']}>
                <div className={styles['value-4']}>
                  <div className={styles['text-wrapper2']}>Breach</div>
                </div>
                <div className={styles['value-5']}>
                  <div className={styles['text-wrapper3']}>
                    {formatKValue(darkWebStats.breachCount)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.legends}>
          <div className={styles['value-7']}>
            <div className={styles.high}>
              <div className={styles['value-8']}>
                <div className={styles['ellipse-2']} />
                <div className={styles['text-wrapper-4']}>Domain</div>
              </div>
            </div>
            <div className={styles.number}>
              <div className={styles['text-wrapper-5']}>
                {formatKValue(darkWebStats.domainCount) || 0}
              </div>
            </div>
          </div>
          <div className={styles['rectangle-3']} />
          <div className={styles['value-7']}>
            <div className={styles.high}>
              <div className={styles['value-8']}>
                <div className={styles['ellipse-3']} />
                <div className={styles['text-wrapper-4']}>P.Email</div>
              </div>
            </div>
            <div className={styles.number}>
              <div className={styles['text-wrapper-5']}>
                {formatKValue(darkWebStats.emailCount) || 0}
              </div>
            </div>
          </div>
          <div className={styles['rectangle-3']} />
          <div className={styles['value-7']}>
            <div className={styles['value-wrapper']}>
              <div className={styles['value-8']}>
                <div className={styles['ellipse-4']} />
                <div className={styles['text-wrapper-4']}>IP Address</div>
              </div>
            </div>
            <div className={styles.number}>
              <div className={styles['text-wrapper-5']}>
                {formatKValue(darkWebStats.ipAddressCount) || 0}
              </div>
            </div>
          </div>
          <div className={styles['rectangle-3']} />
          <div className={styles['value-7']}>
            <div className={styles.high}>
              <div className={styles['value-8']}>
                <div className={styles['ellipse-5']} />
                <div className={styles['text-wrapper-4']}>Username</div>
              </div>
            </div>
            <div className={styles['number-2']}>
              <div className={styles['text-wrapper-6']}>
                {formatKValue(darkWebStats.userNameCount) || 0}
              </div>
            </div>
          </div>
          <div className={styles['rectangle-3']} />
          <div className={styles['value-7']}>
            <div className={styles.high}>
              <div className={styles['value-8']}>
                <div className={styles['ellipse-6']} />
                <div className={styles['text-wrapper-4']}>Phone</div>
              </div>
            </div>
            <div className={styles.number}>
              <div className={styles['text-wrapper-5']}>
                {formatKValue(darkWebStats.phoneCount) || 0}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'
import styles from './ActionItem.module.scss'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'

const ActionItem = ({
  className = '',
  heading,
  icons,
  prop,
  propAlignSelf,
  propFlex,
  handleDrawerOpen,
}) => {
  const contentStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
      flex: propFlex,
    }
  }, [propAlignSelf, propFlex])

  return (
    <div className={[styles.content, className].join(' ')} style={contentStyle}>
      <div className={styles.reportParent}>
        <div className={styles.valueIcon}>
          <a className={styles.reportsPendingApprovalContainer}>
            <p className={styles.reports} onClick={handleDrawerOpen}>
              {heading.split('\\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </a>
          <div className={styles.reportPlaceholder}>
            <div className={styles.iconPlaceholder}>
              <img className={styles.icons} loading="lazy" alt="" src={icons} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.emptyActionParent}>
        <div className={styles.value}>
          <a className={styles.a} onClick={handleDrawerOpen}>
            {prop}
          </a>
          <ArrowForwardOutlinedIcon
            className={styles.icons1}
            fontSize="small"
            onClick={handleDrawerOpen}
          />
        </div>
      </div>
      <div className={styles.contentChild} />
    </div>
  )
}

export default ActionItem

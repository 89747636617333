import React from 'react'
import styles from './style.module.css'
import Chart from 'react-apexcharts'
import { formatKValue } from 'src/utils/commonFns'

export const EABarChart = ({ externalAttackStats, calcPercentage }) => {
  const series = [
    {
      data: [
        calcPercentage(externalAttackStats.criticalFindingsCount) || 0,
        calcPercentage(externalAttackStats.highFindingsCount) || 0,
        calcPercentage(externalAttackStats.mediumFindingsCount) || 0,
        calcPercentage(externalAttackStats.lowFindingsCount) || 0,
      ],
    },
  ]

  const options = useChartOptions(externalAttackStats)

  return (
    <div className={styles['chart-wrapper']}>
      <div className={styles['group-2']}>
        <Chart options={options} series={series} type="bar" height={300} width={'100%'} />
      </div>
      <label className={styles['text-wrapper-14']}>Total Findings</label>
    </div>
  )
}

const useChartOptions = (externalAttackStats) => {
  return {
    chart: {
      id: `ea-bar${Math.random()}`,
      type: 'bar',
      width: '100%', // Let chart fill 100% width of its container
      toolbar: {
        show: false, // Hide the menu (export/download)
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0, // Remove internal padding
      },
    },
    plotOptions: {
      bar: {
        distributed: true, // this line is mandatory
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: true,
        barHeight: '25%',
        dataLabels: {
          position: 'top',
        },
      },
    },
    xaxis: {
      categories: [0, 25, 75, 100],
      tickAmount: 4,
      min: 0,
      max: 100,
      axisBorder: {
        show: false, // Hide the x-axis border
      },
      axisTicks: {
        show: false, // Hide the ticks on the x-axis
      },
      labels: {
        show: true,
        style: {
          colors: 'rgb(162, 163, 164)',
          fontSize: '14px',
          fontFamily: '"SF Pro Text", Helvetica',
          fontWeight: 400,
        },
        formatter: (value) => `${value}%`,
      },
    },
    yaxis: {
      labels: {
        show: false, // Hide Y-axis labels
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 25,
      textAnchor: 'start',
      formatter: function (val, options) {
        if (options.dataPointIndex === 0) {
          return `${formatKValue(externalAttackStats.criticalFindingsCount) || 0} (${val}%)`
        } else if (options.dataPointIndex === 1) {
          return `${formatKValue(externalAttackStats.highFindingsCount) || 0} (${val}%)`
        } else if (options.dataPointIndex === 2) {
          return `${formatKValue(externalAttackStats.mediumFindingsCount) || 0} (${val}%)`
        } else if (options.dataPointIndex === 3) {
          return `${formatKValue(externalAttackStats.lowFindingsCount) || 0} (${val}%)`
        } else {
          return `${val}%`
        }
      },
      style: {
        fontSize: '14px',
        fontFamily: '"SF Pro Text", Helvetica',
        lineHeight: '18px',
      },
    },
    grid: {
      borderColor: '#383B3D', // Light color for the grid (can be transparent if needed)
      strokeDashArray: 4, // Dotted line for the grid
      xaxis: {
        lines: {
          show: true, // Show dotted lines for X-axis categories
        },
      },
      yaxis: {
        lines: {
          show: false, // Hide Y-axis grid lines
        },
      },
    },
    colors: ['#ef4d4f', '#ff7738', '#feaa32', '#09ca95'],
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import InfoIcon from '../../../icons/InfoIcon.svg'
import styles from './RiskHeatMap.module.scss'
import Plot from 'react-plotly.js'
import { getRiskMapScores } from 'src/services/dashboardService'

const RiskHeatMap = ({ orgId }) => {
  const [riskMapScores, setRiskMapScores] = useState({})
  const loadriskMapScores = async () => {
    const result = await getRiskMapScores(orgId)
    setRiskMapScores(result)
  }

  useEffect(() => {
    loadriskMapScores()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const data = [
    {
      x: riskMapScores?.low_RiskScanningScores ?? [],
      y: riskMapScores?.low_ControlTestingMetrics ?? [],
      mode: 'markers',
      type: 'scatter',
      name: 'Low',
      marker: { color: '#30AC79', size: 8 },
      showlegend: false,
    },
    {
      x: riskMapScores?.medium_RiskScanningScores ?? [],
      y: riskMapScores?.medium_ControlTestingMetrics ?? [],
      mode: 'markers',
      type: 'scatter',
      name: 'Medium',
      marker: { color: '#FF7738', size: 8 },
      showlegend: false,
    },
    {
      x: riskMapScores?.high_RiskScanningScores ?? [],
      y: riskMapScores?.high_ControlTestingMetrics ?? [],
      mode: 'markers',
      type: 'scatter',
      name: 'High',
      marker: { color: '#EF4D4F', size: 8 },
      showlegend: false,
    },
  ]

  function calculateRanges(data) {
    // const ranges = data.map((series) => {
    //   return {
    //     x0: Math.min(...series.x) - 2,
    //     x1: Math.max(...series.x) + 2,
    //     y0: Math.min(...series.y) - 2,
    //     y1: Math.max(...series.y) + 2,
    //     fillcolor: series.marker.color,
    //   }
    // })
    // console.log(ranges)
    // return ranges
    return [
      //high region
      {
        x0: 0,
        y0: 80,
        x1: 103,
        y1: 100,
        fillcolor: '#0C9D610D',
      },
      {
        x0: 0,
        y0: 0,
        x1: 33,
        y1: 80,
        fillcolor: '#0C9D610D',
      },
      // Medium region
      {
        x0: 33,
        y0: 40,
        x1: 103,
        y1: 80,
        fillcolor: '#FE9B0E0D',
      },
      {
        x0: 33,
        y0: 0,
        x1: 80,
        y1: 40,
        fillcolor: '#FE9B0E0D',
      },
      // Low region
      {
        x0: 80,
        y0: 0,
        x1: 103,
        y1: 40,
        fillcolor: '#EC2D300D',
      },
    ]
  }

  const layout = {
    config: {
      displayModeBar: false,
    },
    margin: { t: 20, b: 80, l: 50, r: 70 }, // Increased top and right margins
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    font: { color: '#E8E8E8' },
    shapes: [
      ...calculateRanges(data).map((range) => ({
        ...range,
        xref: 'x',
        yref: 'y',
        line: {
          width: 0,
        },
      })),
    ],
    xaxis: {
      title: {
        text: 'Cyber Risk Scanning scores',
        font: {
          color: '#E8E8E8', // X axis title color
          size: '12',
          weight: '400',
          family: '"SF Pro Text", Helvetica',
        },
        standoff: 30,
      },
      ticklen: 10,
      automargin: true,
      autorange: false,
      range: [103, 0],
      tickmode: 'array',
      tickvals: [0, 20, 40, 60, 80, 100],
      fixedrange: true,
      gridcolor: '#5D5E60',
      showticklabels: 'inside',
      linecolor: '#5D5E60',
    },
    yaxis: {
      title: {
        text: 'Control Testing Metrics',
        font: {
          color: '#E8E8E8', // X axis title color
          size: '12',
          weight: '400',
          family: '"SF Pro Text", Helvetica',
        },
        standoff: 20,
      },
      range: [0, 103],
      fixedrange: true,
      automargin: true,
      ticklen: 10,
      gridcolor: '#5D5E60',
      showticklabels: 'inside',
      linecolor: '#5D5E60',
    },
  }

  return (
    <Paper className={styles.section} elevation={3}>
      <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
        <div className={styles.section1}>
          <div className={styles.dataContainer}>
            <h3 className={styles.titleData}>{`Third - Party Risk Heat Map `}</h3>
          </div>
          <div className={styles.iconPlaceholder}>
            <img className={styles.icons} loading="lazy" alt="" src={InfoIcon} />
          </div>
        </div>
        <div className={styles.statuscontainer}>
          <div className={styles.sectionInner}>
            <div className={styles.frameChild} />
            <a className={styles.high}>High</a>
          </div>
          <div className={styles.sectionInner}>
            <div className={styles.frameItem} />
            <a className={styles.medium}>Medium</a>
          </div>
          <div className={styles.sectionInner}>
            <div className={styles.frameInner} />
            <a className={styles.low}>Low</a>
          </div>
        </div>
      </Stack>
      <Stack sx={{ width: '100%' }} className={styles.heatmapchart}>
        <Plot data={data} layout={layout} config={{ displayModeBar: false }} />
      </Stack>
    </Paper>
  )
}

export default RiskHeatMap

import React from 'react'
import styles from './styles.module.scss'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import InfoIcon from '../../../icons/InfoIcon.svg'
import Typography from '@mui/material/Typography'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#3A3C3E',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#5885E6',
  },
}))

const BusinessUnits = ({ businessUnitsData }) => {
  const businessUnits = businessUnitsData ?? []
  return (
    <Paper className={styles.section} elevation={3}>
      <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
        <div className={styles.section1}>
          <div className={styles.dataContainer}>
            <h3 className={styles.titleData}>{`Top 5 business units `}</h3>
          </div>
          <div className={styles.iconPlaceholder}>
            <img className={styles.icons} loading="lazy" alt="" src={InfoIcon} />
          </div>
        </div>
        <div className={styles.subHeading}>
          <p>Based on number of assesstments requested</p>
        </div>
        <Stack direction="column" spacing={3} sx={{ width: '100%' }}>
          {businessUnits.map((item, index) => (
            <Box key={index} className={styles.boxContainer}>
              <Typography variant="h5">{item.businessUnitName}</Typography>
              <Box className={styles.progressBox}>
                <div style={{ width: '100%', position: 'relative' }}>
                  <BorderLinearProgress variant="determinate" value={item.percentage} />
                  <Typography
                    variant="body"
                    className={styles.count}
                    style={{ left: `${item.percentage - 2}%`, position: 'absolute' }}
                  >
                    {item.count}
                  </Typography>
                </div>
                <Typography variant="body2" className={styles.percentage}>{`${Math.round(
                  item.percentage,
                )}%`}</Typography>
              </Box>
            </Box>
          ))}
        </Stack>
      </Stack>
    </Paper>
  )
}

export default BusinessUnits

import React, { useCallback, useEffect, useState } from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { InfoIcon } from 'src/v2/icons/info'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  debounce,
} from '@mui/material'
import classes from './style.module.scss'
import { hasValue } from 'src/utils/commonFns'
import { getImpactedAssetsList } from 'src/services/cyberScanService'
import TableRowsLoader from 'src/v2/components/Table/TableRowsLoader'

export const RiskProfileDetailImpactedAssets = ({ assessmentInfo, profileInfo }) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [impactedAssetData, setImpactedAssetData] = useState([])

  const headCells = [
    {
      name: 'ASSETS',
      disableSort: true,
    },
    {
      name: 'SOURCE',
      disableSort: true,
    },
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5))
    setPage(0)
  }

  const loadRecentImpactedAssets = async (coalitionEntityId, datapoint, page, rowsPerPage) => {
    try {
      if (!hasValue(coalitionEntityId) || !hasValue(datapoint)) return
      setLoading(true)
      const data = await getImpactedAssetsList(coalitionEntityId, datapoint, page, rowsPerPage)
      if (hasValue(data)) {
        var result = JSON.parse(data.json_Response)
        setImpactedAssetData(result.results)
        setTotalCount(result.count)
      } else {
        setImpactedAssetData([])
        setTotalCount(0)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    processDebounce(assessmentInfo.coalitionEntityId, profileInfo.datapoint, page, rowsPerPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentInfo.coalitionEntityId, profileInfo.datapoint, page, rowsPerPage])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const processDebounce = useCallback(
    debounce((coalitionEntityId, datapoint, page, rowsPerPage) => {
      loadRecentImpactedAssets(coalitionEntityId, datapoint, page, rowsPerPage)
    }, 400), // Adjust delay as needed
    [], // Dependencies
  )

  return (
    <div className={styles.frametab2}>
      <div className={styles.section}>
        <div className={styles.div}>
          <div className={styles['title-data']}>Impact List</div>
          <IconPlaceholder
            icon={
              <InfoIcon
                className={styles['icon-instance-node']}
                color="#7F8082"
                height={18}
                width={18}
              />
            }
            size="twenty-four-x-24"
          />
        </div>
        <div className={styles.pagination}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.tablepagination}
            classes={{
              selectLabel: classes.tablePaginationSelectLabel,
              displayedRows: classes.tablePaginationDisplayedRows,
              selectIcon: classes.tablePaginationSelectIcon,
              actions: classes.tablePaginationActions,
            }}
          />
        </div>
      </div>
      <div className={classes['table-wrapper']}>
        <Box className={classes.content}>
          <TableContainer className={classes.table}>
            <Table size={'medium'}>
              <TableHead className={classes.tablehead}>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell className={classes.tableheadcell} key={headCell.name}>
                      {headCell.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tablebody}>
                {loading ? (
                  <TableRowsLoader rows={5} columns={2} tableCellClassName={classes.tablecell} />
                ) : impactedAssetData.length > 0 ? (
                  impactedAssetData.map((row, index) => (
                    <TableRow key={index} className={`${'menu-links'} ${classes['vendor-row']}`}>
                      <TableCell
                        className={classes.tablecell}
                      >{`${row.asset}:${row.port}`}</TableCell>
                      <TableCell className={classes.tablecell}>{row.source}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      className={classes.tablecell}
                      style={{ textAlign: 'center' }}
                    >
                      <h6 className={styles.norecords}>No Records to Display</h6>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {/* </div> */}
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  )
}

import React from 'react'
import { SvgIcon } from '@material-ui/core'

export const CloseIcon = ({ className }) => {
  return (
    <SvgIcon className={`icons-12 ${className}`}>
      <mask
        className="mask"
        height="24"
        id="mask0_1338_18092"
        maskUnits="userSpaceOnUse"
        width="24"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="24" width="24" />
      </mask>
      <g className="g" mask="url(#mask0_1338_18092)">
        <path
          className="path"
          d="M12 13.0533L6.92701 18.1265C6.78851 18.2649 6.61443 18.3357 6.40476 18.339C6.19526 18.3422 6.01801 18.2714 5.87301 18.1265C5.72818 17.9815 5.65576 17.8059 5.65576 17.5995C5.65576 17.3932 5.72818 17.2175 5.87301 17.0725L10.9463 11.9995L5.87301 6.92652C5.73468 6.78802 5.66385 6.61394 5.66051 6.40427C5.65735 6.19477 5.72818 6.01752 5.87301 5.87252C6.01801 5.72769 6.19368 5.65527 6.40001 5.65527C6.60635 5.65527 6.78201 5.72769 6.92701 5.87252L12 10.9458L17.073 5.87252C17.2115 5.73419 17.3856 5.66336 17.5953 5.66002C17.8048 5.65686 17.982 5.72769 18.127 5.87252C18.2718 6.01752 18.3443 6.19319 18.3443 6.39952C18.3443 6.60586 18.2718 6.78152 18.127 6.92652L13.0538 11.9995L18.127 17.0725C18.2653 17.211 18.3362 17.3851 18.3395 17.5948C18.3427 17.8043 18.2718 17.9815 18.127 18.1265C17.982 18.2714 17.8063 18.3438 17.6 18.3438C17.3937 18.3438 17.218 18.2714 17.073 18.1265L12 13.0533Z"
          fill="#A2A3A4"
        />
      </g>
    </SvgIcon>
  )
}

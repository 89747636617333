import React, { useState } from 'react'

import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { InfoIcon } from 'src/v2/icons/info'

export const TitleCaption = ({ title, caption }) => {
  const [showCaption, setShowCaption] = useState(true)
  return (
    <div className={styles['title-caption']}>
      <div className={styles.level}>
        <div className={styles['text-wrapper']}>{title}</div>
        <IconPlaceholder
          icon={<InfoIcon className={styles['icons-1']} color={'var(--info-color-IC-l-300)'} />}
          size="twenty-x-20"
          onClick={() => {
            setShowCaption(!showCaption)
          }}
        />
      </div>
      {showCaption && <p className={styles.div}>{caption}</p>}
      {!showCaption && <p className={styles.div}></p>}
    </div>
  )
}

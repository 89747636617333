import moment from 'moment'
import React from 'react'

export const hasValue = (val) => {
  return val !== null && val !== undefined && val.toString().trim().length > 0
}

export const ClientSubmissionStatus = {
  Draft: 'Draft',
  Requested: 'Requested',
  Review: 'Review',
  SubmittedToVendor: 'SubmittedToVendor',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Accepted: 'Accepted',
  Rejected: 'Rejected',
  Cancelled: 'Cancelled',
}

export const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/

export const emailRegex = /\S+@\S+\.\S+/

export const checkPasswordValidations = (newPassword, confirmPassword, addToast) => {
  if (!hasValue(newPassword)) {
    addToast('ERROR', 'Please Enter New Password')
    return false
  }

  if (!hasValue(confirmPassword)) {
    addToast('ERROR', 'Please Enter Confirm Password')
    return false
  }

  if (newPassword !== confirmPassword) {
    addToast('ERROR', 'New Password and Confirm Password does not match')
    return false
  }

  if (!passwordRegex.test(newPassword)) {
    let ToastContent = () => (
      <>
        <b>New Password should meet following criteria.</b>
        <p>1. Should contain Min. 6 - Max. 16 characters</p>
        <p>2. Should contain atleast 1 number</p>
        <p>
          3. Should contain atleast 1 special character <br />
          {'! @ # $ % ^ & *'}
        </p>
      </>
    )
    addToast('ERROR', <ToastContent />, {
      // autoClose: false,
      closeTimeOut: 20 * 1000,
    })
    return false
  }

  return true
}

export const word = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const TypesOfCompanyData = {
  // EmployeeData: 'Employee Data',
  AuthenticationCredentialsORInternetEncryptionKey:
    'Authentication Credentials or Internet Encryption key',
  SourceCode: 'Source Code',
  Vulnerabilities: 'Vulnerabilities',
  FinancialOrCommercialData: 'Financial / Commercial Data',
  IntellectualPropertyData: 'Intellectual Property Data',
  MarketingData: 'Marketing Data',
  BusinessData: 'Business Data',
  RegulatoryOrComplianceLicensingData: 'Regulatory / Compliance Licensing Data',
  NoDataInvolved: 'No Data Involved',
  NoneOfTheAbove: 'None of the Above',
  IsTherePISPIInvolvedInThisProject: 'Is there a PI/SPI involved in this project?',
}

export const TypesOfCustomerData = {
  PCI: 'PCI (Payment Card Industry Data)',
  // PII: 'Personal Identifiable Information (PII)',
  // SPI: 'Sensitive Personal Identifiable Information (SPI)',
  CustomerPartnerProprietaryInformation: 'Customer & Partner Proprietary Information',
  NoDataInvolved: 'No Data Involved',
  NoneOfTheAbove: 'None of the Above',
  IsTherePISPIInvolvedInThisProject: 'Is there a PI/SPI involved in this project?',
}

export const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

export const getProgressPercentBasedOnStatus = (status) => {
  switch (status) {
    case 'Requested':
      return 20
    case 'Review':
      return 40
    case 'SubmittedToVendor':
      return 60
    case 'InProgress':
      return 80
    case 'Completed':
    case 'Accepted':
    case 'Rejected':
    case 'Cancelled':
      return 100
    default:
      return 0
  }
}

export const ApprovalStatus = {
  SubmittedToLead: 'SubmittedToLead',
  SubmittedToClient: 'SubmittedToClient',
  ClientApproved: 'ClientApproved',
  LeadRejected: 'LeadRejected',
  ClientRejected: 'ClientRejected',
}
export const getResponseForYesAndNo = (val) => {
  if (val === 'Y') {
    return 'Yes'
  } else if (val === 'N') {
    return 'No'
  } else if (val === 'NA') {
    return 'N/A'
  }
}

export const capitalizeFirstLetter = (string) => {
  if (string?.length > 0) return string.charAt(0).toUpperCase() + string.slice(1)
  else return ''
}

export const getDomainReportPercentageColor = (eachDomain) => {
  const percent = parseInt(eachDomain.percentage)
  if (percent > 70) {
    return 'rgb(81, 150, 104)'
  } else if (percent > 40) {
    return 'rgb(213, 164, 57)'
  } else {
    return 'rgb(199, 113, 113)'
  }
}

export const convertUnixTimeStampToDate = (timestamp) => {
  if (timestamp) {
    return moment.unix(timestamp)
  }
  return null
}

export const readLinkTitleFromText = (text) => {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/

  const match = text?.match(regex)
  if (match) {
    return [match[1], match[2]]
  } else {
    return ['', '']
  }
}

// export const caluculateDomainAnswersPercentage = (eachDomain) => {
//   var questionsList = []

//   if (eachDomain.questionsList) {
//     questionsList = eachDomain.questionsList
//   } else {
//     questionsList = eachDomain.sections.map((x) => x.questionsList).flat()
//   }

//   var answeredQuestionsList = questionsList.filter(
//     (x) => hasValue(x.checked || false) || x.answer !== 'NA',
//   )

//   const yesAnsweredQuestionsList = questionsList.filter(
//     (x) => x.checked === true || x.answer === 'Y',
//   )

//   const percent = ((yesAnsweredQuestionsList.length * 100) / answeredQuestionsList.length).toFixed(
//     2,
//   )

//   return percent
// }

export const ascSortFn = (a, b) => {
  if (a.order < b.order) return -1
  if (a.order > b.order) return 1
  return 0
}

export const getColorCodeForRisks = (riskLevel) => {
  const red = ['high', 'not effective']
  const amber = ['medium', 'meidum', 'partially effective']
  const green = ['low', 'fully effective']
  const yellow = ['mostly effective']

  if (red.includes(riskLevel?.toLowerCase())) {
    return 'red'
  } else if (amber.includes(riskLevel?.toLowerCase())) {
    return 'amber'
  } else if (green.includes(riskLevel?.toLowerCase())) {
    return 'green'
  } else if (yellow.includes(riskLevel?.toLowerCase())) {
    return 'yellow'
  } else {
    return ''
  }
}

export const CSReportTextFilters = [
  'is',
  'is not',
  'is empty',
  'is not empty',
  'start with',
  'ends with',
  'contains',
  'does not contain',
]

export const CSReportDateFilters = [
  'on',
  'not on',
  'before',
  'at or before',
  'after',
  'at or after',
  'between',
  'Since Baseline',
]

export const CSReportDayFilters = ['1 Week', '15 Days', '1 Month', '3 Months', '6 Months']

export const CSReportStatusFilters = ['Completed', 'Accepted', 'Cancelled', 'In progress', 'Draft']

export const debounce = (func, timeout = 300) => {
  var timer
  // return (...args) => {
  //   console.log("timerId is: ", timer);
  //   clearTimeout(timer);
  //   timer = setTimeout(() => {
  //     func.apply(this, args);
  //   }, timeout);
  // };

  return function () {
    console.log('timerId is: ', timer)
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, arguments)
    }, timeout)
  }
}

export const CompletionTimePeriod = {
  ThreeDays: 'Three Days',
  Week: 'week',
  AsPerAvailability: 'as per availabilty',
}

export const BusinessData = {
  CompanyEmailID: 'Company email ID',
  Name: 'Name',
  OfficeLocation: 'Office location',
}

export const PublicData = {
  Designation: 'Designation',
  CompanyWebsite: 'Company website',
  MarkettingDocuments: 'Marketing Documents',
  News: 'News',
}

export const PI = {
  Name: 'Name',
  OfficialEmailAddress: 'Official email address',
  PersonalEmailAddress: 'Personal email address',
  HomeAddress: 'Home address',
  OfficeAddress: 'Office address ',
  IDNo: 'ID No.',
  MobileNumber: 'Mobile number',
}

export const SPI = {
  CreditCard: 'Credit card',
  BankDetails: 'Bank details',
  Passport: 'Passport',
  SSN: 'SSN',
  SIN: 'SIN',
  Payslips: 'Payslips',
  BankAccountInfo: 'Bank account info',
}

export const PHI = {
  PatientName: "Patient's name",
  HomeAddress: 'Home Address',
  DOB: 'Date of birth',
  Race: 'Race',
  Ethnicity: 'Ethnicity',
  Gender: 'Gender',
  MedicalHistory: 'Medical History',
  Fingerprints: 'Fingerprints',
  MRN: 'Medical record number',
}

export const RegulatoryComplianceData = {
  AuditReports: 'Audit reports',
  ComplianceDocuments: 'Compliance documents',
  GapDetails: 'Gap details',
}

export const FinancialCommercialData = {
  Pricing: 'Pricing',
  PaymentTransactionDetails: 'Payment transaction details',
  Invoices: 'Invoices',
}

export const IntellectualPropertyData = {
  SourceCode: 'Source Code',
  TradeSecrets: 'Trade secrets',
  InternalProductDetails: 'Internal product details',
}

export const SecurityData = {
  Vulnerabilities: 'Vulnerabilities',
  AuthenticationCredentials: 'Authentication credentials',
  EncryptionKey: 'Encryption key',
  SecurityScanningRpeortsSSN: 'SSN',
}

export const assessmentFrameWorkDescription = `Beaconer has successfully conducted a comprehensive third-party risk assessment of {vendor} with a specific focus on evaluating the overall maturity of their enterprise security program and the presence of controls & sub-controls.
\nOur assessment covered a wide spectrum, encompassing all 21 domains, which includes vital areas such as Risk Management, Information Security Policies, Organization of Information Security, Human Resource Security, Asset Management, Access Control, Physical and Environmental Security, Operations Security, Communications Security, System Acquisition, Development, and Maintenance, Third-Party Risk, Information Security Incident Management, Business Continuity Management, Compliance, Privacy, Cloud Security, Container Security & Architecture.
\nTo tailor the identification of Risk & control gaps to this project's scope, we utilized a targeted Questionnaire, focusing on two key dimensions: Control Family maturity & Control completeness.The maturity model assessed the overall maturity of technical, administrative, and people controls across the 21 different control families. Additionally, the control completeness aspect verified the existence of each control & sub-control.
\nAs a result, this comprehensive assessment provides a detailed account of the organizational Security maturity, along with mapping to various Risk frameworks. These findings empower you to measure the effectiveness of the third-party for your specific scope of work, ensuring that your valuable data remains well-protected.
\nYou can find the Beaconer Assessment methodology at  [beaconer.io/resources](http://beaconer.io/resources/)`

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export const EnumAssessmentTypes = {
  Inprogress: 'In Progress Assessments',
  Completed: 'Completed Assessments',
  DueForNextReview: 'Due for Next Review Assessments',
  ReportsPendingApproval: 'Reports Pending Approval',
  RemediationsPendingApproval: 'Remediations Pending Approval',
}

export const generateUniqueId = (length = 5) =>
  Array.from({ length }, () =>
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(
      Math.floor(Math.random() * 62),
    ),
  ).join('')

export const formatKValue = (value) => {
  const formattedValue = (value / 1000).toFixed(1)
  return value > 1000 ? `${formattedValue.replace('.0', '')} K` : value
}

export const getDataTypesInvlovedInReport = (value) => {
  var stringType = 'Public'
  switch (value) {
    case '2':
      stringType = 'Internal'
      break
    case '3':
      stringType = 'Confidential'
      break
    case '4':
      stringType = 'Secret'
      break
    default:
      stringType = 'Public'
      break
  }
  return stringType
}

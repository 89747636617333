import React, { useEffect, useState } from 'react'
import { Header } from './sections/Header/Header'
import { List } from './sections/List/List'
import styles from './style.module.css'
import Drawer from '@mui/material/Drawer'
import { Stack } from '@mui/material'
import {
  getDasboardAssessments,
  getPendingApprovalRemediations,
} from 'src/services/dashboardService'
import { EnumAssessmentTypes } from 'src/utils/commonFns'

export const DashboardDrawer = ({
  isDrawerOpen,
  handleDrawerClose,
  DrawerType,
  businessOrganizationId,
}) => {
  const [listData, setListData] = useState([])

  const loadAssessments = async () => {
    var displayStatus =
      DrawerType === EnumAssessmentTypes.Completed
        ? 'completed'
        : DrawerType === EnumAssessmentTypes.DueForNextReview
        ? 'DueForNextReview'
        : DrawerType === EnumAssessmentTypes.ReportsPendingApproval
        ? 'PendingApproval'
        : 'InProgress'

    var result = await getDasboardAssessments(businessOrganizationId, displayStatus)
    setListData(result)
  }

  const loadPendingApprovalRemediations = async () => {
    var result = await getPendingApprovalRemediations(businessOrganizationId)
    setListData(result)
  }

  useEffect(() => {
    if (isDrawerOpen) {
      if (DrawerType === EnumAssessmentTypes.RemediationsPendingApproval)
        loadPendingApprovalRemediations()
      else loadAssessments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen])

  return (
    <Drawer
      anchor={'right'}
      open={isDrawerOpen}
      slotProps={{ backdrop: { invisible: true } }}
      onClose={handleDrawerClose}
      PaperProps={{
        className: styles['slidebar-things-yet'],
        sx: {
          height: 'calc(100% - 54px)',
          top: '65px',
        },
      }}
    >
      <Stack sx={{ height: 'inherit' }}>
        <Header
          handleDrawerClose={handleDrawerClose}
          DrawerType={DrawerType}
          total={listData?.length}
        />
        {listData && <List DrawerType={DrawerType} listData={listData} />}
      </Stack>
    </Drawer>
  )
}

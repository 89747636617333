import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'
import { Typography, Grid } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import BeaconerLogo from '../../../../assets/images/beaconer-logo.svg'
import moment from 'moment'
import { hasValue } from 'src/utils/commonFns'
import Link from '@mui/material/Link'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import defaultChatMsgStyles from './defaultChatMsg.styles'

const ChatMsg = withStyles(defaultChatMsgStyles, { name: 'ChatMsg' })((props) => {
  const { classes, msg, GridContainerProps, getTypographyProps } = props
  const attachClass = () => {
    return classes[`${side}First`]
  }
  console.log(moment(msg.sentOn).local())
  const side = hasValue(msg.clientId) ? 'right' : hasValue(msg.assessorId) ? 'left' : ''
  const TypographyProps = getTypographyProps(msg.message, 0, props)

  return (
    <Grid
      container
      spacing={1}
      justify={side === 'right' ? 'flex-end' : 'flex-start'}
      {...GridContainerProps}
      style={{ marginBottom: '16px' }}
    >
      <Grid item xs={12}>
        <>
          {side === 'left' && (
            <>
              <img className={classes['beaconerlogo']} alt="beaconer logo" src={BeaconerLogo} />
              <Typography className={classes['timestamp']} variant="body2">
                {'BEACONER'}
              </Typography>
            </>
          )}
          <div className={classes[`${side}Row`]}>
            <Typography
              align={side}
              {...TypographyProps}
              className={cx(classes.msg, classes[side], attachClass(), TypographyProps.className)}
            >
              {msg.message}
            </Typography>
          </div>
          {msg.queryAttachmentSignedUrls?.length > 0 && (
            <div className={cx(classes[`${side}`], classes.msg, classes.attachments)}>
              {msg.queryAttachmentSignedUrls.map((attachment, index) => (
                <div key={index} className={classes.attachmentLink}>
                  <InsertDriveFileIcon
                    className={classes.attachmentIcon}
                    fontSize="small"
                    style={{ marginRight: '6px' }}
                  />
                  <Link
                    icon
                    key={index}
                    href={attachment.attachmentSignedUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {attachment.attachmentFileName}
                  </Link>
                </div>
              ))}
            </div>
          )}
        </>
      </Grid>
      <Grid
        item
        xs={12}
        className={side === 'right' ? classes['timestamprow'] : classes['timestamprowleft']}
      >
        <Typography align={side} className={classes['timestamp']} variant="body2">
          {moment.utc(msg.sentOn).local().format('MMM DD, YYYY [at] HH:mm [hrs]')}
        </Typography>
      </Grid>
    </Grid>
  )
})

ChatMsg.propTypes = {
  avatar: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
  side: PropTypes.oneOf(['left', 'right']),
  GridContainerProps: PropTypes.shape({}),
  GridItemProps: PropTypes.shape({}),
  AvatarProps: PropTypes.shape({}),
  getTypographyProps: PropTypes.func,
}
ChatMsg.defaultProps = {
  avatar: '',
  messages: [],
  side: 'left',
  GridContainerProps: {},
  GridItemProps: {},
  AvatarProps: {},
  getTypographyProps: () => ({}),
}

export default ChatMsg

import React from 'react'
import styles from './style.module.css'

export const CustomLabel = ({ selected, value }) => {
  return (
    <div className={selected ? styles['label-selected'] : styles['label-not-selected']}>
      {value}
    </div>
  )
}

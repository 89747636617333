import React, { useEffect, useRef } from 'react'
import classes from './style.module.css'
import CTooltip from 'src/v2/components/Tooltip/Tooltip'
import InfoIcon from 'src/v2/screens/dashboard/icons/InfoIcon.svg'
import Typography from '@mui/material/Typography'
import * as d3 from 'd3'
import { arc } from 'd3-shape'
import { scaleLinear } from 'd3-scale'

export const SecurityScore = ({ secureScore }) => {
  const svgRef = useRef(null)

  const drawGraph = () => {
    if (!svgRef.current) return

    d3.select(svgRef.current).selectAll('*').remove()

    const margin = { top: 20, right: 20, bottom: 20, left: 20 }

    const parentElement = svgRef.current.getBoundingClientRect()

    const width = parentElement.width - margin.left - margin.right
    let height = parentElement.height - margin.top - margin.bottom

    if (height < 250) height = 250

    const radius = Math.min(width, height) / 2

    const arcWidth = 40
    const innerRadius = radius - arcWidth

    const svg = d3
      .select(svgRef.current)
      .attr('width', '100%')
      .attr('height', 'auto')
      .append('g')
      .attr('transform', `translate(${parentElement.width / 2}, ${parentElement.height / 1.5})`)

    // Define gradient
    const gradient = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '0%')

    gradient.append('stop').attr('offset', '0%').attr('stop-color', '#EF4D4F') // High color
    gradient.append('stop').attr('offset', '40%').attr('stop-color', '#EF4D4F') // High color

    gradient.append('stop').attr('offset', '40%').attr('stop-color', '#30ac79') // Medium color
    gradient.append('stop').attr('offset', '70%').attr('stop-color', '#30ac79') // Medium color

    gradient.append('stop').attr('offset', '70%').attr('stop-color', '#30AC79') // Low color
    gradient.append('stop').attr('offset', '100%').attr('stop-color', '#30AC79') // Low color

    const securityScore = secureScore ?? 0

    const colorScale = scaleLinear().domain([40, 70, 100]).range(['#EF4D4F', '#FF7738', '#30ac79'])

    const ticks = 37
    const data = Array.from({ length: ticks }, (_, i) => i * (100 / ticks))

    const arcGenerator = arc()
      .innerRadius((d, i) => innerRadius)
      .outerRadius((d, i) => {
        if (i === 10 || i === 26) return radius + 15 // Increased outer radius for 11th and 27th arc
        return radius
      })
      .startAngle((d, i) => (i * Math.PI) / ticks - Math.PI / 2)
      .endAngle((d, i) => ((i + 1) * Math.PI) / ticks - Math.PI / 2)

    svg
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')
      .attr('d', arcGenerator)
      .attr('fill', (d) => (d <= securityScore ? colorScale(d) : '#5D5E60'))
      .attr('stroke', 'var(--collection-BG-100)')
      .attr('stroke-width', 7)

    // Function to calculate label position along an arc
    const calculateLabelPosition = (angle, radius) => ({
      x: radius * Math.cos(angle - Math.PI / 2),
      y: radius * Math.sin(angle - Math.PI / 2),
    })

    // Position the labels at the center of each section
    const labelAngles = [
      (-14 * Math.PI) / ticks, // Angle for "High"
      Math.PI / ticks, // Angle for "Medium"
      (14 * Math.PI) / ticks, // Angle for "Low"
    ]

    const labelTransform = [
      `rotate(-66, -${radius * 0.9}, -${radius * 0.3})`,
      'translate(-18, -18)',
      `rotate(66, ${radius * 0.9}, -${radius * 0.3})`,
    ]

    const labels = ['High', 'Medium', 'Low']
    const labelPositions = labelAngles.map((angle) => calculateLabelPosition(angle, radius - 30)) // Adjust radius as needed

    // Add labels for High, Medium, Low
    labels.forEach((label, i) => {
      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('x', labelPositions[i].x)
        .attr('y', labelPositions[i].y - 20)
        .attr('transform', labelTransform[i])
        .attr('class', 'security-score-label')
        .text(label)
    })

    // Add the center text (e.g., 53%)
    svg
      .append('foreignObject')
      .attr('text-anchor', 'middle')
      .attr('x', '-18px')
      // .attr('y', '-35px')
      .attr('y', -innerRadius / 4)
      .attr('width', '75px')
      .attr('height', '50px')
      .attr('class', 'security-score-value')
      .append('xhtml:div')
      .html(`${securityScore}`)

    // Add labels for 0 and 100
    svg
      .append('text')
      .attr('x', -radius * 0.95)
      .attr('y', 20) // Adjust y position to be below the arcs
      .attr('text-anchor', 'start')
      .attr('class', 'security-score-label')
      .style('font-size', `${radius / 12}px`)
      .text('0')

    svg
      .append('text')
      .attr('x', radius * 0.95)
      .attr('y', 20) // Adjust y position to be below the arcs
      .attr('text-anchor', 'end')
      .attr('class', 'security-score-label')
      .style('font-size', `${radius / 12}px`)
      .text('100')
  }

  useEffect(() => {
    drawGraph()
    window.addEventListener('resize', drawGraph)
    return () => {
      window.removeEventListener('resize', drawGraph)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    drawGraph()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svgRef.current])

  return (
    <div className={`${classes['sercurity-score']} security-score-section`}>
      <div className={classes.section}>
        <div className={classes.title}>
          <div className={classes['title-data']}>Security Score</div>
          <div className={classes.iconPlaceholder}>
            <CTooltip
              arrow
              title={
                <React.Fragment>
                  <Typography variant="body2" fontWeight={500} sx={{ mb: '4px' }}>
                    Security Score
                  </Typography>
                  <Typography variant="body2">
                    A Vendor Assessment Risk Metrics Chart is a visual tool that summarizes the
                    overall risk level of evaluated vendors. It combines various risk indicators.
                  </Typography>
                </React.Fragment>
              }
            >
              <img className={classes.icons} loading="lazy" alt="" src={InfoIcon} />
            </CTooltip>
          </div>
        </div>
        {/* <p className={classes['order-info']}>Jun 2022 - Jun 2024</p> */}
      </div>
      <div className={classes.group}>
        <svg ref={svgRef} style={{ minHeight: '250px' }}></svg>
        <div className={classes.text}>Your Security score</div>
      </div>
    </div>
  )
}

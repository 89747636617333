import axios from 'axios'
import AppConfig from '../config'
import { hasValue } from 'src/utils/commonFns'

const baseURL = `${AppConfig.API_URL}`

const getDashboardCounts = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/dashboard-counts?orgId=${orgId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getRiskMetricsData = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/risk-metrics?orgId=${orgId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getCompletionTrends = async (orgId, year) => {
  try {
    let { data } = await axios.get(`${baseURL}/completion-trends?orgId=${orgId}&year=${year}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getAssessmentPipelineCounts = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/assessment-pipeline?orgId=${orgId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getPendingCounts = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/pending-counts?orgId=${orgId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getBusinessUnits = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/business-units?orgId=${orgId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

//

const getAssessmentOverviewCounts = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/assessment-overview-counts?orgId=${orgId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getDasboardAssessments = async (orgId, displayStatus) => {
  try {
    var url = `${baseURL}/assessments?`

    var queryParams = new URLSearchParams()

    if (hasValue(orgId)) queryParams.set('orgId', orgId)

    if (hasValue(displayStatus)) queryParams.set('displayStatus', displayStatus)

    let { data } = await axios.get(`${url}${queryParams.toString()}`)
    if (data) {
      return data
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getPendingApprovalRemediations = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/pending-remediations?orgId=${orgId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const getRiskMapScores = async (orgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/risk-heat-map?orgId=${orgId}`)
    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export {
  getDashboardCounts,
  getCompletionTrends,
  getRiskMetricsData,
  getAssessmentPipelineCounts,
  getPendingCounts,
  getBusinessUnits,
  getAssessmentOverviewCounts,
  getDasboardAssessments,
  getPendingApprovalRemediations,
  getRiskMapScores,
}

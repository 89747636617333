import React from 'react'

export const InfoIcon = ({ className, height = 14, width = 14, color = '#d32f2f' }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height={height}
      viewBox="0 0 15 15"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height={height}
        id="mask0_1010_3692"
        maskUnits="userSpaceOnUse"
        width={width}
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height={height} width={width} />
      </mask>
      <g className="g" mask="url(#mask0_1010_3692)">
        <path
          className="path"
          d="M7.99644 11.0333C8.11833 11.0333 8.22155 10.9918 8.30611 10.9087C8.39077 10.8257 8.4331 10.7228 8.4331 10.6V7.63335C8.4331 7.51057 8.39188 7.40763 8.30944 7.32452C8.22699 7.24152 8.12488 7.20002 8.00311 7.20002C7.88122 7.20002 7.77799 7.24152 7.69344 7.32452C7.60877 7.40763 7.56644 7.51057 7.56644 7.63335V10.6C7.56644 10.7228 7.60766 10.8257 7.69011 10.9087C7.77255 10.9918 7.87466 11.0333 7.99644 11.0333ZM7.99627 6.19235C8.12894 6.19235 8.24133 6.14746 8.33344 6.05768C8.42555 5.96791 8.47161 5.85668 8.47161 5.72402C8.47161 5.59135 8.42672 5.47896 8.33694 5.38685C8.24716 5.29485 8.13594 5.24885 8.00327 5.24885C7.8706 5.24885 7.75822 5.29368 7.66611 5.38335C7.57399 5.47313 7.52794 5.58435 7.52794 5.71702C7.52794 5.84968 7.57283 5.96207 7.6626 6.05418C7.75238 6.14629 7.86361 6.19235 7.99627 6.19235ZM8.00544 14.0667C7.17088 14.0667 6.38488 13.9088 5.64744 13.593C4.90999 13.2772 4.26455 12.8427 3.71111 12.2895C3.15766 11.7363 2.72294 11.0913 2.40694 10.3547C2.09105 9.61813 1.93311 8.83107 1.93311 7.99352C1.93311 7.15596 2.09099 6.37124 2.40677 5.63935C2.72255 4.90746 3.15705 4.26479 3.71027 3.71135C4.26349 3.1579 4.90844 2.72318 5.64511 2.40718C6.38166 2.09129 7.16872 1.93335 8.00627 1.93335C8.84383 1.93335 9.62855 2.09124 10.3604 2.40702C11.0923 2.72279 11.735 3.15729 12.2884 3.71052C12.8419 4.26374 13.2766 4.90735 13.5926 5.64135C13.9085 6.37546 14.0664 7.15979 14.0664 7.99435C14.0664 8.82891 13.9085 9.6149 13.5928 10.3523C13.277 11.0898 12.8425 11.7352 12.2893 12.2887C11.736 12.8421 11.0924 13.2768 10.3584 13.5928C9.62433 13.9087 8.83999 14.0667 8.00544 14.0667ZM7.99977 13.2C9.44422 13.2 10.672 12.6945 11.6831 11.6833C12.6942 10.6722 13.1998 9.44446 13.1998 8.00002C13.1998 6.55557 12.6942 5.32779 11.6831 4.31668C10.672 3.30557 9.44422 2.80002 7.99977 2.80002C6.55533 2.80002 5.32755 3.30557 4.31644 4.31668C3.30533 5.32779 2.79977 6.55557 2.79977 8.00002C2.79977 9.44446 3.30533 10.6722 4.31644 11.6833C5.32755 12.6945 6.55533 13.2 7.99977 13.2Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

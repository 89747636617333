import React from "react";

export const Icons8 = ({ className }) => {
  return (
    <svg
      className={`icons-8 ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask className="mask" height="22" id="mask0_423_10489" maskUnits="userSpaceOnUse" width="22" x="0" y="0">
        <rect className="rect" fill="#3A70E2" height="21.2308" width="21.2308" x="0.384766" y="0.384766" />
      </mask>
      <g className="g" mask="url(#mask0_423_10489)">
        <path
          className="path"
          d="M11.0008 14.429C10.8942 14.429 10.795 14.412 10.7032 14.3779C10.6113 14.3439 10.524 14.2855 10.4411 14.2028L6.46541 10.2271C6.34304 10.1046 6.28038 9.95059 6.27743 9.76512C6.27463 9.57979 6.33729 9.42299 6.46541 9.29472C6.59368 9.1666 6.74908 9.10254 6.93161 9.10254C7.11413 9.10254 7.26953 9.1666 7.3978 9.29472L11.0008 12.898L14.6039 9.29472C14.7264 9.17235 14.8804 9.10969 15.0659 9.10674C15.2512 9.10394 15.408 9.1666 15.5363 9.29472C15.6644 9.42299 15.7284 9.57839 15.7284 9.76091C15.7284 9.94344 15.6644 10.0988 15.5363 10.2271L11.5606 14.2028C11.4777 14.2855 11.3904 14.3439 11.2985 14.3779C11.2067 14.412 11.1074 14.429 11.0008 14.429Z"
          fill="#A2A3A4"
        />
      </g>
    </svg>
  );
};

import React from 'react'
import Chart from 'react-apexcharts'

export const ReportVulnerabilities = ({ critical, high, medium, low, loading = false }) => {
  const series = loading
    ? []
    : [
        {
          name: 'Low',
          data: [0, 0, 0, low],
        },
        {
          name: 'Gap 1',
          data: [0], // Same data for the gap but will be transparent
        },
        {
          name: 'Medium',
          data: [0, 0, medium, 0],
        },
        {
          name: 'Gap 2',
          data: [0], // Same data for the gap but will be transparent
        },
        {
          name: 'High',
          data: [0, high, 0, 0],
        },
        {
          name: 'Gap 3',
          data: [0], // Same data for the gap but will be transparent
        },
        {
          name: 'Critical',
          data: [critical, 0, 0, 0],
        },
      ]
  const chartOptions = useChartOptions(series, loading)

  return <Chart options={chartOptions} series={series} type="bar" height={265} width={550} />
}

const useChartOptions = (series, loading) => {
  return {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming
      },
    },

    tooltip: {
      enabledOnSeries: [0, 2, 4, 6],
      theme: 'dark',
      cssClass: 'c-apexcharts-tooltip',
      style: {
        fontSize: '12px',
        fontFamily: '"SF Pro Text", Helvetica',
      },
      y: {
        title: {
          formatter(seriesName) {
            return seriesName
          },
        },
      },
      x: {
        title: {
          formatter(seriesName) {
            return seriesName
          },
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30px', // Adjust column thickness (width)
        barHeight: '5px', // Adjust the height of the bar (affects gap between stacks)
        borderRadius: 2, // Add some border radius if you want rounded edges
        dataLabels: {
          position: 'top', // Align data labels at the top of the bars
        },
        distributed: false,
      },
    },
    xaxis: {
      categories: ['Critical', 'High', 'Medium', 'Low'],
      tickPlacement: 'between',
      range: 2.5,
      labels: {
        style: {
          colors: '#E8E8E8',
          fontSize: '12px',
          fontFamily: '"SF Pro Text", Helvetica',
        },
      },
      axisTicks: {
        show: false,
        borderType: 'solid',
        color: '#5D5E60',
        height: 6,
      },
      axisBorder: {
        show: true,
        color: '#5D5E60',
        offsetX: 0,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#E8E8E8',
          fontSize: '12px',
          fontFamily: '"SF Pro Text", Helvetica',
        },
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#5D5E60',
        height: 6,
        // offsetY: 0.6,
      },
      axisBorder: {
        show: true,
        color: '#5D5E60',
        offsetX: 0,
      },
    },
    grid: {
      show: true,
      borderColor: '#3A3C3E',
      xaxis: {
        lines: {
          show: true,
          offsetX: 0,
        },
      },
      yaxis: {
        lines: {
          show: true,
          offsetX: 0,
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: [1, 0, 1, 0, 1, 0, 1],
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      formatter: function (val) {
        return val
      },
      style: {
        colors: ['#1B1C1E'],
        // background: '#1B1C1E',
        fontSize: '12px',
        fontFamily: '"SF Pro Text", Helvetica',
        lineHeight: '18px',
      },
      background: {
        enabled: true,
        borderRadius: 2,
        borderColor: 'transparent',
        foreColor: '#E8E8E8',
      },
    },
    colors: [
      '#30AC79',
      'transparent',
      '#feaa32',
      'transparent',
      '#FF7738',
      'transparent',
      '#ef4d4f',
    ],
    noData: {
      text: loading ? 'Loading...' : 'No Data Available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 8,
      offsetY: -10,
      style: {
        color: '#fff',
        fontSize: '14px',
        fontFamily: '"SF Pro Text-Regular", Helvetica',
      },
    },
  }
}

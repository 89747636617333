import React from 'react'

export const RightArrow = ({ className, color = '#7F8082', size = '22' }) => {
  return (
    <svg
      className={`icons-4 ${className}`}
      fill="none"
      height={size}
      viewBox="0 0 22 22"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height={size}
        id="mask0_815_8522"
        maskUnits="userSpaceOnUse"
        width={size}
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height={size} width={size} />
      </mask>
      <g className="g" mask="url(#mask0_815_8522)">
        <path
          className="path"
          d="M12.28 11.0001L8.36285 7.0832C8.23605 6.95624 8.17112 6.81576 8.16806 6.66176C8.16516 6.50792 8.23009 6.36453 8.36285 6.23162C8.49577 6.09885 8.6377 6.03247 8.78864 6.03247C8.93959 6.03247 9.08404 6.10145 9.222 6.23941L13.4717 10.4888C13.5421 10.5655 13.595 10.6461 13.6302 10.7306C13.6655 10.8151 13.6832 10.9065 13.6832 11.0047C13.6832 11.1029 13.6655 11.1943 13.6302 11.2788C13.595 11.3633 13.5421 11.4408 13.4717 11.5114L9.222 15.7608C9.09 15.8928 8.95082 15.9565 8.80446 15.952C8.65825 15.9472 8.51869 15.8785 8.38577 15.7457C8.25301 15.6128 8.18662 15.4709 8.18662 15.3199C8.18662 15.169 8.25301 15.027 8.38577 14.8941L12.28 11.0001Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

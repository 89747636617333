import React from 'react'

export const UpArrorwIcon = ({ className }) => {
  return (
    <svg
      className={`icons-20 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="20"
        id="mask0_480_777"
        maskUnits="userSpaceOnUse"
        width="20"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" />
      </mask>
      <g className="g" mask="url(#mask0_480_777)">
        <path
          className="path"
          d="M9.37452 6.14396L5.06681 10.4517C4.94292 10.5756 4.79792 10.6367 4.63181 10.6352C4.4657 10.6335 4.31799 10.5681 4.18868 10.439C4.06799 10.3097 4.00549 10.1633 4.00118 9.9998C3.99688 9.83633 4.05938 9.68994 4.18868 9.56063L9.47223 4.27709C9.55028 4.19903 9.63257 4.14403 9.7191 4.11209C9.80563 4.08001 9.8991 4.06396 9.99952 4.06396C10.0999 4.06396 10.1934 4.08001 10.2799 4.11209C10.3665 4.14403 10.4488 4.19903 10.5268 4.27709L15.8104 9.56063C15.9258 9.67605 15.9848 9.81896 15.9874 9.98938C15.9901 10.1598 15.931 10.3097 15.8104 10.439C15.681 10.5681 15.5326 10.6327 15.3649 10.6327C15.1972 10.6327 15.0486 10.5681 14.9193 10.439L10.6245 6.14396V15.6248C10.6245 15.8022 10.5647 15.9506 10.4449 16.0702C10.3254 16.1899 10.1769 16.2498 9.99952 16.2498C9.82216 16.2498 9.67369 16.1899 9.5541 16.0702C9.43438 15.9506 9.37452 15.8022 9.37452 15.6248V6.14396Z"
          fill="#F3F3F4"
        />
      </g>
    </svg>
  )
}

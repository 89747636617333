import PropTypes from 'prop-types'
import React from 'react'
import { IconPlaceholder } from '../IconPlaceholder'
import styles from './style.module.css'
import { FormHelperText } from '@mui/material'
import { InfoIcon } from 'src/v2/icons/info'

export const ErrorText = ({ value }) => {
  return (
    <FormHelperText error style={{ display: 'flex', gap: '3px', alignContent: 'center' }}>
      <>
        {' '}
        {!!value && value.length > 0 && (
          <IconPlaceholder
            icon={<InfoIcon className={styles['error-icon']} />}
            size="sixteen-x-16"
          />
        )}
        <div style={{ paddingTop: '1px' }}>{value}</div>
      </>
    </FormHelperText>
  )
}

ErrorText.propTypes = {
  value: PropTypes.string,
}

import React from 'react'
import Drawer from '@mui/material/Drawer'
import classes from './style.module.css'
import { hasValue } from 'src/utils/commonFns'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { CrossMark } from 'src/v2/icons/cross-mark'
import moment from 'moment'

const ThreatDetail = ({ openDrawer, setOpenDrawer, cyberscanDetailInfo, threatInfo }) => {
  const descriptions = {
    'tld-swap':
      'Top-level domains in the domain name are swapped. \n TLD Swap: Swapping top-level domains may have limited impact on domain security, and severity would depend on the context.',
    dictionary:
      'Dictionary-based fuzzing relies on predefined words, making it less likely to be a significant security concern unless used creatively. \n Dictionary: Dictionary-based fuzzing relies on predefined words, making it less likely to be a significant security concern unless used creatively.',
    addition:
      'Characters are added to the domain name. \n Adding extra characters to domain names is less likely to result in successful attacks unless users frequently mistype.',
    bitsquatting:
      'Single bit errors are introduced into the domain name. \n While not as common as repetition or replacement, bitsquatting targets domains with single-bit differences from legitimate ones, potentially leading to confusion and security risks.',
    homoglyph:
      'Similar looking characters are swapped in the domain name. \n Homoglyph attacks can be medium severity because they rely on visually similar characters from different scripts. These attacks may deceive users but require careful crafting.',
    hyphenation:
      "Hyphens are inserted into the domain name. \n Hyphenation fuzzing mainly focuses on variations in hyphen placement within domain names. While it can lead to confusion, it's less likely to be used in malicious activities.",
    insertion:
      'Characters are inserted into the domain name. \n Insertion adds characters within domain names, making it a lower severity fuzzer as it requires specific circumstances for successful attacks.',
    omission:
      'Characters are removed from the domain name. \n Omission removes characters from domain names, which is less likely to result in successful attacks due to the need for specific user behavior.',
    repetition:
      'Characters are repeated in the domain name. \n Repetition: This fuzzer can generate domain names with repeated characters, making it more likely to mimic common typos and be used in phishing attacks.',
    replacement:
      'Characters are replaced in the domain name. \n Replacement: Similar to repetition, this fuzzer can create domains with intentional character replacements, increasing the likelihood of successful typo-squatting and phishing attacks.',
    subdomain:
      'Parts of the domain name are prepended as a subdomain. \n This fuzzer focuses on subdomains and may have limited impact on overall domain security. Its severity depends on the specific use case.',
    transposition:
      'Adjacent characters are swapped in the domain name. \n Transposition fuzzing swaps adjacent characters, which is less likely to be effective unless users mistype frequently.',
    various:
      'Various manipulations are applied to the domain name. \n Various: This category seems to encompass a range of manipulations, so severity would depend on the specific manipulations involved.',
    'vowel-swap':
      'Vowels in the domain name are swapped. \n Vowel-Swap: This fuzzer targets vowel characters and may have limited impact unless specific user behavior patterns are exploited.',
  }

  return (
    <Drawer
      anchor={'right'}
      open={hasValue(openDrawer)}
      onClose={() => setOpenDrawer(null)}
      slotProps={{ backdrop: { invisible: true } }}
      PaperProps={{
        className: classes['threat-detail-side-drawer'],
        sx: {
          height: 'calc(100% - 54px)',
          top: '65px',
        },
      }}
    >
      <div className={classes['container']}>
        <div className={classes['content-wrapper']}>
          <header className={classes.header}>
            <div className={classes['title-close']}>
              <div className={classes.div}>
                <div className={classes.title}>Threat detail</div>
              </div>
              <IconPlaceholder
                icon={<CrossMark className={classes['icons-5']} />}
                size="twenty-eight-x-28"
                onClick={() => setOpenDrawer(false)}
              />
            </div>
          </header>
          <div className={classes.content}>
            <div className={classes.div}>
              <div className={classes.ID}>
                <div className={classes['text-wrapper']}>ID: {threatInfo.threatId}</div>
              </div>
              <div className={classes['single-content-text']}>
                <div className={classes.issue}>
                  <div className={classes['body-text']}>Description</div>
                </div>
                <div className={classes.text}>
                  <p className={classes['text-wrapper-2']}>{descriptions[threatInfo.fuzzer]}</p>
                </div>
              </div>
              <div className={classes['single-content-text']}>
                <div className={classes.issue}>
                  <div className={classes['body-text']}>Found Date</div>
                </div>
                <div className={classes.text}>
                  <div className={classes['text-wrapper-2']}>
                    {moment(threatInfo.timestamp).format('MM/DD/yy')}
                  </div>
                </div>
              </div>
              <div className={classes['single-content-text']}>
                <div className={classes.issue}>
                  <div className={classes['body-text']}>Phash</div>
                </div>
                <div className={classes.text}>
                  <div className={classes['text-wrapper-2']}>{threatInfo.phash}%</div>
                </div>
              </div>
              <div className={classes['single-content-text']}>
                <div className={classes.issue}>
                  <div className={classes['body-text']}>Domain</div>
                </div>
                <div className={classes.text}>
                  <div className={classes['text-wrapper-2']}>{threatInfo.organizationDomain}</div>
                </div>
              </div>
              <div className={classes['single-content-text']}>
                <div className={classes.issue}>
                  <div className={classes['body-text']}>Fuzzer</div>
                </div>
                <div className={classes.answer}>
                  <div className={classes.label}>
                    <div className={classes['text-wrapper-3']}>{threatInfo.fuzzer}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default ThreatDetail

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { KebabMenuIcon } from 'src/v2/icons/kebab-menu-icon'
import moment from 'moment'
import {
  Popper,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Collapse,
  ClickAwayListener,
} from '@mui/material'
import { StatusCard } from './StatusCard'
import { CompanyDataIcon } from 'src/v2/icons/assessments/company-data-icon'
import { RightArrow } from 'src/v2/icons/right-arrow'

import { CustomerDataIcon } from 'src/v2/icons/assessments/customer-data-icon'
import { DataProcessing } from 'src/v2/icons/assessments/data-processing'
import { LocationTracking } from 'src/v2/icons/assessments/location-tracking'
import {
  assessmentFrameWorkDescription,
  TypesOfCompanyData,
  TypesOfCustomerData,
} from 'src/utils/commonFns'
import { ExpandMore } from 'src/v2/components/ExpandMore'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { AssessmentFramework } from './AssessmentFramework'
import { CompanyDataElements } from './CompanyDataElements'
import { CustomerDataElements } from './CustomerDataElements'

export const ProjectDetails = ({ assessmentDetails }) => {
  const [typesOfComanyDataExpanded, setTypesOfComanyDataExpanded] = useState(true)
  const [detailedCompanyData, setDetailedCompanyData] = useState(false)
  const [typesOfCustomerDataExpanded, setTypesOfCustomerDataExpanded] = useState(true)
  const [detailedCustomerData, setDetailedCustomerData] = useState(false)
  const [isPSIInvolvedExpand, setIsPSIInvolvedExpand] = useState(true)
  const [noOfPSIExpand, setNoOfPSIExpand] = useState(true)
  const [dataStoredinVendorLoactionExpand, setDataStoredinVendorLoactionExpand] = useState(true)
  const [dataProcessedByThirdParty, setDataProcessedByThirdParty] = useState(true)
  const [monitorLocationExpanded, setMonitorLocationExpanded] = useState(true)
  const [mobileAppInvolvedExpanded, setMobileAppInvolvedExpanded] = useState(true)
  const [openAssessmentFramework, setOpenAssessmentFramework] = useState(false)

  const questionaireData = JSON.parse(assessmentDetails?.preAssessmentQuestionnaire ?? '{}')
  const reportData = JSON.parse(assessmentDetails?.reportData ?? '{}')
  const asssementFramework = assessmentFrameWorkDescription
    .replace('{vendor}', questionaireData?.thirdPartyName)
    .replace('{clientName}', questionaireData?.businessOrgName)

  useEffect(() => {
    setDetailedCompanyData(questionaireData?.detailedDataElementsCompanyData)
    setDetailedCustomerData(questionaireData?.detailedDataElementsOfCustomerData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentDetails])

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const clickAwayHandler = () => {
    setOpen(false)
  }

  return (
    <div className={styles['report-wrapper']}>
      <div className={styles.report}>
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles['vendor-detials']}>
              {(!!reportData?.thirdPartyDescription || !!questionaireData?.thirdPartyDesc) && (
                <div className={styles['title-caption']}>
                  <div className={styles.p}>
                    <ReactMarkdown
                      // eslint-disable-next-line react/no-children-prop
                      children={
                        reportData?.thirdPartyDescription ?? questionaireData?.thirdPartyDesc
                      }
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                    />
                    {questionaireData?.thirdPartyWebsite && (
                      <div>
                        Domain:{' '}
                        <a
                          href={questionaireData?.thirdPartyWebsite}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {questionaireData?.thirdPartyWebsite}
                        </a>
                      </div>
                    )}
                    {questionaireData?.thirdPartyWebsite && (
                      <div>
                        <>Linked In:</>{' '}
                        <a
                          href={questionaireData?.thirdPartyLinkedIn}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {questionaireData?.thirdPartyLinkedIn}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className={styles['title-caption']}>
                <div className={styles['level-2']}>
                  <div className={styles['text-wrapper-7']}>Scope of work</div>
                </div>
                <p className={styles['text-wrapper-8']}>
                  <ReactMarkdown
                    // eslint-disable-next-line react/no-children-prop
                    children={
                      reportData?.scopeOfService ?? questionaireData?.detailedDescriptionProject
                    }
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  />
                </p>
                <div className={styles['rectangle-2']} />
                <div className={styles['level-2']}>
                  <div className={styles['text-wrapper-7']}>Assessment Framework</div>
                </div>
                <p>
                  <span>
                    <ReactMarkdown
                      className={styles.assessmentFramework}
                      // eslint-disable-next-line react/no-children-prop
                      children={reportData?.assessmentFramework ?? asssementFramework}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                    />
                  </span>
                  <span
                    className={`menu-links ${styles['text-wrapper-2']}`}
                    onClick={() => {
                      setOpenAssessmentFramework(true)
                    }}
                  >
                    {' '}
                    Learn more...
                  </span>
                </p>
              </div>
            </div>
            <div className={styles['divider-2']} />
            <div className={styles['section-2']}>
              <div className={styles['level-4']}>
                <div className={styles['text-wrapper-10']}>Software / Hardware</div>
                <div className={styles['text-wrapper-11']}>{questionaireData?.projectName}</div>
              </div>
              <div className={styles['level-4']}>
                <div className={styles['text-wrapper-10']}>Date Requested</div>
                <div className={styles['text-wrapper-11']}>
                  {moment(assessmentDetails?.requestedDate).format('MMM DD, YYYY')}
                </div>
              </div>
              <div className={styles['level-4']}>
                <div className={styles['text-wrapper-10']}>Requested By</div>
                <div className={styles['text-wrapper-11']}>
                  {questionaireData?.businessRequestorName}
                </div>
              </div>
              <div className={styles['level-4']}>
                <div className={styles['text-wrapper-10']}>Third Party POC</div>
                <div className={styles['text-wrapper-11']}>
                  {questionaireData?.thirdPartyRepresentativeName}
                </div>
              </div>
              <div className={styles['level-4']}>
                <div className={styles['text-wrapper-10']}>Third Party Email</div>
                <div className={styles['text-wrapper-11']}>
                  {questionaireData?.thirdPartyRepresentativeEmail}
                </div>
              </div>
              <div className={styles['level-5']}>
                <div className={styles['level-6']}>
                  <div className={styles['text-wrapper-12']}>Current Status</div>

                  <IconPlaceholder
                    icon={<KebabMenuIcon className={styles['icons-2']} />}
                    size="twenty-x-20"
                    onClick={handleClick}
                  />
                </div>
                <div className={styles['level-7']}>
                  <div className={styles.status}>
                    <div className={styles['status-2']} />
                  </div>
                  <div className={styles['text-wrapper-13']}>
                    {assessmentDetails?.displayStatus}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', gap: '40px' }}>
          {/* COMPANY DATA */}
          <Card className={styles.card}>
            <div style={{ width: '100%' }}>
              <CardHeader
                className={styles.header}
                avatar={
                  <Avatar sx={{ bgcolor: 'rgba(12, 157, 97, 1)' }} aria-label="recipe">
                    <IconPlaceholder
                      icon={<CompanyDataIcon className={styles['icons-2']} />}
                      size="twenty-x-20"
                    />
                  </Avatar>
                }
                title="COMPANY DATA"
                disableTypography={true}
              />
              <div className={styles['card-rectangle']} />
            </div>
            {questionaireData &&
            !!questionaireData.companyDataInvolved &&
            questionaireData.companyDataInvolved === 'Y' ? (
              <CompanyDataElements questionaireData={questionaireData} />
            ) : questionaireData?.typeOfCompanyData &&
              questionaireData?.typeOfCompanyData.length > 0 ? (
              <CardContent>
                <div className={styles['div-2']}>
                  {/* TYPES OF COMPANY DATA */}
                  <>
                    <div className={styles.level}>
                      <ExpandMore
                        expand={typesOfComanyDataExpanded}
                        onClick={() => {
                          setTypesOfComanyDataExpanded(!typesOfComanyDataExpanded)
                        }}
                        aria-expanded={typesOfComanyDataExpanded}
                        aria-label="show more"
                      >
                        <IconPlaceholder
                          icon={<RightArrow className={styles['design-component-instance-node']} />}
                          size="twenty-four-x-24"
                        />
                      </ExpandMore>
                      <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                        Which types of company data will be processed?
                      </p>
                    </div>
                    <Collapse in={typesOfComanyDataExpanded} timeout="auto" unmountOnExit>
                      <CardContent style={{ padding: 0 }}>
                        {' '}
                        <ul className={styles.answer}>
                          {!!questionaireData?.typeOfCompanyData
                            ? questionaireData?.typeOfCompanyData.map((eachData, index) => {
                                if (
                                  eachData === TypesOfCompanyData.IsTherePISPIInvolvedInThisProject
                                ) {
                                  return (
                                    <li key={index} className={styles['text-wrapper-4']}>
                                      {'PI/SPI'}
                                    </li>
                                  )
                                } else
                                  return (
                                    <li key={index} className={styles['text-wrapper-4']}>
                                      {eachData}
                                    </li>
                                  )
                              })
                            : ''}
                        </ul>
                      </CardContent>
                    </Collapse>
                  </>
                  {/* DETAILED DATA ELEMENTS */}
                  {!!questionaireData?.detailedDataElementsCompanyData && (
                    <>
                      <div className={styles.level}>
                        <ExpandMore
                          expand={detailedCompanyData}
                          onClick={() => {
                            setDetailedCompanyData(!detailedCompanyData)
                          }}
                          aria-expanded={detailedCompanyData}
                          aria-label="show more"
                        >
                          <IconPlaceholder
                            icon={
                              <RightArrow className={styles['design-component-instance-node']} />
                            }
                            size="twenty-four-x-24"
                          />
                        </ExpandMore>
                        <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                          Detailed data elements
                        </p>
                      </div>
                      <Collapse in={detailedCompanyData} timeout="auto" unmountOnExit>
                        <CardContent style={{ padding: 0 }}>
                          {' '}
                          <div className={styles['answer-2']}>
                            {!!questionaireData?.detailedDataElementsCompanyData
                              ? questionaireData?.detailedDataElementsCompanyData
                                  .split(',')
                                  .map((eachData, index) => {
                                    return (
                                      <div key={index} className={styles.chips}>
                                        <div className={styles['text-wrapper-6']}>{eachData}</div>
                                      </div>
                                    )
                                  })
                              : ''}
                          </div>
                        </CardContent>
                      </Collapse>
                    </>
                  )}
                </div>
              </CardContent>
            ) : (
              <CardContent>
                <div className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                  {'No Data Invloved'}
                </div>
              </CardContent>
            )}
          </Card>
          {/* CUSTOMER DATA */}
          <Card className={styles.card}>
            <div style={{ width: '100%' }}>
              <CardHeader
                className={styles.header}
                avatar={
                  <Avatar sx={{ bgcolor: 'rgba(58, 112, 226, 1)' }} aria-label="recipe">
                    <IconPlaceholder
                      icon={<CustomerDataIcon className={styles['icons-2']} />}
                      size="twenty-x-20"
                      onClick={handleClick}
                    />
                  </Avatar>
                }
                title="CUSTOMER DATA"
                disableTypography={true}
              />
              <div className={styles['card-rectangle']} />
            </div>
            {questionaireData &&
            !!questionaireData.customerDataInvolved &&
            questionaireData.customerDataInvolved === 'Y' ? (
              <CustomerDataElements questionaireData={questionaireData} />
            ) : questionaireData?.typeOfCustomerData &&
              questionaireData?.typeOfCustomerData.length > 0 ? (
              <CardContent>
                <div className={styles['div-2']}>
                  {/* Types of Customer */}
                  <>
                    <div className={styles.level}>
                      <ExpandMore
                        expand={typesOfCustomerDataExpanded}
                        onClick={() => {
                          setTypesOfCustomerDataExpanded(!typesOfCustomerDataExpanded)
                        }}
                        aria-expanded={typesOfCustomerDataExpanded}
                        aria-label="show more"
                      >
                        <IconPlaceholder
                          icon={<RightArrow className={styles['design-component-instance-node']} />}
                          size="twenty-four-x-24"
                        />
                      </ExpandMore>
                      <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                        Which types of customer data will be processed?
                      </p>
                    </div>
                    <Collapse in={typesOfCustomerDataExpanded} timeout="auto" unmountOnExit>
                      <CardContent style={{ padding: 0 }}>
                        {' '}
                        <ul className={styles.answer}>
                          {!!questionaireData?.typeOfCustomerData
                            ? questionaireData?.typeOfCustomerData.map((eachData, index) => {
                                if (
                                  eachData === TypesOfCustomerData.IsTherePISPIInvolvedInThisProject
                                ) {
                                  return (
                                    <li key={index} className={styles['text-wrapper-4']}>
                                      {'PI/SPI'}
                                    </li>
                                  )
                                } else {
                                  return (
                                    <li key={index} className={styles['text-wrapper-4']}>
                                      {eachData}
                                    </li>
                                  )
                                }
                              })
                            : ''}
                        </ul>
                      </CardContent>
                    </Collapse>
                  </>
                  {/* PSI */}
                  {questionaireData?.typeOfCustomerData?.indexOf(
                    TypesOfCustomerData.IsTherePISPIInvolvedInThisProject,
                  ) > -1 && (
                    <>
                      <div className={styles.level}>
                        <ExpandMore
                          expand={isPSIInvolvedExpand}
                          onClick={() => {
                            setIsPSIInvolvedExpand(!isPSIInvolvedExpand)
                          }}
                          aria-expanded={isPSIInvolvedExpand}
                          aria-label="show more"
                        >
                          <IconPlaceholder
                            icon={
                              <RightArrow className={styles['design-component-instance-node']} />
                            }
                            size="twenty-four-x-24"
                          />
                        </ExpandMore>
                        <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                          Is there a PI/SPI involved in this project?
                        </p>
                      </div>
                      <Collapse in={isPSIInvolvedExpand} timeout="auto" unmountOnExit>
                        <CardContent style={{ padding: 0 }}>
                          {' '}
                          <ul className={styles.answer}>
                            {questionaireData?.typeOfCustomerData?.indexOf(
                              TypesOfCustomerData.IsTherePISPIInvolvedInThisProject,
                            ) > -1 ? (
                              <li className={styles['text-wrapper-4']}>Yes</li>
                            ) : (
                              <li className={styles['text-wrapper-4']}>No</li>
                            )}
                          </ul>
                        </CardContent>
                      </Collapse>
                      {/* NO OF PSI */}
                      <div className={styles.level}>
                        <ExpandMore
                          expand={noOfPSIExpand}
                          onClick={() => {
                            setNoOfPSIExpand(!noOfPSIExpand)
                          }}
                          aria-expanded={noOfPSIExpand}
                          aria-label="show more"
                        >
                          <IconPlaceholder
                            icon={
                              <RightArrow className={styles['design-component-instance-node']} />
                            }
                            size="twenty-four-x-24"
                          />
                        </ExpandMore>
                        <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                          Number of PI/SPI records
                        </p>
                      </div>
                      <Collapse in={noOfPSIExpand} timeout="auto" unmountOnExit>
                        <CardContent style={{ padding: 0 }}>
                          {' '}
                          <ul className={styles.answer}>
                            {!!questionaireData?.noOfPISPIRecordsCustomer ? (
                              <li className={styles['text-wrapper-4']}>
                                {questionaireData.noOfPISPIRecordsCustomer}
                              </li>
                            ) : (
                              ''
                            )}
                          </ul>
                        </CardContent>
                      </Collapse>
                    </>
                  )}

                  {/* DETAILED CUSTOMER DATA */}
                  {!!questionaireData?.detailedDataElementsOfCustomerData && (
                    <>
                      <div className={styles.level}>
                        <ExpandMore
                          expand={detailedCustomerData}
                          onClick={() => {
                            setDetailedCustomerData(!detailedCustomerData)
                          }}
                          aria-expanded={detailedCustomerData}
                          aria-label="show more"
                        >
                          <IconPlaceholder
                            icon={
                              <RightArrow className={styles['design-component-instance-node']} />
                            }
                            size="twenty-four-x-24"
                          />
                        </ExpandMore>
                        <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                          Detailed data elements
                        </p>
                      </div>
                      <Collapse in={detailedCustomerData} timeout="auto" unmountOnExit>
                        <CardContent style={{ padding: 0 }}>
                          {' '}
                          <div className={styles['answer-2']}>
                            {!!questionaireData?.detailedDataElementsOfCustomerData
                              ? questionaireData.detailedDataElementsOfCustomerData
                                  .split(',')
                                  .map((eachData, index) => {
                                    return (
                                      <div key={index} className={styles.chips}>
                                        <div className={styles['text-wrapper-6']}>{eachData}</div>
                                      </div>
                                    )
                                  })
                              : ''}
                          </div>
                        </CardContent>
                      </Collapse>
                    </>
                  )}
                </div>
              </CardContent>
            ) : (
              <CardContent>
                <div className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                  {'No Data Invloved'}
                </div>
              </CardContent>
            )}
          </Card>
        </div>
        <div style={{ width: '100%', display: 'flex', gap: '40px' }}>
          {/* DATA PROCESSING */}
          <Card className={styles.card}>
            <div style={{ width: '100%' }}>
              <CardHeader
                className={styles.header}
                avatar={
                  <Avatar sx={{ bgcolor: 'rgba(216, 132, 12, 1)' }} aria-label="recipe">
                    <IconPlaceholder
                      icon={<DataProcessing className={styles['icons-2']} />}
                      size="thirty-two-x-32"
                    />
                  </Avatar>
                }
                title="DATA PROCESSING"
                disableTypography={true}
              />
              <div className={styles['card-rectangle']} />
            </div>
            <CardContent>
              <div className={styles['div-2']}>
                {/* DATA STORED IN VENDOR LOACTION */}
                <>
                  <div className={styles.level}>
                    <ExpandMore
                      expand={dataStoredinVendorLoactionExpand}
                      onClick={() => {
                        setDataStoredinVendorLoactionExpand(!dataStoredinVendorLoactionExpand)
                      }}
                      aria-expanded={dataStoredinVendorLoactionExpand}
                      aria-label="show more"
                    >
                      <IconPlaceholder
                        icon={<RightArrow className={styles['design-component-instance-node']} />}
                        size="twenty-four-x-24"
                      />
                    </ExpandMore>
                    <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                      Will the data be stored on-site at your company or at a vendor’s location?
                    </p>
                  </div>
                  <Collapse in={dataStoredinVendorLoactionExpand} timeout="auto" unmountOnExit>
                    <CardContent style={{ padding: 0 }}>
                      {' '}
                      <ul className={styles.answer}>
                        {!!questionaireData?.dataStoreLocationType ? (
                          <li className={styles['text-wrapper-4']}>
                            {questionaireData.dataStoreLocationType
                              .replace(/([A-Z])/g, ' $1')
                              .trim()}
                          </li>
                        ) : (
                          ''
                        )}
                      </ul>
                    </CardContent>
                  </Collapse>
                </>
                {/* DATA PROCESSED BY THIRD PARTY */}
                <>
                  <div className={styles.level}>
                    <ExpandMore
                      expand={dataProcessedByThirdParty}
                      onClick={() => {
                        setDataProcessedByThirdParty(!dataProcessedByThirdParty)
                      }}
                      aria-expanded={dataProcessedByThirdParty}
                      aria-label="show more"
                    >
                      <IconPlaceholder
                        icon={<RightArrow className={styles['design-component-instance-node']} />}
                        size="twenty-four-x-24"
                      />
                    </ExpandMore>
                    <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                      Will the data be processed by a third-party subcontractor?
                    </p>
                  </div>
                  <Collapse in={dataProcessedByThirdParty} timeout="auto" unmountOnExit>
                    <CardContent style={{ padding: 0 }}>
                      {' '}
                      <ul className={styles.answer}>
                        {!!questionaireData?.dataProcessedByThirdPartySubContractor ? (
                          <li className={styles['text-wrapper-4']}>
                            {questionaireData.dataProcessedByThirdPartySubContractor === 'NA'
                              ? 'NN/A'
                              : questionaireData.dataProcessedByThirdPartySubContractor === 'Y'
                              ? 'Yes'
                              : 'No'}
                          </li>
                        ) : (
                          ''
                        )}
                      </ul>
                    </CardContent>
                  </Collapse>
                </>
              </div>
            </CardContent>
          </Card>
          {/* LOCATION TRACKING */}
          <Card className={styles.card}>
            <div style={{ width: '100%' }}>
              <CardHeader
                className={styles.header}
                avatar={
                  <Avatar sx={{ bgcolor: 'rgba(12, 157, 97, 1)' }} aria-label="recipe">
                    <IconPlaceholder
                      icon={<LocationTracking className={styles['icons-2']} />}
                      size="thirty-two-x-32"
                    />
                  </Avatar>
                }
                title="LOCATION TRACKING & DEPENDABILITY"
                disableTypography={true}
              />
              <div className={styles['card-rectangle']} />
            </div>
            <CardContent>
              <div className={styles['div-2']}>
                {/* MONITOR LOCATION */}
                <>
                  <div className={styles.level}>
                    <ExpandMore
                      expand={monitorLocationExpanded}
                      onClick={() => {
                        setMonitorLocationExpanded(!monitorLocationExpanded)
                      }}
                      aria-expanded={monitorLocationExpanded}
                      aria-label="show more"
                    >
                      <IconPlaceholder
                        icon={<RightArrow className={styles['design-component-instance-node']} />}
                        size="twenty-four-x-24"
                      />
                    </ExpandMore>
                    <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                      Does this project/system/software continuously monitor the location of
                      individuals, either virtually or physically? does it track employee locations
                      using mobile work devices?
                    </p>
                  </div>
                  <Collapse in={monitorLocationExpanded} timeout="auto" unmountOnExit>
                    <CardContent style={{ padding: 0 }}>
                      {' '}
                      <ul className={styles.answer}>
                        <li className={styles['text-wrapper-4']}>
                          {questionaireData?.trackingOfIndividual === 'NA'
                            ? 'NN/A'
                            : questionaireData?.trackingOfIndividual === 'Y'
                            ? 'Yes'
                            : 'No'}
                        </li>
                      </ul>
                    </CardContent>
                  </Collapse>
                </>
                {/* MOBILE APP INVOLVED */}
                <>
                  <div className={styles.level}>
                    <ExpandMore
                      expand={mobileAppInvolvedExpanded}
                      onClick={() => {
                        setMobileAppInvolvedExpanded(!mobileAppInvolvedExpanded)
                      }}
                      aria-expanded={mobileAppInvolvedExpanded}
                      aria-label="show more"
                    >
                      <IconPlaceholder
                        icon={<RightArrow className={styles['design-component-instance-node']} />}
                        size="twenty-four-x-24"
                      />
                    </ExpandMore>
                    <p className={styles['text-wrapper-2']} style={{ paddingTop: '10px' }}>
                      Is there a Mobile app involved in this engagement that will contain scoped
                      data?
                    </p>
                  </div>
                  <Collapse in={mobileAppInvolvedExpanded} timeout="auto" unmountOnExit>
                    <CardContent style={{ padding: 0 }}>
                      {' '}
                      <ul className={styles.answer}>
                        <li className={styles['text-wrapper-4']}>
                          {questionaireData?.mobileAppInvolved === 'NA'
                            ? 'NN/A'
                            : questionaireData?.mobileAppInvolved === 'Y'
                            ? 'Yes'
                            : 'No'}
                        </li>
                      </ul>
                    </CardContent>
                  </Collapse>
                </>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      {/* <div className={styles['rectangle-4']} /> */}
      {open && (
        <ClickAwayListener onClickAway={clickAwayHandler}>
          <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
            <StatusCard assessmentData={assessmentDetails} />
          </Popper>
        </ClickAwayListener>
      )}

      <AssessmentFramework
        open={openAssessmentFramework}
        handleAction={setOpenAssessmentFramework}
        assessmentFramework={reportData?.assessmentFramework ?? asssementFramework}
      />
    </div>
  )
}

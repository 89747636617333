import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

const TableRowsLoader = ({ rows = 2, columns = 9, tableCellClassName }) => {
  return [...Array(rows)].map((row, index) => (
    <TableRow key={index}>
      {[...Array(columns)].map((cell, index) => (
        <TableCell key={index} className={tableCellClassName}>
          <Skeleton animation="wave" variant="text" style={{ backgroundColor: '#ffffff1c' }} />
        </TableCell>
      ))}
    </TableRow>
  ))
}

export default TableRowsLoader

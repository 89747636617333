import React, { useState } from 'react'
import { Frame } from './sections/Frame'
import classes from './style.module.css'
import SideDrawer from './sections/SideDrawer/SideDrawer'
import { RemediationTable } from './sections/Table/Table'
import { remediationMenuItems } from 'src/utils/constants'
import { endOfWeek, startOfYear } from 'date-fns'
import { hasValue } from 'src/utils/commonFns'
import dayjs from 'dayjs'

const Remediation = () => {
  const [openDrawer, setOpenDrawer] = useState(null)
  const [selectedRemediationType, setSelectedRemediationType] = React.useState(
    remediationMenuItems[0],
  )
  const [searchQuery, setSearchQuery] = useState('')
  const [refreshTable, setRefreshTable] = useState(null)

  const [dateRangeValue, setDateRangeValue] = React.useState({
    startDate: dayjs(startOfYear(new Date())),
    endDate: dayjs(endOfWeek(new Date())),
    selectedOption: 'This Week',
  })

  return (
    <div className={classes.section}>
      <Frame
        {...{
          selectedRemediationType,
          setSelectedRemediationType,
          searchQuery,
          setSearchQuery,
          dateRangeValue,
          setDateRangeValue,
        }}
      />
      <RemediationTable
        {...{ setOpenDrawer, selectedRemediationType, searchQuery, dateRangeValue, refreshTable }}
      />
      {hasValue(openDrawer) && <SideDrawer {...{ openDrawer, setOpenDrawer, setRefreshTable }} />}
    </div>
  )
}

export default Remediation

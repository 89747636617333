import React, { useRef } from 'react'
import classes from './style.module.css'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { styled, alpha } from '@mui/material/styles'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { ExpandIcon } from 'src/v2/assets/icons/remediation/header/expand'
import { hasValue } from 'src/utils/commonFns'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: '#202020',
    color: 'var(--Grey-900, #E8E8E8)',
    // boxShadow:
    //   'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    // '& .MuiMenu-list': {
    //   padding: '4px 0',
    // },
    '& .MuiMenuItem-root': {
      gap: '8px',
      borderBottom: '0.2px solid #ffffff14',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

export const CategoryDropDown = ({ menuItems, selectedMenu, setSelectedMenu, count }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const menuRef = useRef(null)

  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (event, index, option) => {
    setSelectedMenu(option)
    setSelectedIndex(index)
    setAnchorEl(null)
  }

  return (
    <>
      <div className={classes.title}>
        <div ref={menuRef} className={classes['title-2']}>
          {selectedMenu}
          {hasValue(count) ? ` (${count})` : ''}
        </div>
        <IconPlaceholder
          className={classes['design-component-instance-node']}
          icon={<ExpandIcon className={classes['icons-6']} color="#7F8082" />}
          size="twenty-eight-x-28"
          onClick={(e) => {
            setAnchorEl(menuRef.current)
          }}
        />
      </div>
      <StyledMenu id="lock-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index, option)}
          >
            {option}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useRef } from 'react'
import styles from './Lines.module.css'

export default function Lines({ status, color, percentage, value, handleDrawerOpen }) {
  const containerRef = useRef(null)

  const getContainerWidth = useCallback(() => {
    return containerRef?.current?.offsetWidth
      ? parseInt(parseInt(containerRef?.current?.offsetWidth) / 8) + 1
      : 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, percentage])

  return (
    <>
      <div className={styles.container} style={{ borderLeftColor: color, width: `${percentage}%` }}>
        <div className={styles.value}>
          <div className={styles.title}>
            <a className={styles.inProgress} onClick={handleDrawerOpen}>
              {status}
            </a>
          </div>
          <div className={styles.value2}>
            {value > 0 ? (
              <a className={styles.a1} onClick={handleDrawerOpen}>
                {value}
              </a>
            ) : (
              <a className={styles.a1} onClick={handleDrawerOpen}>
                {0}
              </a>
            )}
          </div>
        </div>
        <div className={styles.shapeContainerParent} ref={containerRef}>
          {getContainerWidth() > 0 &&
            percentage > 0 &&
            Array(getContainerWidth())
              .fill(0)
              .map((x, index) => (
                <div
                  key={index}
                  className={styles.shapeContainer}
                  style={{ backgroundColor: color }}
                />
              ))}
        </div>
      </div>
    </>
  )
}

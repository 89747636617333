import axios from 'axios'
import AppConfig from '../config'

const baseURL = `${AppConfig.API_URL}/CyberScan`

const getCyberScanVendorList = async (businessOrgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/get-vendors-list/${businessOrgId}`)

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getCyberScanVendorDetails = async (insecureWebScanId) => {
  try {
    let { data } = await axios.get(`${baseURL}/get-vendors-details/${insecureWebScanId}`)

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getSecurityFindingList = async (entityId, page, rowsPerPage) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-security-findings/${entityId}?pageNumber=${page || 0}&pageSize=${
        rowsPerPage || 10
      }`,
    )

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getImpactedAssetsList = async (entityId, datapoint, page, rowsPerPage) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-impacted-assets/${entityId}?datapoint=${datapoint}&pageNumber=${
        page || 0
      }&pageSize=${rowsPerPage || 10}`,
    )

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getRiskProfileStats = async (entityId) => {
  try {
    let { data } = await axios.get(`${baseURL}/get-risk-profile-stats/${entityId}`)

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getAssetStats = async (entityId) => {
  try {
    let { data } = await axios.get(`${baseURL}/get-attack-surface-asset-stats/${entityId}`)

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getAssetDomains = async (entityId, pageNumber, pageSize) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-attack-surface-domains/${entityId}?pageNumber=${pageNumber || 0}&pageSize=${
        pageSize || 10
      }`,
    )

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getAssetIPAddresses = async (entityId, pageNumber, pageSize) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-attack-surface-ipaddresses/${entityId}?pageNumber=${
        pageNumber || 0
      }&pageSize=${pageSize || 10}`,
    )

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getAssetTechs = async (entityId, pageNumber, pageSize, category) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-attack-surface-techs/${entityId}?pageNumber=${pageNumber || 0}&pageSize=${
        pageSize || 10
      }&category=${category || 0}`,
    )

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getEntityAssetFindings = async (entityId, assetId, pageNumber, pageSize) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-entity-assets-findings/${entityId}?assetId=${assetId}&pageNumber=${
        pageNumber || 0
      }&pageSize=${pageSize || 10}`,
    )

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getEntityDomainIPAddress = async (entityId, ipaddress, pageNumber, pageSize) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-entity-domains-ipaddress/${entityId}?ipaddress=${ipaddress}&pageNumber=${
        pageNumber || 0
      }&pageSize=${pageSize || 10}`,
    )

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const getExternalAttackSurfaceStats = async (businessOrgId) => {
  try {
    let { data } = await axios.get(`${baseURL}/get-external-attack-surface-stats/${businessOrgId}`)

    if (data.success) {
      return data.result
    } else {
      console.log(data.error)
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export {
  getCyberScanVendorList,
  getCyberScanVendorDetails,
  getSecurityFindingList,
  getImpactedAssetsList,
  getRiskProfileStats,
  getAssetStats,
  getAssetDomains,
  getAssetIPAddresses,
  getAssetTechs,
  getEntityAssetFindings,
  getEntityDomainIPAddress,
  getExternalAttackSurfaceStats,
}

import React, { useEffect, useMemo, useState } from 'react'
import styles from './style.module.css'
import { EADoughnutChart } from 'src/v2/components/ea-donought'
import { ReportVulnerabilities } from './vulnerabilities'
import ReportAssetsChart from './assets-chart'
import { ReportAssetsStatusChart } from './assets-status-chart'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { AngledArrrow } from 'src/v2/icons/angled-arrow'
import { useNavigate } from 'react-router-dom'
import { getAssetStats } from 'src/services/cyberScanService'
import { hasValue } from 'src/utils/commonFns'

export const AttackSurface = ({ insecureWebScanId, coalitionEntityId }) => {
  let navigate = useNavigate()

  const [assetLoading, setAssetLoading] = useState(false)
  const [assetStats, setAssetStats] = useState({})

  const assetsTotalSum = useMemo(
    () =>
      assetStats?.domainCount +
        assetStats?.ipAddressCount +
        assetStats?.techAppCount +
        assetStats?.techServicesCount +
        assetStats?.techHostingCount +
        assetStats?.techMiscCount || 0,
    [assetStats],
  )
  useEffect(() => {
    const fetchAssetStats = async () => {
      try {
        setAssetLoading(true)
        const data = await getAssetStats(coalitionEntityId)
        setAssetStats(data)
      } finally {
        setAssetLoading(false)
      }
    }
    if (hasValue(coalitionEntityId)) {
      fetchAssetStats()
    }
  }, [coalitionEntityId])

  return (
    <div className={styles['attack-surface']}>
      <div className={styles.container}>
        <div className={styles['section-1']}>
          <div className={styles['div-wrapper']}>
            <div className={styles['text-wrapper-12']}>External Attack surface</div>

            <div className={styles['speed-chart']}>
              <div className={styles['overlap']}>
                <EADoughnutChart value={assetStats.riskScore || 0} loading={assetLoading} />
              </div>
            </div>
            <div className={styles['legends']}>
              <div className={styles['div-2']}>
                <div className={styles['rectangle-5']} />
                <div className={styles['text-wrapper-13']}>Low (&lt;40)</div>
              </div>
              <div className={styles['div-2']}>
                <div className={styles['rectangle-6']} />
                <div className={styles['text-wrapper-13']}>Medium(40-70)</div>
              </div>
              <div className={styles['div-2']}>
                <div className={styles['rectangle-7']} />
                <div className={styles['text-wrapper-13']}>High(&gt;70)</div>
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.heading}>
              <div className={styles['text-wrapper-14']}>Vulnerabilities</div>
              {insecureWebScanId > 0 && (
                <div
                  className={`${'menu-links'} ${styles.button}`}
                  onClick={() => {
                    navigate(`/cyberscanning/${insecureWebScanId}`)
                  }}
                >
                  <div className={styles.text}>View details</div>
                  <IconPlaceholder
                    icon={<AngledArrrow className={styles['icons-2']} />}
                    size="twenty-x-20"
                  />
                </div>
              )}
            </div>

            <div className={styles['risk-level']}>
              <div className={styles.high}>
                <div className={styles['value-4']}>
                  <div className={styles['value-5']}>
                    <div className={styles['ellipse']} />
                    <div className={styles['text-wrapper-8']}>Critical</div>
                  </div>
                  <div className={styles['value-3']}>
                    <div className={styles['text-wrapper-10']}>
                      {assetStats.criticalFindingsCount || 0}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.high}>
                <div className={styles['value-4']}>
                  <div className={styles['value-5']}>
                    <div className={styles['ellipse-2']} />
                    <div className={styles['text-wrapper-8']}>High</div>
                  </div>
                  <div className={styles['value-3']}>
                    <div className={styles['text-wrapper-10']}>
                      {assetStats.highFindingsCount || 0}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.high}>
                <div className={styles['value-4']}>
                  <div className={styles['value-5']}>
                    <div className={styles['ellipse-3']} />
                    <div className={styles['text-wrapper-8']}>Medium</div>
                  </div>
                  <div className={styles['value-3']}>
                    <div className={styles['text-wrapper-10']}>
                      {assetStats.mediumFindingsCount || 0}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.high}>
                <div className={styles['value-4']}>
                  <div className={styles['value-2']}>
                    <div className={styles['ellipse-4']} />
                    <div className={styles['text-wrapper-8']}>Low</div>
                  </div>
                  <div className={styles['value-3']}>
                    <div className={styles['text-wrapper-10']}>
                      {assetStats.lowFindingsCount || 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ReportVulnerabilities
              critical={assetStats.criticalFindingsCount || 0}
              high={assetStats.highFindingsCount || 0}
              medium={assetStats.mediumFindingsCount || 0}
              low={assetStats.lowFindingsCount || 0}
              loading={assetLoading}
            />
          </div>
        </div>
        <div className={styles['divider-5']} />
        <div className={styles['assets-status']}>
          <div className={styles['section-wrapper']}>
            <div className={styles['chart-1']}>
              <div className={styles['text-wrapper-31']}>Assets</div>

              <ReportAssetsChart
                chartSeries={[
                  assetStats?.domainCount || 0,
                  assetStats?.ipAddressCount || 0,
                  assetStats?.techAppCount +
                    assetStats?.techServicesCount +
                    assetStats?.techHostingCount +
                    assetStats?.techMiscCount || 0,
                ]}
                loading={assetLoading}
              />
              <div className={styles['risk-level-2']}>
                <div className={styles['high-4']}>
                  <div className={styles['value-14']}>
                    <div className={styles['value-15']}>
                      <div className={styles['ellipse-12']} />
                      <div className={styles['text-wrapper-33']}>Domain</div>
                    </div>
                    <div className={styles['value-16']}>
                      <p className={styles['element-3']}>
                        <span className={styles['text-wrapper-34']}>
                          {assetStats?.domainCount || 0}
                        </span>
                        <span className={styles['text-wrapper-35']}>&nbsp;</span>
                        <span className={styles['text-wrapper-36']}>
                          (
                          {`${
                            assetStats?.domainCount > 0
                              ? parseFloat(
                                  (assetStats?.domainCount / assetsTotalSum) * 100,
                                ).toFixed(2)
                              : 0
                          }%`}
                          )
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles['high-4']}>
                  <div className={styles['value-17']}>
                    <div className={styles['value-18']}>
                      <div className={styles['ellipse-13']} />
                      <div className={styles['text-wrapper-33']}>IP Address</div>
                    </div>
                    <div className={styles['value-16']}>
                      <p className={styles['element-3']}>
                        <span className={styles['text-wrapper-34']}>
                          {assetStats?.ipAddressCount || 0}
                        </span>
                        <span className={styles['text-wrapper-35']}>&nbsp;</span>
                        <span className={styles['text-wrapper-36']}>
                          (
                          {`${
                            assetStats?.ipAddressCount > 0
                              ? parseFloat(
                                  (assetStats?.ipAddressCount / assetsTotalSum) * 100,
                                ).toFixed(2)
                              : 0
                          }%`}
                          )
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles['high-4']}>
                  <div className={styles['value-14']}>
                    <div className={styles['value-15']}>
                      <div className={styles['ellipse-14']} />
                      <div className={styles['text-wrapper-33']}>Technologies</div>
                    </div>
                    <div className={styles['value-16']}>
                      <p className={styles['element-3']}>
                        <span className={styles['text-wrapper-34']}>
                          {assetStats?.techAppCount +
                            assetStats?.techServicesCount +
                            assetStats?.techHostingCount +
                            assetStats?.techMiscCount || 0}
                        </span>
                        <span className={styles['text-wrapper-35']}>&nbsp;</span>
                        <span className={styles['text-wrapper-36']}>
                          (
                          {`${
                            assetStats?.techAppCount +
                              assetStats?.techServicesCount +
                              assetStats?.techHostingCount +
                              assetStats?.techMiscCount >
                            0
                              ? parseFloat(
                                  ((assetStats?.techAppCount +
                                    assetStats?.techServicesCount +
                                    assetStats?.techHostingCount +
                                    assetStats?.techMiscCount) /
                                    assetsTotalSum) *
                                    100,
                                ).toFixed(2)
                              : 0
                          }%`}
                          )
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['chart-2']}>
              <div className={styles.heading}>
                <div className={styles['text-wrapper-37']}>Assets Status</div>
                {insecureWebScanId > 0 && (
                  <div
                    className={`${'menu-links'} ${styles.button}`}
                    onClick={() => {
                      navigate(`/cyberscanning/${insecureWebScanId}`)
                    }}
                  >
                    <div className={styles.text}>View details</div>
                    <IconPlaceholder
                      icon={<AngledArrrow className={styles['icons-2']} />}
                      size="twenty-x-20"
                    />
                  </div>
                )}
              </div>
              <ReportAssetsStatusChart />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

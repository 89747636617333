import React from 'react'
import { Icons7 } from '../../../../icons/Icons7'
import { Icons8 } from '../../../../icons/Icons8'
import Button from '@mui/material/Button'
import classes from './style.module.css'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { styled, alpha } from '@mui/material/styles'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: 'var(--BG---100, #2C2C2C)',
    color: 'var(--Grey-900, #E8E8E8)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      gap: '8px',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

const menuItems = [
  'Software Development',
  'Quality Assurance (QA)',
  'Information Technology (IT)',
  'Cyber Security',
  'Data Analytics',
  'Technical Operations',
]

export const Department = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        className={classes['date-filter']}
        startIcon={<Icons7 className={classes['icon-instance-node']} />}
        endIcon={<Icons8 className={classes['icon-instance-node']} />}
        variant="outlined"
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}
      >
        Department (6)
      </Button>
      <StyledMenu id="lock-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}

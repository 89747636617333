/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Paper from '@mui/material/Paper'
import styles from './AssessmentPipeline.module.css'
import InfoIcon from '../../../icons/InfoIcon.svg'
import Stack from '@mui/material/Stack'
import Lines from './Lines'
import CTooltip from 'src/v2/components/Tooltip/Tooltip'
import Typography from '@mui/material/Typography'
import { EnumAssessmentTypes } from 'src/utils/commonFns'

export default function AssessmentsPipeline({ handleDrawerOpen, assessmentPipelineData }) {
  const inprogress = assessmentPipelineData?.inprogress
  const completed = assessmentPipelineData?.completed
  const nextReview = assessmentPipelineData?.nextReview
  const totalSum = inprogress + completed + nextReview
  return (
    <Paper className={styles.section} elevation={3}>
      <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
        <div className={styles.section1}>
          <div className={styles.dataContainer}>
            <h3 className={styles.titleData}>{`Assessments In Pipeline `}</h3>
            <div className={styles.dataContainerInner}>
              <div className={styles.titleDataWrapper}>
                {totalSum > 0 ? (
                  <div className={styles.titleData1}> {totalSum}</div>
                ) : (
                  <div className={styles.titleData1}> {0}</div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.iconPlaceholder}>
            <CTooltip
              arrow
              title={
                <React.Fragment>
                  <Typography variant="body2" fontWeight={500} sx={{ mb: '4px' }}>
                    Assessments In Pipeline
                  </Typography>
                  <Typography variant="body2">
                    A Vendor Assessment Risk Metrics Chart is a visual tool that summarizes the
                    overall risk level of evaluated vendors. It combines various risk indicators.
                  </Typography>
                </React.Fragment>
              }
            >
              <img className={styles.icons} loading="lazy" alt="" src={InfoIcon} />
            </CTooltip>
          </div>
        </div>
        <div className={styles.statuscontainer}>
          <div className={styles.sectionInner}>
            <div className={styles.frameChild} />
          </div>
          <div className={styles.inProgressWrapper}>
            <a className={styles.inProgress}>In Progress</a>
          </div>
          <div className={styles.sectionChild}>
            <div className={styles.frameItem} />
          </div>
          <div className={styles.completedWrapper}>
            <a className={styles.completed}>Completed</a>
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.frameInner} />
          </div>
          <a className={styles.dueForNext}>Due for next review</a>
        </div>
      </Stack>
      <Stack
        direction="row"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        <Lines
          status={'In Progress'}
          color={'#fe9b0e'}
          percentage={parseInt((inprogress / totalSum) * 100)}
          value={inprogress}
          handleDrawerOpen={() => handleDrawerOpen(EnumAssessmentTypes.Inprogress)}
        />
        <Lines
          status={'Completed'}
          color={'#3A70E2'}
          percentage={parseInt((completed / totalSum) * 100)}
          value={completed}
          handleDrawerOpen={() => handleDrawerOpen(EnumAssessmentTypes.Completed)}
        />
        <Lines
          status={'Due for next review'}
          color={'#F58B8D'}
          percentage={parseInt((nextReview / totalSum) * 100)}
          value={nextReview}
          handleDrawerOpen={() => handleDrawerOpen(EnumAssessmentTypes.DueForNextReview)}
        />
      </Stack>
    </Paper>
  )
}

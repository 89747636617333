import React from 'react'

export const SuccessTickIcon = ({ className, size = 20 }) => {
  return (
    <svg
      className={`icons-11 ${className}`}
      fill="none"
      height={size}
      viewBox="0 0 20 20"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height={size}
        id="mask0_1248_3160"
        maskUnits="userSpaceOnUse"
        width={size}
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height={size} width={size} />
      </mask>
      <g className="g" mask="url(#mask0_1248_3160)">
        <path
          className="path"
          d="M8.81746 11.7887L6.88162 9.85266C6.7662 9.73739 6.62114 9.67836 6.44641 9.67558C6.27183 9.67294 6.12412 9.73197 6.00329 9.85266C5.88259 9.9735 5.82225 10.1199 5.82225 10.2918C5.82225 10.4638 5.88259 10.6102 6.00329 10.731L8.29016 13.0179C8.44086 13.1684 8.61662 13.2437 8.81746 13.2437C9.01829 13.2437 9.19405 13.1684 9.34475 13.0179L13.981 8.38162C14.0963 8.2662 14.1553 8.12113 14.1581 7.94641C14.1607 7.77183 14.1017 7.62412 13.981 7.50329C13.8602 7.38259 13.7138 7.32225 13.5418 7.32225C13.3699 7.32225 13.2235 7.38259 13.1027 7.50329L8.81746 11.7887ZM10.0016 17.9168C8.90662 17.9168 7.87739 17.7091 6.91391 17.2935C5.95044 16.8779 5.11238 16.314 4.39975 15.6016C3.68711 14.8893 3.12287 14.0516 2.70704 13.0885C2.29134 12.1254 2.0835 11.0965 2.0835 10.0016C2.0835 8.90662 2.29127 7.87739 2.70683 6.91391C3.12239 5.95044 3.68634 5.11238 4.3987 4.39975C5.11107 3.68711 5.94877 3.12287 6.91183 2.70704C7.87489 2.29134 8.90384 2.0835 9.9987 2.0835C11.0937 2.0835 12.1229 2.29127 13.0864 2.70683C14.0499 3.12238 14.8879 3.68634 15.6006 4.3987C16.3132 5.11107 16.8775 5.94877 17.2933 6.91183C17.709 7.87489 17.9168 8.90384 17.9168 9.9987C17.9168 11.0937 17.7091 12.1229 17.2935 13.0864C16.8779 14.0499 16.314 14.8879 15.6016 15.6006C14.8893 16.3132 14.0516 16.8775 13.0885 17.2933C12.1254 17.709 11.0965 17.9168 10.0016 17.9168ZM10.0002 16.6668C11.8613 16.6668 13.4377 16.021 14.7293 14.7293C16.021 13.4377 16.6668 11.8613 16.6668 10.0002C16.6668 8.13905 16.021 6.56266 14.7293 5.271C13.4377 3.97933 11.8613 3.3335 10.0002 3.3335C8.13905 3.3335 6.56266 3.97933 5.271 5.271C3.97933 6.56266 3.3335 8.13905 3.3335 10.0002C3.3335 11.8613 3.97933 13.4377 5.271 14.7293C6.56266 16.021 8.13905 16.6668 10.0002 16.6668Z"
          fill="#30AC79"
        />
      </g>
    </svg>
  )
}

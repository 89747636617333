import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
} from '@mui/material'
import classes from './style.module.scss'
import { menus } from '../../assets-list'

export const AssetDetailOverview = ({ assessmentInfo, assetInfo, selectedMenu }) => {
  const data = selectedMenu === menus.Domain ? assetInfo.dns_records : assetInfo.domains
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [dnsRecords, setDNSRecords] = useState([])

  const headDNSCells =
    selectedMenu === menus.Domain
      ? [
          {
            name: 'TYPE',
            disableSort: true,
          },
          {
            name: 'ANSWERS',
            disableSort: true,
          },
        ]
      : [
          {
            name: 'Domain',
            disableSort: true,
          },
        ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    const startIndex = page * rowsPerPage
    const endIndex = Math.min(startIndex + rowsPerPage, data.length)
    setDNSRecords(data.slice(startIndex, endIndex))
  }, [page, rowsPerPage, data])

  return (
    <div className={styles.frametab2}>
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.div}>
            <div className={styles['title-2']}>
              <div className={styles['text-wrapper']}>
                {selectedMenu === menus.Domain ? ' DNS Records' : 'Domains'}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.description}>
          <p className={styles.p}>
            {selectedMenu === menus.Domain
              ? 'DNS Records associated with this specific asset.'
              : 'Latest Domains found to be associated with this specific asset.'}
          </p>
        </div>
      </div>
      <div className={classes['table-wrapper']}>
        <Box className={classes.content}>
          <TableContainer className={classes.table}>
            <Table size={'medium'}>
              <TableHead className={classes.tablehead}>
                <TableRow>
                  {headDNSCells.map((headCell) => (
                    <TableCell className={classes.tableheadcell} key={headCell.name}>
                      <>{headCell.name}</>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tablebody}>
                {dnsRecords.length > 0 ? (
                  dnsRecords.map((row, index) => (
                    <TableRow key={index} className={`${'menu-links'} ${classes['vendor-row']}`}>
                      {selectedMenu === menus.Domain ? (
                        <>
                          <TableCell className={classes.tablecell}>
                            <Chip label={row.type} variant="outlined" className={classes.chip} />
                          </TableCell>
                          <TableCell className={classes.tablecell}>
                            {row.answers?.map((x) => x.answer)?.join(', ')}
                          </TableCell>
                        </>
                      ) : (
                        <TableCell className={classes.tablecell}>{row}</TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={selectedMenu === menus.Domain ? 2 : 1}
                      className={classes.tablecell}
                      style={{ textAlign: 'center' }}
                    >
                      <h6 className={styles.norecords}>No Records to Display</h6>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {/* </div> */}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.tablepagination}
            classes={{
              selectLabel: classes.tablePaginationSelectLabel,
              displayedRows: classes.tablePaginationDisplayedRows,
              selectIcon: classes.tablePaginationSelectIcon,
              actions: classes.tablePaginationActions,
            }}
          />
        </Box>
      </div>
    </div>
  )
}

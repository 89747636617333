import React from 'react'

export const RiskIcon = ({ className }) => {
  return (
    <svg
      className={`icons-6 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="20"
        id="mask0_523_12960"
        maskUnits="userSpaceOnUse"
        width="20"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" />
      </mask>
      <g className="g" mask="url(#mask0_523_12960)">
        <path
          className="path"
          d="M10 13.942C10.1907 13.942 10.3506 13.8775 10.4796 13.7484C10.6086 13.6194 10.6731 13.4595 10.6731 13.2688C10.6731 13.0781 10.6086 12.9183 10.4796 12.7893C10.3506 12.6604 10.1907 12.5959 10 12.5959C9.80931 12.5959 9.64945 12.6604 9.52042 12.7893C9.39139 12.9183 9.32688 13.0781 9.32688 13.2688C9.32688 13.4595 9.39139 13.6194 9.52042 13.7484C9.64945 13.8775 9.80931 13.942 10 13.942ZM10.0002 10.8972C10.1774 10.8972 10.3258 10.8372 10.4454 10.7174C10.5651 10.5977 10.625 10.4493 10.625 10.2722V6.52217C10.625 6.34509 10.5651 6.19662 10.4452 6.07676C10.3253 5.95704 10.1769 5.89717 9.99979 5.89717C9.82257 5.89717 9.67417 5.95704 9.55459 6.07676C9.43486 6.19662 9.375 6.34509 9.375 6.52217V10.2722C9.375 10.4493 9.43493 10.5977 9.55479 10.7174C9.67466 10.8372 9.82313 10.8972 10.0002 10.8972ZM10.0015 17.9163C8.90646 17.9163 7.87722 17.7086 6.91375 17.293C5.95028 16.8775 5.11222 16.3135 4.39959 15.6011C3.68695 14.8888 3.12271 14.0511 2.70688 13.088C2.29118 12.125 2.08334 11.096 2.08334 10.0011C2.08334 8.90613 2.29111 7.8769 2.70667 6.91342C3.12222 5.94995 3.68618 5.1119 4.39854 4.39926C5.11091 3.68662 5.94861 3.12238 6.91167 2.70655C7.87472 2.29085 8.90368 2.08301 9.99854 2.08301C11.0935 2.08301 12.1228 2.29079 13.0863 2.70634C14.0497 3.1219 14.8878 3.68586 15.6004 4.39822C16.3131 5.11058 16.8773 5.94829 17.2931 6.91134C17.7088 7.8744 17.9167 8.90335 17.9167 9.99822C17.9167 11.0932 17.7089 12.1225 17.2933 13.0859C16.8778 14.0494 16.3138 14.8875 15.6015 15.6001C14.8891 16.3127 14.0514 16.877 13.0883 17.2928C12.1253 17.7085 11.0963 17.9163 10.0015 17.9163ZM10 16.6663C11.8611 16.6663 13.4375 16.0205 14.7292 14.7288C16.0208 13.4372 16.6667 11.8608 16.6667 9.99967C16.6667 8.13856 16.0208 6.56217 14.7292 5.27051C13.4375 3.97884 11.8611 3.33301 10 3.33301C8.13889 3.33301 6.5625 3.97884 5.27084 5.27051C3.97917 6.56217 3.33334 8.13856 3.33334 9.99967C3.33334 11.8608 3.97917 13.4372 5.27084 14.7288C6.5625 16.0205 8.13889 16.6663 10 16.6663Z"
          fill="#EF4D4F"
        />
      </g>
    </svg>
  )
}

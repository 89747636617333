import React from 'react'

export const BlueTentacleIcon = ({ size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="33" viewBox="0 0 50 33" fill="none">
      <path
        d="M18.7987 30L18.3753 30.2661L18.5224 30.5H18.7987V30ZM43.9056 30C43.9056 31.4728 45.0995 32.6667 46.5723 32.6667C48.045 32.6667 49.2389 31.4728 49.2389 30C49.2389 28.5272 48.045 27.3333 46.5723 27.3333C45.0995 27.3333 43.9056 28.5272 43.9056 30ZM0.148933 1.26606L18.3753 30.2661L19.222 29.7339L0.995598 0.733937L0.148933 1.26606ZM18.7987 30.5H46.5723V29.5H18.7987V30.5Z"
        fill="#5885E6"
      />
    </svg>
  )
}

import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism'

const Terminal = ({ command }) => {
  return (
    <div style={styles.terminalContainer}>
      <div style={styles.terminalHeader}>
        <div style={styles.circle} />
        <div style={{ ...styles.circle, backgroundColor: '#f4bf75' }} />
        <div style={{ ...styles.circle, backgroundColor: '#61c554' }} />
      </div>
      <div style={styles.terminalBody}>
        <SyntaxHighlighter language="bash" style={oneDark}>
          {command}
        </SyntaxHighlighter>
      </div>
    </div>
  )
}

const styles = {
  terminalContainer: {
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    fontFamily: 'monospace',
    backgroundColor: '#2d2d2d',
  },
  terminalHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#1c1c1c',
  },
  circle: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#ff5f57',
    marginRight: '8px',
  },
  terminalBody: {
    backgroundColor: '#2d2d2d',
    color: '#f8f8f2',
  },
}

export default Terminal

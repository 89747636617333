import React, { useState } from 'react'
import classes from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { Button } from '@mui/material'
import { CloseIcon } from 'src/v2/assets/icons/remediation/sidesheet/close'
import { EnumRemediationGapStatus } from 'src/utils/constants'
import { initiateRemediation, updateRemediationGapStatus } from 'src/services/remediationService'
import Confirmation from 'src/v2/components/Confirmation'
import { useSnackbar } from 'notistack'
import CircularProgress from '@mui/material/CircularProgress'
import { useAuthState } from 'src/context/login'

export const Header = ({
  remediation,
  setOpenDrawer,
  setRefreshTable,
  isFromReport,
  clientSubmissionID,
}) => {
  const { user } = useAuthState()
  const [openConfirmation, setOpenConfirmation] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)

  const handleConfirmationClose = async (isContinue) => {
    setOpenConfirmation(false)
    if (isContinue) {
      try {
        setLoading(true)
        if (remediation.remediationGapId > 0) {
          const { result } = await updateRemediationGapStatus({
            RemediationGapIds: [remediation.remediationGapId],
            RemediationGapStatus: 'Closed',
          })

          if (result) {
            enqueueSnackbar('Remediation accepted successfully.', {
              heading: 'Accepted',
            })
            setOpenDrawer(null)
            setRefreshTable(Date.now())
          }
        } else {
          await handleInitiateRemediation()
        }
      } finally {
        setLoading(false)
      }
    }
  }

  const handleInitiateRemediation = async () => {
    var request = {
      businessOrgId: user.businessOrganizationId,
      clientSubmissionID: clientSubmissionID,
      gaps: [
        {
          reportReferenceId: remediation.id,
          domainID: remediation.domainId,
          issueIdentified: remediation.issueIdentified,
          IssueSeverity: remediation.issueSeverity,
          risk: remediation.risk,
          remediation: remediation.remediation,
          evidence: remediation.evidence,
        },
      ],
    }
    var response = await initiateRemediation(request)

    if (response) {
      enqueueSnackbar('Remediation Initiated Successfully.', {
        heading: 'Initiated',
      })
      setOpenDrawer(null)
      setRefreshTable(Date.now())
    }
  }

  return (
    <header className={classes.header}>
      <div className={classes['title-close']}>
        <div className={classes.div}>
          <div className={classes.title}>{remediation.domainName}</div>
          <div className={classes['link-2']}>
            <div className={classes.date}>Remediation</div>
            {!isFromReport && (
              <>
                <div className={classes.ellipse} />
                <div className={classes['view-report']}>View in Report</div>
              </>
            )}
          </div>
        </div>
        <IconPlaceholder
          icon={<CloseIcon className={classes['icon-instance-node']} />}
          size="twenty-eight-x-28"
          onClick={() => setOpenDrawer(null)}
        />
      </div>
      <div className={classes.btncontainer}>
        <div className={classes.actions}>
          {remediation.remediationGapStatus !== EnumRemediationGapStatus.Closed &&
            remediation.remediationGapId > 0 && (
              <Button
                variant="contained"
                className={
                  remediation.remediationGapStatus !== EnumRemediationGapStatus.ClientReview
                    ? classes['button-disabled-instance']
                    : classes['button-instance']
                }
                size="medium"
                state="active"
                type="primary"
                disabled={
                  remediation.remediationGapStatus !== EnumRemediationGapStatus.ClientReview
                }
                onClick={() => {
                  setOpenConfirmation(true)
                }}
              >
                {loading ? <CircularProgress color="secondary" size={24} /> : 'Accept Remediation'}
              </Button>
            )}
          {!remediation.remediationGapId && (
            <Button
              variant="contained"
              className={classes['button-instance']}
              size="medium"
              state="active"
              type="primary"
              onClick={() => {
                setOpenConfirmation(true)
              }}
            >
              {loading ? <CircularProgress color="secondary" size={24} /> : 'Initiate Remediation'}
            </Button>
          )}
        </div>
      </div>
      <Confirmation
        open={openConfirmation}
        handleAction={handleConfirmationClose}
        title={remediation.remediationGapId > 0 ? 'Accept Remediation' : 'Initiate Remediation'}
        message={
          remediation.remediationGapId > 0
            ? 'Are you sure you want to accept the Remediation'
            : 'Are you sure you want to initiate the Remediation'
        }
        primaryBtnText={remediation.remediationGapId > 0 ? 'Accept' : 'Initiate'}
        secondaryBtnText={'Cancel'}
        infoIcon
      />
    </header>
  )
}

import React from 'react'

export const NotApplicable = ({ className }) => {
  return (
    <svg
      className={`icons-7 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="20"
        id="mask0_1291_22347"
        maskUnits="userSpaceOnUse"
        width="21"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" x="0.333496" />
      </mask>
      <g className="g" mask="url(#mask0_1291_22347)">
        <path
          className="path"
          d="M10.3348 17.5832C9.29535 17.5832 8.31472 17.3858 7.39292 16.9911C6.47111 16.5964 5.66431 16.0532 4.9725 15.3617C4.28069 14.6702 3.73729 13.8637 3.34229 12.9423C2.94743 12.0209 2.75 11.0406 2.75 10.0013C2.75 8.94796 2.94736 7.96386 3.34208 7.049C3.73681 6.13414 4.27993 5.33081 4.97146 4.639C5.66299 3.9472 6.46944 3.4038 7.39083 3.0088C8.31222 2.61393 9.29257 2.4165 10.3319 2.4165C11.3852 2.4165 12.3693 2.61386 13.2842 3.00859C14.199 3.40331 15.0024 3.94643 15.6942 4.63796C16.386 5.32949 16.9294 6.13248 17.3244 7.04692C17.7192 7.96137 17.9167 8.94518 17.9167 9.99838C17.9167 11.0378 17.7193 12.0184 17.3246 12.9403C16.9299 13.8621 16.3867 14.6689 15.6952 15.3607C15.0037 16.0525 14.2007 16.5959 13.2863 16.9909C12.3718 17.3857 11.388 17.5832 10.3348 17.5832ZM10.3333 16.4998C11.129 16.4998 11.8819 16.3641 12.5921 16.0928C13.3021 15.8214 13.9428 15.4421 14.5144 14.955L5.37812 5.8188C4.89632 6.39032 4.5184 7.03109 4.24437 7.74109C3.97035 8.45123 3.83333 9.20414 3.83333 9.99984C3.83333 11.8054 4.46528 13.3401 5.72917 14.604C6.99306 15.8679 8.52778 16.4998 10.3333 16.4998ZM15.2885 14.1809C15.7756 13.6094 16.1549 12.9686 16.4263 12.2586C16.6976 11.5484 16.8333 10.7955 16.8333 9.99984C16.8333 8.19428 16.2014 6.65956 14.9375 5.39567C13.6736 4.13178 12.1389 3.49984 10.3333 3.49984C9.53569 3.49984 8.78097 3.63421 8.06917 3.90296C7.3575 4.17157 6.71854 4.55213 6.15229 5.04463L15.2885 14.1809Z"
          fill="#7F8082"
        />
      </g>
    </svg>
  )
}

import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import InfoIcon from '../../../icons/InfoIcon.svg'
import Chart from 'react-apexcharts'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

const AssessmentOverView = ({ widgetsData }) => {
  const [chartType, setChartType] = useState('month')
  const [last4YearsInitiatedData, setLast4YearsInitiatedData] = useState([null, 0, 0, 0, 0, null])
  const [last4YearsCompletedData, setLast4YearsCompletedData] = useState([null, 0, 0, 0, 0, null])
  const [initiatedMonthData, setInitiatedMonthData] = useState([])
  const [completedMonthData, setCompletedMonthData] = useState([])

  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()
  const last4Years = Array.from({ length: 4 }, (_, i) => currentYear - i).reverse()

  useEffect(() => {
    if (!!widgetsData && !!widgetsData.initiated && widgetsData.initiated.length > 0) {
      var data = []
      data.push(null)
      last4Years.forEach((element) => {
        const yearData = widgetsData.initiated.find((item) => item.year === element)
        if (!!yearData && !!yearData.monthlyData) {
          data.push(yearData.monthlyData.reduce((partialSum, a) => partialSum + a, 0))
          if (element === currentYear) {
            var monthData = []
            monthData.push(null)
            monthData = monthData.concat(yearData.monthlyData.slice(0, currentMonth))
            monthData.push(null)
            setInitiatedMonthData(monthData)
          }
        } else data.push(0)
      })
      data.push(null)
      setLast4YearsInitiatedData(data)
    }
    if (!!widgetsData && !!widgetsData.completed && widgetsData.completed.length > 0) {
      var completedData = []
      completedData.push(null)
      last4Years.forEach((element) => {
        const yearData = widgetsData.completed.find((item) => item.year === element)
        if (!!yearData && !!yearData.monthlyData) {
          completedData.push(yearData.monthlyData.reduce((partialSum, a) => partialSum + a, 0))
          if (element === currentYear) {
            var monthData = []
            monthData.push(null)
            monthData = monthData.concat(yearData.monthlyData.slice(0, currentMonth))
            monthData.push(null)
            setCompletedMonthData(monthData)
          }
        } else completedData.push(0)
      })
      completedData.push(null)
      setLast4YearsCompletedData(completedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetsData])

  const chartSeries = [
    {
      name: 'Assessments Initiated',
      data: chartType === 'month' ? initiatedMonthData : last4YearsInitiatedData,
      fill: {
        type: 'gradient',
        opacity: 0.2,
        gradient: {
          shadeIntensity: 0.1,
          opacityFrom: 0.1,
          opacityTo: 0.2,
          stops: [0, 90, 100],
          colorStops: [
            {
              offset: -0.25,
              color: 'rgba(254, 170, 50, 0)',
              opacity: 0,
            },
            {
              offset: 92.57,
              color: 'rgba(114, 76, 21, 0.24)',
              opacity: 0.24,
            },
          ],
        },
      },
    },
    {
      name: 'Assessments Completed',
      data: chartType === 'month' ? completedMonthData : last4YearsCompletedData,

      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.1,
          opacityFrom: 0.1,
          opacityTo: 0.2,
          stops: [0, 90, 100],
          colorStops: [
            {
              offset: -7.13,
              color: 'rgba(28, 58, 121, 0.24)',
              opacity: 0.24,
            },
            {
              offset: 97.03,
              color: 'rgba(58, 112, 226, 0)',
              opacity: 0,
            },
          ],
        },
      },
    },
  ]

  const chartOptions = useChartOptions(chartType)

  const handleChartType = (event, value) => {
    setChartType(event.target.value)
  }

  return (
    <Paper className={styles.section} elevation={3}>
      <Stack direction="column" spacing={2} sx={{ width: '100%', height: '100%' }}>
        <div className={styles.section1}>
          <div className={styles.dataContainer}>
            <h3 className={styles.titleData}>{`Assessment Overview `}</h3>
          </div>
          <div className={styles.iconPlaceholder}>
            <img className={styles.icons} loading="lazy" alt="" src={InfoIcon} />
          </div>
          <div className={styles.chartType}>
            <ToggleButtonGroup
              value={chartType}
              exclusive
              onChange={handleChartType}
              aria-label="text alignment"
            >
              <ToggleButton value="year" aria-label="year" className={styles.chartTypeBtn}>
                YEAR
              </ToggleButton>
              <ToggleButton value="month" aria-label="month" className={styles.chartTypeBtn}>
                MONTH
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        {initiatedMonthData && initiatedMonthData?.length > 0 && (
          <Box className={styles.chartWrapper}>
            <Chart
              options={chartOptions}
              series={chartSeries}
              type="area"
              width="100%"
              height={380}
              className={styles.apexChart}
            />
          </Box>
        )}
      </Stack>
    </Paper>
  )
}

const generateXAxisCategories = (chartType) => {
  if (chartType === 'year') {
    const currentYear = new Date().getFullYear()
    const last4YearsData = Array.from({ length: 4 }, (_, i) => currentYear - i)
    const categories = last4YearsData.reverse().map((year) => `${year}`)
    categories.unshift(null)
    return categories
  } else if (chartType === 'month') {
    const currentMonth = new Date().getMonth() + 1 // 0-indexed, so no +1
    const currentYear = new Date().getFullYear()
    const categories = [null]
    for (let i = 0; i < currentMonth; i++) {
      const month = i < 9 ? `0${i + 1}` : i + 1 // +1 to convert 0-indexed to 1-indexed month
      categories.push(`${currentYear}-${month}-01`)
    }
    return categories
  }
  return []
}

const formatter = (timestamp, index, chartType) => {
  if (!timestamp) return ''
  const date = new Date(timestamp)
  if (chartType === 'month') {
    let label = date
      .toLocaleString('en-US', { month: 'short', year: '2-digit' })
      .split(' ')
      .join('’')
    if (date.getMonth() === new Date().getMonth()) {
      return [label, '(Current Month)']
    }
    return label
  } else {
    let label = new Date(timestamp).getFullYear()
    if (date.getFullYear() === new Date().getFullYear()) {
      return [label, '(Current Year)']
    }
    return label
  }
}

function useChartOptions(chartType) {
  return {
    chart: {
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: 'dark',
      cssClass: 'c-apexcharts-tooltip',
      style: {
        fontSize: '12px',
        fontFamily: '"SF Pro Text", Helvetica',
      },
      y: {
        title: {
          formatter(seriesName) {
            return seriesName
          },
        },
      },
      x: {
        title: {
          formatter(seriesName) {
            return seriesName
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      colors: ['#FEAA32', '#3A70E2'],
      width: 2,
    },
    colors: ['#FEAA32', '#3A70E2'],
    markers: {
      size: [6, 6],
      colors: ['#FFD69C', '#B2CBFF'],
      strokeOpacity: 0.1,
    },
    xaxis: {
      type: 'category',
      tickAmount: chartType === 'month' ? new Date().getMonth() + 4 : undefined, // Ensure grid lines are evenly spaced
      categories: generateXAxisCategories(chartType),
      labels: {
        style: {
          colors: '#E8E8E8',
          fontSize: '12px',
          fontFamily: '"SF Pro Text", Helvetica',
        },
        formatter: (value, index) => {
          return formatter(value, index, chartType)
        },
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#5D5E60',
        height: 6,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#E8E8E8',
          fontSize: '12px',
          fontFamily: '"SF Pro Text", Helvetica',
        },
        formatter: (value, index) => {
          return index === 0 ? '' : value
        },
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#5D5E60',
        height: 6,
      },
    },
    grid: {
      show: true,
      borderColor: '#5D5E60',
      xaxis: {
        lines: {
          show: true,
          // offsetX: -1, // Offset the grid lines to leave out the first and last
          // position: 'back', // Ensure grid lines are behind the chart
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        bottom: 40, // Add padding on the bottom side of the grid
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      fontSize: '12px',
      fontFamily: '"SF Pro Text", Helvetica',
      inverseOrder: true,
      labels: {
        colors: ['#A2A3A4', '#A2A3A4'],
      },
      markers: {
        width: 12,
        height: 12,
        radius: 0,
        fillColors: ['#FEAA32', '#3A70E2'],
      },
    },
  }
}
export default AssessmentOverView

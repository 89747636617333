import React from 'react'
import styles from './style.module.css'
import { freeSet } from '@coreui/icons'
import moment from 'moment'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import CIcon from '@coreui/icons-react'
import { NistGapLine } from 'src/v2/icons/assessments/nist-gap-line'
import { NistLineChart } from './NistLineChart'

export const NistFramework = ({ reportData, thirdPartyName }) => {
  return (
    <div className={styles.card}>
      {!!reportData?.nistRating ? (
        <>
          <div className={styles.legend}>
            <div className={styles.item}>
              <div className={styles.span}>
                <div className={styles['div-2']} />
                <div className={styles['text-wrapper-18']}>
                  <b>
                    {' '}
                    <b>{`${thirdPartyName}`}</b>
                  </b>{' '}
                  Score
                </div>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.span}>
                <div className={styles['div-3']} />
                <div className={styles['text-wrapper-18']}>Target Score</div>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.span} style={{ gap: '2px' }}>
                <IconPlaceholder className={styles['line-6']} icon={<NistGapLine />} />
                <div className={styles['text-wrapper-18']}>
                  Gap between <b>{`${thirdPartyName}`}</b> and target score
                </div>
              </div>
            </div>
          </div>
          <div className={styles.chart}>
            <div className={styles.graph}>
              <NistLineChart nistRating={reportData?.nistRating?.sort((a, b) => a.id - b.id)} />
            </div>
            <div className={styles['div-wrapper']}>
              <div className={styles.title}>
                <div className={styles['text-wrapper-14']}>
                  {' '}
                  <b>{`${thirdPartyName}`}</b> CSF Score
                </div>
              </div>
              <div className={styles.table}>
                <div className={styles.col}>
                  {reportData?.nistRating?.map((eachCategory, index) => {
                    return (
                      <div key={index} className={styles['cell-2']}>
                        <div className={styles['text-wrapper-15']}>{eachCategory.categoryName}</div>
                      </div>
                    )
                  })}
                </div>
                <div className={styles.col}>
                  {reportData?.nistRating?.map((eachCategory, index) => {
                    return (
                      <div key={index} className={styles['cell-2']}>
                        <div className={styles['text-wrapper-16']}>
                          {parseFloat(eachCategory.score)}
                        </div>
                        {/* <div className={styles['text-wrapper-17']}>OF 5</div> */}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {!!reportData?.recentSecurityIncidents && (
        <>
          <div className={styles['rectangle-7']} />
          <div className={styles['security-incidents']}>
            <div className={styles['legends']}>
              <div className={styles['failing']}>
                <div className={styles['text-wrapper-19']}>Recent Security Incidents</div>
                <div className={styles['badge']}>
                  <div className={styles['text-wrapper-20']}>
                    {reportData?.recentSecurityIncidents?.length}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['legends-2']}>
              {reportData?.recentSecurityIncidents?.map((x) => {
                return (
                  <>
                    <div className={styles['failing-2']}>
                      <div className={styles.icon}>
                        <IconPlaceholder
                          className={styles['frame']}
                          icon={<CIcon icon={freeSet[x.icon]} size="xl" />}
                          size="thirty-two-x-32"
                        />
                      </div>
                      <div className={styles['data']}>
                        <div className={styles['text-wrapper-21']}>{x.subject}</div>
                        <p className={styles['p']}>{x.details}</p>
                        <p className={styles['p']}> {moment(x.date).format('MMM YYYY')}</p>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

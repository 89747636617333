import React, { useEffect, useRef, useState } from 'react'
import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { ClientSubmissionStatus } from 'src/utils/commonFns'
import { OverDueFlagIcon } from 'src/v2/icons/assessment-list/overdue-flag'
import { DueIcon } from 'src/v2/icons/assessment-list/due-icon'
import moment from 'moment'
import { SuccessTickIcon } from 'src/v2/icons/success-tick'
import DateDiff from 'date-diff'

export const AssessmentCard = ({ assessment, onClick, bgcolor = 'var(--collection-BG-100)' }) => {
  const cardRef = useRef(null)
  const [statusDesc, setStatusDesc] = useState('Waiting for response')
  const [statusDescClass, setStatusDescClass] = useState('text-wrapper-13')
  const [isOverDue, setIsOverDue] = useState(false)
  const [dueDate, setDueDate] = useState(false)

  useEffect(() => {
    switch (assessment.status) {
      case ClientSubmissionStatus.Draft:
        setStatusDesc('Drafted')
        setStatusDescClass('text-wrapper-13')
        break
      case ClientSubmissionStatus.Cancelled:
        setStatusDesc('Cancelled')
        setStatusDescClass('text-wrapper-13')
        break
      case ClientSubmissionStatus.Accepted:
      case ClientSubmissionStatus.Completed:
        setRiskStatus()
        break
      default:
        setStatusDesc('Waiting for response')
        setStatusDescClass('text-wrapper-13')
        break
    }

    if (assessment.expiryDate) {
      if (new Date(assessment.expiryDate) < new Date()) {
        setIsOverDue(true)
      } else {
        var dateDiff = new DateDiff(new Date(assessment.expiryDate), new Date()).days()
        if (dateDiff <= 7) setDueDate(dateDiff)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment.status, assessment.expiryDate])

  const setRiskStatus = () => {
    setStatusDesc(assessment.residualRisk ?? assessment.riskRating)
    switch ((assessment.residualRisk ?? assessment.riskRating).toLowerCase()) {
      case 'low':
        setStatusDescClass('text-wrapper-green')
        break
      case 'medium':
        setStatusDescClass('text-wrapper-yellow')
        break
      case 'high':
        setStatusDescClass('text-wrapper-red')
        break
      default:
        setStatusDescClass('text-wrapper-13')
        break
    }
  }

  return (
    <div
      className={styles['assessment-card']}
      id={assessment.id}
      onClick={() => {
        onClick(assessment.id)
      }}
      ref={cardRef}
      style={{ backgroundColor: bgcolor }}
      data-assessment="1"
    >
      {assessment.logoUrl && (
        <img className={styles['img']} alt="Avatar" src={assessment.logoUrl} />
      )}
      {/* <img
        className={styles['img']}
        alt="Avatar"
        src="https://c.animaapp.com/kSrGV7fQ/img/avatar-1.svg"
      /> */}
      <div className={styles['content-2']}>
        <div className={styles['level-2']}>
          <div className={styles['title-3']}>
            <div className={styles['left-2']}>
              <div className={styles['text-wrapper-10']}>{assessment.thirdPartyName}</div>
              {isOverDue && (
                <IconPlaceholder
                  icon={<OverDueFlagIcon className={styles['overdue-icon']} />}
                  size="sixteen-x-16"
                />
              )}
            </div>
            <div className={styles['date']}>
              <div className={styles['text-wrapper-11']}>
                {assessment.draftedDate && !assessment.requestedDate
                  ? moment(assessment?.draftedDate).format('MMM DD')
                  : ''}
                {assessment.requestedDate ? moment(assessment?.requestedDate).format('MMM DD') : ''}
              </div>
            </div>
          </div>
          <div className={styles['level-3']}>
            <div className={styles['text-wrapper-12']}>{assessment.clientSubmissionNo}</div>
            <div className={styles['divider']} />
            <div
              className={
                assessment.status === ClientSubmissionStatus.Completed
                  ? styles['pending-approval']
                  : styles['text-wrapper-12']
              }
              style={{}}
            >
              {assessment.status === ClientSubmissionStatus.Completed
                ? 'Pending Approval'
                : assessment.displayStatus}
            </div>
          </div>
        </div>
        <div className={styles['level-4']}>
          <p className={styles[statusDescClass]}>{statusDesc}</p>
          <div className={styles['tag']}>
            {isOverDue ? (
              <>
                {' '}
                <IconPlaceholder
                  icon={<DueIcon className={styles['status-icon']} />}
                  size="sixteen-x-16"
                />
                <div className={styles['overdue']}>OVERDUE</div>
              </>
            ) : !!dueDate ? (
              <>
                {' '}
                <IconPlaceholder
                  icon={<DueIcon className={styles['status-icon']} />}
                  size="sixteen-x-16"
                />
                <div className={styles['overdue']}>DUE IN {dueDate} Days</div>
              </>
            ) : (
              // ) : assessment.status === ClientSubmissionStatus.Completed ? (
              //   <>
              //     {' '}
              //     <IconPlaceholder
              //       icon={
              //         <InfoIcon
              //           className={styles['status-icon']}
              //           size={18}
              //           color="var(--warning-color-2-WC-l-150)"
              //         />
              //       }
              //       size="sixteen-x-16"
              //     />
              //     <div className={styles['completed']}>{assessment.displayStatus}</div>
              //   </>
              // ) :
              assessment.status === ClientSubmissionStatus.Accepted && (
                <>
                  {' '}
                  <IconPlaceholder
                    icon={<SuccessTickIcon className={styles['status-icon']} size={18} />}
                    size="sixteen-x-16"
                  />
                  <div className={styles['accepted']}>{assessment.displayStatus}</div>
                </>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

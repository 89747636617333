import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import { EASurface } from './ea-surface'
import { Vulnerabilities } from './vulnerabilities'
import { ExternalAssets } from './external-assets'
import { RiskProfileList } from './risk-profile-list'
import { getRiskProfileStats } from 'src/services/cyberScanService'

export const AttackSurfaceRiskProfile = ({ assessmentInfo }) => {
  const [riskProfileStats, setRiskProfielStats] = useState({})

  useEffect(() => {
    const loadRiskProfileStats = async () => {
      const data = await getRiskProfileStats(assessmentInfo.coalitionEntityId)
      setRiskProfielStats(data)
    }
    loadRiskProfileStats()
  }, [assessmentInfo.coalitionEntityId])

  return (
    <div className={styles.report}>
      <div className={styles['section-4']}>
        <div className={styles.row}>
          <EASurface value={riskProfileStats.riskScore} />
          <Vulnerabilities
            critical={riskProfileStats.critical || 0}
            high={riskProfileStats.high || 0}
            medium={riskProfileStats.medium || 0}
            low={riskProfileStats.low || 0}
          />
          <ExternalAssets
            impactedDomins={riskProfileStats.impactedDomins}
            totalDomains={riskProfileStats.totalDomains}
            impactedIPAddresses={riskProfileStats.impactedIPAddresses}
            totalIPAddresses={riskProfileStats.totalIPAddresses}
          />
        </div>
        <RiskProfileList assessmentInfo={assessmentInfo} />
      </div>
    </div>
  )
}

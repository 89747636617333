import React from 'react'

export const SearchIcon = ({ className }) => {
  return (
    <svg
      className={`icons-19 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="20"
        id="mask0_480_300"
        maskUnits="userSpaceOnUse"
        width="20"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" />
      </mask>
      <g className="g" mask="url(#mask0_480_300)">
        <path
          className="path"
          d="M7.93271 13.0127C6.50965 13.0127 5.30451 12.5192 4.31729 11.5321C3.33021 10.5449 2.83667 9.33972 2.83667 7.91667C2.83667 6.49361 3.33021 5.28847 4.31729 4.30125C5.30451 3.31417 6.50965 2.82063 7.93271 2.82063C9.35576 2.82063 10.5609 3.31417 11.5481 4.30125C12.5352 5.28847 13.0287 6.49361 13.0287 7.91667C13.0287 8.51181 12.9289 9.08021 12.7292 9.62188C12.5293 10.1635 12.2627 10.6347 11.9294 11.0352L16.7244 15.8302C16.8398 15.9455 16.8988 16.0905 16.9015 16.2652C16.9041 16.4399 16.8451 16.5876 16.7244 16.7083C16.6037 16.829 16.4573 16.8894 16.2852 16.8894C16.1133 16.8894 15.9669 16.829 15.8462 16.7083L11.0512 11.9133C10.6346 12.2574 10.1554 12.5266 9.61375 12.721C9.07208 12.9155 8.51173 13.0127 7.93271 13.0127ZM7.93271 11.7629C9.00646 11.7629 9.9159 11.3903 10.661 10.645C11.4063 9.89986 11.779 8.99042 11.779 7.91667C11.779 6.84292 11.4063 5.93347 10.661 5.18833C9.9159 4.44306 9.00646 4.07042 7.93271 4.07042C6.85896 4.07042 5.94951 4.44306 5.20437 5.18833C4.4591 5.93347 4.08646 6.84292 4.08646 7.91667C4.08646 8.99042 4.4591 9.89986 5.20437 10.645C5.94951 11.3903 6.85896 11.7629 7.93271 11.7629Z"
          fill="#A2A3A4"
        />
      </g>
    </svg>
  )
}

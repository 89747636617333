import React from 'react'
import styles from './style.module.css'
import { MetricCard } from 'src/v2/components/MetricCard'
import { getColorCodeForRisks } from 'src/utils/commonFns'
import { PrivacyDoughnutChart } from './PrivacyDoughnutChart'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { SuccessTickIcon } from 'src/v2/icons/success-tick'
import { FailedTickIcon } from 'src/v2/icons/failed-tick'
import { NotApplicable } from 'src/v2/icons/not-applicable'

export const PrivacyImpact = ({ reportData }) => {
  const applyColorSchemeForRiskBoxes = (value) => {
    const result = getColorCodeForRisks(value)
    if (result === 'green') return 'success'
    else if (result === 'amber') return 'warning'
    else if (result === 'red') return 'error'
    else if (result === 'yellow') return 'mostlyeffective'
  }
  return (
    <div className={styles['card-screen']}>
      <div className={styles.card}>
        <div className={styles.metrics}>
          <MetricCard
            color={`${applyColorSchemeForRiskBoxes(reportData?.assessmentRisk?.inherentRisk)}`}
            title={reportData?.privacyImpactAssessment?.inherentRisk?.toUpperCase()}
            caption="INHERENT RISK"
            height={'100px'}
          ></MetricCard>
          <div className={styles.divider} />
          <MetricCard
            color={`${applyColorSchemeForRiskBoxes(
              reportData?.privacyImpactAssessment?.controlEffectiveness,
            )}`}
            title={reportData?.privacyImpactAssessment?.controlEffectiveness?.toUpperCase()}
            caption="CONTROL EFFECTIVENESS"
            height={'100px'}
          ></MetricCard>
          <div className={styles.divider} />
          <MetricCard
            color={`${applyColorSchemeForRiskBoxes(reportData?.assessmentRisk?.residualRisk)}`}
            title={reportData?.privacyImpactAssessment?.residualRisk?.toUpperCase()}
            caption="RESIDUAL RISK"
            height={'100px'}
          ></MetricCard>
        </div>
      </div>
      <div className={styles.rectangle} />
      <div className={styles['chart-1']}>
        <div className={styles['chart-wrapper']}>
          <div className={styles.chart}>
            <div className={styles['half-doughnut-chart']}>
              <PrivacyDoughnutChart
                privacyScore={reportData.privacyImpactAssessment.privacyScore.score}
                testedControls={reportData.privacyImpactAssessment.privacyScore.testedControls}
                criticalControls={reportData.privacyImpactAssessment.privacyScore.criticalControls}
              />
            </div>
            <div className={styles.legends}>
              <div className={styles['legends-2']}>
                <div className={styles.test}>
                  <div className={styles['text-wrapper-6']}>Failed Controls</div>
                </div>
                <div className={styles.num}>
                  <div className={styles['text-wrapper-7']}>
                    {reportData.privacyImpactAssessment.privacyScore.failedControls}
                  </div>
                </div>
              </div>
              <div className={styles.divider} />
              <div className={styles['legends-2']}>
                <div className={styles.test}>
                  <div className={styles['text-wrapper-6']}>Control Critical</div>
                </div>
                <div className={styles.num}>
                  <div className={styles['text-wrapper-7']}>
                    {reportData.privacyImpactAssessment.privacyScore.criticalControls}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              <div className={styles['text-wrapper-8']}>Data Elements Involved</div>
            </div>
            <div className={styles.table}>
              <div className={styles.col}>
                {reportData.privacyImpactAssessment.dataElements?.map((eachDataElement, index) => {
                  return (
                    <div key={index} className={styles['cell-2']}>
                      <div className={styles['text-wrapper-9']}>{eachDataElement}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rectangle} />
      <div className={styles['section-1']}>
        <div className={styles.frames}>
          <div className={styles['text-wrapper-35']}>Controls Tested</div>
          <div className={styles['legends-3']}>
            <div className={styles['row-10']}>
              <IconPlaceholder
                className={styles['icon-placeholder-instance']}
                icon={<SuccessTickIcon className={styles['icon-instance-node']} />}
                size="twenty-x-20"
              />
              <div className={styles['text-wrapper-36']}>Control Available</div>
            </div>
            <div className={styles['divider-2']} />
            <div className={styles['row-10']}>
              <IconPlaceholder
                className={styles['icon-placeholder-instance']}
                icon={<FailedTickIcon className={styles['icon-instance-node']} />}
                size="twenty-x-20"
              />
              <div className={styles['text-wrapper-36']}>Control Failed</div>
            </div>
            <div className={styles['divider-2']} />
            <div className={styles['row-10']}>
              <IconPlaceholder
                icon={<NotApplicable className={styles['icon-instance-node']} />}
                size="twenty-x-20"
              />
              <div className={styles['text-wrapper-37']}>Not Applicable</div>
            </div>
          </div>
        </div>
        <div className={styles['section-wrapper']}>
          <div className={styles.content}>
            {reportData.privacyImpactAssessment.privacyQuestionnaire?.map((eachQuestion, index) => {
              if (index < reportData.privacyImpactAssessment.privacyQuestionnaire.length / 2)
                return (
                  <div key={index} className={styles.row}>
                    <div className={styles.question}>
                      <div className={styles['text-icon']}>
                        <IconPlaceholder
                          icon={
                            eachQuestion.answer === 'Y' ? (
                              <SuccessTickIcon className={styles['icons-6']} />
                            ) : eachQuestion.answer === 'N' ? (
                              <FailedTickIcon className={styles['icons-6']} />
                            ) : (
                              <NotApplicable className={styles['icons-6']} />
                            )
                          }
                          size="thirty-two-x-32"
                        />
                        <div className={styles.text}>
                          <p className={styles['documented-privacy']}>
                            {eachQuestion.questionName}
                          </p>
                          {/* <div className={styles['text-wrapper-10']}>
                            {' '}
                            {eachQuestion.answer === 'Y'
                              ? 'Yes'
                              : eachQuestion.answer === 'N'
                              ? 'No'
                              : 'Not Applicable'}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              else return <></>
            })}
          </div>
          <div className={styles['content-wrapper']}>
            {reportData.privacyImpactAssessment.privacyQuestionnaire?.map((eachQuestion, index) => {
              if (
                index >= reportData.privacyImpactAssessment.privacyQuestionnaire.length / 2 &&
                index < reportData.privacyImpactAssessment.privacyQuestionnaire.length
              )
                return (
                  <div key={index} className={styles.row}>
                    <div className={styles.question}>
                      <div className={styles['text-icon']}>
                        <IconPlaceholder
                          icon={
                            eachQuestion.answer === 'Y' ? (
                              <SuccessTickIcon className={styles['icons-6']} />
                            ) : eachQuestion.answer === 'N' ? (
                              <FailedTickIcon className={styles['icons-6']} />
                            ) : (
                              <NotApplicable className={styles['icons-6']} />
                            )
                          }
                          size="thirty-two-x-32"
                        />
                        <div className={styles.text}>
                          <p className={styles['documented-privacy']}>
                            {eachQuestion.questionName}
                          </p>
                          {/* <div className={styles['text-wrapper-10']}>
                            {' '}
                            {eachQuestion.answer === 'Y'
                              ? 'Yes'
                              : eachQuestion.answer === 'N'
                              ? 'No'
                              : 'Not Applicable'}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              else return <></>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

import PropTypes from 'prop-types'
import React from 'react'
import styles from './style.module.css'

export const IconPlaceholder = ({ size, className, icon, onClick, style }) => {
  return (
    <div
      className={`${styles['icon-placeholder']} ${size} ${className}`}
      onClick={onClick}
      style={style}
    >
      {icon}
    </div>
  )
}

IconPlaceholder.propTypes = {
  size: PropTypes.oneOf([
    'twenty-x-20',
    'twenty-four-x-24',
    'twenty-eight-x-28',
    'thirty-two-x-32',
  ]),
}

import React, { useState } from 'react'
import styles from './style.module.css'
import { Card } from '@mui/material'
import { getColorCodeForRisks, capitalizeFirstLetter } from 'src/utils/commonFns'
import { MetricCard } from 'src/v2/components/MetricCard'
import ImgSatisfactory from 'src/assets/risk-icons/satisfactory.png'
import ImgUnsatisfactory from 'src/assets/risk-icons/unsatisfactory.png'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { RightArrow } from 'src/v2/icons/right-arrow'
import { RatingSummaryDetails } from './Details'

export const RatingSummary = ({
  reportData,
  thirdPartyName,
  businessOrgName,
  questionaireData,
}) => {
  const [openDetails, setOpenDetails] = useState(false)
  const applyColorSchemeForRiskBoxes = (value) => {
    const result = getColorCodeForRisks(value)
    if (result === 'green') return 'success'
    else if (result === 'amber') return 'warning'
    else if (result === 'red') return 'error'
    else if (result === 'yellow') return 'mostlyeffective'
  }
  // reportData.dataTypeInvloved = '1'
  return (
    <>
      <Card className={styles.section}>
        <div className={styles.title}>
          <div className={styles.div}>
            <div className={styles['text-wrapper']}>Rating Summary</div>
            <div
              className={`menu-links ${styles.button}`}
              onClick={() => {
                setOpenDetails(true)
              }}
            >
              <div className={`${styles['view-details-instance']}`}>{'View Detailed Summary'}</div>
              <IconPlaceholder
                icon={
                  <RightArrow
                    className={styles['icons-7']}
                    color="var(--info-color-IC-l-300)"
                    size="20"
                  />
                }
                size="twenty-x-20"
                style={{ marginBottom: '-4px' }}
              />
            </div>
          </div>
          {!!reportData?.assessmentRisk?.riskControl && (
            <div className={styles['div-2']}>
              <p className={styles.p}>
                <img
                  alt={'risk control'}
                  src={
                    reportData.assessmentRisk?.riskControl?.toLowerCase() === 'satisfactory'
                      ? ImgSatisfactory
                      : ImgUnsatisfactory
                  }
                  style={{ marginRight: 4, marginBottom: 3 }}
                  width={14}
                  height={14}
                />
                <b>{`${capitalizeFirstLetter(thirdPartyName.trim())} `}</b> has{' '}
                {`"${capitalizeFirstLetter(reportData.assessmentRisk?.controlEffectiveness)}"`}{' '}
                controls and {`"${capitalizeFirstLetter(reportData.assessmentRisk?.residualRisk)}"`}{' '}
                residual risk making them {`"${reportData.assessmentRisk?.riskControl}"`} to work
                with Client.
              </p>
            </div>
          )}
        </div>
        <div className={styles.card}>
          <div className={styles.metrics}>
            <MetricCard
              color={`${applyColorSchemeForRiskBoxes(reportData?.assessmentRisk?.inherentRisk)}`}
              title={reportData?.assessmentRisk?.inherentRisk?.toUpperCase()}
              caption="INHERENT RISK"
              height={'100px'}
            ></MetricCard>
            <div className={styles.divider} />
            <MetricCard
              color={`${applyColorSchemeForRiskBoxes(
                reportData?.assessmentRisk?.controlEffectiveness,
              )}`}
              title={reportData?.assessmentRisk?.controlEffectiveness?.toUpperCase()}
              caption="CONTROL EFFECTIVENESS"
              height={'100px'}
            ></MetricCard>
            <div className={styles.divider} />
            <MetricCard
              color={`${applyColorSchemeForRiskBoxes(reportData?.assessmentRisk?.residualRisk)}`}
              title={reportData?.assessmentRisk?.residualRisk?.toUpperCase()}
              caption="RESIDUAL RISK"
              height={'100px'}
            ></MetricCard>
          </div>
        </div>
        {openDetails && (
          <RatingSummaryDetails
            open={openDetails}
            handleAction={setOpenDetails}
            reportData={reportData}
            questionaireData={questionaireData}
          />
        )}
      </Card>
    </>
  )
}

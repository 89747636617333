import { Stack } from '@mui/material'
import React from 'react'
import { AnalyticsHeader } from './sections/header/AnalyticsHeader'
import { AnalyticsFilter } from './sections/filter/AnalyticsFilter'
import styles from './style.module.scss'
import AnalyticsContent from './sections/content/AnalyticsContent'

const Analytics = () => {
  return (
    <Stack spacing={2} className={styles.container}>
      <AnalyticsHeader />
      <AnalyticsFilter />
      <AnalyticsContent />
    </Stack>
  )
}

export default Analytics
